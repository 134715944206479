<template>
  <div class="speed">
    <div class="top" style="padding-top: 10px;">
      <li>
        <el-select v-model="sousuodianzhan" placeholder="请选择电站" @change="change_vale" filterable>
          <el-option v-for="item in stationlist" :key="item.id" :label="item.stationName" :value="item.id">
          </el-option>
        </el-select>
      </li>
      <li>
        <el-select v-model="times" placeholder="请选择时间类型" filterable @changes="changeType">
          <el-option v-for="item in timeList" :key="item" :label="item == 0 ? '今日' : item == 1 ? '本月' : item == 2 ? '本年' : '营业至今'"
            :value="item">
          </el-option>
        </el-select>
      </li>
      <li>
        <el-date-picker v-model="picker_value2" clearable :picker-options="pickerOptions" type="daterange" align="right"
          range-separator="--" value-format="yyyy-MM-dd" start-placeholder="开始日期" @blur="pickBlur"
          end-placeholder="结束日期" @change="picker_event">
        </el-date-picker>
      </li>
    </div>
    <div class="center">
      <div class="center1">
        <ul>
          <li><span>总收入</span><span>（{{ times == 0 ? '本日' : times == 1 ? '本月' : times == 2 ? '本年' : '营业至今' }}）</span></li>
          <li>
            <span v-if="type" :style="{'align-items': times>2?'center':'','paddingTop':times>2?'10px':'0'}">{{ times == 0 ? jisuan(listobj.todayBillMoney, 100, 2) : times == 1 ?
          jisuan(listobj.currentMonthBillMoney, 100, 2) : times == 2 ? jisuan(listobj.currentYearBillMoney, 100,
            2) : jisuan(listobj.allTotalBillMoney, 100, 2)
              }}
            </span>
            <span v-else>{{ jisuan(listobj.allTotalBillMoney, 100, 2) }}</span>
            <span>（元）</span>
          </li>
          <li v-if="times !== 3 && type"><span>{{ times == 0 ? '昨日' : times == 1 ? '上月' : '去年'
              }}：</span><span>{{ times == 0 ? jisuan(listobj.yesterDayBillMoney, 100, 2) : times == 1 ?
          jisuan(listobj.lastMonthBillMoney, 100, 2) : jisuan(listobj.lastYearBillMoney, 100, 2)
              }}（元）</span></li>
          <li v-if="times !== 3 && type"><span>环比{{ times == 0 ? '昨日' : times == 1 ? '上月' : '去年'
              }}：</span>
              <span>{{
          times == 0 ? sums(listobj.todayBillMoney, listobj.yesterDayBillMoney) : times == 1 ?
            sums(listobj.currentMonthBillMoney, listobj.lastMonthBillMoney) : sums(listobj.currentYearBillMoney,
              listobj.lastYearBillMoney) }}%
              </span>
          </li>
        </ul>
        <ul>
          <li><span>充电电量</span><span>（{{ times == 0 ? '本日' : times == 1 ? '本月' : times == 2 ? '本年' : '营业至今' }}）</span></li>
          <li>
            <span v-if="type" :style="{'align-items': times>2?'center':'','paddingTop':times>2?'10px':'0'}">{{ times == 0 ? listobj.todayKwhs : times == 1 ? listobj.currentMonthKwhs : times == 2
          ?
          listobj.currentYearKwhs : listobj.allTotalKwhs
              }}</span>
            <span v-else>{{ jisuan(listobj.allTotalKwhs, 100, 2) }}</span>
            <span>（度）</span>
          </li>
          <li v-if="times !== 3 && type"><span>{{ times == 0 ? '昨日' : times == 1 ? '上月' : '去年'
              }}：</span><span>{{ times == 0 ? jisuan(listobj.yesterDayKwhs, 100, 2) : times == 1 ?
          jisuan(listobj.lastMonthKwhs, 100, 2) : jisuan(listobj.lastYearKwhs, 100, 2)
              }}（度）</span></li>
          <li v-if="times !== 3 && type"><span>环比{{ times == 0 ? '昨日' : times == 1 ? '上月' : '去年'
              }}：</span><span>{{ times == 0 ? sums(listobj.todayKwhs, listobj.yesterDayKwhs) : times == 1 ?
          sums(listobj.currentMonthKwhs, listobj.lastMonthKwhs) : sums(listobj.currentYearKwhs,
            listobj.lastYearKwhs) }}%</span></li>
        </ul>
        <ul>
          <li><span>用户数</span><span>（{{ times == 0 ? '本日' : times == 1 ? '本月' : times == 2 ? '本年' : '营业至今' }}）</span></li>
          <li>
            <span v-if="type" :style="{'align-items': times>2?'center':'','paddingTop':times>2?'10px':'0'}">{{ times == 0 ? listobj.todayUserCount : times == 1 ? listobj.currentMonthUserCount :
          times ==
            2 ? listobj.currentYearUserCount : listobj.allTotalUserCount
              }}</span>
            <span v-else>{{ listobj.allTotalUserCount }}</span>
            <span>（个）</span>
          </li>
          <li v-if="times !== 3 && type"><span>{{ times == 0 ? '昨日' : times == 1 ? '上月' : '去年'
              }}：</span><span>{{ times == 0 ? listobj.yesterDayUserCount : times == 1 ? listobj.lastMonthUserCount
          : listobj.lastYearUserCount
              }}（个）</span></li>
          <li v-if="times !== 3 && type"><span>环比{{ times == 0 ? '昨日' : times == 1 ? '上月' : '去年'
              }}：</span><span>{{
          times == 0 ? sums(listobj.todayUserCount, listobj.yesterDayUserCount) : times == 1 ? sums(
            listobj.currentMonthUserCount, listobj.lastMonthUserCount) :
            sums(listobj.currentYearUserCount, listobj.lastYearUserCount) }}%</span>
          </li>
        </ul>
        <ul>
          <li><span>订单数量</span><span>（{{ times == 0 ? '本日' : times == 1 ? '本月' : times == 2 ? '本年' : '营业至今' }}）</span></li>
          <li>
            <span v-if="type" :style="{'align-items': times>2?'center':'','paddingTop':times>2?'10px':'0'}">{{ times == 0 ? listobj.todayBillCount : times == 1 ? listobj.currentMonthBillCount :
          times ==
            2 ? listobj.currentYearBillCount : listobj.allTotalBillCount
              }}</span>
            <span v-else>{{ listobj.allTotalBillCount }}</span>
            <span>（条）</span>
          </li>
          <li v-if="times !== 3 && type"><span>{{ times == 0 ? '昨日' : times == 1 ? '上月' : '去年'
              }}：</span><span>{{ times == 0 ? listobj.yesterDayBillCount : times == 1 ? listobj.lastMonthBillCount
          : listobj.lastYearBillCount
              }}（条）</span></li>
          <li v-if="times !== 3 && type"><span>环比{{ times == 0 ? '昨日' : times == 1 ? '上月' : '去年'
              }}：</span><span>{{
          times == 0 ? sums(listobj.todayBillCount, listobj.yesterDayBillCount) : times == 1 ?
            sums(listobj.currentMonthBillCount, listobj.lastMonthBillCount) :
            sums(listobj.currentYearBillCount, listobj.lastYearBillCount) }}%</span>
          </li>
        </ul>
      </div>
      <div class="center2">
        <ul>
          <li><span>低速桩</span><span>（{{ listobj.totalPileCount }}台）</span></li>
          <li><span>在线：</span><span>{{ listobj.onLinePileCount }}（台）</span></li>
          <li><span>故障：</span><span>{{ listobj.faultPileCount }}（台）</span></li>
          <li><span>离线：</span><span>{{ listobj.offLinePileCount }}（台）</span></li>
        </ul>
        <ol>
          <h4>
            <span>插座数</span><span>（{{ listobj.totalSocketPileCount }}个）</span>
          </h4>
          <div class="ceil">
            <li><span>充电中</span><span>{{ listobj.chargePileCount }}</span></li>
            <li><span>空闲中</span><span>{{ listobj.freePileCount }}</span></li>
            <li><span>故障中</span><span>{{ listobj.faultSocketPileCount}}</span></li>
          </div>
        </ol>
      </div>
    </div>
    <div class="bottom">
      <div id="main"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  mixins: [],
  components: {

  },
  props: {

  },
  data() {
    return {
      type: true,
      stationlist: [],
      listobj: {},
      picker_value2: ['', ''],
      sousuodianzhan: '',
      times: 0,
      timeList: [0, 1, 2, 3],
      queryInfo: {
        roleId: window.sessionStorage.getItem('roleId'),
        stationIds: window.sessionStorage.getItem('roleId') == 1 ? 0 : window.sessionStorage.getItem('stationIds'),
        startTime: null,
        endTime: null
      },
      pickerMinDate: '',
      pickerOptions: {
        onPick: obj => {
          this.pickerMinDate = new Date(obj.minDate).getTime()
          const start = this.formatDate(obj.minDate, 'start')
          const end = this.formatDate(obj.maxDate, 'end')
          obj.maxDate && (this.values = [start, end])
        },
        disabledDate: time => {
          if (this.pickerMinDate) {
            const oneMonth = 1000 * 3600 * 24 * 31
            const maxTime = this.pickerMinDate + oneMonth
            const mixTime = this.pickerMinDate - oneMonth + 1000 * 3600 * 24
            const tomorrow = new Date(this.formatDate(new Date().getTime() + 1000 * 3600 * 24, 'start'))
            return time.getTime() >= tomorrow || time.getTime() >= maxTime || time.getTime() < mixTime
          }
        }
      }
    }
  },
  computed: {
    jisuan() {
      return (obj, num, fixed) => {
        if (obj == undefined || obj == null) return 0;
        return (obj / num).toFixed(fixed)
      }
    },
    sums() {
      return (obj, num) => {
        if (obj == null || obj == undefined || num == null || num == undefined) return 0;
        if (obj == 0) return 0;
        if (num == 0) return 100;
        return ((obj / (obj * 1 + num * 1)) * 100).toFixed(2)
      }
    }
  },
  watch: {

  },
  created() {
    this.stationlist.push({
      id: window.sessionStorage.getItem('stationIds'),
      stationName: '全部电站'
    });
    this.$http.get('station-info/getAllStationInfoName', {
      params: {
        roleId: window.sessionStorage.getItem('roleId'),
        stationIds: window.sessionStorage.getItem('stationIds'),
        stationMode: '1'
      }
    }).then(res => {
      res.data.data.forEach(item => {
        this.stationlist.push({
          id: item.id,
          stationName: item.stationName
        })
      })
    });
    this.defaultMethods();
  },
  methods: {
    changeType() {
      this.type = true;
      this.picker_value2 = ['', ''];
      this.queryInfo.startTime = '';
      this.queryInfo.endTime = '';
      this.defaultMethods()
    },
    defaultMethods() {
      const that = this;
      that.listobj = {};
      that.$http.get('getAllLowAnalysis', {
        params: that.queryInfo
      }).then(res => {
        if (res.data.code !== 0) return;
        that.listobj = res.data.data;
      })
    },
    formatDate(datetime, type) {
      const time = new Date(datetime)
      const year = time.getFullYear()
      const month = (time.getMonth() + 1).toString().padStart(2, '0')
      const date = (time.getDate()).toString().padStart(2, '0')
      return type === 'start' ? year + '-' + month + '-' + date + ' ' + '00' + ':' + '00' + ':' + '00' : year + '-' + month + '-' + date + ' ' + '23' + ':' + '59' + ':' + '59'
    },
    pickBlur() {
      this.pickerMinDate = ''
    },
    // 切换时间
    picker_event(e) {
      if (e !== null) {
        this.queryInfo.startTime = e[0];
        this.queryInfo.endTime = e[1];
        this.type = false;
        this.defaultMethods()
      } else {
        this.queryInfo.startTime = null;
        this.queryInfo.endTime = null;
        this.type = true;
        this.defaultMethods()
      }
      // 折柱混合图
    },
    // 更换场站
    change_vale() {
      this.queryInfo.stationIds = this.sousuodianzhan;
      this.picker_value2 = ['', ''];
      this.queryInfo.startTime = '';
      this.queryInfo.endTime = '';
      this.type = true;
      this.defaultMethods()
    }
  }
};
</script>

<style lang='less' scoped>
.speed {
  width: 99%;
  height: 100%;
  background: #ccc;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .top {
    height: 40px;
    display: flex;
    flex-direction: row;

    li:nth-child(2) {
      margin: 0 20px;
    }
  }

  .center {
    height: 40%;
    margin: 10px 0;
    display: flex;
    flex-direction: column;

    .center1,
    .center2 {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .center1 {
      margin-bottom: 20px;

      ul {
        flex: 1;
        background: #fff;
        display: flex;
        border-radius: 15px;
        padding: 10px;
        flex-direction: column;
        margin-right: 10%;
        font-weight: bolder;
        font-size: 17px;

        li {
          flex: 1;
          display: flex;
          flex-direction: row;

          span {
            display: flex;
            align-items: center;
          }
        }

        li:nth-child(1) {
          span:nth-child(1) {
            font-size: 24px;
          }

          span:nth-child(2) {
            font-size: 20px;
          }
        }

        li:nth-child(2) {

          span:nth-child(1) {
            font-size: 24px;
            display: flex;
            align-items: flex-end
          }

          span:nth-child(2) {
            font-size: 22px;
          }
        }

        li:nth-child(4)>span:nth-child(2) {
          color: red;
        }
      }

      ul:nth-child(3)>li:nth-child(4)>span:nth-child(2) {
        color: green;
      }

      ul:last-child {
        margin-right: 0;
      }
    }

    .center2 {
      display: flex;
      flex-direction: row;

      ul {
        width: 16.5%;
        display: flex;
        flex-direction: column;
        background: #fff;
        border-radius: 15px;
        padding: 10px;
        font-weight: bolder;
        font-size: 20px;

        li {
          flex: 1;
          display: flex;
          flex-direction: row;

          span {
            display: flex;
            align-items: center;
          }
        }

        li:nth-child(1) {
          span:nth-child(1) {
            font-size: 24px;
          }

          span:nth-child(2) {
            font-size: 20px;
          }
        }

        li:nth-child(2)>span:nth-child(2) {
          color: green;
        }

        li:nth-child(3)>span:nth-child(2) {
          color: red;
        }

        li:nth-child(4)>span:nth-child(2) {
          color: gray;
        }
      }

      ol {
        // flex: 6.2;
        width: 71.5%;
        display: flex;
        flex-direction: column;
        background: #fff;
        border-radius: 15px;
        padding: 0 10px;

        h4 {
          flex: 1;
          font-size: 24px;
          margin-top: 15px;
        }

        .ceil {
          flex: 9;
          display: flex;
          flex-direction: row;

          li {
            flex: 1;
            display: flex;
            flex-direction: column;
            font-size: 20px;
            font-weight: bolder;

            span {
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            span:nth-child(1) {
              font-size: 20px;
            }
          }

          li:nth-child(1)>span:nth-child(2) {
            color: green;
          }

          li:nth-child(2)>span:nth-child(2) {
            color: blue;
          }

          li:nth-child(3)>span:nth-child(2) {
            color: red;
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    margin: 20px 0;
    background: #fff;
    height: calc(100% - 40% - 40px - 20px - 20px)
  }
}
</style>
