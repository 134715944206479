<template>
  <div class="EquipmentAnalysis">
    <div class="top" style="padding-top: 10px;">
      <li>
        <el-select v-model="sousuodianzhan" placeholder="请选择电站" @change="change_vale" filterable>
          <el-option v-for="item in stationlist" :key="item.id" :label="item.stationName" :value="item.id">
          </el-option>
        </el-select>
      </li>
      <li>
        <el-select v-model="times" placeholder="请选择时间类型" filterable @change="changeType">
          <el-option v-for="item in timeList" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
      </li>
      <li>
        <el-date-picker v-model="picker_value2" clearable :picker-options="pickerOptions" type="daterange" align="right"
          range-separator="--" value-format="yyyy-MM-dd" start-placeholder="开始日期" @blur="pickBlur"
          end-placeholder="结束日期" @change="picker_event">
        </el-date-picker>
      </li>
    </div>
    <div class="center">
      <div class="center1">

      </div>
      <div class="center1">

      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  name: 'EquipmentAnalysis',
  mixins: [],
  components: {

  },
  props: {

  },
  data() {
    return {
      time1:[],
      time2:[],
      time3:[],
      time4:[],
      topX: [],
      topY: [],
      lablelTop:['总电量', '总直流电量', '总交流电量'],
      bottomX: [],
      bottomY: [],
      carInfo: {
        roleId: window.sessionStorage.getItem('roleId'),
        stationIds: window.sessionStorage.getItem('stationIds'),
        type: 0,
        days: 7,
        startTime: '',
        endTime: ''
      },
      maxTop:0,
      activeIndex: 0,
      currentIndex: 0,
      type: true,
      stationlist: [],
      elerticobjs: [],
      cdobj: [],
      picker_value2: ['', ''],
      sousuodianzhan: '',
      times: '近7天',
      timeList: ['近7天', '近30天', '近12个月'],
      queryInfo: {
        roleId: window.sessionStorage.getItem('roleId'),
        stationIds: window.sessionStorage.getItem('roleId') == 1 ? '0' : window.sessionStorage.getItem('stationIds'),
        startTime: '',
        endTime: '',
        type: 0,
        days: 7
      },
      pickerMinDate: '',
      pickerOptions: {
        onPick: obj => {
          this.pickerMinDate = new Date(obj.minDate).getTime()
          const start = this.formatDate(obj.minDate, 'start')
          const end = this.formatDate(obj.maxDate, 'end')
          obj.maxDate && (this.values = [start, end])
        },
        disabledDate: time => {
          if (this.pickerMinDate) {
            const oneMonth = 1000 * 3600 * 24 * 31
            const maxTime = this.pickerMinDate + oneMonth
            const mixTime = this.pickerMinDate - oneMonth + 1000 * 3600 * 24
            const tomorrow = new Date(this.formatDate(new Date().getTime() + 1000 * 3600 * 24, 'start'))
            return time.getTime() >= tomorrow || time.getTime() >= maxTime || time.getTime() < mixTime
          }
        }
      }
    }
  },
  computed: {
    jisuan() {
      return (obj, num, fixed) => {
        if (obj == null || obj == undefined) return 0;
        return (obj / num).toFixed(fixed)
      }
    },
    sums() {
      return (obj, num) => {
        return obj - num
      }
    },
    typs() {
      return (obj) => {
        return obj == undefined || obj == null ? 0 : obj
      }
    }
  },
  watch: {

  },
  created() {
    this.stationlist.push({
      id: window.sessionStorage.getItem('stationIds'),
      stationName: '全部电站'
    });
    this.$http.get('station-info/getAllStationInfoName', {
      params: {
        roleId: window.sessionStorage.getItem('roleId'),
        stationIds: window.sessionStorage.getItem('stationIds'),
        stationMode: '0,1,2'
      }
    }).then(res => {
      res.data.data.forEach(item => {
        this.stationlist.push({
          id: item.id,
          stationName: item.stationName
        })
      })
    });
    this.defaultMethods();
  },
  mounted() {
    //  电量趋势、利用率趋势

  },
  methods: {
    changeType() {
      this.type = true;
      this.picker_value2 = ['', ''];
      this.carInfo.startTime = '';
      this.carInfo.endTime = '';
      console.log(this.times,'154');
      this.carInfo.type = this.times == '近7天' || this.times == '近30天' ? 0 : 2;
      this.carInfo.days = this.times == '近7天' ? 7 : this.times == '近30天' ? 30 : 12;
      this.defaultMethods()
    },
    defaultMethods() {
      const that = this;
      that.elerticobjs = [];
      that.time1=[];
      that.time2=[];
      that.time3=[];
      that.time4=[];
      that.$http.get('device-analysis/getAllDeviceEfficiencyAnalysis', {
        params: this.carInfo
      }).then(res => {
        if (res.data.code !== 0) {
          that.empty = true;
          that.qingchu();
        } else {
          res.data.data.forEach(item=>{
            that.time1.push(item.allElectricityLevel==null||item.allElectricityLevel==undefined||item.allElectricityLevel==''||item.allElectricityLevel==0?0:(item.allElectricityLevel/100).toFixed(2));
            that.time2.push(item.allDcElectricityLevel==null||item.allDcElectricityLevel==undefined||item.allDcElectricityLevel==''||item.allDcElectricityLevel==0?0:(item.allDcElectricityLevel/100).toFixed(2));
            that.time3.push(item.allAcElectricityLevel==null||item.allAcElectricityLevel==undefined||item.allAcElectricityLevel==''||item.allAcElectricityLevel==0?0:(item.allAcElectricityLevel/100).toFixed(2));
            that.time4.push(item.bottomTime==null||item.bottomTime==undefined||item.bottomTime==''?'':item.bottomTime);
          })
          that.maxTop=Math.max(...that.time1,...that.time2,...that.time3);
          that.elertic();
        }
      })
    },
    qingchu() {
      const myChart = echarts.init(document.getElementsByClassName('center1')[0]);
      myChart.clear()
    },
    elertic() {
      const myChart = echarts.init(document.getElementsByClassName('center1')[0]);
      const myChart2 = echarts.init(document.getElementsByClassName('center1')[1]);
      const option = {
        title: {
          text: '电量趋势'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: this.lablelTop,
          bottom: '-1%',
          icon: 'react'
        },
        color: ['#FABBF1', '#7FDEFF', '#62E4CE'],
        grid: {
          left: '3%',
          right: '4%',
          bottom: '5%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.time4,
          axisTick: {
            show: false
          },
        },
        yAxis: {
          type: 'value',
          // max: (this.maxTop<100?this.maxTop * 1 + 10 * 1:this.maxTop<1000?this.maxTop * 1 + 100 * 1:this.maxTop<10000?this.maxTop * 1 + 1000 * 1:this.maxTop * 1 + 10000 * 1).toFixed(0),
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [
          {
            name: this.lablelTop[0],
            type: 'line',
            stack: 'Total',
            data: this.time1
          },
          {
            name: this.lablelTop[1],
            type: 'line',
            stack: 'Total',
            data: this.time2
          },
          {
            name: this.lablelTop[2],
            type: 'line',
            stack: 'Total',
            data: this.time3
          }
        ]
      };
      const option2 = {
        title: {
          text: '利用率趋势'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['Email', 'Union Ads'],
          bottom: '-1%',
          icon: 'react'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '5%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [
          {
            name: 'Email',
            type: 'line',
            stack: 'Total',
            data: [120, 132, 101, 134, 90, 230, 210]
          },
          {
            name: 'Union Ads',
            type: 'line',
            stack: 'Total',
            data: [220, 182, 191, 234, 290, 330, 310]
          }
        ]
      };
      myChart.setOption(option);
      myChart2.setOption(option2);
    },
    formatDate(datetime, type) {
      const time = new Date(datetime)
      const year = time.getFullYear()
      const month = (time.getMonth() + 1).toString().padStart(2, '0')
      const date = (time.getDate()).toString().padStart(2, '0')
      return type === 'start' ? year + '-' + month + '-' + date + ' ' + '00' + ':' + '00' + ':' + '00' : year + '-' + month + '-' + date + ' ' + '23' + ':' + '59' + ':' + '59'
    },
    pickBlur() {
      this.pickerMinDate = ''
    },
    // 切换时间
    picker_event(e) {
      if (e !== null) {
        this.carInfo.startTime = e[0];
        this.carInfo.endTime = e[1];
        this.type = false;
        this.carInfo.type = 3;
        this.carInfo.days = 0;
        this.defaultMethods()
      } else {
        this.carInfo.startTime = null;
        this.carInfo.endTime = null;
        this.type = true;
        this.carInfo.type = 0;
        this.carInfo.days = 7;
        this.times = '近7天';
        this.defaultMethods()
      }
      // 折柱混合图
    },
    // 更换场站
    change_vale() {
      this.carInfo.stationIds = this.sousuodianzhan;
      this.carInfo.type = 0;
      this.carInfo.days = 7;
      this.times = '近7天';
      this.picker_value2 = ['', ''];
      this.carInfo.startTime = '';
      this.carInfo.endTime = '';
      this.type = true;
      this.defaultMethods()
    }
  }
};
</script>

<style lang='less' scoped>
.EquipmentAnalysis {
  width: 99%;
  height: 99%;
  background: #ccc;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .top {
    height: 40px;
    display: flex;
    flex-direction: row;

    li:nth-child(2) {
      margin: 0 20px;
    }
  }

  .center {
    height: 100%;
    margin: 10px 0;
    display: flex;
    flex-direction: column;

    .center1 {
      flex: 1;
      display: flex;
      background: #fff;
      flex-direction: column;
      padding: 10px 10px 30px 10px;

    }

    .center1:nth-child(1) {
      margin-bottom: 20px;
    }
  }


}
</style>
