<template>
  <Oldpile v-if="defaultType" @fatherMethod="fatherMethod"></Oldpile>
  <!-- 新版试图区域 -->
  <Newpile v-else @fatherMethod="fatherMethod"></Newpile>
</template>

<script>
import Oldpile from "./components/Oldpile.vue";
import Newpile from './components/Newpile.vue';
export default {
  name: 'elePileWorld',
  components: {
    Oldpile,
    Newpile
  },
  data() {
    return {
      defaultType: true,
    }
  },
  methods: {
    fatherMethod(e){
      this.defaultType=e==0?1:false;
    }
  }
};
</script>

