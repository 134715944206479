<template>
  <div>
    <el-form class="form" :inline="true" :model="queryInfo">
      <el-form-item label="电桩编号">
        <el-input placeholder="请输入电桩编号" clearable v-model="queryInfo.pileCode" @change="current" @clear="getLogList">
        </el-input>
      </el-form-item>
      <el-form-item label="更新时间">
        <el-date-picker v-model="value" @blur="pickBlur" :picker-options="pickerOptions" type="daterange"
          range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm" @change="changeTime">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="指令码">
        <el-input placeholder="请输入指令码" clearable v-model="queryInfo.operaCode" @change="current" @clear="getLogList">
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="getLogList">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- 卡片视图区域 -->
    <el-card class="card">
      <li style="display: flex;flex-direction: row;justify-content: flex-end;padding-bottom: 20px;margin-left: -1%;">
        <el-button type="primary" v-if="caozuoquanxian" @click="wenjiandaochu">导出</el-button>
      </li>
      <!-- table表格区域 -->
      <el-table class="table" :data="logList" border stripe @selection-change="handleSelectionChange" empty-text="暂无数据">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column align="center" type="expand">
          <template slot-scope="scope">
            <el-form label-position="left" class="demo-table-expand">
              <el-row :gutter="20" type="flex" class="row-bg" justify="space-around" style="padding: 0 1%;">
                <el-col :span="6">
                  <el-form-item label="充电枪号：">
                    <el-tag v-if="scope.row.gunNumber == 1">{{ scope.row.gunNumName }}</el-tag>
                    <el-tag v-if="scope.row.gunNumber == 2">{{ scope.row.gunNumName }}</el-tag>
                    <el-tag v-if="scope.row.gunNumber == 3">{{ scope.row.gunNumName }}</el-tag>
                    <el-tag v-if="scope.row.gunNumber == 4">{{ scope.row.gunNumName }}</el-tag>
                  </el-form-item>
                  <el-form-item label="关键数据：">
                    <span>{{ scope.row.criticalData }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="网关序号：">
                    <span>{{ scope.row.gatewayCode }}</span>
                  </el-form-item>
                  <el-form-item label="指令码：">
                    <span>{{ scope.row.operaCode }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="电桩序号：">
                    <span>{{ scope.row.pileCode }}</span>
                  </el-form-item>
                  <el-form-item label="数据报文：">
                    <el-tooltip class="item" effect="dark" :content="scope.row.message" placement="top">
                      <span
                        style="display: block;width: 80px; text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">{{
      scope.row.message }}</span>
                    </el-tooltip>
                    <span></span>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="类型：" prop="covenantType">
                    <el-tag v-if="scope.row.covenantType == 0">接收</el-tag>
                    <el-tag v-else>发送 </el-tag>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column align="center" label="ID" prop="id" width="100"></el-table-column>
        <el-table-column align="center" label="类型" prop="covenantType" width="80">
          <template scope="scope">
            <el-tag :type="scope.row.covenantType == 0 ? 'success' : 'warning'">{{ scope.row.covenantType == 0 ? '接收' : '发送'
              }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="电桩编号" prop="pileCode" width="160"></el-table-column>
        <el-table-column align="center" label="电桩当前状态" prop="preStatusName" width="110"></el-table-column>
        <el-table-column align="center" label="电桩充电状态" prop="curStatusName" width="110"></el-table-column>
        <el-table-column align="center" label="充电枪号" prop="gunNumName" width="80"></el-table-column>
        <el-table-column align="center" label="指令码" prop="operaCode"></el-table-column>
        <el-table-column align="center" label="关键数据" prop="criticalData"></el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime" width="100"></el-table-column>
        <el-table-column align="center" label="更新时间" prop="updateTime" width="100"></el-table-column>
        <!-- <el-table-column align="center" label="操作" width="130px">
              <template slot-scope="scope">
                  <el-button size="small" type="danger"
                  @click="removeById(scope.row)">删除</el-button>
              </template>
          </el-table-column> -->
      </el-table>
      <!-- 分页导航区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[8, 20, 50, 100]" :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { saveBlobToFile } from '@/utils/saveBlobToFile';
import caozuo from "@/utils/pubilc.js";
export default {
  name: 'logWorld',
  data() {
    return {
      caozuoquanxian: false,
      pickerMinDate: '',
      pickerOptions: {
        onPick: obj => {
          this.pickerMinDate = new Date(obj.minDate).getTime()
          const start = this.formatDate(obj.minDate, 'start')
          const end = this.formatDate(obj.maxDate, 'end')
          obj.maxDate && (this.value = [start, end])
        },
        disabledDate: time => {
          if (this.pickerMinDate) {
            const oneMonth = 1000 * 3600 * 24 * 31
            const maxTime = this.pickerMinDate + oneMonth
            const mixTime = this.pickerMinDate - oneMonth + 1000 * 3600 * 24
            const tomorrow = new Date(this.formatDate(new Date().getTime() + 1000 * 3600 * 24, 'start'))
            return time.getTime() >= tomorrow || time.getTime() >= maxTime || time.getTime() < mixTime
          }
        }
      },
      // 查询参数对象
      queryInfo: {
        startTime: null,
        endTime: null,
        alarmstate: null,
        operaCode: null,
        pileCode: null,
        pileType: null,
        facilityType: null,
        page: 1,
        limit: 8
      },
      // 商品列表
      logList: [],
      chooseLogList: [],
      // 总数据条数
      total: 0,
      value: ['', ''],
      ids: ''
    }
  },
  created() {
    caozuo(156).then(res => {
      this.caozuoquanxian = res;
    });
    this.getLogList()
  },
  methods: {
    pickBlur() {
      this.pickerMinDate = ''
    },
    // 重置搜索条件
    chongzhi() {
      this.queryInfo = {
        startTime: null,
        endTime: null,
        alarmstate: null,
        operaCode: null,
        pileCode: null,
        pileType: null,
        facilityType: null,
        page: 1,
        limit: 8
      };
      this.value = ['', ''];
      this.pickerMinDate = '';
      this.getLogList()
    },
    // 电桩分析（导出）
    wenjiandaochu() {
      if (this.queryInfo.startTime != null && this.queryInfo.endTime != null && this.queryInfo.pileCode != null) {
        this.$http.get('/covenant-log/export', {
          params: {
            startTime: this.queryInfo.startTime,
            endTime: this.queryInfo.endTime,
            pileCode: this.queryInfo.pileCode
          },
          responseType: 'blob'
        }).then(res => {
          saveBlobToFile(res.data, '电桩分析')
          if (res.data) {
            this.$message.success('文件导出成功')
          } else {
            this.$message.error('文件导出失败')
          }
        })
      } else {
        this.$message.error('请选择你要导出的电桩与时间')
      }
    },
    current() {
      this.queryInfo.page = 1;
    },
    changeTime(value) {
      this.queryInfo.page = 1;
      if (value) {
        this.queryInfo.startTime = value[0];
        this.queryInfo.endTime = value[1];
        if (this.queryInfo.startTime == this.queryInfo.endTime) {
          this.$message.error('起始时间不得为同一天！');
          this.queryInfo.startTime = ''
          this.queryInfo.endTime = ''
        }
      } else {
        this.queryInfo.startTime = null;
        this.queryInfo.endTime = null;
        this.pickerMinDate = '';
        this.getLogList()
      }
    },
    // 根据分页获取对应的日志列表
    async getLogList() {
      this.logList = [];
      console.log(this.queryInfo, '257');
      const { data: res } = await this.$http.get('covenant-log/getAllCovenantLog', { params: this.queryInfo })
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      this.logList = res.data
      this.total = res.count
    },

    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.queryInfo.page = 1;
      this.getLogList()
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getLogList()
    },
    // 批量删除的复选框改变事件
    handleSelectionChange(value) {
      if (value.length > 0) {
        this.ids = ''
        value.forEach(item => {
          this.ids += item.id + ','
        })
        this.ids = this.ids.substring(0, this.ids.length - 1)
      } else {
        return false
      }
    },
    formatDate(datetime, type) {
      const time = new Date(datetime)
      const year = time.getFullYear()
      const month = (time.getMonth() + 1).toString().padStart(2, '0')
      const date = (time.getDate()).toString().padStart(2, '0')
      return type === 'start' ? year + '-' + month + '-' + date + ' ' + '00' + ':' + '00' + ':' + '00' : year + '-' + month + '-' + date + ' ' + '23' + ':' + '59' + ':' + '59'
    },
    getDate() {
      const start = new Date()
      this.value[0] = this.formatDate(start.getTime() - 1000 * 3600 * 24 * 30, 'start')
      this.value[1] = this.formatDate(start.getTime(), 'end')
    },
    // 删除日志
    async removeById(row) {
      const confirmResult = await this.$confirm('此操作将永久删除该日志, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除！')
      }
      if (row.id === null || row.id === '' || row.id === undefined) {
        const { data: res } = await this.$http.post('operationLog/delAdminOperationLogById/' + this.ids)
        if (res.code !== 0) {
          return this.$message.error('删除日志失败！')
        }
        this.$message.success('删除日志成功！')
        this.getLogList()
      } else {
        const { data: res } = await this.$http.post('operationLog/delAdminOperationLogById/' + row.id.toString())
        if (res.code !== 0) {
          return this.$message.error('删除日志失败！')
        }
        this.$message.success('删除日志成功！')
        this.getLogList()
      }
    }
  }
}
</script>

<style lang="less">
.el-tooltip__popper {
  width: 70%;
  padding: 8px 10px;
  height: auto;
  display: flex;
  justify-content: center;
}
</style>