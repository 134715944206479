<template>
   <div>
     <Old v-if="old"></Old>
     <New v-else></New>
   </div>
</template>
<script>
import Old from './compontents/Old.vue';
import New from './compontents/New.vue';
export default {
   name: '',
   mixins: [],
   components: {
     Old,
     New
   },
   props: {

   },
   data() {
     return {
       old:true
     }
   },
   computed: {

   },
   watch: {

   },
   mounted() {

   },
   methods: {

   }
};
</script>

