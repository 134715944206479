<template>
  <div>
    <el-table :data="tableData" stripe style="width: 100%" empty-text="暂无数据">
      <el-table-column prop="pileIpAddr" label="登录IP">
      </el-table-column>
      <el-table-column prop="threadId" label="连接线程">
      </el-table-column>
      <el-table-column prop="pileCode" label="登陆桩号">
      </el-table-column>
      <el-table-column prop="loginTime" label="上线时间">
      </el-table-column>
      <el-table-column prop="loginOutTime" label="离线时间">
        <template slot-scope="scope">
          <span v-if="scope.row.loginOutTime != null">{{ scope.row.loginOutTime }}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="loginState" label="登录状态">
        <template slot-scope="scope">
          <el-tag type="info" v-model="scope.row.loginState" v-if="scope.row.loginState == '1'">离线</el-tag>
          <el-tag type="success" v-model="scope.row.loginState" v-if="scope.row.loginState == '0'">成功</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handlesize" @current-change="handleCurrent" :current-page="rizi.page"
      :page-sizes="[10, 20, 50, 100]" :page-size="rizi.limit" layout="total, sizes, prev, pager, next, jumper"
      :total="total_all" background>
    </el-pagination>
  </div>
</template>
<script>
export default {
  name: '',
  mixins: [],
  components: {

  },
  props: {

  },
  data() {
    return {
      tableData: [],
      rizi: {
        pileCode: null,
        startTime: null,
        endTime: null,
        page: 1,
        limit: 10
      },
      total_all: 0,
    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {
    this.rizi.pileCode = this.$route.query.code;
    this.public_function()
  },
  methods: {
    // 改变页码
    handleCurrent(newPage) {
      this.rizi.page = newPage
      // 页码跳跃
      this.public_function()
    },
    public_function() {
      this.$http.get('pile-login-log/getAllPileLoginLog', {
        params: this.rizi
      }).then(resb => {
        this.total_all = resb.data.count;
        this.tableData = resb.data.code !== 0 ? [] : resb.data.data;
        if (resb.data.code !== 0) {
          this.$message.error('暂无电桩日志');
        }
      })
    },
    // 改变数据条数
    handlesize(newSize) {
      this.rizi.limit = newSize;
      this.rizi.page = 1;
      // 页码跳跃
      this.public_function()
    }
  }
};
</script>

<style lang='' scoped>
</style>
