<template>
  <div>
    <el-row :gutter="20" style="padding-bottom: 20px;">
        <el-col :span="4" style="display: flex;align-items: center;flex-direction: row;">
          <span style="flex:3">充电车主:</span>
          <el-input style="flex:7" placeholder="请输入充电车主:" clearable v-model="queryInfo.userNumber" @clear="chongzhi"
            @change="current">
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-search" @click="getRolesList">查询</el-button>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
        </el-col>

      </el-row>
    <!-- 卡片视图区域 运营人员-->
    <el-card>
      <!-- 添加角色按钮区域  -->

      <li style="display: flex;justify-content: flex-end;margin-left: -1%;padding-bottom: 10px;">
          <el-button type="primary" icon="el-icon-plus" @click="addMethods">赠送服务费券</el-button> <el-button type="danger" @click="delMethods(0)">批量删除服务费券</el-button>
        </li>
      <!-- 角色列表区域 -->
      <el-table :data="rolesList" border empty-text="暂无数据" stripe @selection-change="handleSelectionChange">
        <el-table-column align="center" type="selection" width="55"></el-table-column>
        <el-table-column align="center" label="优惠对象" prop="userNumber" width="120"></el-table-column>
        <el-table-column align="center" label="优惠场站" prop="stationName" width="120"></el-table-column>
        <el-table-column align="center" label="卡券金额" prop="money" width="100">
          <template slot-scope="scope">
            <div>
              ￥{{ scope.row.money == null || scope.row.money == undefined ? 0 : scope.row.money }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="订单最低金额" prop="fullAmount" width="110">
          <template slot-scope="scope">
            <div>
              ￥{{ scope.row.fullAmount == null || scope.row.fullAmount == undefined ? 0 : scope.row.fullAmount }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="优惠类型" prop="money" width="120">
          <template slot-scope="scope">
            <span>
              <!-- 三元判断 -->
              {{
        scope.row.preferentialType == 8 ? '车场服务费' : ''
      }}
            </span>
          </template>
        </el-table-column>
        <!-- /折扣 -->
        <el-table-column align="center" label="有效期" prop="dayNums" width="200">
          <template slot-scope="scope">
            <!-- 条件判断 -->
            <div v-if="scope.row.dayNums.length > 4 || scope.row.des.includes('长期')">
              {{ scope.row.dayNums }}天
            </div>
            <div v-else>
              <p> {{ scope.row.startTime }}~</p>
              <p>{{ scope.row.endTime }}</p>
            </div>
            <!-- <div>
              领取后{{  }}天内可用
            </div> -->
          </template>
        </el-table-column>
        <el-table-column align="center" label="购买单号" prop="billCode"></el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime"></el-table-column>
        <el-table-column align="center" label="券状态" prop="state" width="100">
          <template slot-scope="scope">
            <el-tag :type="scope.row.state == 0 ? 'default' : scope.row.state == 1 ? 'danger' : 'warning'">
              {{ scope.row.state == 0 ? '待使用' : scope.row.state == 1 ? '已失效' : '已使用' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="备注" prop="des"></el-table-column>
        <el-table-column label="操作" width="180" v-if="trypings">
          <template slot-scope="scope">
            <el-button type="warning" v-if="scope.row.state == 0" @click="TypeMethods(scope.row)">失效</el-button>
            <el-button type="danger" @click="delMethods(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[8, 20, 50, 100]" :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-card>
    <el-dialog title="赠送优惠券" :visible.sync="dialogVisible" width="25%" :before-close="falseMethods">
      <el-form ref="form" :model="form" label-width="120px" :rules="formRules">
        <el-form-item label="所属场站" prop="stationIds">
          <el-select v-model="form.stationIds" placeholder="请选择所属场站" @change="stationMethods" filterable>
            <el-option v-for="item in stationList" :label="item.stationName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="form.phone" @change="phoneMethods"></el-input>
        </el-form-item>
        <el-form-item label="金额" prop="money">
          <el-input-number v-model="form.money" :min="0" :step="1" :precision="0"></el-input-number>
        </el-form-item>
        <el-form-item label="车架号(VIN)" prop="carvin">
          <el-input v-model="form.carvin"></el-input>
        </el-form-item>
        <el-form-item label="是否绑定VIN" prop="isCarvin">
          <el-select v-model="form.isCarvin" placeholder="请选择是否绑定VIN">
            <el-option v-for="item in typeList" :label="item == 0 ? '是' : '否'" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间类型" prop="time">
          <el-select v-model="form.time" placeholder="请选择事件类型">
            <el-option v-for="item in timeList" :label="item == 0 ? '长期' : '有效期'" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属时间" v-if="form.time > 0">
          <el-date-picker v-model="picker_value2" clearable type="datetimerange" :picker-options="pickerOptions"
            align="right" range-separator="--" value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期"
            @blur="pickBlur" end-placeholder="结束日期" @change="picker_event" :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="falseMethods">取 消</el-button>
        <el-button type="primary" @click="okMethods">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import caozuo from "@/utils/pubilc.js";
export default {
  name: 'stationActive',
  data() {
    var validatePass = (rule, value, callback) => {
      const abc = /^1[3578][01379]\d{8}|1[34578][01256]\d{8}|(134[012345678]\d{7}|1[34578][012356789]\d{8})$/g;
      if (value == '' || value == null || value == undefined) {
        callback(new Error('请输入手机号！'))
      } else if (abc.test(value) == false) {
        callback(new Error('请输入正确的手机号！'))
      } else {
        callback()
      }
    };
    return {
      ids: '',
      trypings: false,
      timeList: [0, 1],
      pickerMinDate: '',
      typeList: [0, 1],
      formRules: {
        stationIds: [
          { required: true, message: '请选择你的活动场站', trigger: 'change' }
        ],
        phone: [
          { validatePass: validatePass, required: true, message: '请输入你的手机号', trigger: 'change' }
        ],
        money: [
          { required: true, message: '请输入/选择你的金额', trigger: 'change' }
        ],
        carvin: [
          { required: false, message: '请输入车架号', trigger: 'change' }
        ],
        isCarvin: [
          { required: true, message: '请选择是否绑定VIN', trigger: 'change' }
        ]
      },
      pickerOptions: {
        onPick: ({ minDate }) => {
          // 保存首次选择的日期时间戳
          if (minDate && !this.pickerMinDate) {
            this.pickerMinDate = minDate.getTime();
          } else {
            this.pickerMinDate = null; // 重新选择时重置状态
          }
        },
        disabledDate: time => {
          const now = Date.now() - 8.64e7; // 解决时差导致的今日0点禁用问题
          // 限制开始日期不小于今日
          if (time.getTime() < now) return true;
          // 限制结束日期与开始日期间隔不超过一个月
          if (this.pickerMinDate) {
            const oneMonth = 30 * 8.64e7; // 按30天计算一个月
            const maxRange = this.pickerMinDate + oneMonth;
            const minRange = this.pickerMinDate - oneMonth;
            return time.getTime() > maxRange || time.getTime() < minRange;
          }
          return false;
        }
      },
      picker_value2: ['', ''],
      dialogVisible: false,
      activeIndex: '',
      caozuoquanxian: false,
      newarr: [],
      quxiaoarr: [],
      arr: [],
      hr: '',
      fen: false,
      flag: false,
      del_btn: true,
      user_title: '',
      dialoag: false,
      form: {
        stationIds: '',
        time: 0,
        carvin: '',
        money: 0,
        phone: '',
        isCarvin: '',
        startTime: null,
        endTime: null,
        preferentialType: 8,
        adminId: window.sessionStorage.getItem('adminId')
      },
      queryInfo: {
        preferentialType: 8,
        name: '',
        type: '',
        stationIds: window.sessionStorage.getItem('stationIds'),
        page: 1,
        limit: 8,
        adminId: window.sessionStorage.getItem('adminId') * 1,
        userNumber: null
      },
      total: null,
      title: '',
      editForm: {
        rolename: null,
        // roleType: null,
        roleTypeName: null,
        des: null,
        id: null
      },
      editFormRules: {
        rolename: [
          { required: true, message: '角色名称不能为空', trigger: 'blur' }
        ]
        // ,
        // roleTypeName: [
        //   { required: true, message: '角色类型不能为空', trigger: 'blur' }
        // ]
      },
      shanchuarr: [],
      editDialogVisible: false,
      // 所有角色列表数据
      rolesList: [],
      // 角色类型数据
      // roleTypeList: [
      //   { roleType: 0, roleTypeName: '管理员' },
      //   { roleType: 1, roleTypeName: '商户' },
      //   { roleType: 2, roleTypeName: '运营商(编辑)' },
      //   { roleType: 3, roleTypeName: '运营商(只读)' },
      //   { roleType: 4, roleTypeName: '发卡' },
      //   { roleType: 5, roleTypeName: '保留' },
      //   { roleType: 6, roleTypeName: '保留' },
      //   { roleType: 7, roleTypeName: '保留' },
      //   { roleType: 9996, roleTypeName: '销售' },
      //   { roleType: 9997, roleTypeName: '平台财务' },
      //   { roleType: 9998, roleTypeName: '普通管理员' },
      //   { roleType: 9999, roleTypeName: '售后' }
      // ],
      // 控制分配权限对话框的显示与隐藏
      powerDialogVisible: false,
      // 所有权限数据
      powerList: [],
      // 角色对应权限数据
      roleLogList: [],
      // 树形控件的属性绑定对象
      treeProps: {
        label: 'menuname',
        children: 'childList'
      },
      // 默认选中节点Id值的数组
      defKeys: [],
      ids: null,
      sttt: null,
      aaa: [],
      ntr: [],
      shuju: {
        name: null,
        page: 1,
        limit: 8
      },
      stationList: [],
      Typelist: [
        {
          type: 0,
          name: '全部'
        },
        {
          type: 1,
          name: '电费'
        },
        {
          type: 2,
          name: '电费服务费'
        }
      ]
    }
  },
  computed: {
    jisuan() {
      return (val, num, fixed) => {
        return (val / num).toFixed(fixed)
      }
    }
  },
  watch: {
    defKeys: {
      handler(newval) {
        this.defKeys = newval
      }
    }
  },
  created() {
    caozuo(148).then(res => {
      this.caozuoquanxian = res;
    });
    caozuo(214).then(res => {
      this.trypings = res;
    });
    this.$http.get('station-info/getAllStationInfoName', {
      params: {
        roleId: window.sessionStorage.getItem('roleId'),
        stationIds: window.sessionStorage.getItem('stationIds'),
        stationMode: '0,1,2'
      }
    }).then(res => {
      this.stationList = res.data.data;
    });
    this.getRolesList();
    if (this.ids != null) {
      this.del_btn = !this.del_btn
    } else {
      this.del_btn = true
    };
    this.default_value();
  },
  methods: {
    // 批量删除的复选框改变事件
    handleSelectionChange(value) {
      if (value.length > 0) {
        this.ids = ''
        value.forEach(item => {
          this.ids += item.id + ','
        })
        this.ids = this.ids.substring(0, this.ids.length - 1)
      } else {
        this.ids = '';
        return false
      }
    },
    delMethods(row) {
      const that = this;
      let ids = row == 0 ? that.ids : row.id;
      that.$http.post(`coupons/delCouponsById/${ids}/${window.sessionStorage.getItem('adminId')}`).then(res => {
        that.$message({
          type: res.data.code !== 0 ? 'error' : 'success',
          message: res.data.code !== 0 ? '删除失败' : '删除成功'
        })
        if (res.data.code !== 0) return;
        that.getRolesList()
      })
    },
    TypeMethods(row) {
      if (row.state == 2) return;
      const that = this;
      that.$http.post('coupons/editCoupons', {
        id: row.id,
        state: row.state == 0 ? 1 : 0
      }).then(res => {
        that.$message({
          message: res.data.code == 0 ? `${row.state == 0 ? '失效成功' : '生效成功'}` : `${row.state == 0 ? '失效失败' : '生效失败'}`,
          type: res.data.code == 0 ? 'success' : 'error'
        });
        that.getRolesList();
      })
    },
    phoneMethods() {
      const that = this;
      if (that.form.stationIds == null || that.form.stationIds == undefined || that.form.stationIds == '') return that.$message.error('请选择你的所属电站');
      that.$http.get(`coupons/getUserInfoByPhone/${window.sessionStorage.getItem('roleId') == 11 || window.sessionStorage.getItem('roleId') == 1 ? 0 : window.sessionStorage.getItem('appId')}/${that.form.stationIds}/${that.form.phone}`).then(res => {
        if (res.data.code !== 0) return that.$message.error('本站没有此手机号，请检查你的电站、手机号是否正确')
      })
    },
    stationMethods() {
      const that = this;
      if (that.form.phone == null || that.form.phone == undefined || that.form.phone == '') return;
      that.$http.get(`coupons/getUserInfoByPhone/${window.sessionStorage.getItem('roleId') == 11 || window.sessionStorage.getItem('roleId') == 1 ? 0 : window.sessionStorage.getItem('appId')}/${that.form.stationIds}/${that.form.phone}`).then(res => {
        if (res.data.code !== 0) return that.$message.error('本站没有此手机号，请检查你的电站、手机号是否正确')
      })
    },
    pickBlur() {
      this.pickerMinDate = ''
    },
    formatDate(datetime, type) {
      const time = new Date(datetime)
      const year = time.getFullYear()
      const month = (time.getMonth() + 1).toString().padStart(2, '0')
      const date = (time.getDate()).toString().padStart(2, '0')
      return type === 'start' ? year + '-' + month + '-' + date + ' ' + '00' + ':' + '00' + ':' + '00' : year + '-' + month + '-' + date + ' ' + '23' + ':' + '59' + ':' + '59'
    },
    picker_event(e) {
      if (e != null) {
        this.form.startTime = e[0];
        this.form.endTime = e[1];
      } else {
        this.form.startTime = null;
        this.form.endTime = null;
        this.pickerMinDate = ''
      }
    },
    // 优惠券方法
    okMethods() {
      const that = this;
      if (that.form.stationIds == null || that.form.stationIds == undefined || that.form.stationIds == '') return that.$message.error('请选择你的所属电站');
      if (that.form.phone == null || that.form.phone == undefined || that.form.phone == '') return that.$message.error('请输入场站所属手机号');
      that.$http.get(`coupons/getUserInfoByPhone/${window.sessionStorage.getItem('roleId') == 11 || window.sessionStorage.getItem('roleId') == 1 ? 0 : window.sessionStorage.getItem('appId')}/${that.form.stationIds}/${that.form.phone}`).then(res => {
        if (res.data.code !== 0) return that.$message.error('本站没有此手机号，请检查你的电站、手机号是否正确')
      });
      if (that.form.money == 0) return that.$message.error('赠送金额必须大于0');
      if (that.form.time !== 0) {
        if (that.form.startTime == '' || that.form.startTime == undefined || that.form.time == null) return that.$message.error('请选择时间');
      }
      that.$http.get(`coupons/getUserInfoByPhone/${window.sessionStorage.getItem('appId')}/${that.form.stationIds}/${that.form.phone}`).then(resb => {
        if (resb.data.code == 0) {
          that.addCoupontMethods();
        } else {
          this.$confirm('该用户在本站已有服务费券, 是否继续添加?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => that.addCoupontMethods()).catch(action => {
            this.$message({
              type: 'info',
              message: '取消添加服务费券成功'
            })
          })
        }
      })
    },
    addCoupontMethods() {
      const that = this;
      that.$http.post('coupons/editCoupons', {
        money: that.form.money * 100,
        stationIds: that.form.stationIds,
        time: that.form.time,
        carvin: that.form.carvin,
        phone: that.form.phone,
        isCarvin: that.form.isCarvin,
        startTime: that.form.startTime,
        endTime: that.form.endTime,
        preferentialType: 8,
        adminId: window.sessionStorage.getItem('adminId')
      }).then(res => {
        if (res.data.code !== 0) return;
        that.dialogVisible = false;
        that.form = {
          stationIds: '',
          time: 0,
          carvin: '',
          money: 0,
          phone: '',
          isCarvin: '',
          startTime: null,
          endTime: null,
          preferentialType: 8,
          adminId: window.sessionStorage.getItem('adminId')
        };
        that.getRolesList()
      })
    },
    // 取消创建优惠券
    falseMethods() {
      this.dialogVisible = false;
      this.form = {
        stationIds: '',
        time: 0,
        carvin: '',
        money: 0,
        phone: '',
        isCarvin: '',
        startTime: null,
        endTime: null,
        preferentialType: 8,
        adminId: window.sessionStorage.getItem('adminId')
      };
    },
    // 创建优惠券
    addMethods() {
      this.dialogVisible = true;
      this.form = {
        stationIds: '',
        time: 0,
        carvin: '',
        money: 0,
        phone: '',
        isCarvin: '',
        startTime: null,
        endTime: null,
        preferentialType: 8,
        adminId: window.sessionStorage.getItem('adminId')
      };
    },
    current() {
      this.queryInfo.page = 1;
    },
    chongzhi() {
      this.queryInfo = {
        preferentialType: 8,
        billCode: '',
        stationIds: window.sessionStorage.getItem('stationIds'),
        page: 1,
        limit: 8,
        adminId: window.sessionStorage.getItem('adminId') * 1,
        userNumber: null
      };
      this.getRolesList()
    },
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.queryInfo.page = 1;
      this.getRolesList();
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getRolesList();
    },
    setPowerDialogClosed() {
      this.dialoag = false;
    },
    checkCange(data, checked) {
      this.quxiaoarr = this.defKeys;
      if (checked == true) {
        this.arr.push(data.id);
      } else {
        this.shanchuarr.push(data.id);
      };
      if (this.shanchuarr.length > 0) {
        this.arr = this.quxiaoarr.filter(item => !this.shanchuarr.includes(item))
      } else {
        let ntr = this.arr;
        this.arr = Array.from(new Set(this.quxiaoarr.concat(ntr)));
      };
      this.hr = this.arr.map(item => item).join(',');
    },
    async default_value() {
      // 获取所有权限的数据
      const { data: res } = await this.$http.get('adminMenu/getAllAdminMenu', {
        params: this.shuju
      })
      this.powerList = res.data;
      this.powerList.forEach(item => {
        item.childList.forEach(ite => {
          ite.childList = ite.childList3
        })
      });
    },
    // 获取所有角色的列表
    async getRolesList() {
      const { data: res } = await this.$http.get('coupons/getAllCoupons', { params: this.queryInfo })
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      this.rolesList = res.data;
      this.total = res.count;
      this.rolesList.forEach(item => {
        if (item.state !== 1) {
          item.disabl = false;
        } else {
          item.disabl = true;
        }
        item.usecharge = (item.usecharge / 100).toFixed(2);
        item.chargenum = (item.chargenum / 100).toFixed(2);
        item.money = (item.money / 100).toFixed(2)
      });
    },
    // 复选框的改变事件
    handleSelectionChange(value) {
      this.ids = '';
      if (value.length > 0) {
        if (value.length > 1) {
          this.del_btn = false;
          value.forEach(item => {
            this.ids += item.id + ','
          })
          this.ids = this.ids.substring(0, this.ids.length - 1)
        } else {
          this.ids = value[0].id
        }
      } else {
        this.del_btn = true;
      }
    },
    // 根据id删除对应的角色

    // 失效
    async removeRoleById(row) {
      const confirmResult = await this.$confirm('此操作将导致该优惠彻底失效, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        row.disabl = false;
        this.$message.info('已取消失效操作')
      } else {
        if (row.state == '0') {
          row.state = '1'
        } else if (row.state == '2') {
          row.state = '0'
        };
        const { data: res } = await this.$http.post('coupons/updCoupons', {
          id: row.id,
          state: row.state,
          adminId: window.sessionStorage.getItem('adminId') * 1
        })
        if (res.code == 0) {
          this.$message.success(res.msg);
          row.state = res.data.state;
          if (row.state == 1) {
            row.disabl = true;
            this.getRolesList()
          } else {
            row.disabl = false;
            this.getRolesList()
          }
        } else {
          this.$message.error(res.msg)
        }
      }

    },
  }
};
</script>

