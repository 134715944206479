<template>
  <div class="historybill">
    <el-form :inline="true" :model="queryInfo" class="form">
      <el-row :gutter="24">
        <el-col style="display: flex;flex-direction: row;">
          <el-form-item label="所属电站">
            <el-select v-model="suoshudianzhan" placeholder="请选择" @change="change_value" clearable
              @clear="getPowerStationList" filterable>
              <el-option v-for="item in powerStationList" :key="item.id" :label="item.stationName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="电桩编号">
            <el-input placeholder="请输入电桩编号" @change="current" clearable @keyup.enter.native="getPowerStationList"
              v-model="queryInfo.pileCode" @clear="getPowerStationList">
            </el-input>
          </el-form-item>
          <el-form-item label="订单编号">
            <el-input placeholder="请输入订单号" clearable @change="current" @keyup.enter.native="getPowerStationList"
              v-model="queryInfo.billCode" @clear="getPowerStationList">
            </el-input>
          </el-form-item>
          <el-form-item label="充电车主">
            <el-input placeholder="请输入充电车主/手机号" @change="current" clearable @keyup.enter.native="getPowerStationList"
              v-model="queryInfo.icCode" @clear="getPowerStationList">
            </el-input>
          </el-form-item>
          <el-form-item v-if="!zhuangkuang">
            <el-button type="primary" icon="el-icon-search" @click="chaxun">查询</el-button><el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button> <el-button type="primary" @click="jizaigengduo">更多筛选</el-button>
          </el-form-item>
        </el-col>
        <el-col v-if="zhuangkuang" style="display: flex;flex-direction: row;">
          <el-form-item label="选择时间">
            <el-date-picker type="datetimerange" @blur="pickBlur" :picker-options="pickerOptions" v-model="values"
              range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']" @change="changeTime">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="桩类型">
            <el-select v-model="queryInfo.pileType" clearable placeholder="请选择工作状态" @change="chage_zhuangType"
              @clear="getPowerStationList" filterable>
              <el-option v-for="item in pileType_list" :key="item.pileType" :label="item.label" :value="item.pileType">
              </el-option>
            </el-select>
            <!-- <el-select v-model="queryInfo.pileType" placeholder="请选择" @change="chage_zhuangType" filterable clearable
                @clear="getPowerStationList" style="width: 120px;">
                <el-option v-for="item in zhuangTypelist" :key="item.pileType" :label="item.stationName"
                  :value="item.pileType">
                </el-option>
              </el-select> -->
          </el-form-item>
          <!-- :span="4" -->

          <el-form-item label="启动方式">
            <el-select v-model="queryInfo.startType" placeholder="请选择" @change="chage_startType" filterable clearable
              @clear="getPowerStationList">
              <el-option v-for="item in startTypelist" :key="item.startType" :label="item.label"
                :value="item.startType">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="支付方式">
            <el-select v-model="shifou" placeholder="请选择" @change="chage_isPay" filterable clearable
              @clear="getPowerStationList">
              <el-option v-for="item in isPay" :key="item.payState" :label="item.label" :value="item.payState">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="zhuangkuang">
          <el-form-item label="无效订单">
            <el-select v-model="queryInfo.defaultValue" placeholder="请选择" clearable @change="current"
              @clear="getPowerStationList" filterable>
              <el-option v-for="item in invalidOrder" :key="item.defaultValue" :label="item.label"
                :value="item.defaultValue">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="zhuangkuang" style="display: flex;flex-direction: row;justify-content: flex-end;padding-bottom: 20px;margin-left: -1%;">
          <el-button type="primary" icon="el-icon-search" @click="chaxun">查询</el-button>
          <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
          <el-button type="primary" @click="jizaigengduo">更多筛选</el-button>
        </el-col>
      </el-row>
    </el-form>
    <!-- 卡片视图区域 -->
    <el-card class="card">
      <li style="margin-bottom: 10px;display: flex;justify-content: flex-end;margin-top: -10px;">
      <el-button type="primary" @click="daochu">导出</el-button>
    </li>
      <div class="listname" v-if="listall.length > 0">
        <div class="dl" v-for="(item, index) in listall" :key="index">
          <p>
            {{ item.name }}
          </p>
          <p>
            {{ index !== 0 ? timesalls(item.countMoney, 100, 2) : timesalls(item.countMoney, 100, 4) }}
          </p>
        </div>
      </div>
      <!--  -->
      <!-- table表格区域 -->
      <el-table class="table" :data="chargingList" border @row-dblclick="dbclick" :lazy="true" empty-text="暂无数据">
        <el-table-column align="center" type="selection" width="55"></el-table-column>
        <el-table-column align="center" type="expand">
          <template slot-scope="scope">
            <el-form label-position="left" class="demo-table-expand">
              <el-row :gutter="20" style="display: flex;flex-direction: row;padding: 0 1%;">
                <el-col :span="6" style="display: flex;flex-direction: column;">
                  <el-form-item label="订单编号：">
                    <span v-if="scope.row.startType == 5">{{ scope.row.billCode }}</span>
                    <span v-else>{{ time_all(scope.row) }}</span>
                    <span v-if="scope.row.billCode == null">--</span>
                  </el-form-item>
                  <el-form-item label="电桩编号：">
                    <span v-if="scope.row.pileCode">{{ scope.row.pileCode }}</span>
                    <span v-else> -- </span>
                  </el-form-item>
                  <el-form-item label="开始时间：">
                    <span v-if="scope.row.startTime">{{ scope.row.startTime }}</span>
                    <span v-else> -- </span>
                  </el-form-item>
                  <el-form-item label="结束时间：">
                    <span v-if="scope.row.endTime">{{ scope.row.endTime }}</span>
                    <span v-else> -- </span>
                  </el-form-item>
                </el-col>
                <el-col :span="6" style="display: flex;flex-direction: column;">
                  <el-form-item label="充电车主：">
                    <span v-if="scope.row.icCode !== null">{{ scope.row.icCode }}</span>
                    <span v-else> -- </span>
                  </el-form-item>
                  <el-form-item label="车牌号：">
                    <span v-if="scope.row.carNum !== null">{{ scope.row.carNum }}</span>
                    <span v-else> -- </span>
                  </el-form-item>
                  <el-form-item label="车架号：">
                    <span v-if="scope.row.carvin">{{ scope.row.carvin }}</span>
                    <span v-else> -- </span>
                  </el-form-item>
                  <el-form-item label="结束原因："><span>{{ scope.row.stopReason }}</span></el-form-item>
                </el-col>
                <el-col :span="6" style="display: flex;flex-direction: column;">
                  <el-form-item label="尖时电量（度）：">
                    <span v-if="scope.row.tipKwh">{{ timesalls(scope.row.tipKwh, 100, 4) }}度</span>
                    <span v-else> 0 度</span>
                  </el-form-item>
                  <el-form-item label="峰时电量（度）：">
                    <span v-if="scope.row.peakKwh">{{ timesalls(scope.row.peakKwh, 100, 4) }}度</span>
                    <span v-else> 0 度</span>
                  </el-form-item>
                  <el-form-item label="平时电量（度）：">
                    <span v-if="scope.row.flatKwh">{{ timesalls(scope.row.flatKwh, 100, 4) }}度</span>
                    <span v-else> 0 度</span>
                  </el-form-item>
                  <el-form-item label="谷时电量（度）：">
                    <span v-if="scope.row.valleyKwh">{{ timesalls(scope.row.valleyKwh, 100, 4) }}度</span>
                    <span v-else> 0 度</span>
                  </el-form-item>
                </el-col>
                <el-col :span="6" style="display: flex;flex-direction: column;">
                  <el-form-item label="开始SOC：">
                    <el-progress :text-inside="true" :stroke-width="20" :percentage="scope.row.startSoc"
                      status="success"></el-progress>
                  </el-form-item>
                  <el-form-item label="当前SOC：">
                    <el-progress :text-inside="true" :stroke-width="20" :percentage="scope.row.nowSoc"
                      status="success"></el-progress>
                  </el-form-item>
                  <el-form-item label="充电时长（分钟）：">
                    <span>{{ timesalls(scope.row.lengthTime, 60, 0) }}分钟</span>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column align="center" label="电桩编号" prop="pileCode" width="150"></el-table-column>
        <el-table-column align="center" label="手机号" prop="phone" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.phone.length == 0">--</span>
            <span v-else>{{ scope.row.phone }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="枪号" prop="gunNumber" width="70">
          <template slot-scope="scope">
            <el-tag type="" v-if="scope.row.gunNumber == 1">{{ scope.row.gunNumName }}</el-tag>
            <el-tag type="success" v-if="scope.row.gunNumber == 2">{{ scope.row.gunNumName }}</el-tag>
            <el-tag type="warning" v-if="scope.row.gunNumber == 3">{{ scope.row.gunNumName }}</el-tag>
            <el-tag type="danger" v-if="scope.row.gunNumber == 4">{{ scope.row.gunNumName }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="开始时间" prop="startTime" width="110">
          <template slot-scope="scope">
            <span v-if="scope.row.startTime == null">--</span>
            <span v-else>{{ scope.row.startTime }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="结束时间" prop="endTime" width="110">
          <template slot-scope="scope">
            <span v-if="scope.row.endTime == null">--</span>
            <span v-else>{{ scope.row.endTime }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="充电SOC" prop="nowSoc" width="80">
          <template scope="scope">
            <span>{{ scope.row.nowSoc }}%</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="充电功率" prop="chargeKwh" width="80">
          <template scope="scope">
            <span>{{ jisuan(scope.row) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="电量（度）" prop="totalKwh" width="100">
          <template scope="scope">
            <span>{{ timesalls(scope.row.totalKwh, 100, 4) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="电费  |  服务费（元）" width="170">
          <template scope="scope">
            <el-col>
              <span>￥{{ timesalls(scope.row.chargemoney, 100, 2) }}&emsp;|&emsp;</span>
              <span
                v-if="scope.row.chargemoneygive == 0 && scope.row.servicemoneygive == 0 && scope.row.billMoneygive == 0"></span>
              <span>￥{{ timesalls(scope.row.servicemoney, 100, 2) }}</span>
            </el-col>
            <el-col
              v-if="scope.row.chargemoneygive == 0 && scope.row.servicemoneygive == 0 && scope.row.billMoneygive == 0"></el-col>
            <el-col v-else>
              <span style="color: green;">
                ￥{{ timesalls(scope.row.chargemoneygive, 100, 2) }}&emsp;|&emsp;</span>
              <span></span>
              <span style="color: green;">
                ￥{{ timesalls(scope.row.servicemoneygive, 100, 2) }}</span>
            </el-col>
          </template>
        </el-table-column>
        <el-table-column align="center" label="总费用  |  优惠（元）" prop="billMoney" width="170">
          <template scope="scope">
            <span>￥{{ timesalls(scope.row.billMoney, 100, 2) }}&emsp;|&emsp;</span><span style="color: green;">￥{{
      timesalls(scope.row.billMoneygive, 100, 2) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="实付金额" width="80">
          <template scope="scope">
            <span>￥{{ shifu(scope.row) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="启动方式" prop="startType" width="80">
          <template slot-scope="scope">
            <img style="width: 40px;
           height: 40px;" :src="scope.row.startType == 0 || scope.row.startType == 9 ? tupian + '小程序.png' : scope.row.startType == 1 ? tupian + '在线卡.png' : scope.row.startType == 2 ? tupian + '离线卡.png' : scope.row.startType == 3 ? tupian + '管理卡.png' : scope.row.startType == 4 ? tupian + 'Vin.png' : scope.row.startType == 5 ? tupian + 'hlht.png' : scope.row.startType == 6 ? tupian + 'zfb.png' :
      scope.row.startType == 9 ? tupian + 'app.png' : tupian + 'ocpp_App.png'" alt="">
          </template>
        </el-table-column>
        <el-table-column align="left" label="所属电站" prop="stationName" :show-overflow-tooltip='true'></el-table-column>
        <el-table-column align="center" label="是否支付" prop="payState" width="80">
          <template slot-scope="scope">
            <el-tag type="info">{{ scope.row.payTypeName }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="120" label="操作">
          <template slot-scope="scope">
            <span v-if="scope.row.pileType == 5"></span>
            <el-button type="primary" size="small" @click="EleticMask(scope.row)" v-else>充电趋势图</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页导航区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :page-sizes="[6, 20, 50, 100]" :current-page="queryInfo.page" :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-card>
    <el-dialog title="" :visible.sync="diaload" width="80%" @close="closeMethods" append-to-body @open="open">
      <div class="alls">
        <div class="center1">
          <h4>电流趋势图</h4>
          <div id="main1"></div>
        </div>
        <div class="center2">
          <h4>电压趋势图</h4>
          <div id="main2"></div>
        </div>
        <div class="footer" style="display: flex;justify-content: center;align-items: center">
          <el-button type="primary" v-debounce="closeMethods">确 定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import caozuo from "@/utils/pubilc.js";
import { saveBlobToFile } from '@/utils/saveBlobToFile';
import * as echarts from 'echarts';
export default {
  name: 'chargingWorld',
  data() {
    return {
      diaload: false,
      timeJson: [],
      // 需求电流
      currentDemandJson: [],
      // 需求电压
      requiredVoltageJson: [],
      // 满足电流
      outputCurrentJson: [],
      // 满足电压
      outputVoltageJson: [],
      // 电压输出
      voltageOutputValueJson: [],
      // 电流输出满足率
      outputCurrentRateJson: [],
      // 电压输出满足率
      outputVoltageRateJson: [],
      // 输出电流功率
      outputPowerJson: [],
      // 輸出电压功率
      outputYaJson: [],
      listall: [],
      caozuoquanxian: false,
      zhuangkuang: false,
      values: ['', ''],
      shifou: null,
      daochuanniu: false,
      chongzhianiu: false,
      chaxuananiu: false,
      pileType_list: [
        {
          pileType: 0,
          label: '锐创直流桩'
        },
        {
          pileType: 1,
          label: '锐创交流桩'
        },
        {
          pileType: 2,
          label: '无疆(二代)交流桩'
        },
        {
          pileType: 3,
          label: '光法(一代)直流桩'
        },
        {
          pileType: 4,
          label: '光法(一代)交流桩'
        },
        {
          pileType: 5,
          label: '锐创低速桩'
        },
        {
          pileType: 6,
          label: '其他电桩'
        }
      ],
      // zhuangTypelist: [],
      startTypelist: [
        {
          label: "小程序",
          startType: 0
        },
        {
          label: "在线卡",
          startType: 1
        },
        {
          label: "离线卡",
          startType: 2
        },
        {
          label: "管理卡",
          startType: 3
        },
        {
          label: "Vin",
          startType: 4
        },
        {
          label: "互联互通",
          startType: 5
        }, {
          label: "支付宝",
          startType: 6
        },
        {
          label: "APP",
          startType: 9
        },
        {
          label: "OCPP_APP",
          startType: 9
        },
      ],
      tupian: this.$imgUrls,
      maxValue: 0,
      // 查询充电订单列表所需参数
      queryInfo: {
        defaultValue: null,
        billCode: null,
        pileCode: null,
        pileType: null,
        gunState: '0,3,4,5,6,7',
        userNum: null,
        icCode: null,
        payState: null,
        startTime: null,
        endTime: null,
        page: 1,
        limit: 6,
        stationIds: null,
        roleId: window.sessionStorage.getItem('roleId'),
        startType: null,
        phone: null,
        billInfoType: 0
      },
      page_num: 0,
      // 充电订单数据
      chargingList: [],
      stateName: '',
      total: 0,
      value: '',
      detailList: [],
      pickerMinDate: '',
      pickerOptions: {
        onPick: obj => {
          this.pickerMinDate = new Date(obj.minDate).getTime()
          const start = this.formatDate(obj.minDate, 'start')
          const end = this.formatDate(obj.maxDate, 'end')
          obj.maxDate && (this.values = [start, end])
        },
        disabledDate: time => {
          if (this.pickerMinDate) {
            const oneMonth = 1000 * 3600 * 24 * 31
            const maxTime = this.pickerMinDate + oneMonth
            const mixTime = this.pickerMinDate - oneMonth + 1000 * 3600 * 24
            const tomorrow = new Date(this.formatDate(new Date().getTime() + 1000 * 3600 * 24, 'start'))
            return time.getTime() >= tomorrow || time.getTime() >= maxTime || time.getTime() < mixTime
          }
        }
      },
      // 电站列表数据
      powerStationList: [],
      shuchufalse: [],
      // 是否已支付
      isPay: [
        {
          label: "已支付",
          payState: 1
        },
        {
          label: "未支付",
          payState: 0
        }
      ],
      maxValue2: 0,
      // 是否无效订单数据
      invalidOrder: [
        {
          label: "是",
          defaultValue: 0
        },
        {
          label: "否",
          defaultValue: 1
        }
      ],
      suoshudianzhan: null,
    }
  },
  computed: {
    time_all() {
      return function (val) {
        const hr = val.billCode.slice(val.pileCode.length, val.billCode.length)
        return hr
      }
    },
    timesalls() {
      return function (value, num, count) {
        return (value / num).toFixed(count)
      }
    },
    shifu() {
      return function (val) {
        return ((val.billMoney - val.billMoneygive - val.chargemoneygive - val.servicemoneygive) / 100).toFixed(2)
      }
    },
    jisuan() {
      return function (val) {
        return val.pileType !== 5 ? (val.chargeKwh / 100000).toFixed(4) : val.chargeKwh
      }
    }
  },
  watch: {
    chargingList: {
      handler(newval, oldval) {
        this.chargingList = newval
      }
    }
  },
  created() {
    window.addEventListener('keydown', this.keyDown);
    caozuo(165).then(res => {
      this.caozuoquanxian = res;
    })
    this.getPowerStationList()
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDown, false)
  },
  methods: {
    // 监听回车键执行事件
    keyDown(e) {
      // // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13 || e.keyCode === 108) {
        this.getPowerStationList() // 需要执行的方法方法
      }
    },
    closeMethods() {
      this.diaload = false;
      // window.location.reload()
    },
    EleticMask(e) {
      const that = this;
      that.outputPowerJson = [];
      that.shuchufalse = [];
      that.timeJson = e.timeJson == null || e.timeJson == undefined || e.timeJson == '' ? [] : e.timeJson.split(',');
      // 需求电流json
      that.currentDemandJson = e.currentDemandJson == null || e.currentDemandJson == undefined || e.currentDemandJson == '' ? [] : e.currentDemandJson.split(",").map(item => parseFloat(item).toFixed(2));
      // // 输出电流
      that.outputCurrentJson = e.outputCurrentJson == null || e.outputCurrentJson == undefined || e.outputCurrentJson == '' ? e.currentOutputValueJson.split(",").map(item => parseFloat(item).toFixed(2)) : e.outputCurrentJson.split(",").map(item => parseFloat(item).toFixed(2));
      // // 输出电流满足率
      that.outputCurrentRateJson = e.outputCurrentRateJson == null || e.outputCurrentRateJson == undefined || e.outputCurrentRateJson == '' ? [] : e.outputCurrentRateJson.split(",").map(item => parseFloat(item).toFixed(2));
      // 输出电压
      that.outputVoltageJson = e.outputVoltageJson == null || e.outputVoltageJson == undefined || e.outputVoltageJson == '' ? [] : e.outputVoltageJson.split(",").map(item => parseFloat(item).toFixed(2));
      // // 需求电压
      that.requiredVoltageJson = e.requiredVoltageJson == null || e.requiredVoltageJson == undefined || e.requiredVoltageJson == '' ? [] : e.requiredVoltageJson.split(",").map(item => parseFloat(item).toFixed(2));
      // // 电压输出
      that.voltageOutputValueJson = e.outputVoltageJson == null || e.outputVoltageJson == undefined || e.outputVoltageJson == '' ? e.voltageOutputValueJson.split(",").map(item => parseFloat(item).toFixed(2)) : e.outputVoltageJson.split(",").map(item => parseFloat(item).toFixed(2));
      // // 输出电压满足率
      that.outputVoltageRateJson = e.outputVoltageRateJson == null || e.outputVoltageRateJson == undefined || e.outputVoltageRateJson == '' ? [] : e.outputVoltageRateJson.split(",").map(item => parseFloat(item).toFixed(2));
      that.maxValue = Math.max(...that.currentDemandJson);
      // 电压
      that.maxValue2 = Math.max(...that.requiredVoltageJson);
      if (that.currentDemandJson.length > 0 && that.outputCurrentJson.length > 0) {
        for (let i = 0; i < that.currentDemandJson.length; i++) {
          for (let j = 0; j < that.outputCurrentJson.length; j++) {
            if (i == j) {
              that.shuchufalse.push(Math.abs((that.currentDemandJson[i] - that.outputCurrentJson[j])).toFixed(2))
              break;
            }
          }
        }
      }
      if (that.outputCurrentRateJson.length > 0 && that.outputCurrentJson.length > 0) {
        for (let i = 0; i < that.outputCurrentRateJson.length; i++) {
          const element = that.outputCurrentRateJson[i];
          for (let j = 0; j < that.outputCurrentJson.length; j++) {
            const elements = that.outputCurrentJson[j];
            that.outputPowerJson.push(((elements / element) * 100).toFixed(2));
            break;
          }
        }
      }
      if (that.outputVoltageRateJson.length > 0 && that.voltageOutputValueJson.length > 0) {
        for (let i = 0; i < that.outputVoltageRateJson.length; i++) {
          const element = that.outputVoltageRateJson[i];
          for (let j = 0; j < that.voltageOutputValueJson.length; j++) {
            const elements = that.voltageOutputValueJson[j];
            that.outputYaJson.push(((elements / element) * 100).toFixed(2))
          }
        }
      }
      that.diaload = true;
      that.defaultMethods();
    },
    open() {
      this.$nextTick(() => {
        this.diaload = true;
        this.defaultMethods();
      })
    },
    defaultMethods() {
      const that = this;
      const myChart = echarts.init(document.getElementById('main1'));
      const myCharts = echarts.init(document.getElementById('main2'));
      // 电流
      const option = {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          textStyle: {
            fontSize: 16
          }
        },
        // , '输出电流功率'
        legend: {
          data: ['需求电流', '输出电流', '输出电流满足率'],
          textStyle: {
            fontSize: 20
          }
        },
        // , '#D3910F'
        color: ['#C6372E', '#1077C2', '#41CA12'],
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: that.timeJson,
          textStyle: {
            fontSize: 16
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#c5c5c5',
              type: 'solid',
              width: 0.8
            }
          }
        },
        yAxis: {
          type: 'value',
          min: 0,
          max: that.maxValue < 1000 ? (that.maxValue * 1 + 100 * 1) : (that.maxValue * 1 + 1000 * 1),
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#59924d',
            type: 'solid',
            width: 0.8
          }
        },
        series: [
          {
            name: '需求电流',
            type: 'line',
            smooth: true,
            tooltip: {
              valueFormatter: function (value) {
                return value == null || value == undefined || value == '' || value == 'NaN' ? 0 + 'A' : value + 'A';
              }
            },
            data: that.currentDemandJson,
            markPoint: {
              data: [
                { type: 'max', name: 'Max', label: { show: true, color: '#fff' } },
                { type: 'min', name: 'Min', label: { show: true, color: '#fff' } }
              ],
              symbolSize: 80
            },
            lineStyle: {
              color: '#C6372E' // 将 'red' 替换为您想要的颜色
            }
          },
          {
            name: '输出电流',
            type: 'line',
            smooth: true,
            tooltip: {
              valueFormatter: function (value) {
                return value == null || value == undefined || value == '' || value == 'NaN' ? 0 + 'A' : value + 'A';
              }
            },
            data: that.outputCurrentJson,
            markPoint: {
              data: [
                { type: 'max', name: 'Max', label: { show: true, color: '#fff' } },
                { type: 'min', name: 'Min', label: { show: true, color: '#fff' } }
              ],
              symbolSize: 80
            },
            lineStyle: {
              color: '#1077C2' // 将 'red' 替换为您想要的颜色
            }
          },
          {
            name: '输出电流满足率',
            type: 'line',
            smooth: true,
            tooltip: {
              valueFormatter: function (value) {
                return value == null || value == undefined || value == '' || value == 'NaN' ? 0 + '%' : value + '%';
              }
            },
            data: that.outputCurrentRateJson,
            markPoint: {
              data: [
                { type: 'max', name: 'Max', label: { show: true, color: '#fff' } },
                { type: 'min', name: 'Min', label: { show: true, color: '#fff' } }
              ],
              symbolSize: 80
            },
            lineStyle: {
              color: '#41CA12' // 将 'red' 替换为您想要的颜色
            }
          },
          // {
          //   name: '输出电流功率',
          //   type: 'line',
          //   smooth: true,
          //   tooltip: {
          //     valueFormatter: function (value) {
          //       return value == null || value == undefined || value == '' || value == 'NaN' ? 0 + 'Kw' : value + 'Kw';
          //     }
          //   },
          //   data: that.outputPowerJson,
          //   markPoint: {
          //     data: [
          //       { type: 'max', name: 'Max', label: { show: true, color: '#fff' } },
          //       { type: 'min', name: 'Min', label: { show: true, color: '#fff' } }
          //     ],
          //     symbolSize: 80
          //   },
          //   lineStyle: {
          //     color: '#D3910F' // 将 'red' 替换为您想要的颜色
          //   }
          // }
        ]
      };
      // 电压
      const options = {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          textStyle: {
            fontSize: 16
          }
        },
        // , '输出电压功率'
        legend: {
          data: ['需求电压', '输出电压', '输出电压满足率'],
          textStyle: {
            fontSize: 20
          }
        },
        // , '#D3910F'
        color: ['#C6372E', '#1077C2', '#41CA12'],
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: that.timeJson,
          splitLine: {
            show: true,
            lineStyle: {
              color: '#c5c5c5',
              type: 'solid',
              width: 0.8
            }
          }
        },
        yAxis: {
          type: 'value',
          min: 0,
          max: that.maxValue2.toFixed(0) < 1000 ? (that.maxValue2.toFixed(0) * 1 + 100 * 1) : (that.maxValue2.toFixed(0) * 1 + 1000 * 1),
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [
          {
            name: '需求电压',
            type: 'line',
            tooltip: {
              valueFormatter: function (value) {
                return value == null || value == undefined || value == '' || value == 'NaN' ? 0 + 'V' : value + 'V';
              }
            },
            data: that.requiredVoltageJson,
            markPoint: {
              data: [
                { type: 'max', name: 'Max', label: { show: true, color: '#fff' } },
                { type: 'min', name: 'Min', label: { show: true, color: '#fff' } }
              ],
              symbolSize: 80
            },
            lineStyle: {
              color: '#C6372E' // 将 'red' 替换为您想要的颜色
            }
          },
          {
            name: '输出电压',
            type: 'line',
            tooltip: {
              valueFormatter: function (value) {
                return value == null || value == undefined || value == '' || value == 'NaN' ? 0 + 'V' : value + 'V';
              }
            },
            data: that.voltageOutputValueJson,
            markPoint: {
              data: [
                { type: 'max', name: 'Max', label: { show: true, color: '#fff' } },
                { type: 'min', name: 'Min', label: { show: true, color: '#fff' } }
              ],
              symbolSize: 80
            },
            lineStyle: {
              color: '#1077C2' // 将 'red' 替换为您想要的颜色
            }
          },
          {
            name: '输出电压满足率',
            type: 'line',
            tooltip: {
              valueFormatter: function (value) {
                return value == null || value == undefined || value == '' || value == 'NaN' ? 0 + '%' : value + '%';
              }
            },
            data: that.outputVoltageRateJson,
            markPoint: {
              data: [
                { type: 'max', name: 'Max', label: { show: true, color: '#fff' } },
                { type: 'min', name: 'Min', label: { show: true, color: '#fff' } }
              ],
              symbolSize: 80
            },
            lineStyle: {
              color: '#41CA12' // 将 'red' 替换为您想要的颜色
            }
          },
          // {
          //   name: '输出电压功率',
          //   type: 'line',
          //   tooltip: {
          //     valueFormatter: function (value) {
          //       return value == null || value == undefined || value == '' || value == 'NaN' ? 0 + 'Kw' : value + 'Kw';
          //     }
          //   },
          //   data: that.outputYaJson,
          //   markPoint: {
          //     data: [
          //       { type: 'max', name: 'Max', label: { show: true, color: '#fff' } },
          //       { type: 'min', name: 'Min', label: { show: true, color: '#fff' } }
          //     ],
          //     symbolSize: 80
          //   },
          //   lineStyle: {
          //     color: '#D3910F' // 将 'red' 替换为您想要的颜色
          //   }
          // }
        ]
      };
      myChart.setOption(option);
      myCharts.setOption(options);
    },
    pickBlur() {
      this.pickerMinDate = ''
    },
    formatDate(datetime, type) {
      const time = new Date(datetime)
      const year = time.getFullYear()
      const month = (time.getMonth() + 1).toString().padStart(2, '0')
      const date = (time.getDate()).toString().padStart(2, '0')
      return type === 'start' ? year + '-' + month + '-' + date + ' ' + '00' + ':' + '00' + ':' + '00' : year + '-' + month + '-' + date + ' ' + '23' + ':' + '59' + ':' + '59'
    },
    getDate() {
      const start = new Date()
      this.values[0] = this.formatDate(start.getTime() - 1000 * 3600 * 24 * 30, 'start')
      this.values[1] = this.formatDate(start.getTime(), 'end')
    },
    zhanshi() {
      this.yincang = !this.yincang
    },
    daochu() {
      if (this.queryInfo.stationIds.length == 0 || this.queryInfo.stationIds == undefined || this.queryInfo.stationIds == null) { this.queryInfo.stationIds = window.sessionStorage.getItem('stationIds') };
      if (this.queryInfo.startTime != null && this.queryInfo.endTime != null) {
        this.$http.get('bill-info/export', {
          responseType: 'blob',
          params: {
            startTime: this.queryInfo.startTime,
            endTime: this.queryInfo.endTime,
            stationIds: this.queryInfo.stationIds.toString(),
            adminId: window.sessionStorage.getItem('adminId') * 1
          }
        }).then(res => {
          if (res.data) {
            saveBlobToFile(res.data, '历史订单电站列表');
            this.$message.success('文件导出成功');
          } else {
            this.$message.error('文件导出失败');
          };
        })
      } else {
        this.$message.error('请选择你要导出的电站与时间');
      };

    },
    jizaigengduo() {
      this.zhuangkuang = !this.zhuangkuang;
    },

    changeTime(value) {
      this.queryInfo.page = 1;
      if (value) {
        this.queryInfo.startTime = value[0];
        this.queryInfo.endTime = value[1];
        if (this.queryInfo.startTime == this.queryInfo.endTime) {
          this.$message.error('起始时间不得为同一天！');
          this.queryInfo.startTime = ''
          this.queryInfo.endTime = ''
        }
      } else {
        this.queryInfo.startTime = null
        this.queryInfo.endTime = null
        this.pickerMinDate = '';
        this.getPowerStationList()
      }
    },
    change_value(e) {
      this.queryInfo.page = 1;
      if (e !== null || e !== undefined) {
        this.queryInfo.stationIds = e;
      }
    },
    // 启动方式
    chage_startType(e) {
      this.queryInfo.page = 1;
      if (e !== null || e !== undefined) {
        this.queryInfo.startType = e;
        // this.queryInfo.pileType = e;
      }
    },
    // 桩类型
    chage_zhuangType(e) {
      this.queryInfo.page = 1;
      if (e !== null || e !== undefined) {
        // this.queryInfo.startType = e;
        this.queryInfo.pileType = e;
      }
    },
    current() {
      this.queryInfo.page = 1;
    },
    // 支付方式
    chage_isPay(e) {
      this.queryInfo.page = 1;
      if (e !== null || e !== undefined) {
        this.queryInfo.payState = e;
      }
    },
    // 重置搜索条件
    chongzhi() {
      this.chongzhianiu = true;
      this.queryInfo = {
        defaultValue: null,
        billCode: null,
        pileCode: null,
        pileType: null,
        gunState: '0,3,4,5,6,7',
        userNum: null,
        icCode: null,
        payState: null,
        startTime: null,
        endTime: null,
        page: 1,
        limit: 6,
        stationIds: null,
        roleId: window.sessionStorage.getItem('roleId'),
        startType: null,
        phone: null,
        billInfoType: 0
      };
      this.suoshudianzhan = null;
      this.values = [];
      this.pickerMinDate = '';
      this.getPowerStationList();
      setTimeout(() => {
        this.chongzhianiu = false;
      }, 500);
    },
    chaxun() {
      this.chaxuananiu = true;
      this.getPowerStationList();
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.queryInfo.page = 1;
      this.chaxun()
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.chaxun()
    },
    // 获取电站列表数据
    getPowerStationList() {
      this.queryInfo.stationIds = this.suoshudianzhan ? this.suoshudianzhan : window.sessionStorage.getItem('stationIds');
      this.chargingList = [];
      this.powerStationList = [];
      this.listall = [];
      if (this.queryInfo.stationIds == null || this.queryInfo.stationIds == undefined) return;
      if (this.queryInfo.billCode !== null && this.queryInfo.billCode !== '' && this.queryInfo.billCode !== undefined) {
        this.queryInfo.billCode = this.queryInfo.billCode.trim().split(" ").join("");
      }
      if (this.queryInfo.icCode !== null && this.queryInfo.icCode !== '' && this.queryInfo.icCode !== undefined) {
        this.queryInfo.icCode = this.queryInfo.icCode.trim().split(" ").join("");
      }
      this.$http.get('bill-info/getAllBillInfo', { params: this.queryInfo }).then(res => {
        if (res.data.code == 0) {
          this.chargingList = res.data.data;
          this.total = res.data.count;
          this.listall = [
            {
              name: '充电电量（度）',
              countMoney: res.data.data[0].totalBillKwhs
            },
            {
              name: '电费总额（元）',
              countMoney: res.data.data[0].totalChargeMoney
            },
            {
              name: '服务费总额（元）',
              countMoney: res.data.data[0].totalServiceMoney
            },
            {
              name: '充电总额（元）',
              countMoney: res.data.data[0].totalBillMoney
            },
            {
              name: '充电优惠总额（元）',
              countMoney: res.data.data[0].totalDiscountMoney
            },
            {
              name: '实收总额（元）',
              countMoney: res.data.data[0].totalBillMoney - res.data.data[0].totalDiscountMoney
            }
          ];
        }
      });
      setTimeout(() => {
        this.chongzhianiu = false;
      }, 500);
      this.$http.get('station-info/getAllStationInfoName', {
        params: {
          roleId: window.sessionStorage.getItem('roleId'),
          stationIds: window.sessionStorage.getItem('stationIds'),
          stationMode: '0,1,2'
        }
      }).then(resb => {
        if (resb.data.code == 0) {
          resb.data.data.forEach(item => {
            this.powerStationList.push({
              id: item.id,
              stationName: item.stationName
            })
          })
        }
      });

    },
    // 展示订单包含子项内容
    async showDetail(id) {
      const { data: res } = await this.$http.get('order/getOrderById?id=' + id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.detailList = res.data
    },
    // 双击展开折叠
    dbclick(row, column, event) {
      // console.log(event.currentTarget.querySelector(".el-table__expand-icon"))
      if (event.currentTarget.querySelector(".el-table__expand-icon")) {
        event.currentTarget.querySelector(".el-table__expand-icon").click()
      }
    },
    // 结算订单
    async removeById(id) {
      const confirmResult = await this.$confirm('此操作将结算该订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消结算！')
      }
      const { data: res } = await this.$http.post('adminLog/delAdminLogById?id=' + id)
      if (res.code !== 0) {
        return this.$message.error('结算失败！')
      }
      this.$message.success('结算成功！')
      this.getPowerStationList()
    }
  }
};
</script>

<style lang="less" scoped>
.historybill {
  width: 99%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.el-row{
  display: flex;
  flex-direction: column;
  .el-col{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.card {
  width: 100%;
  height: 99%;
  overflow-y: scroll;
  margin-bottom: 1%;
}

.alls {
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  .footer {
    height: 30px;
    margin: 10px 0;
  }

  .center1,
  .center2 {
    display: flex;
    flex-direction: column;

    h4 {
      height: 30px;
      font-size: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    #main1,
    #main2 {
      height: 400px;
      width: 90%;
      margin: 0 5%;
    }
  }
}

.listname {
  display: flex;
  flex-direction: row;
  height: 120px;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 20px;
  border: 1px solid gray;
  list-style: none;
  border-radius: 15px;
  font-size: 16px;

  .dl {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 20px;

    p {
      flex: 1;
      display: flex;
      justify-content: center;
      // font-size: 16px;
    }

    p:nth-child(1) {
      color: gray;
      // font-size: 16px;
    }

  }

  .dl:last-child {
    margin-right: 0;
  }
}
</style>
<!-- <style lang="less" scoped>
.demo-table-expand {
  color: rgb(52, 115, 250);
}

.demo-table-expand .el-form-item {
  margin: 0 20px;
}
</style>