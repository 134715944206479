<template>
  <div class="totallist">
    <el-row :gutter="24" style="padding:0 0 0px 20px">
      <el-form :inline="true" :model="querinfo" class="form">
        <el-form-item label="地图类型">
          <el-select v-model="querinfo.type" placeholder="请选择地图类型" clearable @clear="chongzhi"
            filterable>
            <el-option v-for="item in mapList" :key="item" :label="item == 0 ? '地图平台' : '聚合平台'" :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地图名称">
          <el-input v-model="querinfo.name" placeholder="请输入地图名称" clearable @clear="chongzhi"
            filterable></el-input>
        </el-form-item>
        <el-form-item label="引流状态">
          <el-select v-model="querinfo.status" placeholder="请选择引流状态" clearable @clear="chongzhi"
            filterable>
            <el-option v-for="item in typeList" :key="item" :label="item == 0 ? '待开通' : '已开通'" :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="优惠码">
          <el-input v-model="querinfo.code" placeholder="请输入优惠码" clearable @clear="chongzhi"
            filterable></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="listMethods">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-card>
      <!-- <el-row :gutter="20">
        <el-col>
          <span>引流平台</span>
          <el-select filterable v-model="queryinfo.name" placeholder="请选择引流平台" @clear="getOnAccountList"
            @change="current">
            <el-option v-for="item in listname" :key="item.name" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </el-col>
      </el-row> -->
      <el-table :data="List" border width="80%">
        <el-table-column align="center" label="引流电站" prop="stationName"></el-table-column>
        <el-table-column align="center" label="引流类型" prop="type">
          <template slot-scope="scope">
            <span>{{ scope.row.type == 1 ? '聚合平台' : '地图平台' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="引流平台" prop="name"></el-table-column>
        <el-table-column align="center" label="开通时间" prop="createTime">
          <template slot-scope="scope">
            <span>{{ scope.row.createTime != null ? scope.row.createTime : '--' }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" label="优惠码" prop="code">
          <template slot-scope="scope">
            <span>{{scope.row.code !== null&&scope.row.code!==''? scope.row.code:'--' }}</span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column align="center" label="付款金额" prop="money">
          <template slot-scope="scope">
            <span>￥{{ jisuan(scope.row.money,100,2) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="申请时间" prop="updateTime">
          <template slot-scope="scope">
            <span>{{scope.row.updateTime !== null? scope.row.updateTime:'--' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="开始时间" prop="startTime">
          <template slot-scope="scope">
            <span>{{scope.row.startTime !== null? scope.row.startTime:'--' }}</span>
          </template>
        </el-table-column> -->
        <el-table-column align="center" label="到期时间" prop="updateTime">
          <template slot-scope="scope">
            <span>{{ scope.row.endTime !== null ? scope.row.endTime : '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态" prop="status">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.status == 0 ? 'warning' : scope.row.status == 1 ? 'success' : scope.row.status == 2 ? 'info' : scope.row.status == 3 ? 'primary' : ''">{{
      scope.row.status == 0 ? '待开通' : scope.row.status == 1 ? '已开通' : scope.row.status == 2 ? '已到期' :
        scope.row.status == 3 ? '未到期' : ''
    }}</el-tag>
            <!-- <span>{{ scope.row.status == 0 ? '待开通' : '已开通' }}</span> -->
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button @click="shenhe(scope.row)" v-if="scope.row.status == 2" type="success">续费</el-button>
            <el-button @click="rizi(scope.row)" type="warning">日志</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="querinfo.page"
        :page-sizes="[8, 20, 50, 100]" :page-size="querinfo.limit" layout="total, sizes, prev, pager, next, jumper"
        :total="total" background>
      </el-pagination>
    </el-card>
    <el-dialog title="引流日志" :visible.sync="dialoag" width="60%" @close="MoneyDialoag" append-to-body
      :close-on-click-modal="false">
      <el-table :data="carInfoTable" border width="80%">
        <el-table-column align="center" label="引流电站" prop="stationName"></el-table-column>
        <el-table-column align="center" label="引流类型" prop="type" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.type == 1 ? '聚合平台' : '地图平台' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="引流平台" prop="name" width="100"></el-table-column>
        <el-table-column align="" label="订单编号" prop="billCode"></el-table-column>
        <el-table-column align="center" label="优惠码" prop="code" width="100"></el-table-column>
        <el-table-column align="center" label="引流备注" prop="des"></el-table-column>
        <el-table-column align="center" label="开通时间" prop="createTime" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.createTime != null ? scope.row.createTime : '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="更新时间" prop="updateTime" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.updateTime !== null ? scope.row.updateTime : '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态" prop="status" width="80">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.status == 0 ? 'warning' : scope.row.status == 1 ? 'success' : scope.row.status == 2 ? 'info' : scope.row.status == 3 ? 'primary' : ''">{{
      scope.row.status == 0 ? '待开通' : scope.row.status == 1 ? '已开通' : scope.row.status == 2 ? '已到期' :
        scope.row.status == 3 ? '未到期' : ''
    }}</el-tag>
            <!-- <span>{{ scope.row.status == 0 ? '待开通' : '已开通' }}</span> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="sizeChange" @current-change="CurrentChange" :current-page="carInfo.page"
        :page-sizes="[8, 20, 50, 100]" :page-size="carInfo.limit" layout="total, sizes, prev, pager, next, jumper"
        :total="CarInfototal" background>
      </el-pagination>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: '',
  mixins: [],
  data() {
    return {
      total: 0,
      listname: [],
      List: [],
      mapList: [0, 1],
      typeList: [0, 1],
      querinfo: {
        page: 1,
        name: '',
        limit: 8,
        adminId: window.sessionStorage.getItem('adminId'),
        type: null,
        code: null,
        status: null
      },
      total: 0,
      carInfo: {
        page: 1,
        limit: 8,
        id: null,
        adminId: window.sessionStorage.getItem('adminId'),
        drainageId: null,
        type: null,
        name: null,
        code: null
      },
      dialoag: false,
      CarInfototal: 0,
      carInfoTable: []
    }
  },
  computed: {
    jisuan() {
      return (obj, num, fixed) => {
        return (obj / num).toFixed(fixed)
      }
    }
  },
  watch: {

  },
  created() {
    this.listMethods()
  },
  mounted() {

  },
  methods: {
    chongzhi() {
      this.querinfo = {
        page: 1,
        name: '',
        limit: 8,
        adminId: window.sessionStorage.getItem('adminId'),
        type: null,
        code: null,
        status: null
      }
      this.listMethods()
    },
    sizeChange(newPage) {
      this.carInfo.page = newPage;
      this.demoMethods()
    },
    CurrentChange(newSize) {
      this.carInfo.limit = newSize;
      this.carInfo.page = 1;
      this.demoMethods()
    },
    MoneyDialoag() {
      this.dialoag = false;
      this.carInfo.page = 1;
      this.carInfo.limit = 8;
    },
    // 日志
    rizi(e) {
      const that = this;
      that.carInfo.drainageId = e.id;
      that.dialoag = true;
      that.demoMethods()
    },
    demoMethods() {
      const that = this;
      that.$http.get('drainage-pay-log/getAllDrainagePayLog', { params: that.carInfo }).then(res => {
        that.CarInfototal = res.data.count;
        that.carInfoTable = res.data.code == 0 ? res.data.data : []
      })
    },
    // 续费
    shenhe(e) {
      const that = this;
      // that.$http.post('drainage/createNative', e).then(res => {
      //   that.out_trade_no = res.data.out_trade_no;
      //   that.imageurls = res.data.QrCode;
      //   that.dialoag = false;
      //   that.dialoag2 = true;
      // })
      // that.$http.post('drainage/editDrainage', {
      //   id: e.id,
      //   status: 1
      // }).then(res => {
      //   if (res.data.code !== 0) return that.$message.error('开通失败');
      //   that.$confirm('审核通过，请及时为其开通。', '审核通过', {
      //     confirmButtonText: '确定'
      //   }).then(() => {
      //     that.listMethods()
      //   })
      // })
    },
    listMethods() {
      const that = this;
      that.$http.get('drainage/getAllDrainage', {
        params: that.querinfo
      }).then(res => {
        that.List =res.data.code !== 0?[]: res.data.data;
        that.total = res.data.count;
      })
    },
    // 检索
    current() {

    },
    // 检索
    getOnAccountList() {

    },
    handleSizeChange(newSize) {
      this.querinfo.limit = newSize;
      this.querinfo.page = 1;
      this.listMethods()
    },
    handleCurrentChange(newPage) {
      this.querinfo.page = newPage;
      this.listMethods()
    }
  }
};
</script>

<style lang='less' scoped>
.totallist {
  width: 100%;
  height: 100%
}
</style>