<template>
  <div class="all">
    <div class="top" style="padding-bottom: 20px;">
      <li>
        <el-select v-model="queryInfo.stationIds" placeholder="请选择电站" @change="commicMethods" filterable>
          <el-option v-for="item in stationlist" :key="item.id" :label="item.stationName" :value="item.id">
          </el-option>
        </el-select>
      </li>
      <li>
        <el-select v-model="queryInfo.type" placeholder="请选择时间类型" @change="commicMethods" filterable>
          <el-option v-for="item in timeList" :key="item" :label="item == 0 ? '今日' : item == 1 ? '昨日' : item == 2 ? '当月' : '上月'"
            :value="item">
          </el-option>
        </el-select>
      </li>
      <li>
        <el-date-picker style="margin-left: 20px;" v-model="picker_value2" clearable :picker-options="pickerOptions"
          type="daterange" align="right" range-separator="--" value-format="yyyy-MM-dd" start-placeholder="开始日期"
          @blur="pickBlur" end-placeholder="结束日期" @change="picker_event">
        </el-date-picker>
      </li>
    </div>
    <div class="content">
      <div class="center">
        <div class="center1">
          <li>
            <span>总金额：</span><span>￥{{ jisuan(listobj.totalMoney, 100, 2) }}</span>
          </li>
          <li>
            <p><span>电费：</span><span>￥{{ jisuan(listobj.totalChargeMoney, 100, 2) }}</span></p>
            <p><span>服务费：</span><span>￥{{ jisuan(listobj.totalServiceMoney, 100, 2) }}</span></p>
            <p><span>站桩费：</span><span>￥{{ jisuan(listobj.totalSpaceOccupancyFee, 100, 2) }}</span></p>
            <p><span>用户充值：</span><span>￥{{ jisuan(listobj.totalRechargeMoney, 100, 2) }}</span></p>
            <p><span>用户退款：</span><span>￥{{ jisuan(listobj.totalRefundMoney, 100, 2) }}</span></p>
            <p><span>赠送：</span><span>￥{{ jisuan(listobj.totalGiveMoney, 100, 2) }}</span></p>
          </li>
        </div>
        <div class="center2">
          <li>
            <dt>
              <p>充电电量</p>
              <p>{{ jisuan(listobj.totalElectricityLevel, 100, 4) }}度</p>
            </dt>
            <dd>
              <img src="../../../assets/images/icon.png" alt="">
            </dd>
          </li>
          <li>
            <dt>
              <p>充电次数</p>
              <p>{{ listobj.totalBillCount }}次</p>
            </dt>
            <dd>
              <img src="../../../assets/images/icon.png" alt="">
            </dd>
          </li>
          <li>
            <h4>
              <span>实时状态</span>
              <!-- <el-tag text="已停用0" type="info"></el-tag> -->
            </h4>
            <div class="bottoms">
              <dl>
                <dt>
                  <img src="../../../assets/images/icon.png" alt="">
                </dt>
                <dd>
                  <span>充电中</span>
                  <span>{{ listobj.chargePileCount }}</span>
                </dd>
              </dl>
              <dl>
                <dt>
                  <img src="../../../assets/images/icon.png" alt="">
                </dt>
                <dd>
                  <span>空闲中</span>
                  <span>{{ listobj.freePileCount }}</span>
                </dd>
              </dl>
              <dl>
                <dt>
                  <img src="../../../assets/images/icon.png" alt="">
                </dt>
                <dd>
                  <span>离线中</span>
                  <span>{{ listobj.offLinePileCount }}</span>
                </dd>
              </dl>
              <dl>
                <dt>
                  <img src="../../../assets/images/icon.png" alt="">
                </dt>
                <dd>
                  <span>故障中</span>
                  <span>{{ listobj.faultPileCount }}</span>
                </dd>
              </dl>
              <dl>
                <dt>
                  <img src="../../../assets/images/icon.png" alt="">
                </dt>
                <dd>
                  <span>占用中</span>
                  <span>{{ listobj.occupyPileCount }}</span>
                </dd>
              </dl>
            </div>
          </li>
        </div>
      </div>
      <div class="bottom">
        <h4>场站经营数据</h4>
        <div id="main">
          <!-- 折混合图1 -->
        </div>
        <div id="mains">
          <!-- 折混合图2 -->
        </div>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  name: '',
  mixins: [],
  props: {

  },
  data() {
    return {
      moneyType: 0,
      stationlist: [],
      timeList: [
        0, 1, 2, 3
      ],
      pars: {

      },
      listobj: {
        money: 0,
        num: 0
      },
      queryInfo: {
        wxIds: window.sessionStorage.getItem('appId'),
        roleId: window.sessionStorage.getItem('roleId'),
        stationIds: window.sessionStorage.getItem('stationIds'),
        type: 0,
        startTime: null,
        endTime: null
      },
      pickerMinDate: '',
      pickerOptions: {
        onPick: obj => {
          this.pickerMinDate = new Date(obj.minDate).getTime()
          const start = this.formatDate(obj.minDate, 'start')
          const end = this.formatDate(obj.maxDate, 'end')
          obj.maxDate && (this.values = [start, end])
        },
        disabledDate: time => {
          if (this.pickerMinDate) {
            const oneMonth = 1000 * 3600 * 24 * 31
            const maxTime = this.pickerMinDate + oneMonth
            const mixTime = this.pickerMinDate - oneMonth + 1000 * 3600 * 24
            const tomorrow = new Date(this.formatDate(new Date().getTime() + 1000 * 3600 * 24, 'start'))
            return time.getTime() >= tomorrow || time.getTime() >= maxTime || time.getTime() < mixTime
          }
        }
      },
      powerStationList: [],
      picker_value2: []
    }
  },
  computed: {
    circle2() {
      return (obj, obj2, num, num2) => {
        return (obj - obj2) * num.toFixed(num2)
      }
    },
    circle() {
      return (obj, obj2, num) => {
        return (obj - obj2).toFixed(num)
      }
    },
    jisuan() {
      return (obj, num, fixed) => {
        if (obj == null || obj == undefined || obj == '') return 0;
        return (obj / num).toFixed(fixed)
      }
    }
  },
  watch: {

  },
  created() {
    this.stationlist.push({
      id: window.sessionStorage.getItem('stationIds'),
      stationName: '全部电站'
    });
    this.$http.get('station-info/getAllStationInfoName', {
      params: {
        roleId: window.sessionStorage.getItem('roleId'),
        stationIds: window.sessionStorage.getItem('stationIds'),
        stationMode: '0,2'
      }
    }).then(res => {
      res.data.data.forEach(item => {
        this.stationlist.push({
          id: item.id,
          stationName: item.stationName
        })
      })
    });
    this.commicMethods()
    // this.zhexian();
    // this.bingtu();
  },
  mounted() {

  },
  methods: {
    formatDate(datetime, type) {
      const time = new Date(datetime)
      const year = time.getFullYear()
      const month = (time.getMonth() + 1).toString().padStart(2, '0')
      const date = (time.getDate()).toString().padStart(2, '0')
      return type === 'start' ? year + '-' + month + '-' + date + ' ' + '00' + ':' + '00' + ':' + '00' : year + '-' + month + '-' + date + ' ' + '23' + ':' + '59' + ':' + '59'
    },
    commicMethods() {
      const that = this;
      that.$http.get('data-analysis/getAllDataAnalysis', {
        params: that.queryInfo
      }).then(res => {
        if (res.data.code !== 0) return;
        that.listobj = res.data.data;
      })
    },
    // 折柱混合图
    async zhexian() {
      this.operateTime = [];
      this.arr1 = [];
      this.arr2 = [];
      this.arr3 = [];
      this.arr4 = [];
      const { data: res } = await this.$http.get('bill-info/getEveryDayBill', {
        params: this.pars
      })
      if (res.code == 0) {
        for (let i = 0; i < res.data.length; i++) {
          this.arr1.push(Number(res.data[i].totalCount));
          this.arr2.push(Number(res.data[i].totalServiceMoney / 100).toFixed(2));
          this.arr3.push(Number(res.data[i].totalChargeMoney / 100).toFixed(2));
          this.arr4.push(Number(res.data[i].totalKwhs / 100).toFixed(2));
          this.operateTime.push(res.data[i].operateTime);
        };
        let aaa = Math.max(...this.arr1, ...this.arr2, ...this.arr3, ...this.arr4);
        this.dianfei = (aaa + 100).toFixed(2);
        let chartDom = document.getElementById('main');
        let myCharta = echarts.init(chartDom);
        let option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          grid: {
            left: '.5%',    // 距离左边的距离
            right: '1%',   // 距离右边的距离
            top: '12%',     // 距离顶部的距离
            bottom: '1%',   // 距离底部的距离
            containLabel: true
          },
          barWidth: 10,
          legend: {
            data: ['订单数', '服务费（元）', '电费（元）', '电量（度）']
          },
          xAxis: [
            {
              type: 'category',
              data: this.operateTime,
              axisPointer: {
                type: 'shadow'
              },
              axisLabel: {
                fontSize: 10
              },
              axisTick: {
                show: false
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '电费（元）',
              min: 0,
              max: this.dianfei,

            },
            {
              type: 'value',
              min: 0,
              max: this.dianfei,

            }
          ],
          series: [
            {
              name: '订单数',
              type: 'bar',
              tooltip: {
                valueFormatter: function (value) {
                  return value;
                }
              },
              data: this.arr1,

            },
            {
              name: '服务费（元）',
              type: 'bar',
              tooltip: {
                valueFormatter: function (value) {
                  return value + '元';
                }
              },
              data: this.arr2,
            },
            {
              name: '电费（元）',
              type: 'bar',
              yAxisIndex: 1,
              tooltip: {
                valueFormatter: function (value) {
                  return value + '元';
                }
              },
              data: this.arr3,
            },
            {
              name: '电量（度）',
              type: 'line',
              yAxisIndex: 1,
              tooltip: {
                valueFormatter: function (value) {
                  return value + '度';
                }
              },
              data: this.arr4,
              symbol: 'none'
            }
          ]
        };
        // setTimeout(() => {
        option && myCharta.setOption(option);
        // }, 1000)

      } else {
        this.value3 = [];
      }
    },
    pickBlur() {
      this.pickerMinDate = ''
    },
    picker_event(e) {
      if (e != null) {
        this.queryInfo.startTime = e[0];
        this.queryInfo.endTime = e[1];
        this.queryInfo.type = 4;
        this.commicMethods()
      } else {
        this.queryInfo.type = 0;
        this.queryInfo.startTime = null;
        this.queryInfo.endTime = null;
        this.commicMethods()
      }
      // 折柱混合图
      // this.zhexian();
      // this.zongshouru();
    }
  }
};
</script>

<style lang='less' scoped>
.all {
  width: 100%;
  height: 100%;
  background: #ccc;

  .top {
    height: 1%;
    display: flex;
    align-items: center;
    flex-direction: row;
    background: #fff;
    margin-bottom: 10px;

    li {
      margin-right: 10px;
    }
  }

  .content {
    height: 99%;
    display: flex;
    flex-direction: column;

    .center {
      flex: 2;
      display: flex;
      flex-direction: column;

      .center1,
      .center2 {
        flex: 1;
      }

      .center1 {
        display: flex;
        flex-direction: column;
        background-color: #fff;

        li {
          flex: 1;
          display: flex;
          flex-direction: row;
        }

        li:nth-child(1) {
          align-items: center;

          span:nth-child(1) {
            font-size: 18px;
          }

          span:nth-child(2) {
            font-size: 16px;
          }
        }

        li:nth-child(2) {
          p {
            flex: 1;
            font-size: 16px;
            display: flex;
            align-items: center;
          }
        }
      }

      .center2 {
        display: flex;
        flex-direction: row;
        margin: 10px 0;

        li:nth-child(1),
        li:nth-child(2) {
          background-color: #fff;
          margin-right: 10px;
          flex: 2;
          display: flex;
          flex-direction: row;
          font-size: 16px;

          dt {
            flex: 8;
            display: flex;
            flex-direction: column;

            p {
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          img {
            width: 50px;
            height: 50px;
          }

          dd {
            flex: 2;
            display: flex;
            align-items: center;
          }
        }

        li:nth-child(3) {
          flex: 6;
          display: flex;
          flex-direction: column;
          background-color: #fff;
          padding-left: 20px;
          font-size: 16px;

          h4 {
            flex: 1;
          }

          .bottoms {
            flex: 9;
            display: flex;
            flex-direction: row;

            dl {
              flex: 1;
              display: flex;
              flex-direction: row;

              dt {
                flex: 3;
                display: flex;
                align-items: center;

                img {
                  width: 50px;
                  height: 50px;
                }
              }

              dd {
                flex: 7;
                display: flex;
                flex-direction: column;

                span {
                  flex: 1;
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }

        //   p:nth-child(1),p:nth-child(2){
        //   flex:2;

        // }
        // p:nth-child(3){
        //   flex:6;
        // }
      }
    }

    .bottom {
      flex: 8;
      background: #fff;
    }
  }



}
</style>
