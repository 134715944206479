<template>
  <div class="Preferential">

      <el-form :inline="true" :model="queryInfo">
        <el-row :gutter="24">
        <el-form-item label="活动名称：">
          <el-input placeholder="请输入活动名称" clearable v-model="queryInfo.name" @change="current">
          </el-input>
        </el-form-item>
        <el-form-item label="活动玩法：">
          <el-select v-model="playGm" placeholder="请选择活动玩法" @change="playchange" filterable>
            <el-option v-for="item in playGmList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动状态：">
          <el-select v-model="activeActive" placeholder="请选择活动状态" @change="activechange" filterable>
            <el-option v-for="item in activeActiveList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="getLogList">查询</el-button>
          <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
        </el-form-item>
      </el-row>
      </el-form>

    <!-- 创建活动（营销活动） -->
    <el-dialog title="营销活动" :visible.sync="activeDialog" width="60%">
      <div class="activeMore">
        <div class="" v-if="OwnType"
          style="background-color: orange;width: 50%;height: 40%;border-radius: 15px;padding: 0 10px;padding-bottom: 10px;">
          <div style="display: flex;flex-direction: column;">
            <div class="dl" style="width: 50%; display: flex;flex-direction: row;">
              <dt style="display: flex;align-items: center">
                <el-image style="width: 50px; height: 50px;" :src="listall[0].url" fit="scale-down"></el-image>
              </dt>
              <dd>
                <p>{{ listall[0].activeName }}</p>
                <p>
                  <el-tag type="warning">{{ listall[0].activeDes }}</el-tag>
                </p>
              </dd>
            </div>
            <div class="li" style="color: gray;">{{ listall[0].activeMore }}</div>
          </div>
        </div>
        <div class="" v-else-if="TsyType"
          style="background-color: orange;width: 50%;height: 40%;border-radius: 15px;padding: 0 10px;padding-bottom: 10px;">
          <div style="display: flex;flex-direction: column;">
            <div class="dl" style="width: 50%; display: flex;flex-direction: row;">
              <dt style="display: flex;align-items: center">
                <el-image style="width: 50px; height: 50px;" :src="listall[0].url" fit="scale-down"></el-image>
              </dt>
              <dd>
                <p>{{ listall[1].activeName }}</p>
                <p>
                  <el-tag type="warning">{{ listall[1].activeDes }}</el-tag>
                </p>
              </dd>
            </div>
            <div class="li" style="color: gray;">{{ listall[1].activeMore }}</div>
          </div>
        </div>
        <div class="content" v-else-if="OwnType">
          <div v-for="(item, index) in listall" :key="index" class="activeDl" @click="changeColor(index)" :style="{
      background: ActiveIndex == index ? 'orange' : '#c5c5c5'
    }">
            <div class="dl">
              <dt>
                <el-image style="width: 50px; height: 50px" :src="item.url" fit="scale-down"></el-image>
              </dt>
              <dd>
                <p>{{ item.activeName }}</p>
                <p>
                  <el-tag :type="index == 0 ? '' : 'warning'">{{ item.activeDes }}</el-tag>
                </p>
              </dd>
            </div>
            <div class="li">{{ item.activeMore }}</div>
          </div>
        </div>
        <div class="footers">
          <button @click="activeDialogFalse">取消</button>
          <button @click="activeDialogBtn">确定</button>
        </div>
      </div>
    </el-dialog>
    <!-- 社群推广 -->
    <el-dialog title="社群推广" :visible.sync="publicTalk" width="60%" :before-close="publicTalkDialoag">
      <div class="publicTalk">
        <dl>
          <p>
            充值送
          </p>
          <p>
            活动时间：{{ talkobj.startTime }}--{{ talkobj.endTime }}
          </p>
          <p>
            使用场站：{{ talkobj.stationName }}
          </p>
        </dl>
        <dl>
          <dt>二维码</dt>
          <dd>
            <p>
              <VueQr :text='tupian + url' style="height: 22vh"></VueQr>
            </p>
            <p>用微信扫码跳转优惠券分享页面，可分享至群聊让用户自主领券，进行社群运营</p>
            <p>
              <button @click="publicTalkDialoag">关闭</button>
            </p>
          </dd>
        </dl>
      </div>
    </el-dialog>
    <!-- 详情（单价立减、绑定充值送、小程序充值送） -->
    <el-dialog title="" :visible.sync="dDialog" width="80%">
      <el-form :model="newinfo" label-width="110px" :rules="newinforules">
        <el-form-item label="基础信息"></el-form-item>
        <el-form-item label="活动名称" prop="name">
          <el-input v-model="newinfo.name" autocomplete="off" style="width: 80%;" type="textarea" autosize
            maxlength="20" placeholder="请输入活动名称" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="活动描述" prop="content">
          <el-input v-model="newinfo.content" autocomplete="off" style="width: 80%;" type="textarea" autosize
            maxlength="500" show-word-limit placeholder="请输入活动描述"></el-input>
        </el-form-item>
        <el-form-item prop="value3">
          <span style="color: red;margin-left: -6%;padding-right:3px;">*</span><span
            style="padding-right: 16px;">活动时间</span>
          <el-date-picker v-model="value3" :disabled="checked" @change="time_all3" type="datetimerange"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
        </el-form-item>
        <el-row :gutter="24" style="display: flex;flex-direction: row;">
          <el-col :span="10" v-if="ActiveIndex !== 1">
            <!-- obj.type == 5 || obj.type == 6 || -->
            <el-form-item label="适用场站" prop="stationiIds">
              <el-select v-model="newinfo.stationIds" placeholder="请选择适用场站" @change="playGmMethods" filterable multiple>
                <el-option v-for="item in playList" :key="item.id" :label="item.stationName" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display: flex;flex-direction: row;">
            <el-form-item label="赠送类型" prop="discountType">
              <el-select v-model="newinfo.discountType" @change="discountChange" filterable>
                <el-option v-for="item in typelist" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <span v-if="typsvalue" style="color:red;margin-left: 25px;margin-top: 10px;">注：赠送的优惠券，满足订单金额后抵扣</span>
          </el-col>

        </el-row>
        <!-- <el-form-item label="规则类型" prop="" v-if="obj.type == 5 || ActiveIndex == 0">
          <el-tag>获取费率</el-tag>
        </el-form-item> -->
        <!-- <el-form-item label="减免范围" prop="reductionRange" v-if="obj.type == 5 || ActiveIndex == 0">
          <el-tag v-for="(item, index) in getlist" :key="index" @click="changeTag(index)"
            :type="tagActive == index ? '' : 'info'" style="margin-right: 10px;">{{ item }}</el-tag>
        </el-form-item> -->
        <!-- <el-form-item label="定价方案" prop="reductionPlan" v-if="obj.type == 5 || ActiveIndex == 0">
          <div>
            <el-tag v-for="(item, index) in getPrice" :key="index" @click="changePrice(index)"
              :type="tagPrcice == index ? '' : 'info'" style="margin-right: 10px;">{{ item }}</el-tag>
          </div>
          <div v-if="tagPrcice == 0" style="background-color: #c5c5c5;">
            <p style="height: 10px;padding-left: 10px;">当所选场站无分时段规则时，以默认价为准</p>
            <li style="display: flex;flex-direction: row;justify-content: space-between;padding:10px;">
              <p v-if="tagActive == 0 || tagActive == 2"
                style="flex:1;display: flex;flex-direction: row;align-items: center;border: 1px solid gray;background-color: #fff;border-top-left-radius: 5px;border-bottom-left-radius: 5px;">
                <span style="width: 40px;padding:0 10px;">默认</span><el-input v-model="tariffForm.fixFee"
                  placeholder="电费"></el-input>
              </p>
              <p v-if="tagActive == 2 || tagActive == 1"
                style="flex:1;display: flex;flex-direction: row;align-items: center;border: 1px solid gray;background-color: #fff;border-top-right-radius: 5px;border-bottom-right-radius: 5px;">
                <el-input v-model="tariffForm.fixServiceFee" placeholder="服务费"></el-input><span
                  style="width: 50px;padding:0 10px;">元/度</span>
              </p>
              <p v-if="tagActive !== 2" style="flex:1;"></p>
            </li>
          </div>
          <div v-else style="background-color: #c5c5c5;">
            <li
              style="display: flex;flex-direction: row;justify-content: space-between;background-color: #c5c5c5;padding:10px;">
              <p v-if="tagActive == 0 || tagActive == 2"
                style="flex:1;display: flex;flex-direction: row;align-items: center;border: 1px solid gray;background-color: #fff;border-top-left-radius: 5px;border-bottom-left-radius: 5px;">
                <span style="width: 30px;padding:0 10px;">尖</span><el-input v-model="tariffForm.tip"
                  placeholder="电费"></el-input>
              </p>
              <p v-if="tagActive == 2 || tagActive == 1"
                style="flex:1;display: flex;flex-direction: row;align-items: center;border: 1px solid gray;background-color: #fff;border-top-right-radius: 5px;border-bottom-right-radius: 5px;">
                <el-input v-model="tariffForm.tipService" placeholder="服务费"></el-input><span
                  style="width: 50px;padding:0 10px;">元/度</span>
              </p>
              <p v-if="tagActive !== 2" style="flex:1;"></p>
            </li>
            <li
              style="display: flex;flex-direction: row;justify-content: space-between;background-color: #c5c5c5;padding:10px;">
              <p v-if="tagActive == 0 || tagActive == 2"
                style="flex:1;display: flex;flex-direction: row;align-items: center;border: 1px solid gray;background-color: #fff;border-top-left-radius: 5px;border-bottom-left-radius: 5px;">
                <span style="width: 30px;padding:0 10px;">峰</span><el-input v-model="tariffForm.peak"
                  placeholder="电费"></el-input>
              </p>
              <p v-if="tagActive == 2 || tagActive == 1"
                style="flex:1;display: flex;flex-direction: row;align-items: center;border: 1px solid gray;background-color: #fff;border-top-right-radius: 5px;border-bottom-right-radius: 5px;">
                <el-input v-model="tariffForm.peakService" placeholder="服务费"></el-input><span
                  style="width: 50px;padding:0 10px;">元/度</span>
              </p>
              <p v-if="tagActive !== 2" style="flex:1;"></p>
            </li>
            <li
              style="display: flex;flex-direction: row;justify-content: space-between;background-color: #c5c5c5;padding:10px;">
              <p v-if="tagActive == 0 || tagActive == 2"
                style="flex:1;display: flex;flex-direction: row;align-items: center;border: 1px solid gray;background-color: #fff;border-top-left-radius: 5px;border-bottom-left-radius: 5px;">
                <span style="width: 30px;padding:0 10px;">平</span><el-input v-model="tariffForm.flat"
                  placeholder="电费"></el-input>
              </p>
              <p v-if="tagActive == 2 || tagActive == 1"
                style="flex:1;display: flex;flex-direction: row;align-items: center;border: 1px solid gray;background-color: #fff;border-top-right-radius: 5px;border-bottom-right-radius: 5px;">
                <el-input v-model="tariffForm.flatService" placeholder="服务费"></el-input><span
                  style="width: 50px;padding:0 10px;">元/度</span>
              </p>
              <p v-if="tagActive !== 2" style="flex:1;"></p>
            </li>
            <li
              style="display: flex;flex-direction: row;justify-content: space-between;background-color: #c5c5c5;padding:10px;">
              <p v-if="tagActive == 0 || tagActive == 2"
                style="flex:1;display: flex;flex-direction: row;align-items: center;border: 1px solid gray;background-color: #fff;border-top-left-radius: 5px;border-bottom-left-radius: 5px;">
                <span style="width: 30px;padding:0 10px;">谷</span><el-input v-model="tariffForm.valley"
                  placeholder="电费"></el-input>
              </p>
              <p v-if="tagActive == 2 || tagActive == 1"
                style="flex:1;display: flex;flex-direction: row;align-items: center;border: 1px solid gray;background-color: #fff;border-top-right-radius: 5px;border-bottom-right-radius: 5px;">
                <el-input v-model="tariffForm.valleyService" placeholder="服务费"></el-input><span
                  style="width: 50px;padding:0 10px;">元/度</span>
              </p>
              <p v-if="tagActive !== 2" style="flex:1;"></p>
            </li>
          </div>
        </el-form-item> -->
        <!-- || ActiveIndex !== 0 -->
        <!-- v-if="obj.type == 5" -->
        <el-divider content-position="center">充值赠送方案</el-divider>

        <!-- || ActiveIndex !== 0 -->
        <!-- v-if="obj.type == 6 || obj.type == 7" -->
        <el-form-item>
          <div v-for="(item, index) in objValue" :key="index" style="margin-left: -10%;">
            <el-row :gutter="30" style="display:flex;flex-direction:row">
              <el-col :span="5">
                <el-form-item label="充值金额" prop="paramKey" style="padding-bottom: 10px;">
                  <el-input-number v-model="item.paramKey" :precision="2" :step="0.01" controls-position="right"
                    label="充值金额"></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item :label="typsvalue ? '卡券金额' : '赠送金额'" prop="paramValue" style="padding-bottom: 10px;">
                  <el-input-number v-model="item.paramValue" controls-position="right" :precision="2" :step="0.01"
                    label="赠送金额" :size="typsvalue ? 'small' : 'default'"></el-input-number>
                </el-form-item>
              </el-col>
              <!-- -->
              <el-col :span="5" v-if="typsvalue">
                <el-form-item label="卡券数量" prop="num" style="padding-bottom: 10px;">
                  <el-input-number v-model="item.num" controls-position="right" :precision="0" :step="1" label="卡券数量"
                    :size="typsvalue ? 'small' : 'default'"></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="6" v-if="typsvalue" style="display:flex;flex-direction:row;">
                <el-form-item label="卡券有效期" prop="day" style="padding-bottom: 10px;">
                  <el-input-number v-model="item.day" controls-position="right" :precision="0" :step="1" label="卡券有效期"
                    :size="typsvalue ? 'small' : 'default'"></el-input-number>
                </el-form-item>
                <span style="margin-left:5px">天</span>
              </el-col>
              <el-col :span="5" v-if="typsvalue">
                <el-form-item label="订单金额" prop="orderValue" style="padding-bottom: 10px;">
                  <el-input-number v-model="item.orderValue" controls-position="right" :precision="2" :step="0.01"
                    label="订单金额" :size="typsvalue ? 'small' : 'default'"></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-tag @click="delMethods(index)">删除</el-tag>
              </el-col>
            </el-row>
          </div>
          <el-button style="margin-left: 40%;" @click="addobj">新增方案</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="display: flex;justify-content: center">
        <button @click="DeatilDailog"
          style="display: flex;justify-content: center;align-items: center;color: #fff;font-size: 18px;width: 100px;height: 30px;border-radius: 15px;background-color: gray;margin-right: 20px;border: none;">取消</button>
        <button v-debounce="Deatilbtns" :disabled="disabledBtn"
          style="display: flex;justify-content: center;align-items: center;color: #fff;font-size: 18px;width: 100px;height: 30px;border-radius: 15px;background-color:#409EFF;border: none;">确定</button>
      </div>
    </el-dialog>
    <!-- 下架活动 -->
    <el-dialog :title="newinfo.status == 4 ? '上架确认' : '下架确认'" :visible.sync="downDialog" width="20%" @close="colseDown">
      <div class="downTest">
        <h4>
          {{ newinfo.status == 4 ? '下架后活动直接结束' : '上架后将开启活动' }}
        </h4>
        <button @click="downMethods">确认</button>
      </div>
    </el-dialog>
    <!-- 卡片视图区域 -->
    <el-card>
      <li style="display: flex;justify-content: flex-end;margin-left: -1%;padding-bottom: 10px;">
        <el-button v-if="CreateActive" type="primary" icon="el-icon-plus" @click="Addactive">创建活动</el-button>
      </li>
      <!-- table表格区域 -->
      <el-table style="width: 100%" :data="list" empty-text="暂无数据">
        <el-table-column align="center" label="活动名称/ID" prop="createTime">
          <template scope="scope">
            <div style="display:flex;justify-content:flex-start">
              {{ scope.row.name }}
            </div>
            <div style="display:flex;justify-content:flex-start">
              ID：{{ scope.row.id }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="活动玩法" prop="type" width="120">
          <template scope="scope">
            <div>
              {{ scope.row.type == 5 ? '单价立减' : scope.row.type == 6 ? '绑定站充值送' : scope.row.type == 7 ? '小程序充值送' : '其他活动'
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="活动时间" prop="nickname">
          <template scope="scope">
            <div>
              {{ scope.row.startTime }}
            </div>
            <div>
              {{ scope.row.endTime }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建人" prop="adminName"></el-table-column>
        <el-table-column align="center" label="创建时间" prop="dutyParagraph">
          <template scope="scope">
            <div>
              {{ scope.row.createTime }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="type" width="100">
          <template scope="scope">
            <el-tag :type="scope.row.status == 0 ? 'success' : scope.row.status == 1 ? 'info' :
      scope.row.status == 2 ? 'danger' : scope.row.status == 3 ? 'warning' : ''">{{ scope.row.status == 0
      ? '正常' : scope.row.status == 1 ? '结束' :
        scope.row.status == 2 ? '作废' : scope.row.status == 3 ? '冻结' : '临时下架' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" v-if="caozuoquanxian">
          <template slot-scope="scope">
            <span @click="detail(scope.row)" style="margin-right: 5px;color:blue" v-if="CreateActive">详情/编辑</span>
            <span v-if="scope.row.type == 6 && scope.row.status !== 4 && CreatePublic" @click="speakTal(scope.row)"
              style="margin-right: 5px;color:green">社群推广</span>
            <!-- scope.row.status !== 4 &&  -->
            <span v-if="CreateSelf" @click="down(scope.row)" style="margin-right: 5px;color:red">{{ scope.row.status ==
      4 ? '上架' : '下架' }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页导航区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[10, 20, 50, 100]" :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
import caozuo from "@/utils/pubilc.js";
import VueQr from 'vue-qr';
export default {
  components: {
    VueQr
  },
  data() {
    return {
      disabledBtn: false,
      value1: [],
      dis: false,
      typsvalue: false,
      typelist: [
        {
          id: 0,
          name: '送钱'
        },
        {
          id: 1,
          name: '送券'
        }
      ],
      newinforules: {
        name: [
          { required: true, message: '活动名称不能为空', trigger: 'blur' }
        ],
        discountType: [
          {
            required: true, message: '请选择赠送类型', trigger: 'change'
          }
        ]
      },
      obj: {

      },
      tupian: this.$imgUrl,
      talkobj: {

      },
      // tariffForm: {
      //     fixFee: '',
      //     fixServiceFee: '',
      //     tip: '',
      //     tipService: '',
      //     peak: '',
      //     peakService: '',
      //     flat: '',
      //     flatService: '',
      //     valley: '',
      //     valleyService: ''
      //   },
      checked: false,
      newinfo: {
        // 活动名称
        name: '',
        // 活动描述
        content: '',
        // 5单价立减6绑定站充值送7小程序充值送，
        type: 6,
        // 0正常1结束2作废3冻结4临时下架
        status: 0,
        requirement1: '',
        requirement2: '',
        stationIds: [],
        appId: window.sessionStorage.getItem('appId') * 1,
        adminId: window.sessionStorage.getItem('adminId') * 1,
        roleId: window.sessionStorage.getItem('roleId') * 1,
        discountType: 0,
        days: 0
      },
      downDialog: false,
      publicTalk: false,
      ActiveIndex: 1,
      total: 0,
      tagActive: 0,
      tagPrcice: 0,
      getlist: [
        '电费', '服务费', '电费服务费'
      ],
      getPrice: [
        '全天可用', '分时段可用'
      ],
      timelist: [
        {
          time: '尖',
          timeMoneyElertic: '',
          timeMoneyService: ''
        },
        {
          time: '峰',
          timeMoneyElertic: '',
          timeMoneyService: ''
        },
        {
          time: '平',
          timeMoneyElertic: '',
          timeMoneyService: ''
        },
        {
          time: '谷',
          timeMoneyElertic: '',
          timeMoneyService: ''
        },
        {
          time: '深',
          timeMoneyElertic: '',
          timeMoneyService: ''
        }
      ],
      defaultTimelist: [
        {
          time: '默认',
          timeMoneyElertic: '',
          timeMoneyService: ''
        }
      ],
      dDialog: false,
      activeDialog: false,
      caozuoquanxian: true,
      list: [],
      activeActive: 0,
      playGm: 0,
      url: '',
      objValue: [],
      listall: [
        // {
        //   url: require('../../assets/images/img/PriceDown.png'),
        //   activeName: '单价立减',
        //   activeDes: '低价促转化',
        //   activeMore: '可针对不同用户、场站，时间设置单价优惠，提升复购和转化'
        // },
        {
          url: require('../../assets/images/img/MoneyStation.png'),
          activeName: '绑定站充值送',
          activeDes: '促销引流',
          activeMore: '针对单站做充值送活动，该活动金额默认为第一付款项'
        },
        {
          url: require('../../assets/images/img/miniMoney.png'),
          activeName: '小程序充值送',
          activeDes: '低价促转化',
          activeMore: '可针对不同用户、场站，时间设置单价优惠，提升复购和转化'
        }
      ],
      playGmList: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '单价立减'
        },
        {
          id: 2,
          name: '绑定站充值送'
        }
      ],
      playList: [

      ],
      toalslist: [],
      activeActiveList: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '已过期'
        },
        {
          id: 2,
          name: '进行中'
        }
      ],
      miniurlType: false,
      value3: ['', ''],
      queryInfo: {
        page: 1,
        limit: 8,
        discountType: null,
        // type:0充值送，1优惠券，2新人送，3折扣，5单价立减，6绑定站充值送，7小程序充值送，8订单优惠券，9电费券，10服务费，99其他活动
        // 5,6,7
        type: '5,6,7',
        name: '',
        userGroupId: null,
        stationIds: window.sessionStorage.getItem('stationIds'),
        adminId: window.sessionStorage.getItem('adminId')
      },
      CreateActive: false,
      CreatePublic: false,
      CreateSelf: false,
      roleType: false,
      TsyType: false,
      stationUrl: false,
      miniUrl: false,
      OwnType: false
    }
  },
  computed: {
    jisuan() {
      return function (obj, num, fixed) {
        return (obj / num).toFixed(fixed)
      }
    }
  },
  watch: {

  },
  created() {
    const that = this;
    // 添加、编辑新版活动权限
    caozuo(190).then(res => {
      that.CreateActive = res;
    });
    // // 绑定站充值送
    // caozuo(195).then(res => {
    //   that.stationUrl = res;
    // });
    // // 小程序充值送
    // caozuo(194).then(res => {
    //   that.miniUrl = res;
    // });
    // 推广权限
    caozuo(191).then(res => {
      that.CreatePublic = res;
    });
    // 下架权限
    caozuo(192).then(res => {
      that.CreateSelf = res;
    });
    that.roleType = window.sessionStorage.getItem('roleId') == 1 ? true : false;
    that.TsyType = window.sessionStorage.getItem('roleId') == 5 ? true : false;
    that.OwnType = window.sessionStorage.getItem('roleId') == 11 ? true : false;
    that.list = [];
    that.$http.get('station-info/getAllStationInfoName', {
      params: {
        roleId: window.sessionStorage.getItem('roleId'),
        stationIds: window.sessionStorage.getItem('stationIds'),
        stationMode: '0,1'
      }
    }).then(res => {
      if (res.data.code !== 0) return;
      that.playList = res.data.data;
      that.toalslist = res.data.data;
    })
    // that.playGmList
    that.getLogList();
    // caozuo(149).then(res => {
    //   if (window.sessionStorage.getItem('roleId') == 5) {
    //     that.caozuoquanxian = res;
    //   }
    // });
  },
  mounted() {

  },
  methods: {
    colseDown() {
      this.downDialog = false;
    },
    delMethods(e) {
      if (this.objValue.length > 1) return this.objValue.splice(e, 1);
      this.objValue = []
    },
    // 切换适用场站（费率）
    publicMethods(e, feeModelId, stationMode) {
      const that = this;
      // 费率
      // that.$http.get(e == 0 ? `feeModel/getFeeModelById/${feeModelId}` : `feeModel/getLowFeeModelById/${feeModelId}`).then(ress => {
      //   if (ress.data.code !== 0) return;
      //   that.tariffForm.fixFee = ress.data.data.fixFee / 100;
      //   that.tariffForm.fixServiceFee = ress.data.data.fixServiceFee / 100;
      //   let billingPolicy = JSON.parse(ress.data.data.billingPolicy);
      //   if (stationMode !== 0) return;
      //   that.tariffForm.tip = billingPolicy.tip / 1000;
      //   that.tariffForm.tipService = billingPolicy.tipService / 1000;
      //   that.tariffForm.peak = billingPolicy.peak / 1000;
      //   that.tariffForm.peakService = billingPolicy.peakService / 1000;
      //   that.tariffForm.flatService = billingPolicy.flatService / 1000;
      //   that.tariffForm.valley = billingPolicy.valley / 1000;
      //   that.tariffForm.valleyService = billingPolicy.valleyService / 1000;
      // })
    },
    // 切换营销活动
    changeColor(e) {
      this.ActiveIndex = e;
      this.newinfo = {};
      this.value3 = ['', ''];
      this.objValue = [];
      this.newinfo.type = e == 0 ? 6 : 7;
    },
    // 充值送方案
    addobj() {
      if (this.objValue.length > 5) return;
      this.objValue.push({
        paramKey: '',
        paramValue: '',
        num: '',
        orderValue: '',
        day: ''
      })
    },
    // 切换适用场站（更新费率展示）
    playGmMethods() {
    },
    // 修改活动时间
    time_all3(e) {
      if (e !== null) {
        if (e[0] == e[1]) {
          this.$message.error('起始时间不得为同一天！');
          this.newinfo.startTime = ''
          this.newinfo.endTime = ''
        } else {
          this.newinfo.startTime = e[0];
          this.newinfo.endTime = e[1]
        }
        this.dis = true;
      } else {
        this.newinfo.startTime = '';
        this.newinfo.endTime = '';
        this.value3 = [];
        // 时间
        this.dis = false;
        this.checked = false;
      }
      this.newinfo.effectiveType = 1;
      this.newinfo.timeType = 0;
    },
    // 开启营销弹窗
    activeDialogBtn() {
      // if (this.ActiveIndex == 0) return this.$message({
      //   type: 'error', // success error warning
      //   message: '正在开发中...',
      //   duration: 2000,
      // });
      this.objValue = [];
      // 商户，小程序充值送
      if (this.TsyType) {
        this.ActiveIndex = 1;
        this.typsvalue = false;
        // 分佣商户绑定站
      } else if (this.roleType) {
        this.ActiveIndex = 0;
        this.typsvalue = true;
      } else {
        // 超管
        this.newinfo.discountType = this.ActiveIndex == 1 ? 1 : 0;
        this.typsvalue = this.newinfo.discountType == 0 ? false : true;
      }
      this.dDialog = true;
    },
    //关闭营销弹窗
    activeDialogFalse() {
      this.activeDialog = false;
    },
    // 关闭社群推广弹窗
    publicTalkDialoag() {
      this.publicTalk = false;
    },
    // 关闭详情
    DeatilDailog() {
      this.ActiveIndex = 1;
      this.newinfo.discountType = 0;
      this.dDialog = false;
    },
    // 添加、修改活动
    Deatilbtns() {
      this.status = 0;
      if (this.newinfo.name == '') return this.$message.error('请添加您的活动名称');
      if (this.newinfo.startTime == '' || this.newinfo.endTime == '') return this.$message.error('请选择你的活动时间');
      // this.obj.type == 5 || this.obj.type == 6 ||
      // 分账上户
      if (this.OwnType) {
        if (this.newinfo.stationIds.length == 0 || this.newinfo.stationIds == '') return this.$message.error('请选择您的适用场站');
        // if (this.newinfo.days == '' || this.newinfo.days == null || this.newinfo.days == undefined) return this.$message.error('请输入您的券有效期')
      }
      // 商户
      if (this.TsyType) {
        this.newinfo.type = 7;
      }
      const abcType = this.objValue.some(item => item.paramKey == 0);
      if (abcType == true) return this.$message.error('您的充值赠送方案中有包含0元充值的方案,请检查完再次提交请求');
      let objaa = [];
      objaa = this.objValue.sort((a, b) => {
        return a.paramKey - b.paramKey
      })
      this.newinfo.requirement1 = this.ActiveIndex == 0 ? JSON.stringify(objaa) : '';
      this.newinfo.requirement2 = this.ActiveIndex == 1 ? JSON.stringify(objaa) : '';
      this.Deatilbtn()
    },
    // 添加、修改、下架活动方法
    Deatilbtn() {
      const that = this;
      let abc = {}; let ntr = '';
      // 商户
      if (that.TsyType) {
        that.newinfo.type = 7;
        that.newinfo.stationIds = '';
        that.ActiveIndex = 0;
      } else if (that.OwnType) {
        // 分账上户
        that.newinfo.type = 6;
        that.newinfo.stationIds = that.newinfo.stationIds;
      } else {
        // 超管
        that.newinfo.stationIds = that.newinfo.stationIds !== '' ? that.newinfo.stationIds : window.sessionStorage.getItem('stationIds');
      }
      that.newinfo.appId = window.sessionStorage.getItem('appId') * 1;
      that.newinfo.adminId = window.sessionStorage.getItem('adminId') * 1;
      ntr = that.newinfo.stationIds;
      abc = that.newinfo;
      if (that.newinfo.type == 6) {
        abc.stationIds = that.newinfo.stationIds.length == 1 ? that.newinfo.stationIds[0] : ntr.map(item => item).join(',');
      }
      that.$http.post('preferential/editPreferential', abc).then(res => {
        that.disabledBtn = res.data.code !== 0 ? true : false;
        that.$message({
          type: res.data.code !== 0 ? 'error' : 'success',
          message: that.newinfo.status == 4 && res.data.code !== 0 ? '下架活动失败' : that.newinfo.status == 0 && res.data.code !== 0 ? '添加或更新优惠活动失败' : that.newinfo.status == 4 && res.data.code == 0 ? '下架活动成功' : '添加或更新优惠活动成功'
        });
        // if (that.newinfo.status == 4) {
        // that.downDialog = false;
        // that.clearMethods();
        // that.getLogList();
        // } else {
        that.downDialog = false;
        that.dDialog = res.data.code !== 0 ? true : false;
        that.ActiveIndex = res.data.code !== 0 ? that.ActiveIndex : 0;
        if (res.data.code !== 0) return;
        that.activeDialogFalse();
        that.clearMethods();
        that.getLogList();
        // }
      })
    },
    clearMethods() {
      const that = this;
      that.newinfo = {};
      that.ActiveIndex = 0;
      that.tagActive = 0;
      that.tagPrcice = 0;
      that.tariffForm = {};
      that.value3 = '';
    },
    // 关闭：下架活动弹窗
    downMethods() {
      // this.downDialog=false;
      this.Deatilbtn();
    },
    // 更换减免范围
    changeTag(e) {
      this.tagActive = e;
    },
    // 更换定价方案
    changePrice(e) {
      this.tagPrcice = e;
    },
    // 切换（搜索）活动状态
    activechange() {

    },
    // 切换优惠类型
    discountChange(e) {
      this.newinfo.discountType = e;
      this.typsvalue = e == 0 ? false : true;
    },
    //切换（搜索）活动玩法
    playchange(e) {

    },
    // 重置搜索条件
    chongzhi() {

    },
    // 添加活动
    Addactive() {
      this.activeDialog = true;
      this.newinfo.status = 0;
      this.ActiveIndex = 0;
    },
    // 获取活动列表
    getLogList() {
      const that = this;
      that.$http.get('preferential/getAllPreferential', { params: that.queryInfo }).then(res => {
        if (res.data.code !== 0) return;
        that.list = res.data.data;
        that.total = res.data.count;
      })
    },
    // 更换活动名称，切换活动列表
    current() {
      this.getLogList()
    },
    // 切换列表条数
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.queryInfo.page = 1;
      this.getLogList()
    },
    // 切换列表页码
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getLogList()
    },
    // 详情弹窗
    detail(e) {
      const that = this;
      // 充值送方案
      that.$http.get(`preferential/getPreferentialById/${e.id}`).then(res => {
        if (res.data.code !== 0) return;
        that.newinfo = res.data.data;
        that.ActiveInde
        if (that.newinfo.stationIds !== null || that.newinfo.stationIds !== undefined || that.newinfo.stationIds !== '') {
          // that.newinfo.discountType == 1 &&
          if (that.newinfo.type == 6) {
            console.log(that.newinfo.stationIds, '829');
            that.newinfo.stationIds = !that.newinfo.stationIds.includes(',') ? [that.newinfo.stationIds * 1] : that.newinfo.stationIds.split(',').map(item => item * 1);
          }
        }
        // .map(item => item * 1)
        that.typsvalue = that.newinfo.discountType == 0 ? false : true;
        that.ActiveIndex = that.newinfo.type == 6 ? 0 : 1;
        // that.ActiveIndex = that.newinfo.type == 6 ? 1 : that.newinfo.type == 7 ? 2 : 0;
        that.value3 = [that.newinfo.startTime, that.newinfo.endTime];
        that.objValue = that.newinfo.type == 6 && that.newinfo.requirement1 !== '' ? JSON.parse(that.newinfo.requirement1) : that.newinfo.type == 7 && that.newinfo.requirement2 !== '' ? JSON.parse(that.newinfo.requirement2) : [];
        that.dDialog = true;
      })
    },
    // 社群推广弹窗
    speakTal(e) {
      const that = this; that.publicTalk = true;
      that.$http.get(`/preferential/getPreferentialById/${e.id}`).then(res => {
        if (res.data.code !== 0) retun;
        that.talkobj = res.data.data;
        // that.talkobj.stationName = res.data.data.stationName;
        // .map(item => item.stationName).join(',');
        that.url = 'coupton/' + that.talkobj.appId + '/' + that.talkobj.id + '/' + that.talkobj.stationIds + '/' + that.talkobj.stationName;
      })
    },
    // 下架弹窗
    down(e) {
      this.downDialog = true;
      this.newinfo = e;
      this.newinfo.status = e.status == 4 ? 0 : 4;
      this.downDialog = true;
    }
  }
};
</script>

<style lang='less' scoped>
/deep/.el-table tr>th {
  background: #F5F7FA;
}

.Preferential {
  width: 100%;
  height: 100%;
  list-style: none;

  // 下架确认
  .downTest {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 18px;
      width: 100px;
      height: 35px;
      border-radius: 15px;
      background-color: blue;
      border: none;
    }
  }

  // 社群推广
  .publicTalk {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;

    dl:nth-child(1) {
      border: 1px solid gray;
      padding: 0 20px;
      border-radius: 15px;

    }

    dl:nth-child(2) {
      dt {
        font-size: 18px;
        font-weight: bolder;
      }

      dd {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        p {
          button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            width: 100px;
            height: 30px;
            border: none;
            border-radius: 15px;
            color: #fff;
            background-color: #409EFF;
          }
        }
      }
    }

    .tag {
      flex: 1;
      border: 1px solid gray;
    }

    .hlhtIntent {
      color: gray;
    }


  }

  // 表格详情
  .activeMore {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;

    .content {
      flex: 9;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .activeDl {
        flex: 1;
        background-color: #F7F8FA;
        border-radius: 15px;
        padding: 0 10px 20px 10px;
        margin-right: 20px;

        .dl {
          flex: 6;
          display: flex;
          flex-direction: row;
          align-items: center;

          dt {
            flex: 1;
          }

          dd {
            flex: 9;

            p:first-child {
              font-size: 18px;
              font-weight: bolder;
            }


          }
        }

        .li {
          flex: 4;
          color: #909399;
          display: flex;
          align-items: center;
        }
      }

      .activeDl:last-child {
        margin-right: 0;
      }
    }



    .footers {
      margin-top: 30px;
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;

      button {
        width: 100px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border: none;
        font-size: 18px;
        border-radius: 15px;
        background-color: #909399;
      }

      button:nth-child(2) {
        margin-left: 20px;
        background-color: #409EFF;
      }
    }
  }
}
</style>
