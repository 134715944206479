<template>
  <div v-if="money_mounth_list.length > 0" class="home">
    <div class="he_right" style="display: flex;flex-direction: row;">
      <el-select v-model="sousuodianzhan" placeholder="请选择电站" @change="change_vale" filterable>
        <el-option v-for="item in powerStationList" :key="item.id" :label="item.stationName" :value="item.id">
        </el-option>
      </el-select>
      <el-select v-model="times" placeholder="请选择时间类型" @change="change_vale2" filterable>
        <el-option v-for="item in timeList" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
      <el-date-picker style="margin-left: 20px;" v-model="picker_value2" clearable :picker-options="pickerOptions"
        type="daterange" align="right" range-separator="--" value-format="yyyy-MM-dd" start-placeholder="开始日期"
        @blur="pickBlur" end-placeholder="结束日期" @change="picker_event">
      </el-date-picker>
    </div>
    <div class="center">
      <div class="left">
        <p>总消费（元）</p>
        <p>{{ totalMoneys }}元</p>
      </div>
      <div class="right">
        <!-- 饼图*3 -->
        <el-col :span="8" v-for="(item, index) in list" :key="index">
          <div class="cols"></div>
        </el-col>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom1">
        <dl>
          <li><span>微信收入</span><span>（0）</span></li>
          <li><span>0</span><span>0（元）</span></li>
          <li><span>{{ times }}</span><span>0（元）</span></li>
          <li><span>环比{{ times }}</span><span>0%</span></li>
        </dl>
        <dl>
          <li><span>微信消费</span><span>（0）</span></li>
          <li><span>0</span><span>0（元）</span></li>
          <li><span>{{ times }}</span><span>0（元）</span></li>
          <li><span>环比{{ times }}</span><span>0%</span></li>
        </dl>
        <dl>
          <li><span>微信赠送</span><span>（0）</span></li>
          <li><span>0</span><span>0（元）</span></li>
          <li><span>{{ times }}</span><span>0（元）</span></li>
          <li><span>环比{{ times }}</span><span>0%</span></li>
        </dl>
        <dl>
          <li><span>微信退款</span><span>（0）</span></li>
          <li><span>0</span><span>0（元）</span></li>
          <li><span>{{ times }}</span><span>0（元）</span></li>
          <li><span>环比{{ times }}</span><span>0%</span></li>
        </dl>
      </div>
      <div class="bottom1">
        <dl>
          <li><span>支付宝收入</span><span>（0）</span></li>
          <li><span>0</span><span>0（元）</span></li>
          <li><span>{{ times }}</span><span>0（元）</span></li>
          <li><span>环比{{ times }}</span><span>0%</span></li>
        </dl>
        <dl>
          <li><span>支付宝消费</span><span>（0）</span></li>
          <li><span>0</span><span>0（元）</span></li>
          <li><span>{{ times }}</span><span>0（元）</span></li>
          <li><span>环比{{ times }}</span><span>0%</span></li>
        </dl>
        <dl>
          <li><span>支付宝赠送</span><span>（0）</span></li>
          <li><span>0</span><span>0（元）</span></li>
          <li><span>{{ times }}</span><span>0（元）</span></li>
          <li><span>环比{{ times }}</span><span>0%</span></li>
        </dl>
        <dl>
          <li><span>支付宝退款</span><span>（0）</span></li>
          <li><span>0</span><span>0（元）</span></li>
          <li><span>{{ times }}</span><span>0（元）</span></li>
          <li><span>环比{{ times }}</span><span>0%</span></li>
        </dl>
      </div>
      <div class="bottom1">
        <dl>
          <li><span>平台卡收入</span><span>（0）</span></li>
          <li><span>0</span><span>0（元）</span></li>
          <li><span>{{ times }}</span><span>0（元）</span></li>
          <li><span>环比{{ times }}</span><span>0%</span></li>
        </dl>
        <dl>
          <li><span>平台卡消费</span><span>（0）</span></li>
          <li><span>0</span><span>0（元）</span></li>
          <li><span>{{ times }}</span><span>0（元）</span></li>
          <li><span>环比{{ times }}</span><span>0%</span></li>
        </dl>
        <dl>
          <li><span>平台卡退款</span><span>（0）</span></li>
          <li><span>0</span><span>0（元）</span></li>
          <li><span>{{ times }}</span><span>0（元）</span></li>
          <li><span>环比{{ times }}</span><span>0%</span></li>
        </dl>
      </div>
      <div class="bottom1">
        <dl>
          <li><span>离线卡收入</span><span>（0）</span></li>
          <li><span>0</span><span>0（元）</span></li>
          <li><span>{{ times }}</span><span>0（元）</span></li>
          <li><span>环比{{ times }}</span><span>0%</span></li>
        </dl>
        <dl>
          <li><span>离线卡消费</span><span>（0）</span></li>
          <li><span>0</span><span>0（元）</span></li>
          <li><span>{{ times }}</span><span>0（元）</span></li>
          <li><span>环比{{ times }}</span><span>0%</span></li>
        </dl>
        <dl>
          <li><span>离线卡退款</span><span>（0）</span></li>
          <li><span>0</span><span>0（元）</span></li>
          <li><span>{{ times }}</span><span>0（元）</span></li>
          <li><span>环比{{ times }}</span><span>0%</span></li>
        </dl>
      </div>
      <div class="bottom1">
        <dl>
          <li><span>车架号消费</span><span>（0）</span></li>
          <li><span>0</span><span>0（元）</span></li>
          <li><span>{{ times }}</span><span>0（元）</span></li>
          <li><span>环比{{ times }}</span><span>0%</span></li>
        </dl>
        <dl>
          <li><span>互联互通消费</span><span>（0）</span></li>
          <li><span>0</span><span>0（元）</span></li>
          <li><span>{{ times }}</span><span>0（元）</span></li>
          <li><span>环比{{ times }}</span><span>0%</span></li>
        </dl>
        <dl>
          <li><span>停车费用</span><span>（0）</span></li>
          <li><span>0</span><span>0（元）</span></li>
          <li><span>{{ times }}</span><span>0（元）</span></li>
          <li><span>环比{{ times }}</span><span>0%</span></li>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'Detail',
  data() {
    return {
      times: '今日',
      timeList: [
        '今日', '本月', '本年', '营业至今'
      ],
      sousuodianzhan: '全部电站',
      zhuangTypelist: [],
      dandu: {
        startTime: null,
        endTime: null,
      },
      Wxmoney1: 0,
      Wxmoney2: 0,
      Wxmoney3: 0,
      Alipaymoney1: 0,
      Alipaymoney2: 0,
      Alipaymoney3: 0,
      Alipay1: 0,
      Alipay2: 0,
      Alipay3: 0,
      alipayTotalRefundMoney: 0,
      alipayTotalGiveMoney: 0,
      alipayTotalRechargeMoney: 0,
      queryInfo: {
        nickName: '',
        userName: '',
        userNumber: '',
        stationIds: window.sessionStorage.getItem('stationIds'),
        phone: '',
        adminId: window.sessionStorage.getItem('adminId'),
        roleId: window.sessionStorage.getItem('roleId'),
        page: 1,
        limit: 10
      },
      totalRechargeMoney: 0,
      totalRefundMoney: 0,
      totalGiveMoney: 0,
      arr_time: [],
      totalMoneys: 0,
      dianfei: null,
      arr1: [],
      arr2: [],
      arr3: [],
      arr4: [],
      pickerMinDate: '',
      pickerOptions: {
        onPick: obj => {
          this.pickerMinDate = new Date(obj.minDate).getTime()
          const start = this.formatDate(obj.minDate, 'start')
          const end = this.formatDate(obj.maxDate, 'end')
          obj.maxDate && (this.picker_value2 = [start, end])
        },
        disabledDate: time => {
          if (this.pickerMinDate) {
            const oneMonth = 1000 * 3600 * 24 * 31
            const maxTime = this.pickerMinDate + oneMonth
            const mixTime = this.pickerMinDate - oneMonth + 1000 * 3600 * 24
            const tomorrow = new Date(this.formatDate(new Date().getTime() + 1000 * 3600 * 24, 'start'))
            return time.getTime() >= tomorrow || time.getTime() >= maxTime || time.getTime() < mixTime
          }
        }
      },
      operateTime: [],
      value1: '',
      picker_value2: ['', ''],
      value3: '',
      list: [{
        id: 1,
        value1: 0,
        value2: 0,
        name: "移动消费（元）",
        color: '#9DE490'
      },
      {
        id: 2,
        value1: 0,
        value2: 0,
        name: "刷卡消费（元）",
        color: '#6FA1FF'
      },
      {
        id: 3,
        value1: 0,
        value2: 0,
        name: "其他消费（元）",
        color: '#657798'
      }
      ],
      Max_num: 0,
      money_mounth_list: [
        {
          h4: '总收入（元）',
          price_all: '',
          name: '上月：',
          price_mounth: ''
        },
        {
          h4: '电费收入（元）',
          price_all: '',
          name: '上月：',
          price_mounth: ''
        },
        {
          h4: '服务费收入（元）',
          price_all: '',
          name: '上月：',
          price_mounth: ''
        },
        {
          h4: '使用电量（度）',
          price_all: '',
          name: '上月：',
          price_mounth: ''
        }
      ],
      money_all: '61096.88',
      money_day_list: [
        {
          h4: '总收入（元）',
          price: ''
        },
        {
          h4: '电费收入（元）',
          price: ''
        },
        {
          h4: '服务费收入（元）',
          price: ''
        },
        {
          h4: '使用电量（度）',
          price: ''
        }
      ],
      powerStationList: [],
      dianliang: null,
      pars: {
        stationIds: window.sessionStorage.getItem('stationIds'),
        startTime: null,
        endTime: null,
        todayTime: null,
        adminId: window.sessionStorage.getItem('adminId'),
        roleId: window.sessionStorage.getItem('roleId'),
      },
      all: null,
      arr: []
    }
  },
  computed: {
    chuli: function () {
      return function (val) {
        return (val / 100).toFixed(2)
      }
    }
  },
  created() {
    this.bingtu();
    this.zongshouru()
  },

  methods: {
    // 更改时间
    change_vale2() {

    },
    pickBlur() {
      this.pickerMinDate = ''
    },
    // 所属电站搜索条件
    async change_vale(e) {
      this.pars.stationIds = String(e);
      this.shuju();
      this.bingtu();
      this.moren();
    },
    //常用电站
    chage_zhuangType(e) {
      this.queryInfo.stationIds = e;
    },
    // 初始
    async default() {
      this.powerStationList.push({
        id: window.sessionStorage.getItem('stationIds'),
        stationName: '全部电站'
      });
      await this.$http.get('station-info/getAllStationInfoName', {
        params: {
          roleId: window.sessionStorage.getItem('roleId'),
          stationIds: window.sessionStorage.getItem('stationIds'),
          stationMode: '0,1,2'
        }
      }).then(res => {
        res.data.data.forEach(item => {
          this.powerStationList.push({
            id: item.id,
            stationName: item.stationName
          })
        });
      });
      // 只统计当天
      await this.$http.get(`bill-info/getAllRechargeRefund/${window.sessionStorage.getItem('adminId')}`).then(ress => {
        console.log(ress.data.code, ress.data.data, '315');
        if (ress.data.code !== 0 || ress.data.data == null) return;
        this.totalRechargeMoney = ress.data.data.totalRechargeMoney;
        this.totalRefundMoney = ress.data.data.totalRefundMoney;
        this.totalGiveMoney = ress.data.data.totalGiveMoney;
        this.Alipaymoney1 = ress.data.data.alipayTotalRechargeMoney;
        this.Alipaymoney2 = ress.data.data.alipayTotalRefundMoney;
        this.Alipaymoney3 = ress.data.data.alipayTotalGiveMoney;
      });
      this.moren()
    },
    // 默认
    async moren() {
      const { data: res } = await this.$http.get('bill-info/getTodayBill', {
        params: this.pars
      })
      this.money_day_list[0].price = res.data[0].totalBillMoney * 1;
      this.money_day_list[1].price = res.data[0].totalChargeMoney * 1;
      this.money_day_list[2].price = res.data[0].totalServiceMoney * 1;
      this.money_day_list[3].price = res.data[0].totalKwhs * 1;
    },
    // 时间选择器
    picker_event(e) {
      if (e != null) {
        this.pars.startTime = e[0];
        this.pars.endTime = e[1];
        this.dandu.startTime = e[0];
        this.dandu.endTime = e[1];
      } else {
        this.pars.startTime = null;
        this.pars.endTime = null;
        this.dandu.startTime = null;
        this.dandu.endTime = null;
        this.pickerMinDate = ''
      }
      // 折柱混合图
      this.bingtu();
      this.zongshouru();
    },
    // 总收入
    zongshouru() {
      this.shuju()
    },
    // 数据
    async shuju() {
      const that = this;
      await that.$http.get('bill-info/getThisMonthBill', {
        params: that.pars
      }).then(resb => {
        that.money_mounth_list[0].price_all = resb.data.data[0].totalBillMoney * 1;
        that.money_mounth_list[0].price_mounth = resb.data.data[0].lastMonthTotalBillMoney * 1;
        that.money_mounth_list[1].price_all = resb.data.data[0].totalChargeMoney;
        that.money_mounth_list[1].price_mounth = resb.data.data[0].lastMonthTotalChargeMoney * 1;
        that.money_mounth_list[2].price_all = resb.data.data[0].totalServiceMoney;
        that.money_mounth_list[2].price_mounth = resb.data.data[0].lastMonthTotalServiceMoney * 1;
        that.money_mounth_list[3].price_all = resb.data.data[0].totalKwhs * 1;
        that.money_mounth_list[3].price_mounth = resb.data.data[0].lastMonthTotalKwhs * 1;
      })
      await that.$http.get('bill-info/getAllMonthRechargeRefund', {
        params: that.pars
      }).then(resn => {
        if (resn.data.code !== 0) return;
        that.Wxmoney1 = resn.data.data.totalRechargeMoney;
        that.Wxmoney2 = resn.data.data.totalRefundMoney;
        that.Wxmoney3 = resn.data.data.totalGiveMoney;
        that.Alipay1 = resn.data.data.alipayTotalRechargeMoney;
        that.Alipay2 = resn.data.data.alipayTotalRefundMoney;
        that.Alipay3 = resn.data.data.alipayTotalGiveMoney;
      })
    },
    // 饼图
    async bingtu() {
      const { data: resb } = await this.$http.get('bill-info/getAllBill', {
        params: this.pars
      })
      let abc = resb.data[0].totalMoneys;
      if (resb.data[0].totalAppMoney == '0' || resb.data[0].totalAppMoney == null) {
        this.list[0].value1 = 0;
      } else {
        this.list[0].value1 = (resb.data[0].totalAppMoney / 100).toFixed(2);
      }
      if (resb.data[0].totalICMoney == '0' || resb.data[0].totalICMoney == null) {
        this.list[1].value1 = 0;
      } else {
        this.list[1].value1 = (resb.data[0].totalICMoney / 100).toFixed(2);
      }
      if (resb.data[0].totalOtherMoney == '0' || resb.data[0].totalOtherMoney == null) {
        this.list[2].value1 = 0;
      } else {
        this.list[2].value1 = (resb.data[0].totalOtherMoney / 100).toFixed(2);
      }
      if (resb.data[0].totalMoneys == '0' || resb.data[0].totalMoneys == null) {
        this.totalMoneys = 0;
        this.list[0].value2 = this.list[1].value2 = this.list[2].value2 = 0;
      } else {
        this.totalMoneys = (abc / 100).toFixed(2);
        this.list[0].value2 = this.list[1].value2 = this.list[2].value2 = this.totalMoneys;
      }
      var roseCharts = document.getElementsByClassName("cols"); // 对应地使用ByClassName
      for (var i = 0; i < roseCharts.length; i++) {
        // 通过for循环，在相同class的dom内绘制元素
        var myChart = echarts.init(roseCharts[i]);
        myChart.setOption({
          legend: {
            top: '5%',
            left: 'left',
            selectedMode: false
          },
          series: [
            {
              type: "pie",
              color: this.list[i].color,
              radius: ['70%', '40%'],
              center: ['50%', '50%'],
              avoidLabelOverlap: true,
              startAngle: 360,
              emphasis: {
                label: {
                  show: true,
                  fontSize: 20,
                }
              },
              labelLine: {
                show: false
              },
              data: [
                {
                  value: this.list[i].value1 * 2,
                  value2: this.totalMoneys * 2,
                  name: this.list[i].name,
                  label: {
                    show: true,
                    position: 'left',
                    formatter(param) {
                      if (param.data.value2 == 0) {
                        return param.data.value + '元' + '占比' + param.data.value2 + '%';
                      } else {
                        return (param.data.value / 2) + '元' + '占比' + ((param.data.value / param.data.value2) * 100).toFixed(0) + '%';
                      }
                    }
                  }
                },
                {
                  value: this.totalMoneys,
                  itemStyle: {
                    color: '#efefef',
                    decal: {
                      symbol: 'none'
                    }
                  }
                }
              ],
            },
          ],
        });
      }
    },
    formatDate(datetime, type) {
      const time = new Date(datetime)
      const year = time.getFullYear()
      const month = (time.getMonth() + 1).toString().padStart(2, '0')
      const date = (time.getDate()).toString().padStart(2, '0')
      return type === 'start' ? year + '-' + month + '-' + date + ' ' + '00' + ':' + '00' + ':' + '00' : year + '-' + month + '-' + date + ' ' + '23' + ':' + '59' + ':' + '59'
    },
    getDate() {
      const start = new Date()
      this.picker_value2[0] = this.formatDate(start.getTime() - 1000 * 3600 * 24 * 30, 'start')
      this.picker_value2[1] = this.formatDate(start.getTime(), 'end')
    }
  }
};
</script>

<style lang="less" scoped>
.home {
  display: flex;
  flex-direction: column;
  background-color: #ccc;

  .he_right {
    margin-bottom: 10px;
  }

  .center {
    flex: 2;
    display: flex;
    flex-direction: row;
    font-weight: bolder;
    background-color: #fff;

    .left {
      margin-top: 20px;
      margin-bottom: 20px;
      flex: 2;
      font-size: 18px;
      border-right: 1px solid gray;
    }

    .right {
      flex: 8;
      display: flex;
      flex-direction: row;
      align-items: center;


      .el-col {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;

        .cols {
          flex: 1;
        }
      }
    }
  }

  .bottom {
    flex: 8;
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;

    .bottom1 {
      flex: 1;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;

      dl {
        flex: 1;
        display: flex;
        flex-direction: column;

        li {
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          span {
            flex: 1;
            display: flex;
            align-items: center;
          }

        }

        li:last-child>span:nth-child(2) {
          color: red;
        }
      }

      dl:last-child>li:last-child>span:nth-child(2) {
        color: green;
      }
    }
  }


}
</style>