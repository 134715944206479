<template>
  <div>
    <el-form :inline="true" :model="queryInfo" class="form">
      <el-form-item label="电站名称：">
        <el-input placeholder="请输入电站名称" clearable v-model="queryInfo.name" @change="current"
          @clear="getPowerStationList">
        </el-input>
      </el-form-item>
      <el-form-item label="联系人：">
        <el-input placeholder="请输入联系人" @change="current" clearable v-model="queryInfo.contacts"
          @clear="getPowerStationList">
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="getPowerStationList">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
      </el-form-item>
      <!-- v-if="tianjiadianzhan" -->
    </el-form>
  <!-- 设备站点 -->
  <!-- 卡片视图区域 -->
  <el-card class="card">
    <!--  -->
    <li style="display: flex;flex-direction: row;justify-content: flex-end;padding-bottom: 20px;margin-left: -1%;">
        <el-button type="success" icon="el-icon-plus" @click="showPowerStation">添加设备站点</el-button> <el-button v-if="shanchu" type="danger" icon="el-icon-delete" @click="removeById(0)">批量删除</el-button>
      </li>
    <!-- table表格区域 -->
    <el-table class="table" :data="powerStationList" border @selection-change="handleSelectionChange" empty-text="暂无数据">
      <el-table-column align="center" type="selection" width="55"></el-table-column>
      <el-table-column align="center" label="客户名称" prop="name" width="160">
        <template slot-scope="scope">
          <div style="display: flex;justify-content: flex-start;">
            {{ scope.row.name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="地址" prop="address"></el-table-column>
      <el-table-column align="center" label="设备数量" prop="count" width="80">
        <template slot-scope="scope">
          <div style="display: flex;justify-content: flex-start;">
            {{ scope.row.count }}台
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="类型" prop="type" width="80">
        <template slot-scope="scope">
          <div style="display: flex;justify-content:center;">
            {{ scope.row.type == 2 ? '点胶机' : '载钉机' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态" prop="state" width="80">
        <template slot-scope="scope">
          <div style="display: flex;justify-content:center;">
            {{
      scope.row.state == 0 ? '规划' : scope.row.state == 1 ? '修建' : scope.row.state == 2 ? '仅供寻桩' : scope.row.state == 3 ? '正常运营' : scope.row.state == 4 ? '故障' : scope.row.state == 5 ? '维修' : scope.row.state == 6 ? '停用' : ''
    }}
          </div>
        </template>
      </el-table-column>
      <el-table-column align="left" label="操作" width="200">
        <template slot-scope="scope">
          <el-button size="small" :disabled="scope.row.s_c_anniu" @click="img_station(scope.row)"
            v-if="changzhantupian">上传站图</el-button>
          <el-button size="small" type="primary" icon="el-icon-edit" :disabled="scope.row.b_j_anniu"
            @click="showPowerStation(scope.row)" v-if="bianji">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页导航区域 -->
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page"
      :page-sizes="[8, 20, 50, 100]" :page-size="queryInfo.limit" layout="total, sizes, prev, pager, next, jumper"
      :total="total" background>
    </el-pagination>
    <!-- 添加&编辑设备站点的对话框 -->
    <el-dialog :title="title" :visible.sync="powerStationVisible" @close="powerStationDialogClosed" append-to-body
      :close-on-click-modal="false">
      <!-- 内容主体区域 -->
      <el-form :model="powerStationForm" :rules="powerStationRules" ref="powerStationRef" label-width="100px">
        <el-form-item label="设备名称" prop="name">
          <el-input v-model="powerStationForm.name" placeholder="请输入电站名称"></el-input>
        </el-form-item>
        <el-form-item label="设备地址" prop="address">
          <el-input v-model="powerStationForm.address" placeholder="请输入电站地址"></el-input>
          <el-button style="margin-top: 5px;" size="small" @click="getMap">获取地图位置</el-button>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="经度" prop="longitude">
              <el-input disabled v-model="powerStationForm.longitude" placeholder="请通过地图获取经度"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="纬度" prop="latitude">
              <el-input disabled v-model="powerStationForm.latitude" placeholder="请通过地图获取纬度"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 底部按钮区域 -->
      <p slot="footer" class="dialog-footer">
        <el-button @click="powerStationDialogClosed">取 消</el-button>
        <el-button type="primary" v-debounce="editPowerStation">确 定</el-button>
      </p>
    </el-dialog>
    <!-- 地图选点对话框 -->
    <el-dialog title="选择位置" :visible.sync="mapDialogVisible" width="70%" class="dialogStyle" @close="dialoag"
      append-to-body :close-on-click-modal="false">
      <div id="myPageTop">
        <el-input id="tipinput" style="width: 300px" placeholder="输入提示后查询" v-model="keyword">
          <el-button type="primary" slot="append" icon="el-icon-search" @click="searchKeyWord"></el-button>
        </el-input>
      </div>
      <div id="container"></div>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="qx4">取 消</el-button>
        <el-button type="primary" @click="editMapPosition">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 上传图片对话框 -->
    <el-dialog title="上传图片" :visible.sync="imgVisible" width="30%" @close="img_close" append-to-body
      :close-on-click-modal="false">
      <el-upload class="upload-demo" action="/api0/station-info/upLoadStationPicture" :on-change="handleChange"
        :file-list="fileList" :auto-upload="false">
        <el-button slot="trigger" size="small" type="primary">点击上传场站默认图片（单）</el-button>
        <el-button style="margin-left: 10px;" size="small" type="success" @click="ok">确定上传图片（单）</el-button>
      </el-upload>
      <input v-if="imgg != null" type="image" :src="tupian+imgg" alt="" style="margin-top: 20px;margin-bottom: 20px;width: 100px;
      height: 100px;">
      <el-upload class="upload-demo" action="/api0/station-info/upLoadStationPictures" :file-list="newfilelist"
        :on-change="beforeChange" :auto-upload="false" list-type="picture" style="margin-top: 40px;">
        <el-button slot="trigger" size="small" type="primary">点击上传场站轮播图（多）</el-button>
        <el-button style="margin-left: 10px;" size="small" type="success" v-debounce="ok_succ">确定上传图片（多）</el-button>
      </el-upload>
      <li v-if="imgges != null" style="list-style: none;">
        <img :src="tupian + item" alt="" v-for="(item, index) in imgges" :key="index"
          style="width: 100px;height: 100px;margin:20px;">
      </li>
    </el-dialog>

  </el-card>
</div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
// import { getMap } from 'echarts';
window._AMapSecurityConfig = {
  // 设置安全密钥
  securityJsCode: "36ffc476104cf8a1b5433c836cd27f72",
}

import caozuo from "@/utils/pubilc.js";
export default {
  name: 'powerStationWorld',
  data() {
    // 验证联系人的规则
    const checkContacts = (rule, value, callback) => {
      const reg = /^(?=.{2,4}$)[\u4e00-\u9fa5]+$/
      if (reg.test(value)) return callback();
      this.powerStationForm.contacts = '';
      callback(new Error('联系人姓名由2-4个汉字组成'))
    }
    // 验证手机号的规则
    const checkMobile = (rule, value, callback) => {
      // 验证手机号的正则表达式
      const regMobile = /^1[3-9]\d{9}$/;
      if (reg.test(value)) return callback();
      this.powerStationForm.contactsTel = '';
      callback(new Error('请输入正确的手机号码'))
    }
    const checkChargerPower = (rule, value, callback) => {
      const reg = /^[0-9]*$/
      if (reg.test(value)) return callback();
      this.powerStationForm.chargerPower = '';
      callback(new Error('电站总功率只能为数字'))
    }
    return {
      editDialogVisible3: false,
      radioMSK: [
        {
          qrCodeType: 0,
          name: '互联互通二维码'
        },
        {
          qrCodeType: 1,
          name: '通用二维码'
        }
      ],
      radioisInvoice: [
        {
          isInvoice: 0,
          name: '不开票'
        },
        {
          isInvoice: 1,
          name: '开票'
        }
      ],
      radioparkType: [{
        parkType: 0,
        name: '无停车'
      },
      {
        parkType: 1,
        name: '自营'
      }, {
        parkType: 2,
        name: '自营(无设备)'
      }, {
        parkType: 3,
        name: '第三方'
      }],
      editDialogVisible2: false,
      objMoney: [],
      activeIndex: 0,
      feeModelType: 0,
      list: [
        {
          paramKey: 0
        },
        {
          paramKey: 0
        },
        {
          paramKey: 0
        },
        {
          paramKey: 0
        }
      ],
      feeModelId: 0,
      adds: true,
      // 赠费
      // objARRAY: [],
      shuzu: [
        {
          name: '0',
          keys: '0',
          value2: '0'
        },
        {
          name: '0',
          keys: '0',
          value2: 0
        },
        {
          name: '0',
          keys: '0',
          value2: 0
        },
        {
          name: '0',
          keys: '0',
          value2: 0
        },
        {
          name: '0',
          keys: '0',
          value2: 0
        },
        {
          name: '0',
          keys: '0',
          value2: 0
        },
        {
          name: '0',
          keys: '0',
          value2: 0
        },
        {
          name: '0',
          keys: '0',
          value2: 0
        }
      ],
      editDialogVisibles: false,
      tupian: this.$imgUrls2,
      // 其他
      tupiananiu: false,
      chaxunaniu: false,
      tianjiadianzhan: false,
      shanchuanniu2: false,
      chongzhianniu: false,
      d_s_feilvbianji: false,
      celve: false,
      cardType: [
        {
          value: 0,
          label: '禁用刷卡'
        },
        {
          value: 1,
          label: '限本站卡'
        },
        {
          value: 2,
          label: '通用刷卡'
        }
      ],
      changzhantupian: false,
      j_z_l_feilvbianji: false,
      bianji: false,
      shanchu: false,
      newfilelist: [],
      ids_all: '',
      title_name: '',
      fileurl: '',
      img_list: '',
      fileList: [],
      imgVisible: false,
      value1: ['', ''],
      num: 1,
      btn_index: 0,
      startTypelist: [
        {
          label: "小程序",
          startType: 0
        },
        {
          label: "在线卡",
          startType: 1
        },
        {
          label: "离线卡",
          startType: 2
        },
        {
          label: "管理卡",
          startType: 3
        },
        {
          label: "Vin",
          startType: 4
        },
        {
          label: "互联互通",
          startType: 5
        },
        {
          label: "未知方式",
          startType: 9
        },
      ],
      // 查询电站管理列表的参数对象
      queryInfo: {
        stationIds: window.sessionStorage.getItem('stationIds'),
        name: '',
        contacts: '',
        contactsTel: '',
        stationState: '',
        page: 1,
        limit: 8,
        roleId: window.sessionStorage.getItem('roleId'),
        stationMode: '0,1,2'
      },
      total: 0,
      radioeletric: [
        {
          id: 0,
          name: '高速电站'
        },
        {
          id: 1,
          name: '低速电站'
        }
      ],
      // 电站列表的数据
      powerStationList: [],
      powerStationForm: {
      name:'',
      address:'',
      latitude:'',
      longitude:''
      },
      shareFixedTariffer: false,
      // 电站的状态列表
      stateData: [
        {
          label: '正在规划',
          value: "0"
        }, {
          label: '正在修建',
          value: "1"
        }, {
          label: '仅供寻桩',
          value: "2"
        }, {
          label: '正常运营',
          value: "3"
        }, {
          label: '场站维护',
          value: "5"
        }, {
          label: '场站停用',
          value: "6"
        }
      ],
      derateTypeData: [
        {
          label: '停车平台自行处理',
          value: 0
        }, {
          label: '减免金额',
          value: 1
        }, {
          label: '减免时长',
          value: 2
        }, {
          label: '折扣',
          value: 3
        }
      ],
      carTypeData: [],
      // 电站类型的列表
      stationTypeData: [
        {
          label: '公共设备',
          value: 1
        }, {
          label: '个人专用',
          value: 50
        }, {
          label: '内部专用',
          value: 104
        }, {
          label: '公交专用',
          value: 100
        }, {
          label: '环卫专用',
          value: 101
        }, {
          label: '物流专用',
          value: 102
        }, {
          label: '出租车专用',
          value: 103
        }, {
          label: '其他',
          value: 255
        }
      ],
      powerStationRules: {
        name: [
          { required: true, message: '电站名称不能为空', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '电站地址不能为空', trigger: 'blur' }
        ],
        longitude: [
          { required: true, message: '经度不能为空', trigger: 'blur' }
        ],
        latitude: [
          { required: true, message: '纬度不能为空', trigger: 'blur' }
        ]
      },
      powerStationVisible: false,
      title: '',
      // 省市区/县
      provinceData: [],
      cityData: [],
      districtData: [],
      addressCounty: {
        provinceId: '',
        provinceName: '',
        cityId: '',
        cityName: '',
        districtId: '',
        districtName: ''
      },
      page_num: 0,
      value: '',
      cursor: 'default',
      editDialogVisible: false,
      // 费率相关参数及数据
      tariffData: [],
      editFormRules: {},
      editFormRuless: {},
      radio: 2,
      imgg: '',
      imgges: null,
      fixedTariff: true,
      shareFixedTariff: false,
      shareTariff: false,
      halfTime: false,
      dialogVisible: false,
      ids: '',
      // 电站地图弹框
      mapDialogVisible: false,
      keyword: '',
      bianji_ok: false,
      isDragSelecting: false,
      startBoxIndex: '',
      secolor: '#fff',
      allid: '',
      parkModelId: '',
      newarray: [],
      parkid: '',
    }
  },
  computed: {
    jisuan() {
      return function (abc, bac, num) {
        if (bac !== 0) {
          const hr = (Number(bac) * Number(abc) / 100).toFixed(num)
          return hr
        } else {
          return 0
        }
      }
    },
    jisuanTime() {
      return function (abc, bac) {
        let hr = '';
        if (!abc) return hr = '0';
        return hr = ((abc - bac) / 60 / 1000).toFixed(0);
      }
    },
    money() {
      return function (abc, bca, num) {
        return (abc / bca).toFixed(num)
      }
    }
  },
  watch: {
    'tariffForms.duration'(newvalue) {
      if (newvalue == null || newvalue == undefined || newvalue == '') return;
      let pattern = /^\d+$/;
      if (!pattern.test(this.tariffForms.duration)) {
        this.tariffForms.duration = 0;
        this.$message.error('时长必须为正整数')
      }
    },
    newarray(newvalue) {
      if (newvalue.length < 2) return;
      this.objArray = newvalue;
    },
    'tariffForms.fixFee'(newvalue) {
      if (newvalue == '' || newvalue == null || newvalue == undefined) return;
      const abc = /^(?:[1-9]\d*|0)(?:\.\d{1,2})?$/;
      if (!abc.test(newvalue)) return this.$message.error('请输入正确的金额')
      if (newvalue > 6.5) return newvalue = 6.5;
      if (newvalue < 0.01) return newvalue = 0.01;
      this.tariffForms.fixFee = newvalue;
    },
    'tariffForms.fixServiceFee'(newvalue) {
      if (newvalue == '' || newvalue == null || newvalue == undefined) return;
      const abc = /^(?:[1-9]\d*|0)(?:\.\d{1,2})?$/;
      if (!abc.test(newvalue)) return this.$message.error('请输入正确的金额')
      if (newvalue > 6.5) return newvalue = 6.5;
      if (newvalue < 0.01) return newvalue = 0.01;
      this.tariffForms.fixServiceFee = newvalue;
    }
  },
  created() {
    // caozuo(161).then(res => {
    //   this.tianjiadianzhan = res;
    // });
    caozuo(153).then(ress => {
      this.changzhantupian = ress
    });
    caozuo(94).then(resb => {
      this.j_z_l_feilvbianji = resb;
    });
    caozuo(175).then(resb => {
      this.d_s_feilvbianji = resb;
    });
    caozuo(174).then(rese => {
      this.celve = rese
    });
    caozuo(154).then(rese => {
      this.bianji = rese
    });
    caozuo(155).then(resr => {
      this.shanchu = resr;
    });
    this.getPowerStationList();
    this.getProvinceData();
    // 一卡通车牌门禁？
    this.$http.get('three-parking-api/getAllThreeParkingApi', {
      params: this.queryInfo
    }).then(res => {
      if (res.data.code !== 0) return;
      this.carTypeData = res.data.data;
    })
  },
  methods: {
    jianmian() {
      const that = this;
      that.powerStationForm.derateValue = '';
    },
    editTariff2() {
      const that = this;
      if (that.tariffForm.fixFee == '' || that.tariffForm.fixFee == null || that.tariffForm.fixFee == undefined) return that.$message.error('占位费为必传项');
      // if (that.tariffForm.fixServiceFee.includes('.')) return that.tariffForm.fixServiceFee = that.tariffForm.fixServiceFee.split('.')[0];
      that.tariffForm.feeModelType = that.feeModelType == 0 ? 5 : 6;
      that.$http.post('feeModel/updGLModel', {
        feeModelType: that.tariffForm.feeModelType,
        fixFee: parseFloat(that.tariffForm.fixFee * 100),
        fixServiceFee: that.tariffForm.feeModelType == 5 ? parseFloat(that.tariffForm.fixServiceFee) : 0,
        stationId: that.ids_all,
        id: that.parkModelId,
        adminId: window.sessionStorage.getItem('adminId') * 1,
        stationMode: that.stationMode,
        billingPolicy: that.tariffForm.billingPolicy,
        timeFeeModel: (that.tariffForm.timeFeeModel * 100).toString()
      }).then(res => {
        if (res.data.code == 0) {
          that.$message.success(`停车策略${that.parkModelId == 0 ? '设置' : '编辑'}成功`);
        } else {
          that.$message.error(`停车策略${that.parkModelId == 0 ? '设置' : '编辑'}失败`);
          that.pubilc_function()
        }
        that.editDialogVisible2 = false;
        that.getPowerStationList()
      })
    },
    btn_click2() {
      this.editDialogVisible2 = false;
      this.tariffForm.feeModelType = 0;
    },
    current() {
      this.queryInfo.page = 1;
    },
    // 停车类型
    parkType_change(e) {
      const that = this;
      if (e !== 3) return that.powerStationForm;
    },
    // 低高速修改
    radioelerc_change(e) {
      this.powerStationForm.stationMode = e;
    },
    // 选择位置取消（地图组件）
    qx4() {
      this.keyword = this.powerStationForm.address;
      this.initAMap()
    },
    // 关闭地图组件弹窗
    dialoag() {
      this.keyword = '';
      this.mapDialogVisible = false;
    },
    // 编辑（取消）
    qx1() {
      this.powerStationVisible = false;
      this.powerStationForm = {};
    },
    // 获取电站列表
    getPowerStationList() {
      this.chaxunaniu = true;
      this.$http.get('kc-device-customer/getAllKcDeviceCustomer', { params: this.queryInfo }).then(res => {
        if (res.data.code !== 0) return this.$message.error(res.data.msg)
        this.powerStationList = [];
        this.powerStationList = res.data.data;
        this.total = res.data.count;
        this.powerStationList.forEach(item => {
          item.isstopsoc == 0 ? item.isstopsoc = false : item.isstopsoc = true;
          item.b_j_anniu = false;
          // item.j_z_l_f_l_b_j_anniu = false;
          item.s_c_anniu = false
        })
      })
    },
    // 重置搜索条件
    chongzhi() {
      this.chongzhianniu = true;
      this.queryInfo = {
        stationIds: window.sessionStorage.getItem('stationIds'),
        name: null,
        contacts: null,
        contactsTel: null,
        stationState: null,
        page: 1,
        limit: 8,
        stationMode: '0,1',
        roleId: window.sessionStorage.getItem('roleId')
      };
      this.getPowerStationList();
      setTimeout(() => {
        this.chongzhianniu = false
      }, 500)
    },
    // 时间设置
    changeTimeall(value) {
      if (value.length !== 0) {
        this.powerStationForm.startTime = value[0] == value[1] ? '' : value[0];
        this.powerStationForm.endTime = value[0] == value[1] ? '' : value[1];
        if (value[0] == value[1]) return this.$message.error('起始时间不得为同一天！');
      } else {
        this.powerStationForm.noticeStartTime = '';
        this.powerStationForm.noticeEndTime = '';
      }
    },
    // 上传场站图片
    ok() {
      const formData = new FormData();
      this.fileList.forEach((item => {
        formData.append('files', item.raw);
        formData.append('id', this.img_list);
        console.log(this.img_list,item.raw,'784');
      }))
      this.$http.post('kc-device-customer/upLoadPicture', formData).then(response => {
        this.$message.success(response.data.msg);
        this.imgVisible = false;
      }).catch(error => {
        // 处理错误
        this.$message(error.data.msg)
      });
    },
    // 上传场站轮播图
    ok_succ() {
      const formData = new FormData();
      this.newfilelist.forEach((item => {
        formData.append('files', item.raw);
        formData.append('id', this.img_list);
      }))
      console.log(this.newfilelist,'799');
      this.$http.post('kc-device-customer/upLoadPictures', formData).then(response => {
        this.$message.success(response.data.msg);
        this.imgVisible = false;
      }).catch(error => {
        // 处理错误
        this.$message(error.data.msg)
      });
    },
    handleSuccess() {
      this.imgVisible = false;
    },
    // 图
    async img_station(row) {
      this.img_list = row.id;
      this.imgg = null;
      this.imgges = null;
      let abc = '';
      this.fileList = [];
      this.newfilelist = [];
      this.imgVisible = true;
      await this.$http.get('kc-device-customer/getAllKcDeviceCustomer',{
        params:{
          id:row.id,
          page:1,
          limit:10,
          roleId:1
        }
      }).then(res => {
        row.s_c_anniu = res.data.code == 0 ? true : false;
        this.imgg = res.data.code == 0 && res.data.data[0].picture !== null && res.data.data[0].picture !== undefined && res.data.data[0].picture !== '' ? res.data.data[0].picture : null;
        abc = res.data.code == 0 && res.data.data[0].pictures !== null && res.data.data[0].pictures !== undefined && res.data.data[0].pictures !== '' ? res.data.data[0].pictures.split(',') : '';
        console.log(abc, '1699');
        this.imgges = abc == '' ? null : abc;
      })
    },
    // 图
    img_close() {
      this.imgVisible = false;
      this.fileList = [];
      this.newfilelist = [];
      this.powerStationList.forEach(item => {
        item.s_c_anniu = false;
      });
    },
    beforeChange(file, fileList) {
      this.newfilelist = fileList;
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
    },
    // 关闭费率编辑弹框
    btn_click() {
      this.editDialogVisible = false;
      this.tariffForm.feeModelType = 0;
    },
    // 关闭低速费率编辑弹窗
    btn_clicks() {
      this.editDialogVisibles = false;
      this.tariffForms.feeModelType = 0;
    },
    btn_event(item) {
      this.secolor = item.color;
    },
    handleMouseDown(index) {
      this.isDragSelecting = true;
      this.startBoxIndex = index;
      if (this.secolor == '#fff') return;
      this.timeList.forEach((item, inde) => {
        if (inde == index) return item.color = this.secolor;
      })
    },
    drag(event, index) {
      if (this.isDragSelecting && index !== this.startBoxIndex) {
        for (let i = Math.min(this.startBoxIndex, index); i <= Math.max(this.startBoxIndex, index); i++) {
          this.timeList[i].selected = true;
          if (this.timeList[i].selected == false) return;
          this.timeList[i].background = this.secolor !== '#fff' ? this.secolor : '#409eff';
        }
      }
    },
    btnChange(value) {
      for (let i = 0; i <= this.timeList.length; i++) {
        this.timeList[i].selected = i == value ? true : false;
        this.timeList[i].background = i == value ? this.secolor : '#fff'
      }
    },
    getMap() {
      this.mapDialogVisible = true;
      this.keyword = this.powerStationForm.address;
      this.initAMap()
    },
    // 双击展开折叠
    dbclick(row, column, event) {
      // console.log(event.currentTarget.querySelector(".el-table__expand-icon"))
      if (event.currentTarget.querySelector(".el-table__expand-icon")) {
        event.currentTarget.querySelector(".el-table__expand-icon").click()
      }
    },
    initAMap() {
      const that = this;
      let markers = [];
      AMapLoader.load({
        key: "9747ad6cffe4a3e286980b02cbff8a41", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          'AMap.ToolBar',
          'AMap.Scale',
          'AMap.Geocoder',
          'AMap.Geolocation',
          'AMap.PlaceSearch',
          'AMap.AutoComplete',
          'AMap.CitySearch'
        ],
        resizeEnable: true
      }).then((AMap) => {
        that.map = new AMap.Map("container", {
          // 设置地图容器id
          viewMode: "3D", // 是否为3D地图模式
          zoom: 15, // 初始化地图级别
          center: [112.453868, 34.619631], // 初始化地图中心点位置
          resizeEnable: true
        })
        // 关键字查询
        that.searchMap();
        // 选中logo（定位）
        that.map.on('click', function (e) {
          // 点标记显示内容，HTML要素字符串
          const markerContent = '' +
            '<div class="custom-content-marker">' +
            '   <img src="//a.amap.com/jsapi_demos/static/demo-center/icons/dir-via-marker.png">' +
            // '   <div class="close-btn" onclick="clearMarker()">X</div>' +
            '</div>';
          const position = new AMap.LngLat(e.lnglat.getLng(), e.lnglat.getLat()) //Marker经纬度
          this.remove(markers)
          const marker = new AMap.Marker({
            position: position,
            icon: new AMap.Icon({
              image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
              size: [20, 30],
            }),
            content: markerContent, // 将 html 传给 content
            offset: new AMap.Pixel(-20, -28)
          })
          markers.push(marker)
          this.add(marker)
          that.powerStationForm.longitude = e.lnglat.getLng()
          that.powerStationForm.latitude = e.lnglat.getLat()
        })
      }).catch((e) => {
        console.log(e)
      })
    },
    searchKeyWord() {
      this.initAMap()
    },
    // 关键字查询
    searchMap() {
      // 搜索框自动完成类
      this.auto = new AMap.AutoComplete({
        input: "tipinput", // 使用联想输入的input的id
        city: '全国'
      });
      //构造地点查询类
      this.placeSearch = new AMap.PlaceSearch({
        map: this.map,
        city: this.keyword
      });
      // 当选中某条搜索记录时触发
      this.placeSearch.search(this.keyword, function (status, result) {
        // this.powerStationForm.address=result;
        // console.log(status)
        // console.log(result,'1185')
      })
    },
    editMapPosition() {
      this.powerStationForm.address = this.keyword !== null ? this.keyword : this.powerStationForm.address;
      this.mapDialogVisible = false
    },
    changeTime(value) {
      this.queryInfo.startTime = !value || value[0] == value[1] ? '' : value[0];
      this.queryInfo.endTime = !value || value[0] == value[1] ? '' : value[1];
      if (!value) return this.getPowerStationList();
      if (value[0] == value[1]) return this.$message.error('起始时间不得为同一天！');
    },
    // 省
    async getProvinceData() {
      this.$http.get('sysArea/getAllSysArea').then(res => {
        if (res.data.code !== 0) return this.$message.error(res.data.msg);
        this.provinceData = res.data.data;
      })
    },
    // 省改变
    provinceChange(value) {
      this.getCityData(value);
      this.addressCounty.cityName = '';
      this.addressCounty.districtName = '';
      this.addressCounty.provinceId = value;
    },
    // 市
    async getCityData(id) {
      this.$http.get('sysArea/getAreaCityByParentId/' + id)
      console.log(res)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.cityData = res.data;
    },
    // 城市改变（没用到）
    cityChange(value) {
      this.getDistrictData(value)
      this.addressCounty.districtName = ''
      this.addressCounty.cityId = value
    },
    // 区、县
    async getDistrictData(id) {
      this.$http.get('sysArea/getAreaCountyByParentId/' + id)
      console.log(res)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.districtData = res.data
    },
    districtChange(value) {
      this.addressCounty.districtId = value
    },

    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.queryInfo.page=1;
      this.getPowerStationList()
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getPowerStationList()
    },
    // 展示添加&编辑对话框事件
    async showPowerStation(row) {
      const that = this;
      that.title = row.id == undefined || row.id == null ? '添加设备站点' : '编辑设备站点';
      that.tianjiadianzhan = row.id == undefined || row.id == null ? true : false;
      if(that.title=='添加设备站点')return that.powerStationVisible = true;
      await that.$http.get('kc-device-customer/getAllKcDeviceCustomer', {
        params: {
          id: row.id,
          page: 1,
          limit: 10,
          roleId: window.sessionStorage.getItem('roleId')
        }
      }).then(res => {
        if (res.data.code !== 0) return;
        that.powerStationForm = res.data.data[0];
        that.initAMap();
        that.powerStationVisible = true;
      })
    },
    // 添加&编辑设备站点的提交事件
    editPowerStation() {
      const that = this;
      if(that.powerStationForm.name=='')return that.$message.error('请输入客户名称');
      if(that.powerStationForm.address=='')return that.$message.error('请输入站点地址');
      if(that.powerStationForm.longitude==''||that.powerStationForm.latitude=='')return that.$message.error('请选择电站地址的位置信息');
         that.$http.post('kc-device-customer/editKcDeviceCustomer', that.powerStationForm).then(res => {
          that.$message({
            message: res.data.code == 0 ? `${that.title}成功！` : `${that.title}失败！`,
            type: res.data.code == 0 ? 'success' : 'error'
          });
          that.powerStationVisible = false;
          if (res.data.code == 0) return that.getPowerStationList();
        })
    },
    // 添加&编辑对话框的关闭事件
    powerStationDialogClosed() {
      this.$refs.powerStationRef.resetFields()
      this.powerStationVisible = false;
      this.powerStationForm={};
    },
    // 批量删除的复选框事件
    handleSelectionChange(value) {
      if (value.length < 0) return false;
      this.ids = '';
      value.forEach(item => {
        this.ids += item.id + ','
      });
      this.ids = this.ids.substring(0, this.ids.length - 1);
    },
    // 删除对应id的电站事件
    async removeById(row) {
      const confirmResult = await this.$confirm('此操作将永久删除该电站, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') return this.$message.info('已经取消删除！');
      let url = row == 0 ? this.ids : row.id;
      if (url == null || url == undefined || url == '') return this.$message.error('请选择你要删除的电站名称');
      this.$http.post(`kc-device-customer/delKcDeviceCustomer/${url}`).then(res => {
        this.$message({
          message: res.data.code !== 0 ? '删除失败！' : '删除成功！',
          type: res.data.code !== 0 ? 'error' : 'success'
        })
        if (res.data.code == 0) return this.getPowerStationList();
      })
    },
    pubilc_function() {
      this.tariffForm.fixFee = 0;
      this.tariffForm.fixServiceFee = 0;
      // this.tariffForm.billingPolicy.tip = 0;
      this.tariffForm.billingPolicy.tipService = 0;
      this.tariffForm.billingPolicy.peak = 0;
      this.tariffForm.billingPolicy.peakService = 0;
      this.tariffForm.billingPolicy.flat = 0;
      this.tariffForm.billingPolicy.flatService = 0;
      this.tariffForm.billingPolicy.valley = 0;
      this.tariffForm.billingPolicy.valleyService = 0
    },
    tariffDialogClosed() {
      // this.$router.push('/blank')
      this.$refs.editFormRef.clearValidate();
      this.getPowerStationList()
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-input.is-disabled .el-input__inner::-webkit-input-placeholder {
  text-align: center;
}



.el-col-24 {
  width: 8%;
}

.money1 {
  height: 20px;
  width: 40%;
  border: 1px solid #ccc;
  text-align: center;
}

.el-col-24 button {
  font-weight: 700;
  color: #fff;
}

.btnstyle {
  display: inline-block;
  text-align: center;
  border-bottom: none;
}

.el-checkbox-button:nth-child(6n+1) {
  margin-right: -2px;
}

.el-checkbox-button:nth-child(6n+2) {
  margin-right: -2px;
}

.el-checkbox-button:nth-child(6n+3) {
  margin-right: -2px;
}

.el-checkbox-button:nth-child(6n+4) {
  margin-right: -2px;
}

.el-checkbox-button:nth-child(6n+5) {
  margin-right: -2px;
}

.el-checkbox-button:nth-child(n+6) {
  margin-top: -2px;
}

#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 540px;
}

#myPageTop {
  position: absolute;
  top: 100px;
  right: 60px;
  z-index: 9999;
}
</style>