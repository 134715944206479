<template>
  <div class="sysparam">
    <!-- 卡片视图区域 键值对-->
    <el-row :gutter="20" v-if="tianjia" style="padding-bottom: 20px;">
      <el-col :span="5" style="display: flex;flex-direction: row;align-items: center;">
        <span style="font-size: 14px;padding-right: 10px;">参数名称:</span>
        <el-input v-model="queryInfo.keyName" style="width: 70%;" @change="getSysParamList"
          placeholder="请输入参数名称，离开输入框将检索"></el-input>
      </el-col>
      <!--<el-col :span="3"> <el-button @click="removeSysParamById">批量删除</el-button> </el-col>-->
    </el-row>
      <!-- 添加&编辑参数的对话框 -->
      <el-dialog :title="title" :visible.sync="editDialogVisible" width="35%" @close="editDialogClosed" append-to-body
      :close-on-click-modal="false">
      <!-- 内容主体区域 -->
      <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="80px">
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="参数名称" prop="keyName">
              <el-input v-model="editForm.keyName" placeholder="请输入参数名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="参数类型" prop="type">
              <el-select v-model="editForm.type" placeholder="请选择参数类型" style="width: 100%" @change="typechange"
                filterable>
                <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="参数描述" prop="des">
          <el-input v-model="editForm.des" placeholder="请输入参数描述"></el-input>
        </el-form-item>
        <el-divider></el-divider>
        <!--字符串 -->
        <el-form-item v-if="showText" label="参数文本" prop="valueName">
          <el-input v-model="editForm.valueName" placeholder="请输入参数文本" type="textarea"
            :autosize="{ minRows: 2, maxRows: 10 }"></el-input>
        </el-form-item>
        <!-- 数组 -->
        <div v-if="showObj">
          <div v-for="(item, index) in objValue" :key="index">
            <el-row :gutter="24">
              <el-col :span="10">
                <!-- :disabled="item.paramKey ? index + 1 > item.paramKey : null" -->
                <el-form-item label="参数值" prop="paramKey">
                  <el-input v-model="item.paramKey" placeholder="请输入参数值"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <!-- :disabled="item.paramValue ? index + 1 > item.paramValue : null" -->
                <el-form-item label="参数键" prop="paramValue">
                  <el-input v-model="item.paramValue" placeholder="请输入参数键"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-button style="margin-left: 20px;" @click="delObj(index)" type="danger">删除</el-button>
              </el-col>
            </el-row>
          </div>
          <el-button style="margin-left: 40%;" @click="addObj">添加键值对</el-button>
        </div>
        <!-- 文章内容 -->
        <div v-if="showDes">
          <div style="border: 1px solid #ccc;">
            <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig"
              mode="simple" />
            <Editor style="height: 500px; overflow-y: hidden;" v-model="html" :defaultConfig="editorConfig"
              mode="simple" @onCreated="onCreated" />
          </div>
        </div>
      </el-form>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogClosed">取 消</el-button>
        <el-button type="primary" v-debounce="editRole">确 定</el-button>
      </span>
    </el-dialog>
    <el-card>
      <!-- 添加参数按钮区域 -->
      <li style="display: flex;justify-content: flex-end;margin-left: -1%;padding-bottom: 10px;">
        <el-button type="success" @click="showEditRole" v-if="tianjia" icon="el-icon-plus">添加参数</el-button>
      </li>
      <!-- 参数列表区域 -->
      <el-table :data="sysParamList" border @selection-change="handleSelectionChange" empty-text="暂无数据">
        <!-- 索引列 -->
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column align="center" label="ID" prop="id" width="100"></el-table-column>
        <el-table-column align="left" label="参数名称" prop="keyName"></el-table-column>
        <el-table-column align="left" label="参数键值" prop="valueName">
          <template slot-scope="scope">
            <div style="display: flex;justify-content: flex-start;" v-if="scope.row.valueName.length < 40">
              {{ scope.row.valueName }}
            </div>
            <el-tooltip class="item" effect="dark" :content="scope.row.valueName" placement="top" v-else>
              <span>{{ qiege(scope.row.valueName) }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column align="left" label="参数描述" prop="des">
          <template scope="scope">
            <span>{{ scope.row.des == null ? '--' : scope.row.des }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="参数状态" prop="status" width="90" v-if="bianji">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" :active-color="scope.row.status == false ? '#ff4949' : '#13ce66'"
              :inactive-color="scope.row.status == false ? '#ff4949' : '#13ce66'"
              @change="paramStatusChange(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column align="left" label="操作" width="120" v-if="bianji">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" icon="el-icon-edit" :disabled="scope.row.b_j_anniu"
              @click="showEditRole(scope.row)" v-if="bianji">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSize" @current-change="handleCurrent" :current-page.sync="queryInfo.page"
        :page-sizes="[5, 10, 20, 30]" :page-size="queryInfo.limit" layout="total, sizes, prev, pager, next, jumper"
        :total="dangqiantotal" background>
      </el-pagination>
    </el-card>

  </div>
</template>

<script>
import caozuo from '@/utils/pubilc';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
  name: 'roleWorld',
  components: { Editor, Toolbar },
  data() {
    return {
      // 富文本参数
      editor: null,
      html: '<p></p>',
      toolbarConfig: {},
      editorConfig: { placeholder: '请输入内容...' },
      // 其他
      showDes: false,
      tianjia: false,
      bianji: false,
      title: '',
      dangqiantotal: 0,
      queryInfo: {
        keyName: '',
        page: 1,
        limit: 5
      },
      objValue: [],
      editForm: {
        id: null,
        keyName: null,
        valueName: null,
        objValue: '',
        des: null,
        type: 0,
        status: 0
      },
      editFormRules: {
        keyName: [
          { required: true, message: '参数名称不能为空', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择参数类型', trigger: 'change' }
        ],
        valueName: [
          { required: true, message: '参数文本不能为空', trigger: 'blur' }
        ]
      },
      editDialogVisible: false,
      // 所有参数列表数据
      sysParamList: [],
      // 所有参数类型
      typeList: [
        {
          label: '键值对',
          value: 0
        },
        {
          label: '文本',
          value: 1
        },
        {
          label: '文章',
          value: 2
        }
      ],
      showObj: true,
      showText: false,
      ids: '',
      desValue: []
    }
  },
  computed: {
    qiege() {
      return (obj) => {
        return obj.substring(0, 40) + '...'
      }
    }
  },
  created() {
    caozuo(143).then(res => {
      this.tianjia = res;
    });
    caozuo(144).then(res => {
      this.bianji = res;
    });
    this.getSysParamList()
  },

  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      console.log('onCreated', editor, this.editor)
    },
    handleCurrent(newPage) {
      this.queryInfo.page = newPage;
      this.getSysParamList()
    },
    handleSize(newPage) {
      this.queryInfo.limit = newPage;
      this.queryInfo.page = 1;
      this.getSysParamList()
    },
    // 获取所有参数的列表
    async getSysParamList() {
      const { data: res } = await this.$http.get('/sysConfig/getAllSysConfig', { params: this.queryInfo })
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      this.dangqiantotal = res.count;
      res.data.forEach(item => {
        item.status === 0 ? item.status = true : item.status = false;
        item.b_j_anniu = false;
      })
      this.sysParamList = res.data
    },
    // 参数类型改变事件
    typechange(value) {
      this.showObj = value == 0 ? true : false;
      this.showText = value == 1 ? true : false;
      this.showDes = value == 2 ? true : false;
      this.editForm.objValue = '';
      this.objValue = [];
      this.desValue = [];
      this.editForm.valueName = '';
    },
    // 添加&编辑对话框
    showEditRole(row) {
      const that = this;
      that.editDialogVisible = true;
      that.typechange(row.type);
      that.editForm = {
        id: null,
        keyName: null,
        valueName: null,
        objValue: '',
        des: null,
        type: 0,
        status: 0
      };
      that.title = row.id === undefined || row.id === null || row.id === '' ? '添加参数' : '编辑参数'
      if (row.id == undefined || row.id == null || row.id == '') return that.showObj=true;
      that.$http.get(`/sysConfig/getSysConfigBOById/${row.id}`).then(res => {
        if (res.data.code !== 0) return that.$message.error(res.msg);
        that.editForm = res.data.data;
        if (that.editForm.type == 0) {
          that.objValue.push(...JSON.parse(that.editForm.valueName));
        } else if (that.editForm.type == 2) {
          // const editors = that.editor;
          // editors.setHtml
          console.log(that.editForm, '268');
          const editors = that.editor;
          editors.setHtml(that.editForm.valueName);
        }
        //
        // that.$nextTick(() => {
        //   if (that.$refs.editFormRef) {
        that.showObj = that.editForm.type == 0 ? true : false;
        that.showText = that.editForm.type == 1 ? true : false;
        that.showDes = that.editForm.type == 2 ? true : false;
        that.$refs.editFormRef.clearValidate()
        //   }
        // })

      })

    },
    // 添加键值对
    addObj() {
      this.objValue.push({
        paramKey: '',
        paramValue: ''
      });
      this.$forceUpdate();
      console.log(this.objValue, '246');
    },
    // 添加、编辑对话框中的jianzhidui删除事件
    delObj(index) {
      this.objValue.splice(index, 1);
      this.$forceUpdate()
    },
    // 添加&编辑方法
    editRole() {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        this.editForm.adminId = window.sessionStorage.getItem('adminId') * 1;
        if (this.title == '添加参数') {
          if (this.editForm.type == 0) {
            this.editForm.valueName = JSON.stringify(this.objValue);
          } else if (this.editForm.type == 2 && this.showDes) {
            const editors = this.editor;
            if (editors == null) return;
            this.editForm.valueName = editors.getHtml();
          };
          const { data: res } = await this.$http.post('sysConfig/addSysConfig', this.editForm)
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message.success('添加参数成功！')
          this.getSysParamList()
          this.editDialogVisible = false;
          this.showDes = false;
        } else {
          if (this.editForm.type == 0) {
            this.editForm.valueName = JSON.stringify(this.objValue);
          }
          const { data: res } = await this.$http.post('/sysConfig/updateSysConfigById', this.editForm)
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message.success('编辑参数成功！')
          this.getSysParamList()
          this.editDialogVisible = false;
          this.showDes = false;
        }
      })
    },
    // 监听添加&修改对话框的关闭事件
    editDialogClosed() {
      // this.$router.push('/blank')
      this.$refs.editFormRef.resetFields()
      this.editForm.objValue = []
      this.editForm.keyName = '';
      this.editDialogVisible = false;
      this.sysParamList.forEach(item => {
        item.b_j_anniu = false;
      });
      if (this.editor == null) return;
      this.editor.destroy()
    },
    // 监听switch 开关状态的改变
    async paramStatusChange(row) {
      row.status = row.status == false ? 1 : row.status == true ? 0 : row.status;
      const { data: res } = await this.$http.post(`sysConfig/updateStatusById/${row.id}/${row.status}`)
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      this.$message.success('更新系统参数状态成功！')
      this.getSysParamList()
    },
    // 批量删除的复选框改变事件
    handleSelectionChange(value) {
      if (value.length < 1) {
        this.ids = ''
        value.forEach(item => {
          this.ids += item.id + ','
        })
        this.ids = this.ids.substring(0, this.ids.length - 1)
      } else {
        return false
      }
    },
    // 根据id删除对应的权限
    // async removeSysParamById(row) {
    //   const confirmResult = await this.$confirm('此操作将永久删除该参数, 是否继续?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).catch(err => err)
    //   if (confirmResult !== 'confirm') {
    //     return this.$message.info('取消删除！')
    //   }
    //   if (row.id === null || row.id === '' || row.id === undefined) {
    //     const { data: res } = await this.$http.post('sysConfig/delSysConfigId/' + this.ids)
    //     if (res.code !== 0) {
    //       return this.$message.error(res.msg)
    //     }
    //     this.$message.success('删除成功！')
    //     this.getSysParamList()
    //   } else {
    //     const { data: res } = await this.$http.post('sysConfig/delSysConfigId/' + row.id.toString())
    //     if (res.code !== 0) {
    //       return this.$message.error(res.msg)
    //     }
    //     this.$message.success('删除成功！')
    //     this.getSysParamList()
    //   }
    // }
  }
};
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less" scoped>
.sysparam {
  width: 100%;
  height: 100%;
}

.el-tooltip__popper {
  max-width: 70%;
  min-width: 70%;
  padding: 8px 10px;
  height: auto;
}
</style>