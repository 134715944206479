import Vue from 'vue'
import Router from 'vue-router'
import Login from '../views/Login.vue'
import Menu from '../views/Menu.vue'
import Blank from '../views/blank/Blank.vue'
import Detail from "../views/DataCenter/Detail.vue"
import Power from '../views/system/Power.vue'
import User from '../views/system/User.vue'
import Role from '../views/system/Role.vue'
import equipmentManagement from '../views/equipmentManagement/equipmentManagement.vue'
import EletricPileLog from '../views/Site/Dailoag/EletricPileLog.vue'
import ElectricPileAdd from '../views/Site/Dailoag/ElectricPileAdd.vue'
import EquipmentSite from '../views/equipmentManagement/EquipmentSite.vue'
import Speed from "../views/DataCenter/Speed.vue"
import OperateLog from '../views/system/OperateLog.vue'
import LoginLog from '../views/system/LoginLog.vue'
import Settlement from '../views/system/Settlement.vue'
import WechartApplet from '../views/system/WechartApplet.vue'
import Application from '../views/system/Application.vue'
import Hlht from '../views/system/Hlht.vue'
import Coupon from '../views/Preferential/Coupon.vue'
import Invoice from '../views/Preferential/Invoice.vue'
import Preferential from '../views/Preferential/Preferential.vue'
import PowerStation from '../views/Site/PowerStation.vue'
import ElectricPile from '../views/Site/ElectricPile.vue'
import PileAnalysis from '../views/Site/PileAnalysis.vue'
import ChargingBill from '../views/OrderCenter/ChargingBill.vue'
import HistoryBill from '../views/OrderCenter/HistoryBill.vue'
import DataStatistics from '../views/DataCenter/DataStatistics.vue'
import Default from '../views/DataCenter/Default.vue'
import Sites from '../views/DataCenter/Sites.vue'
import OrderCenter from '../views/OrderCenter/OrderCenter.vue'
import ManageAccount from '../views/Account/ManageAccount.vue'
import WechartAccount from '../views/Account/WechartAccount.vue'
import VinAccount from '../views/Account/VinAccount.vue'
import SysParam from '../views/sysParams/sysParam.vue'
import Parkingorders from '../views/OrderCenter/Parkingorders.vue'
import trainstop from '../views/Site/trainstop.vue'
import Groundlockmanagement from '../views/Site/Groundlockmanagement.vue'
import ActiveName from "../views/Preferential/ActiveName"
import FreeCoupton from "../views/Preferential/FreeCoupton"
import OperatorAccount from "../views/Account/OperatorAccount"
import AccountOpening from "../views/Account/AccountOpening"
import Withdrawal from "../views/Account/Withdrawal"
import Flowmanagement from "../views/Flowmanagement/Flowmanagement"
import FlowmanagementList from "../views/Flowmanagement/FlowmanagementList"
import FlowmanagementStation from "../views/Flowmanagement/FlowmanagementStation"
import DefaultH5 from "../views/DefaultH5"
import ElecticAustor from "../views/Site/ElecticAustor"
import CodeM from "../views/sysParams/codeM.vue"
import EquipmentAnalysis from "../views/DataCenter/EquipmentAnalysis.vue"
import EquipmentEfficiencyAnalysis from "../views/DataCenter/EquipmentEfficiencyAnalysis.vue"
import stationActive from "../views/Preferential/stationActive.vue"
// 历史标签使用重复路由导致的报错（已解决）：
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)
const router = new Router({
  routes: [
    { path: '/', redirect: '/dataStatistics' },
    {
      path: '/DefaultH5', component: DefaultH5
    },
    {
      path: '/Default', component: Default
    },
    { path: '/login', component: Login },

    {
      path: '/menu',
      component: Menu,
      redirect: '/dataStatistics',
      children: [
        { path: '/stationActive', component: stationActive },
        { path: '/equipmentManagement', component: equipmentManagement },
        { path: '/EquipmentAnalysis', component: EquipmentAnalysis },
        { path: '/EquipmentEfficiencyAnalysis', component: EquipmentEfficiencyAnalysis },
        { path: '/Speed', component: Speed },
        { path: '/EquipmentSite', component: EquipmentSite },
        { path: '/EletricPileLog',component:EletricPileLog, props: true},
        { path: '/ElectricPileAdd/:id',component:ElectricPileAdd, name: 'ElectricPileAdd', props: true},
        { path: '/blank', component: Blank },
        { path: '/user', component: User },
        { path: '/role', component: Role },
        { path: '/power', component: Power },
        { path: '/settlement', component: Settlement },
        { path: '/wechartApplet', component: WechartApplet },
        { path: '/application', component: Application },
        { path: '/hlht', component: Hlht },
        { path: '/Detail', component: Detail },
        { path: '/operateLog', component: OperateLog },
        { path: '/loginLog', component: LoginLog },
        { path: '/orderCenter', component: OrderCenter },
        { path: '/manageAccount', component: ManageAccount },
        { path: '/wechartAccount', component: WechartAccount },
        { path: '/vinAccount', component: VinAccount },
        { path: '/preferential', component: Preferential },
        { path: '/coupon', component: Coupon },
        { path: '/invoice', component: Invoice },
        { path: '/powerStation', component: PowerStation },
        { path: '/electricPile', component: ElectricPile },
        { path: '/pileAnalysis', component: PileAnalysis },
        { path: '/chargingBill', component: ChargingBill },
        { path: '/historyBill', component: HistoryBill },
        { path: '/dataStatistics', component: DataStatistics },
        {
          path: '/ElecticAustor', component: ElecticAustor
        },
        {
          path: '/CodeM', component: CodeM
        },
        { path: '/sysParam', component: SysParam },
        { path: '/Parkingorders', component: Parkingorders },
        { path: '/trainstop', component: trainstop },
        { path: '/Groundlockmanagement', component: Groundlockmanagement },
        { path: '/Sites', component: Sites },
        { path: '/ActiveName', component: ActiveName },
        { path: '/FreeCoupton', component: FreeCoupton },
        { path: '/OperatorAccount', component: OperatorAccount },
        { path: '/AccountOpening', component: AccountOpening },
        { path: '/Withdrawal', component: Withdrawal },
        { path: '/Flowmanagement', component: Flowmanagement },
        { path: '/FlowmanagementList', component: FlowmanagementList },
        { path: '/FlowmanagementStation', component: FlowmanagementStation },
      ]
    }
  ]
})
router.beforeEach((to, from, next) => {
  // 定义公开路径（无需Token）
  const publicPaths = ['/login', '/DefaultH5'];
  if (publicPaths.includes(to.path)) {
    return next(); // 放行并终止后续逻辑
  }

  // 检查Token是否存在
  const tokenStr = window.sessionStorage.getItem('token');
  if (!tokenStr) {
    next('/login'); // 无Token跳转登录页
  } else {
    next(); // 有Token放行
  }
});
export default router
