<template>
  <div>
    <link href="https://unpkg.com/@wangeditor-next/editor@latest/dist/css/style.css" rel="stylesheet">
    <div style="border: 1px solid #ccc;">
      <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" mode="default" />
      <Editor style="height: 500px; overflow-y: hidden;" v-model="html" :defaultConfig="editorConfig" mode="default"
        @onCreated="onCreated" />
    </div>
  </div>
</template>
<script src="https://unpkg.com/@wangeditor-next/editor@latest/dist/index.js"></script>
<script>
import Vue from 'vue';
import { Editor, Toolbar } from '@wangeditor-next/editor-for-vue2';

export default Vue.extend({
  name: 'codeM',
  components: {
    Editor, Toolbar
  },
  data() {
    var E = window.wangEditor;
    return {
      editor: null,
      html: '<p>hello</p>',
      toolbarConfig: {},
      editorConfig: { placeholder: '请输入内容...' },
      mode: 'default', // or 'simple'
    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {
    setTimeout(() => {
      this.html = '<p>模拟 Ajax 异步设置内容 HTML</p>'
    }, 1500)
  },
  beforeDestroy() {
    const editor = this.editors;
    if (editor == '') return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  methods: {
    onCreateds(editor) {
      this.editors = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
  }
});
</script>
<!-- <style src="@wangeditor-next/editor/dist/css/style.css"></style> -->
<style lang="less" scoped>
#editor—wrapper {
  border: 1px solid #ccc;
  z-index: 100;
  /* 按需定义 */
}

#toolbar-container {
  border-bottom: 1px solid #ccc;
}

#editor-container {
  height: 500px;
}

.alls {
  width: 100%;
  height: 100%;
  overflow: hidden;

}
</style>
