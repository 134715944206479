<template>
  <div>
    <el-row :gutter="20">
      <el-form :inline="true" :model="queryInfo" class="demo-form-inline">
        <el-form-item label="平台ID：">
          <el-input placeholder="请输入平台ID" clearable v-model="queryInfo.myOperatorId" @clear="getPowerStationList"
            @change="current">
          </el-input>
        </el-form-item>
        <el-form-item label="对接平台ID：">
          <el-input placeholder="请输入对接平台ID" clearable v-model="queryInfo.toOperatorId" @clear="getPowerStationList"
            @change="current">
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="互联互通：">
            <el-select v-model="queryInfo.hlhtType" placeholder="互联互通" filterable clearable @change="changes">
              <el-option v-for="item in hlhtlist" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item> -->
        <el-form-item label="互联互通状态：">
          <el-select v-model="queryInfo.hlhtState" placeholder="请选择状态" filterable clearable @change="change">
            <el-option v-for="item in typelist" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="互联互通类型：">
          <el-select v-model="queryInfo.pushType" placeholder="请选择状态" filterable clearable>
            <el-option v-for="item in pushTypelist" :key="item"
              :label="item == 1 ? '高德地图' : item == 2 ? '河南省平台' : item == 3 ? '新电途' : item == 4 ? '小桔充电' : item == 5 ? '待定' : ''"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="getPowerStationList">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-refresh-left" @click="qingkong">重置</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <!-- 卡片视图区域 -->
    <el-card class="card">
      <li style="display: flex;justify-content: flex-end;margin-left: -1%;padding-bottom: 10px;">
        <el-button icon="el-icon-plus" type="success" @click="bianji(0)">添加</el-button>
      </li>
      <!-- table表格区域 -->
      <el-table class="table" :data="chargingList" border empty-text="暂无数据">
        <el-table-column align="center" label="ID序列" prop="id" width="80"></el-table-column>
        <el-table-column align="center" label="我的平台名称" prop="myName"></el-table-column>
        <el-table-column align="center" label="我的平台ID" prop="myOperatorId" width="120"></el-table-column>
        <el-table-column align="center" label="对接平台名称" prop="toname"></el-table-column>
        <el-table-column align="center" label="对接场站" prop="StationName"></el-table-column>
        <el-table-column align="center" label="对接平台ID" prop="toOperatorId" width="120"></el-table-column>
        <el-table-column align="center" label="互联互通" prop="hlhtType" width="100">
          <template scope="scope">
            <el-tag
              :type="scope.row.hlhtType == '无' ? 'info' : scope.row.hlhtType == '互联' ? '' : scope.row.hlhtType == '互通' ? 'warning' : 'success'">{{
      scope.row.hlhtType }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime" width="100">
          <template scope="scope">
            <span v-if="scope.row.createTime">{{ scope.row.createTime }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态" prop="hlhtState" width="80">
          <template scope="scope">
            <el-tag :type="scope.row.hlhtState == '正常' ? 'success' : 'danger'">{{ scope.row.hlhtState }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="400">
          <template slot-scope="scope">
            <el-button size="small" type="primary" icon="el-icon-edit" @click="bianjiMethods(scope.row)"
              :disabled="scope.row.b_j_aniu">编辑</el-button>
            <el-button size="small" :type="scope.row.onlineStatus == 0 ? 'success' : 'info'"
              @click="zhuangtai(scope.row)" :disabled="scope.row.b_j_aniu">{{ scope.row.onlineStatus == 0 ? '上线' : '下线'
              }}</el-button>
            <el-button size="small" type="warning" @click="caozuorizhi(scope.row)">操作日志</el-button>
            <el-button size="small" type="danger" @click="tuisongrizhi(scope.row)">推送日志</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination v-if="showpage1" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="queryInfo.page" :page-sizes="[8, 20, 50, 100]" :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-card>
    <!-- 编辑弹框 -->
    <el-dialog :title="titles" :visible.sync="bianjidialoag" width="35%" @close="qx" append-to-body
      :close-on-click-modal="false">
      <el-form :model="editForm" label-position="left" label-width="auto" :rules="rules" ref="editFormRef"
        style="display:flex;flex-direction:column;list-style:none">
        <div style="display:flex;flex-direction:row;flex:1;justify-content:space-between;">
          <li class="left" style="display:flex;flex-direction:column;flex:1;">
            <el-form-item label="我的平台名称" prop="myName">
              <el-input placeholder="请输入我的平台名称" v-model="editForm.myName"></el-input>
            </el-form-item>
            <el-form-item label="我的运营商秘钥" prop="comeOperatorSecret">
              <el-input placeholder="请输入我的运营商秘钥" v-model="editForm.comeOperatorSecret"></el-input>
            </el-form-item>
            <el-form-item label="Aes初始向量" prop="comeIv">
              <el-input placeholder="请输入Aes初始向量" v-model="editForm.comeIv"></el-input>
            </el-form-item>
          </li>
          <li class="right" style="display:flex;flex-direction:column;flex:1;margin-left: 20px;">
            <el-form-item label="我的平台ID" prop="myOperatorId">
              <el-input placeholder="请输入我的平台ID" v-model="editForm.myOperatorId"></el-input>
            </el-form-item>
            <el-form-item label="Aes秘钥" prop="comeKey">
              <el-input placeholder="请输入Aes秘钥" v-model="editForm.comeKey"></el-input>
            </el-form-item>
            <el-form-item label="签名秘钥" prop="comeSecret">
              <el-input placeholder="请输入签名秘钥" v-model="editForm.comeSecret"></el-input>
            </el-form-item>
          </li>
        </div>
        <div style="display:flex;flex:1;justify-content:flex-start;">
          <li style="width:100%">
            <el-form-item label="访问地址" prop="comeUrl">
              <el-input placeholder="请输入访问地址" v-model="editForm.comeUrl"></el-input>
            </el-form-item>
          </li>
        </div>
        <div style="display:flex;margin-top:-20px;flex:1;justify-content:flex-start">
          <li style="width:100%">
            <el-divider content-position="center"></el-divider>
          </li>
        </div>
        <div style="display:flex;flex-direction:row;flex:1;justify-content:space-between;">
          <li class="left" style="display:flex;flex-direction:column;flex:1;">
            <el-form-item label="对接平台" prop="toname">
              <el-input placeholder="请输入对接平台" v-model="editForm.toname"></el-input>
            </el-form-item>
            <el-form-item label="对方的运营商秘钥" prop="toOperatorSecret">
              <el-input placeholder="请输入对方的运营商秘钥" v-model="editForm.toOperatorSecret"></el-input>
            </el-form-item>
            <el-form-item label="Aes初始向量" prop="toIv">
              <el-input placeholder="请输入Aes初始向量" v-model="editForm.toIv"></el-input>
            </el-form-item>
          </li>
          <li class="right" style="display:flex;flex-direction:column;flex:1;margin-left: 20px;">
            <el-form-item label="对接平台ID" prop="toOperatorId">
              <el-input placeholder="请输入对接平台ID" v-model="editForm.toOperatorId"></el-input>
            </el-form-item>
            <el-form-item label="Aes秘钥" prop="toKey">
              <el-input placeholder="请输入Aes秘钥" v-model="editForm.toKey"></el-input>
            </el-form-item>
            <el-form-item label="签名秘钥" prop="toSecret">
              <el-input placeholder="请输入签名秘钥" v-model="editForm.toSecret"></el-input>
            </el-form-item>
          </li>
        </div>
        <div style="display:flex;flex:1;justify-content:flex-start">
          <li style="width: 100%;"> <el-form-item label="访问地址" prop="toUrl">
              <el-input placeholder="请输入访问地址" v-model="editForm.toUrl"></el-input>
            </el-form-item></li>
        </div>
        <div style="display:flex;flex:1;margin-top:-20px;justify-content:flex-start">
          <li style="width:100%"> <el-divider content-position="center"></el-divider></li>
        </div>
        <div style="display:flex;flex-direction:row;flex:1;justify-content:flex-start">
          <li class="left" style="display:flex;flex-direction:column;flex:1;">
            <el-form-item label="互联互通" prop="hlhtType">
              <el-select v-model="editForm.hlhtType" placeholder="请选择互联互通" filterable>
                <el-option v-for="item in hlhtlist1" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </li>
          <li class="right"
            style="display:flex;flex-direction:column;flex:1;justify-content:flex-start;margin-left: 20px;">
            <el-form-item label="状态" prop="hlhtState">
              <el-select v-model="editForm.hlhtState" placeholder="请选择状态" filterable>
                <el-option v-for="item in typelist1" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </li>
        </div>
        <div style="display:flex;flex:1;justify-content:flex-start">
          <li style="width:100%"> <el-form-item label="备注" prop="phone">
              <el-input placeholder="请输入备注" v-model="editForm.des"></el-input>
            </el-form-item></li>
        </div>
      </el-form>
      <!-- </el-row> -->
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="ok(2)">确定</el-button>
        <el-button @click="qx">取消</el-button>
      </span>
    </el-dialog>
    <!-- 推送日志、操作日志 -->
    <el-dialog :title="title" :visible.sync="type" width="80%" @close="type = false" append-to-body
      :close-on-click-modal="false" class="tanceng">
      <el-form :model="souForm" label-width="auto">
        <el-row :gutter="20" style="margin-bottom: 20px;">
          <el-col :span="4"
            style="display: flex;align-items: flex-end;font-size: 14px;padding: 10px 0;margin-left: 10px;">
            <span>我的平台ID：</span><span>{{ souForm.myOperatorId }}</span>
          </el-col>
          <el-col :span="4"
            style="display: flex;align-items: flex-end;font-size: 14px;padding: 10px 0;margin-left: 20px;">
            <span>对接平台ID：</span><span>{{ souForm.toOperatorId }}</span>
          </el-col>
          <el-col :span="5"><el-form-item label="流水号：" prop="equipBizSeq">
              <el-input placeholder="请输入流水号" v-model="souForm.equipBizSeq" clearable @clear="chongzhi1(1)"></el-input>
            </el-form-item></el-col>
          <el-col :span="5">
            <el-form-item label="充电编号：" prop="connectorId">
              <el-input placeholder="请输入充电编号" v-model="souForm.connectorId" clearable @clear="chongzhi1(2)"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" icon="el-icon-search" @click="chaxun">查询</el-button>
          </el-col>
          <el-col :span="1">
            <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi1(3)">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-table class="table" :data="dangqianlist" border empty-text="暂无数据">
        <el-table-column align="center" label="充电编号" prop="connectorId" width="150">
          <template slot-scope="scope">
            <span>{{
      scope.row.connectorId == null || scope.row.connectorId == undefined || scope.row.connectorId == '' ? '--'
        : scope.row.connectorId }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="流水号" prop="equipBizSeq" width="140">
          <template slot-scope="scope">
            <span>{{
      scope.row.equipBizSeq == null || scope.row.equipBizSeq == undefined || scope.row.equipBizSeq == '' ? '--'
        : scope.row.equipBizSeq }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="报文时间" prop="timeStamp" width="140">
          <template slot-scope="scope">
            <span>{{ scope.row.timeStamp == undefined || scope.row.timeStamp == '' || scope.row.timeStamp ==
      null ? '--' : scope.row.timeStamp }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="接口" prop="api" width="200"></el-table-column>
        <el-table-column align="center" label="信息" prop="msg" width="140"></el-table-column>
        <el-table-column align="center" label="推送数据" prop="postData">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="scope.row.postData" placement="top">
              <span style="overflow:hidden;white-space:nowrap;text-overflow:ellipsis">{{ scope.row.postData }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column align="center" label="返回代码" prop="httpcode" width="80">
          <template slot-scope="scope">
            <span
              v-if="scope.row.httpcode == null || scope.row.httpcode == undefined || scope.row.httpcode == ''">--</span>
            <el-tooltip class="item" effect="dark" :content="scope.row.httpcode" placement="top" v-else>
              <span style="overflow:hidden;white-space:nowrap;text-overflow:ellipsis">{{ scope.row.httpcode }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column align="center" label="返回数据" prop="response">
          <template slot-scope="scope">
            <span
              v-if="scope.row.response == undefined || scope.row.response == '' || scope.row.response == null">--</span>
            <el-tooltip v-else class="item" effect="dark" :content="scope.row.response" placement="top">
              <span style="overflow:hidden;white-space:nowrap;text-overflow:ellipsis">{{
      scope.row.response }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.createTime == undefined || scope.row.createTime == '' || scope.row.createTime ==
      null ? '--' : scope.row.createTime }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态" prop="hlhtState" width="100">
          <template slot-scope="scope">
            <el-tag v-if="title == '操作日志'" :type="scope.row.hlhtState == 0 ? 'success' : 'danger'">{{
      scope.row.hlhtState == 0 ? "成功" : "失败" }}</el-tag>
            <el-tag v-else :type="scope.row.hlhtState == 0 ? 'danger' : 'success'">{{ scope.row.hlhtState == 0 ? "未发送" :
      "已发送" }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column v-if="title == '推送日志'" align="center" label="推送Post时间" prop="sendtime" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.sendtime == null || scope.row.sendtime == undefined || scope.row.sendtime == '' ? '--' :
      scope.row.sendtime }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination v-if="showpage" @size-change="handleSize1" @current-change="handleCurrent1"
        :current-page.sync="souForm.page" :page-sizes="[8, 20, 50, 100]" :page-size="souForm.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="dangqiantotal" background>
      </el-pagination>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="type = false">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 分页导航区域 -->
  </div>
</template>

<script>
export default {
  name: 'chargingWorld',
  data() {
    return {
      title2: '',
      showpage: true,
      titles: '添加',
      showpage1: true,
      souForm: {
        toOperatorId: '',
        equipBizSeq: '',
        connectorId: '',
        page: 1,
        limit: 8
      },
      dangqiantotal: 0,
      bianjidialoag: false,
      rules: {
        hlhtState: [
          {
            required: true, message: '请选择互联互通', trigger: 'blur'
          }
        ],
        hlhtType: [
          {
            required: true, message: '请选择状态', trigger: 'blur'
          }
        ],
        // 我的
        myOperatorId: [
          {
            required: true, message: '请输入我的平台ID', trigger: 'blur'
          }
        ],
        myName: [
          {
            required: true, message: '请输入我的平台名称', trigger: 'blur'
          }
        ],
        comeIv: [
          {
            required: true, message: '请输入我的Aes初始向量', trigger: 'blur'
          }
        ],
        comeKey: [
          {
            required: true, message: '请输入我的Aes秘钥', trigger: 'blur'
          }
        ],
        comeSecret: [
          {
            required: true, message: '请输入我的签名秘钥', trigger: 'blur'
          }
        ],
        comeOperatorSecret: [
          {
            required: true, message: '请输入我的运营商秘钥', trigger: 'blur'
          }
        ],
        comeUrl: [
          {
            required: true, message: '请输入我的访问地址', trigger: 'blur'
          }
        ],
        // 对接平台
        toOperatorId: [
          {
            required: true, message: '请输入对接平台ID', trigger: 'blur'
          }
        ],
        toname: [
          {
            required: true, message: '请输入对接平台名称', trigger: 'blur'
          }
        ],
        toKey: [
          {
            required: true, message: '请输入对接平台的Aes秘钥', trigger: 'blur'
          }
        ],
        toSecret: [
          {
            required: true, message: '请输入对接平台的签名秘钥', trigger: 'blur'
          }
        ],
        toIv: [
          {
            required: true, message: '请输入对接平台的Aes初始向量', trigger: 'blur'
          }
        ],
        toOperatorSecret: [
          {
            required: true, message: '请输入对接平台的运营商秘钥', trigger: 'blur'
          }
        ],
        toUrl: [
          {
            required: true, message: '请输入对接平台访问地址', trigger: 'blur'
          }
        ]
      },
      or: false,
      editForm: {
        id: null,
        hlhtState: '',
        hlhtType: '',
        toUrl: '',
        comeIv: '',
        comeKey: '',
        comeSecret: '',
        comeOperatorSecret: '',
        comeUrl: '',
        toIv: '',
        toKey: '',
        toOeratorSecret: '',
        toOperatorId: '',
        toSecret: '',
        toname: '',
        des: '',
        myName: '',
        myOperatorId: ''
      },
      hlhtlist1: [
        {
          id: '0',
          name: '无'
        },
        {
          id: '1',
          name: '互联'
        },
        {
          id: '2',
          name: '互通'
        },
        {
          id: '3',
          name: '互联互通'
        }
      ],
      hlhtlist: [
        {
          id: '0',
          name: '无'
        },
        {
          id: '1',
          name: '互联'
        },
        {
          id: '2',
          name: '互通'
        },
        {
          id: '3',
          name: '互联互通'
        }
      ],
      typelist: [
        {
          id: '0',
          name: '正常'
        },
        {
          id: '1',
          name: '禁用'
        }
      ],
      pushTypelist: [1, 2, 3, 4, 5],
      typelist1: [
        {
          id: '0',
          name: '正常'
        },
        {
          id: '1',
          name: '禁用'
        }
      ],
      morentotal: 0,
      yincang: true,
      page_num: 0,
      queryInfo: {
        myOperatorId: '',
        toOperatorId: '',
        toName: '',
        myName: '',
        page: 1,
        limit: 8,
        pushType: ''
      },
      ids: 0,
      title: '推送日志',
      type: false,
      // 充电订单数据
      dangqianlist: [],
      stateName: '',
      obj: {},
      // 数值写死了
      total: 0,
      value: '',
      suoshudianzhan: null,
      // 电站列表数据
      chargingList: []
    }
  },
  created() {
    this.getPowerStationList()
  },
  methods: {
    zhuangtai(e) {
      this.$http.post(`amap/charging/pushStationInfo/${e.onlineStatus == 1 ? 0 : 1}/${e.stationIds}/${window.sessionStorage.getItem('adminId')}`).then(res => {
        this.$message({
          type: 'success',
          message: `${e.onlineStatus == 0 ? '上线成功' : '下线成功'}`,
        });
        this.getPowerStationList()
      })
    },
    qx() {
      const that = this;
      that.bianjidialoag = false;
      that.editForm = {};
    },
    current() {
      this.queryInfo.page = 1;
    },
    // 互联互通更改
    changes(e) {
      this.queryInfo.page = 1;
      if (e !== null) {
        this.queryInfo.hlhtType = e;
        this.getPowerStationList()
      } else {
        if (this.queryInfo.hlhtState !== null) {
          this.queryInfo = {
            hlhtState,
            myOperatorId,
            toOperatorId,
            toName,
            myName,
            page: 1,
            limit: 10,
          }
        }
        this.getPowerStationList()
      }
    },
    //  状态更改
    change(e) {
      this.queryInfo.page = 1;
      if (e !== null) {
        this.queryInfo.hlhtState = e;
        this.getPowerStationList()
      } else {
        if (this.queryInfo.hlhtType !== null) {
          this.queryInfo = {
            hlhtType,
            myOperatorId,
            toOperatorId,
            toName,
            myName,
            page: 1,
            limit: 10,
          }
        }
        this.getPowerStationList()

      }
    },
    // 添加、编辑互联互通
    ok(e) {
      const that = this;
      that.hlhtlist1.forEach(item => {
        if (item.name == that.editForm.hlhtType) {
          that.editForm.hlhtType = item.id;
        }
      });
      that.typelist1.forEach(item => {
        if (item.name == that.editForm.hlhtState) {
          that.editForm.hlhtState = item.id;
        }
      });
      // // 添加
      if (that.ids == 0) {
        that.moren();
        that.editForm.adminId = window.sessionStorage.getItem('adminId') * 1;
        if (that.or == false) {
          that.editForm.hlhtState = Number(that.editForm.hlhtState);
          that.editForm.hlhtType = Number(that.editForm.hlhtType)
          that.$http.post('/hlht-station/editHlht', that.editForm).then(res => {
            if (res.data.code == 0) {
              that.$message.success('添加成功');
              that.getPowerStationList()
            } else {
              that.$message.error('添加失败')
            }
            that.bianjidialoag = false;
            that.editForm = {}
          })
        }
      } else {
        // 编辑
        if (e == 2) {
          that.moren();
          if (that.or == false) {
            that.editForm.hlhtState = Number(that.editForm.hlhtState);
            that.editForm.hlhtType = Number(that.editForm.hlhtType);
            that.editForm.adminId = window.sessionStorage.getItem('adminId') * 1;
            that.$http.post('/hlht-station/editHlht', that.editForm).then(res => {
              if (res.data.code == 0) {
                that.$message.success('编辑成功');
                that.getPowerStationList()
              } else {
                that.$message.error('编辑失败')
              }
              that.bianjidialoag = false;
              that.editForm = {};
            })
          }
        }
      }
    },
    moren() {
      const that = this;
      that.or = false;
      if (that.editForm == 0 ||
        that.editForm.toUrl == 0 ||
        that.editForm.comeIv == 0 ||
        that.editForm.comeKey == 0 ||
        that.editForm.comeSecret == 0 ||
        that.editForm.comeOperatorSecret == 0 ||
        that.editForm.comeUrl == 0 ||
        that.editForm.toIv == 0 ||
        that.editForm.toKey == 0 ||
        that.editForm.toOeratorSecret == 0 ||
        that.editForm.toOperatorId == 0 ||
        that.editForm.toname == 0 ||
        that.editForm.myName == 0 ||
        that.editForm.myOperatorId == 0) {
        that.$message.error('此为必传项');
        that.or = true;
      }
    },
    // 搜索（操作日志、推送日志）
    chaxun() {
      if (this.souForm.toOperatorId.length !==0|| this.souForm.equipBizSeq.length !== 0 || this.souForm.connectorId.length !== 0) {
        this.publicFunction()
      }
    },
    // 搜索重置（操作日志、推送日志）
    chongzhi1(e) {
      this.souForm.toOperatorId = this.obj.toOperatorId;
      this.souForm.myOperatorId = this.obj.myOperatorId;
      if (e == 3) {
        this.souForm = {
          equipBizSeq: '',
          connectorId: '',
          page: 1,
          limit: 10
        };
      } else {
        if (e == 1) {
          this.souForm.equipBizSeq = ''
        } else {
          this.souForm.connectorId = ''
        }
      }
      this.publicFunction()
    },
    // 互联互通重置
    qingkong() {
      this.queryInfo.hlhtType = null;
      this.queryInfo.hlhtState = null;
      this.queryInfo = {
        myOperatorId: '',
        toOperatorId: '',
        toName: '',
        myName: '',
        page: 1,
        limit: 8,
        pushType: ''
      };
      this.getPowerStationList()
    },
    // 添加、编辑互联互通
    bianjiMethods(e) {
      this.ids = e;
      if (e == 0) {
        this.titles = '添加';
        this.editForm = {};
        this.bianjidialoag = true;
      } else {
        e.b_j_aniu = true;
        this.titles = '编辑';
        this.editForm = e;
        this.hlhtlist1.forEach(item => {
          if (item.id == this.editForm.hlhtType) {
            this.editForm.hlhtType = item.name.toString();
          }
        });
        this.typelist1.forEach(item => {
          if (item.id == this.editForm.hlhtState) {
            this.editForm.hlhtState = item.name.toString();
          }
        });
        this.bianjidialoag = true;
      }
    },
    // 公共方法
    publicFunction() {
      const that = this;
      that.dangqianlist = [];
      that.dangqiantotal = 0;
      that.$http.get(that.title == '推送日志' ? '/hlht-send/getAllHlhtSend' : '/hlht-log/getAllHlhtLog', { params: that.souForm }).then(res => {
        that.dangqiantotal = res.data.count;
        that.dangqianlist = res.data.code == 0 ? res.data.data : [];
      })
    },
    // 推送日志
    tuisongrizhi(row) {
      this.title = '推送日志';
      row.t_s_r_z_aniu = true;
      this.obj.toOperatorId = row.toOperatorId;
      this.obj.myOperatorId = row.myOperatorId;
      this.souForm.page = 1;
      this.souForm.limit = 10;
      this.publicFunction();
      this.type = !this.type;
    },
    // 操作日志
    async caozuorizhi(row) {
      row.c_z_anniu = true;
      this.title = '操作日志';
      this.obj.toOperatorId = row.toOperatorId;
      this.obj.myOperatorId = row.myOperatorId;
      this.souForm.page = 1;
      this.souForm.limit = 10;
      this.publicFunction();
      this.type = !this.type;
    },
    // 监听互联互通条数改变的事件
    handleSizeChange(pageSize) {
      this.queryInfo.limit = pageSize;
      this.queryInfo.page = 1;
      this.getPowerStationList()
    },
    // 监听互联互通页码改变事件
    handleCurrentChange(newPage) {
      this.showpage1 = false;
      this.$nextTick(() => {
        this.showpage1 = true;
      })
      this.queryInfo.page = newPage;
      this.getPowerStationList()
    },
    // 获取电站列表数据
    getPowerStationList() {
      const that = this;
      that.$http.get('hlht-station/getAllHlht', { params: that.queryInfo }).then(res => {
        that.total = res.data.code !== 0 ? 0 : res.data.count;
        if (res.data.code !== 0) return false;
        that.chargingList = res.data.data;
        that.chargingList.forEach(item => {
          item.b_j_aniu = false; item.c_z_anniu = false; item.t_s_r_z_aniu = false;
        });
        that.chargingList.forEach(item => {
          if (item.hlhtState == 0) {
            item.hlhtState = '正常'
          } else {
            item.hlhtState = '禁用'
          }
          if (item.hlhtType == 0) {
            item.hlhtType = '无'
          }
          if (item.hlhtType == 1) {
            item.hlhtType = '互联'
          }
          if (item.hlhtType == 2) {
            item.hlhtType = '互通'
          }
          if (item.hlhtType == 3) {
            item.hlhtType = '互联互通'
          }
        })
      })
    },
    // 监听日志页码值改变的事件
    handleSize1(pageSize) {
      this.souForm.limit = pageSize;
      this.publicFunction()
    },
    // 监听日志页码值改变的事件
    handleCurrent1(newPage) {
      this.showpage = false;
      this.$nextTick(() => {
        this.showpage = true;
      })
      this.souForm.page = newPage;
      this.publicFunction()
    }
  }
}
</script>

<style lang="less">
.el-tooltip__popper {
  width: 100%;
  padding: 8px 10px;
  height: auto;
  display: flex;
  justify-content: center;
}

.el-form-item__label-wrap {
  margin-left: 0px !important;
}
</style>