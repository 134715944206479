<template>
  <div class="apps">
    <!-- <el-row :gutter="24">
      <el-col>
        <el-button type="primary" @click="addMethods">添加</el-button>
      </el-col>
    </el-row>  style="margin-top: 10px;"-->
    <el-card>
      <el-table style="width: 100%" :data="tableData" empty-text="暂无数据">
        <el-table-column align="center" label="第三方名称" prop="name"></el-table-column>
        <el-table-column align="center" label="第三方logo" prop="name" width="160">
          <template slot-scope="scope">
            <img style="width: 120px;
           height: 80px;" :src="scope.row.imageUrl" alt="图片加载错误">
          </template>
        </el-table-column>
        <el-table-column align="center" label="关联充电站" prop="stationName"></el-table-column>
        <el-table-column align="center" label="所属运营商" prop="adminName"></el-table-column>
        <el-table-column align="center" label="状态" prop="status">
          <template slot-scope="scope">
            <span :style="{
        color: scope.row.status == 1 ? 'green' : '#000'
      }" style="font-weight:bolder;font-size: 17px;">{{ scope.row.status == 0 ? '已到期' : '进行中' }}</span>
            <!-- <el-switch v-model="scope.row.status" :active-color="scope.row.status == 0 ? '#ff4949' : '#13ce66'"
              :inactive-color="scope.row.status == 1 ? '#13ce66' : '#ff4949'" @change="changeMethods(scope.row)">
            </el-switch> -->
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="danger" @click="delMethods(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[6, 20, 50, 100]" :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="count" background>
      </el-pagination>
    </el-card>
    <!-- <el-dialog :visible.sync="dialoagType" :title="titles" @close="qx_vipdialoag" append-to-body
      :close-on-click-modal="false">
      <div>
        <el-form :model="formobj" ref="editFormRef" label-width="160px">
          <el-row style="display: flex;flex-direction: row;" :gutter="24">
            <el-col :span="12">
              <el-form-item label="电站名称：" prop="stationIds" placeholder="请选择你的开通场站" filterable>
                <el-select v-model="formobj.stationIds" v-for="item in Stationlist" :key="item.id"
                  :label="item.stationName" :value="item.id">
                  <el-option></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="互联互通类别：" prop="stationIds" placeholder="请选择你的开通场站" filterable>
                <el-select v-model="formobj.stationIds" v-for="item in hlhtlist" :key="item.id"
                  :label="item.stationName" :value="item.id">
                  <el-option></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="允许访问的组织代码：" prop="stationIds" placeholder="请选择你的开通场站" filterable>
                <el-select v-model="formobj.stationIds" v-for="item in ownlist" :key="item.id"
                  :label="item.stationName" :value="item.id">
                  <el-option></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="乡镇代码：" prop="stationIds" placeholder="请选择你的开通场站" filterable>
                <el-select v-model="formobj.stationIds" v-for="item in hotTowlist" :key="item.id"
                  :label="item.stationName" :value="item.id">
                  <el-option></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="关联第三方平台：" prop="stationIds" placeholder="请选择你的开通场站" filterable>
                <el-select v-model="formobj.stationIds" v-for="item in otherlist" :key="item.id"
                  :label="item.stationName" :value="item.id">
                  <el-option></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="推送类别：" prop="stationIds" placeholder="请选择你的开通场站" filterable>
                <el-select v-model="formobj.stationIds" v-for="item in speaklist" :key="item.id"
                  :label="item.stationName" :value="item.id">
                  <el-option></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="行政区码：" prop="stationIds" placeholder="请选择你的开通场站" filterable>
                <el-select v-model="formobj.stationIds" v-for="item in addresslist" :key="item.id"
                  :label="item.stationName" :value="item.id">
                  <el-option></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="组织机构代码：" prop="stationIds" placeholder="请选择你的开通场站" filterable>
                <el-select v-model="formobj.stationIds" v-for="item in companylist" :key="item.id"
                  :label="item.stationName" :value="item.id">
                  <el-option></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="qx_vipdialoag">取 消</el-button>
        <el-button type="primary" @click="qx_vipdialoag">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>
<script>
export default {
  name: '',
  mixins: [],
  components: {

  },
  props: {

  },
  data() {
    return {
      companylist: [],
      addresslist: [],
      hotTowlist: [],
      speaklist: [],
      otherlist: [],
      ownlist: [],
      hlhtlist: [],
      editFormRef: {

      },
      Stationlist: [],
      formobj: {
        stationIds: '',
        ownPublic: '',
        hlhtInternet: '',
        speak: '',
        friend: '',
        adress: '',
        hotTown: '',
        adressFrined: ''
      },
      tupian: this.$imgUrl,
      dialoagType: false,
      titles: '添加引流场站',
      tableData: [],
      count: 0,
      hlhtlist: [],
      queryInfo: {
        stationIds: '',
        page: 1,
        limit: 6,
        adminId: window.sessionStorage.getItem('adminId'),
        id: ''
      }
    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {
    this.defaultMethods()
  },
  methods: {
    // 更改方法（状态）
    // changeMethods(e) {
    //   const that = thsi;
    //   that.$http.post('', {
    //     data: {
    //       id: e.id,
    //       status: e.status == 1 ? 0 : 1
    //     }
    //   }).then(res => {
    //     if (res.data.code !== 0) return;
    //     this.$message.success('引流电站状态修改成功！');
    //     this.defaultMethods()
    //   })
    // },
    defaultMethods() {
      const that = this;
      that.$http.get('drainage-station-info/getAllDrainageStationInfo', { params: that.queryInfo }).then(res => {
        if (res.data.code !== 0) return;
        that.tableData = res.data.data;
        that.tableData.forEach(item => {
          item.imageUrl = item.name == '高德地图' ? require('../../assets/images/img/Nice.jpg') : item.name == '百度地图' ? require('../../assets/images/img/baidu.jpg') : item.name == '腾讯地图' ? require('../../assets/images/img/qq.jpeg') : item.name == '新电途' ? require('../../assets/images/img/newEleric.png') : item.name == '中原智充' ? require('../../assets/images/img/look.png') : item.name == '小桔充电' ? require('../../assets/images/img/orange.png') : item.name == '快电特充' ? require('../../assets/images/img/quickly.png') : ''
        })
        that.count = res.data.count;
      })
    },
    qx_vipdialoag() {

    },
    delMethods(e) {
      const that = this;
      that.$alert('确定删除么', '温馨提示', {
        confirmButtonText: '确定',
        callback: action => {
          that.$http.post('drainage-station-info/editDrainageStationInfo', {
            id: e.id,
            adminId: window.sessionStorage.getItem('adminId')
          }).then(res => {
            that.$message({
              type: res.data.code == 0 ? 'success' : 'error',
              message: `action: ${action}`
            });
          })
        }
      });
    },
    // addMethods() {
    //   this.dialoagType = true;
    // },
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.queryInfo.page=1;
      this.defaultMethods()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.defaultMethods()
    }
  }
};
</script>

<style lang='less' scoped>
.apps {
  width: 100%;
  height: 100%;
}
</style>