<template>
  <div class="EquipmentAnalysis">
    <div class="top" style="padding-top: 10px;">
      <li>
        <el-select v-model="sousuodianzhan" placeholder="请选择电站" @change="change_vale" filterable>
          <el-option v-for="item in stationlist" :key="item.id" :label="item.stationName" :value="item.id">
          </el-option>
        </el-select>
      </li>
      <li>
        <el-select v-model="times" placeholder="请选择时间类型" filterable @change="changeType">
          <el-option v-for="item in timeList" :key="item" :label="item==0?'近七天':item==1?'近30天':'近12个月'" :value="item">
          </el-option>
        </el-select>
      </li>
      <li>
        <el-date-picker v-model="picker_value2" clearable :picker-options="pickerOptions" type="daterange" align="right"
          range-separator="--" value-format="yyyy-MM-dd" start-placeholder="开始日期" @blur="pickBlur"
          end-placeholder="结束日期" @change="picker_event">
        </el-date-picker>
      </li>
    </div>
    <div class="center2">
      <div class="centers1">设备分析</div>
      <div class="centers2">
        <li><span>{{ typs2(shebeiobjs.onLineFreePileCount, shebeiobjs.totalPileCount) }}</span><span>可用率（%）</span></li>
        <li><span>{{ typs2(shebeiobjs.onLinePileCount, shebeiobjs.totalPileCount) }}</span><span>利用率（%）</span></li>
        <li><span>{{ typs2(shebeiobjs.offLinePileCount, shebeiobjs.totalPileCount) }}</span><span>离线率（%）</span></li>
        <li><span>{{ typs2(shebeiobjs.faultPileCount, shebeiobjs.totalPileCount) }}</span><span>故障率（%）</span></li>
      </div>
    </div>
    <div class="center" v-if="!empty">
      <div class="center1">
        <div class="centers1">充电分析</div>
        <div class="centers2">
          <li v-for="(item, index) in cdobj" :key="index"
            :style="{ 'border-bottom': currentIndex == index ? '1px solid blue' : 'none' }"
            @click="currentMethods(index)">
            <span>{{ index == 0 ? jisuan(item.money, 100, 4) : index == 1 ? typs(item.money) : jisuan(item.money, 100,
          2)
              }}</span><span>{{ item.name }}</span>
          </li>
        </div>
        <div class="centers3">
          <div id="main1"></div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <h4>
        <span>电站数据</span>
        <span>
          订单统计范围即结算时间在所选时段内的订单，设备统计范围即所有未删除并目通过审核的设备
        </span>
      </h4>
      <div class="hezi">
        <el-table class="table" :data="tableData" border :lazy="true" empty-text="暂无数据">
          <el-table-column align="center" label="电站信息" prop="id">
            <template slot-scope="scope">
              <p>{{ scope.row.stationName }}</p>
              <p>ID:{{ scope.row.stationId }}</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="总充电量（度）" prop="totalKwh">
            <template slot-scope="scope">
              <p>{{ jisuan(scope.row.totalKwh, 100, 4) }}</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="总充电次数（次）" prop="totalChargeCount"></el-table-column>
          <el-table-column align="center" label="总充电金额（元）" prop="billMoney">
            <template slot-scope="scope">
              <p>{{ jisuan(scope.row.billMoney, 100, 2) }}</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="总充电服务费（元）" prop="serviceMoney">
            <template slot-scope="scope">
              <p>{{ jisuan(scope.row.serviceMoney, 100, 2) }}</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="枪日均电量（度）" prop="id">
            <template slot-scope="scope">
              <p>{{ jisuan(scope.row.gunNumberCount, 100, 4) }}</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="可用率（%）" prop="">
            <template slot-scope="scope">
              <p>{{ typs2(scope.row.onLineFreePileCount, scope.row.totalPileCount) }}</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="利用率（%）" prop="">
            <template slot-scope="scope">
              <p>{{ typs2(scope.row.onLinePileCount, scope.row.totalPileCount) }}</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="离线率（%）" prop="">
            <template slot-scope="scope">
              <p>{{ typs2(scope.row.offLinePileCount, scope.row.totalPileCount) }}</p>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination style="padding: 20px 0;" @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[2, 4, 6, 10]"
        :current-page="tableInfo.page" :page-size="tableInfo.limit" layout="total, sizes, prev, pager, next, jumper"
        :total="total" background>
      </el-pagination>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
  name: 'EquipmentAnalysis',
  mixins: [],
  components: {

  },
  props: {

  },
  data() {
    return {
      total: 0,
      empty: false,
      tableInfo: {
        page: 1,
        limit: 2,
        roleId: window.sessionStorage.getItem('roleId'),
        adminId: window.sessionStorage.getItem('adminId'),
        stationIds: window.sessionStorage.getItem('stationIds'),
        startTime: '',
        endTime: '',
        type: 0,
        days: 7
      },
      title: [],
      tableData: [],
      currentIndex: 0,
      leftY: [],
      leftX: [],
      type: true,
      stationlist: [],
      shebeiobjs: {},
      cdobj: [],
      tslist: [],
      picker_value2: ['', ''],
      sousuodianzhan: '',
      times: 0,
      timeList: [0,1,2],
      queryInfo: {
        roleId: window.sessionStorage.getItem('roleId'),
        stationIds: window.sessionStorage.getItem('roleId') == 1 ? '0' : window.sessionStorage.getItem('stationIds'),
        startTime: '',
        endTime: '',
        type: 0,
        days: 7
      },
      pickerMinDate: '',
      pickerOptions: {
        onPick: obj => {
          this.pickerMinDate = new Date(obj.minDate).getTime()
          const start = this.formatDate(obj.minDate, 'start')
          const end = this.formatDate(obj.maxDate, 'end')
          obj.maxDate && (this.values = [start, end])
        },
        disabledDate: time => {
          if (this.pickerMinDate) {
            const oneMonth = 1000 * 3600 * 24 * 31
            const maxTime = this.pickerMinDate + oneMonth
            const mixTime = this.pickerMinDate - oneMonth + 1000 * 3600 * 24
            const tomorrow = new Date(this.formatDate(new Date().getTime() + 1000 * 3600 * 24, 'start'))
            return time.getTime() >= tomorrow || time.getTime() >= maxTime || time.getTime() < mixTime
          }
        }
      }
    }
  },
  computed: {
    jisuan() {
      return (obj, num, fixed) => {
        if (obj == null || obj == undefined) return 0;
        return (obj / num).toFixed(fixed)
      }
    },
    sums() {
      return (obj, num) => {
        return obj - num
      }
    },
    typs() {
      return (obj) => {
        return obj == undefined || obj == null ? 0 : obj
      }
    },
    typs2() {
      return (obj, num) => {
        if (obj == undefined || obj == null||obj=='') {
          return 0
        } else {
          return ((obj / num) * 100).toFixed(2)
        }
      }
    }
  },
  watch: {

  },
  created() {
    window.addEventListener('keydown', this.keyDown);
    this.stationlist.push({
      id: window.sessionStorage.getItem('stationIds'),
      stationName: '全部电站'
    });
    this.$http.get('station-info/getAllStationInfoName', {
      params: {
        roleId: window.sessionStorage.getItem('roleId'),
        stationIds: window.sessionStorage.getItem('stationIds'),
        stationMode: '0,2'
      }
    }).then(res => {
      res.data.data.forEach(item => {
        this.stationlist.push({
          id: item.id,
          stationName: item.stationName
        })
      })
    });
    this.defaultMethods();
  },
  methods: {
    destroyed() {
      window.removeEventListener('keydown', this.keyDown, false)
    },
    // 监听回车键执行事件
    keyDown(e) {
      // // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13 || e.keyCode === 108) {
        this.defaultMethods() // 需要执行的方法方法
      }
    },
    handleSizeChange(newSize) {
      this.tableInfo.limit = newSize;
      this.tableInfo.page=1;
      this.shuzu()
    },
    handleCurrentChange(newPage) {
      this.tableInfo.page = newPage;
      this.shuzu()
    },
    // 表格数据接口
    chaxun() {

    },
    initMethods() {
      var chartDom = document.getElementById('main1');
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        xAxis: {
          type: 'category',
          data: this.leftX,
          axisTick: {
            show: false
          },
        },
        yAxis: {
          type: 'value',
          max: Math.max(...this.leftY) < 100 ? Math.max(...this.leftY) * 1 + 10 * 1 : Math.max(...this.leftY) < 1000 ? Math.max(...this.leftY) * 1 + 100 * 1 : Math.max(...this.leftY) < 10000 ? Math.max(...this.leftY) * 1 + 1000 * 1 : 100000 * 1 + 1 * Math.max(...this.leftY),
          min: 0,
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        legend: {
          data: [this.currentIndex == 0 ? '总充电电量（度）' : this.currentIndex == 1 ? '总充电次数（次）' : this.currentIndex == 2 ? '总充电金额（元）' : '总充电服务费（元）'],
          icon: 'react',
          // color: this.currentIndex == 0 ? 'blue' : this.currentIndex == 1 ? 'orange' : this.currentIndex == 2 ? 'green' : 'red' // 将 'red' 替换为您想要的颜色
        },
        grid: {
          left: '0',
          right: '0',
          bottom: '2%',
          containLabel: true
        },
        series: [
          {
            data: this.leftY,
            stack: 'Total',
            name: this.currentIndex == 0 ? '总充电电量（度）' : this.currentIndex == 1 ? '总充电次数（次）' : this.currentIndex == 2 ? '总充电金额（元）' : '总充电服务费（元）',
            type: 'line',
            label: {
              show: true, // 开启显示
              position: 'top', // 标签的位置，可以是'top', 'bottom', 'left', 'right'等
            },
            itemStyle: {
              normal: {
                color: this.currentIndex == 0 ? 'blue' : this.currentIndex == 1 ? 'orange' : this.currentIndex == 2 ? 'green' : 'red'
              },
              lineStyle: {
                color: this.currentIndex == 0 ? 'blue' : this.currentIndex == 1 ? 'orange' : this.currentIndex == 2 ? 'green' : 'red' // 将 'red' 替换为您想要的颜色
              },
            }
          }
        ]
      };
      option && myChart.setOption(option);
    },
    qingkong() {
      var chartDom = document.getElementById('main1');
      var myChart = echarts.init(chartDom);
      myChart.clear();
    },
    currentMethods(e) {
      this.currentIndex = e;
      this.leftY = [];
      this.tslist.forEach(item => {
        if (e == 0) {
          this.leftY.push(item.allElectricityLevel == null || item.allElectricityLevel == undefined || item.allElectricityLevel == '' || item.allElectricityLevel == 0 ? 0 : (item.allElectricityLevel / 100).toFixed(2));
        } else if (e == 1) {
          this.leftY.push(item.allChargeCount == null || item.allChargeCount == undefined || item.allChargeCount == '' || item.allChargeCount == 0 ? 0 : item.allChargeCount)
        } else if (e == 2) {
          this.leftY.push(item.allMoney == null || item.allMoney == undefined || item.allMoney == '' || item.allMoney == 0 ? 0 : (item.allMoney / 100).toFixed(2))
        } else {
          this.leftY.push(item.allServiceMoney == null || item.allServiceMoney == undefined || item.allServiceMoney == '' || item.allServiceMoney == 0 ? 0 : (item.allServiceMoney / 100).toFixed(2))
        }
      })
      this.initMethods()
    },
    changeType() {
      this.type = true;
      this.picker_value2 = ['', ''];
      this.queryInfo.startTime = '';
      this.queryInfo.endTime = '';
      this.queryInfo.type = this.times<2 ? 0 : 2;
      this.queryInfo.days = this.times == 0 ? 7 : this.times == 1 ? 30 : 12;
      this.tableInfo.startTime = '';
      this.tableInfo.endTime = '';
      this.tableInfo.type = this.times<2 ? 0 : 2;
      this.tableInfo.days = this.times == 0 ? 7 : this.times == 1 ? 30 : 12;
      this.defaultMethods()
    },
    defaultMethods() {
      this.shebeiobjs = {};
      this.cdobj = [];
      //  设备分析
      this.shebei();
      this.chongdian();
      // 接口数据
      this.shuzu()
    },
    shuzu() {
      const that = this;
      that.tableData = [];
      that.$http.get('device-analysis/getAllStationAnalysis', {
        params: that.tableInfo
      }).then(res => {
        if (res.data.code !== 0) return;
        that.total = res.data.count;
        that.tableData = res.data.data;
      })
    },
    shebei() {
      const that = this;
      that.$http.get('device-analysis/getAllDeviceAnalysis', {
        params: that.queryInfo
      }).then(res => {
        if (res.data.code !== 0) return;
        that.shebeiobjs = res.data.data;
      })
    },
    chongdian() {
      const that = this;
      that.leftX = [];
      that.leftY = [];
      that.cdobj = [];
      that.$http.get('device-analysis/getAllChargeAnalysis', {
        params: that.queryInfo
      }).then(res => {
        if (res.data.code !== 0) {
          that.empty = true;
          that.qingkong();
        } else {
          that.empty = false;
          that.tslist = res.data.data;
          that.cdobj = [{
            name: '总充电电量（度）',
            money: that.tslist[0].totalElectricityLevel
          }, {
            name: '总充电次数（次）',
            money: that.tslist[0].totalChargeCount
          }, {
            name: '总充电金额（元）',
            money: that.tslist[0].totalMoney
          }, {
            name: '总充电服务费（元）',
            money: that.tslist[0].totalServiceMoney
          }];
          that.tslist.forEach(item => {
            that.leftX.push(item.bottomTime);
          });
          that.currentMethods(0)
        }
      })
    },
    formatDate(datetime, type) {
      const time = new Date(datetime)
      const year = time.getFullYear()
      const month = (time.getMonth() + 1).toString().padStart(2, '0')
      const date = (time.getDate()).toString().padStart(2, '0')
      return type === 'start' ? year + '-' + month + '-' + date + ' ' + '00' + ':' + '00' + ':' + '00' : year + '-' + month + '-' + date + ' ' + '23' + ':' + '59' + ':' + '59'
    },
    pickBlur() {
      this.pickerMinDate = ''
    },
    // 切换时间
    picker_event(e) {
      if (e !== null) {
        this.queryInfo.startTime = e[0];
        this.queryInfo.endTime = e[1];
        this.tableInfo.startTime = e[0];
        this.tableInfo.endTime = e[1];
        this.type = false;
        this.queryInfo.type = 3;
        this.queryInfo.days = 0;
        this.tableInfo.type = 3;
        this.tableInfo.days = 0;
        this.defaultMethods()
      } else {
        this.queryInfo.startTime = null;
        this.queryInfo.endTime = null;
        this.tableInfo.startTime = null;
        this.tableInfo.endTime = null;
        this.type = true;
        this.queryInfo.type = 0;
        this.queryInfo.days = 7;
        this.tableInfo.type = 3;
        this.tableInfo.days = 0;
        this.times = 0;
        this.defaultMethods()
      }
      // 折柱混合图
    },
    // 更换场站
    change_vale() {
      this.queryInfo.stationIds = this.sousuodianzhan;
      this.tableInfo.stationIds = this.sousuodianzhan;
      this.queryInfo.type = 0;
      this.queryInfo.days = 7;
      this.tableInfo.type = 0;
      this.tableInfo.days = 7;
      this.times = 0;
      this.picker_value2 = ['', ''];
      this.queryInfo.startTime = '';
      this.queryInfo.endTime = '';
      this.tableInfo.startTime = '';
      this.tableInfo.endTime = '';
      this.type = true;
      this.defaultMethods()
    }
  }
};
</script>

<style lang='less' scoped>
.EquipmentAnalysis {
  width: 99%;
  height: 99%;
  background: #ccc;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .top {
    height: 40px;
    display: flex;
    flex-direction: row;

    li:nth-child(2) {
      margin: 0 20px;
    }
  }

  .center2 {
    margin-top: 10px;
    height: 10%;
    display: flex;
    flex-direction: column;
    background-color: #fff;

    .centers1 {
      flex: 1;
      font-size: 18px;
      padding: 10px 0 10px 10px;
      font-weight: bolder;
    }

    .centers2 {
      flex: 9;
      font-size: 15px;
      display: flex;
      flex-direction: row;

      li {
        flex: 1;
        display: flex;
        flex-direction: column;

        span {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        span:nth-child(1) {
          font-size: 16px;
          font-weight: bolder;
        }
      }
    }
  }

  .center {
    height: 40%;
    margin: 10px 0;
    display: flex;
    flex-direction: row;

    .center1 {
      flex: 1;
      padding: 10px;
      display: flex;
      flex-direction: column;
      background-color: #fff;

      .centers1 {
        flex: 1;
        font-size: 18px;
        font-weight: bolder;
      }

      .centers2 {
        flex: 2;
        display: flex;
        flex-direction: row;
        font-size: 15px;

        li {
          flex: 1;
          display: flex;
          flex-direction: column;

          span {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          span:nth-child(1) {
            font-size: 16px;
            font-weight: bolder;
          }
        }

        li>span:nth-child(2) {
          font-size: 13px;
        }
      }

      .centers3 {
        flex: 7;
        display: flex;
        justify-content: center;

        #main1 {
          width: 90%;
          height: 100%;
        }
      }
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
padding:0 10px;
    background: #fff;
    overflow: hidden;

    h4 {
      font-size: 18px;

      span:nth-child(2) {
        font-size: 14px;
        color: #c5c5c5
      }
    }

    .hezi {
      overflow-y: scroll;
    }
  }
}
</style>