<template>
  <div>
    <!-- 卡片视图区域 菜单管理-->
    <el-card>
      <el-row :gutter="20" v-if="tianjiacaidan" style="padding-bottom: 20px;">
        <!-- <el-col :span="2">
            <el-button @click="removePowerById">批量删除</el-button>
          </el-col> -->
        <el-col :span="2">
          <el-button @click="addRole" type="success" icon="el-icon-plus">添加一级菜单</el-button>
        </el-col>
      </el-row>
      <el-table :data="rightsList" border stripe row-key="id" :tree-props="{ children: 'childList' }"
        @selection-change="handleSelectionChange" @row-dblclick="dbclick" empty-text="暂无数据">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="ID" prop="id" align="center" width="200"></el-table-column>
        <el-table-column label="菜单名称" prop="menuname" align="center"></el-table-column>
        <el-table-column label="菜单路径" prop="url" align="center"></el-table-column>
        <el-table-column label="菜单等级" prop="parentId" align="center" width="140">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.lever == 1">一级目录</el-tag>
            <el-tag type="warning" v-if="scope.row.lever == 2">二级目录</el-tag>
            <el-tag type="success" v-if="scope.row.lever == 3">三级目录</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="是否导航" prop="isNavi" align="center" v-if="bianji == true" width="90">
          <template slot-scope="scope" v-if="scope.row.lever != 3">
            <el-switch :active-color="scope.row.isNavi == false ? '#ff4949' : '#13ce66'"
              :inactive-color="scope.row.isNavi == false ? '#ff4949' : '#13ce66'" v-model="scope.row.isNavi"
              @change="changeIsNavi(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="菜单排序" prop="sort" align="center" width="100">
          <template slot-scope="scope">
            <el-input v-model="scope.row.sort" @change="inpts(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" v-if="tianjiacaidan == true || bianji == true || shanchu == true"
          width="360">
          <template slot-scope="scope">
            <el-button size="mini" type="success" icon="el-icon-plus" @click="addRole(scope.row)"
              v-if="scope.row.lever != 3 && tianjiacaidan == true">添加菜单</el-button>
            <el-button size="mini" type="primary" icon="el-icon-edit" v-if="bianji == true"
              :disabled="scope.row.b_j_anniu" @click="showEditRole(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" icon="el-icon-delete" @click="removePowerById(scope.row)"
              v-if="scope.row.lever != 3 && shanchu == true">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 添加菜单&&编辑菜单对话框 -->
    <el-dialog :title="title" :visible.sync="editDialogVisible" width="35%" @close="editDialogClosed" append-to-body
      :close-on-click-modal="false">
      <!-- 内容主体区域 -->
      <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="80px">
        <el-form-item label="菜单名称" prop="menuname">
          <el-input placeholder="请输入你的菜单名称" v-model="editForm.menuname" style="width: 100%;">
          </el-input>
        </el-form-item>
        <el-form-item label="菜单级别" prop="lever" required>
          <el-radio-group v-model="editForm.lever" style="width: 100%;">
            <el-radio v-for="item in leaavel" :key="item.lever" :label="item.lever" :disabled="item.disabd">{{
        item.lever }}</el-radio>
          </el-radio-group>
          <!-- <el-select v-model="editForm.lever" style="width: 100%;">
              <el-option v-for="item in leaavel" :key="item.lever" :value="item.lever">{{ item.lever }}</el-option>
            </el-select> -->
        </el-form-item>
        <el-form-item label="原地址" prop="url" required style="position: relative;" v-if="sun || title == '编辑菜单'">
          <el-input :placeholder="placeholder_title" v-model="editForm.url" style="width: 100%;"
            :disabled="title == '编辑菜单'"></el-input>
        </el-form-item>
        <el-form-item label="目标地址" prop="parentId" style="position: relative;"
          v-if="title == '编辑菜单' && editForm.lever != 3">
          <el-input :placeholder="placeholder_title" v-model="editForm.parentId" style="width: 100%;">
            <i v-if="syn" slot="suffix" :class="iconclass" @click="icon_change2"></i>
          </el-input>
          <div class="change" v-if="select_flag2" style="position: absolute;top: 100%;
          left: 0;width: 100%;
          height: 300px;z-index: 3;background: #fff;display: flex;flex-direction: column;">
            <!-- @node-click="handleNodeClick" -->
            <el-tree :node-key="id" ref="tree" :check-on-click-node="true" :data="rightsList" :props="defaultProps"
              @node-click="handleNodeClick"></el-tree>
          </div>
        </el-form-item>
        <el-form-item required label="菜单图标" prop="icon" v-if="sun">
          <el-input placeholder="请输入权限图标类名" v-model="editForm.icon" @keyup.enter.native="select_icon"
            style="width: 84%;" :disabled="editForm.icon == '--'"></el-input>
          <button @click="del_icon" style="width: 14%;
          margin-left: 2%;
          height: 40px;outline: none;background-color: #fff;border: 1px solid gray;">{{ btn_title }}</button>
          <el-row>
            <div class="iconlist" style="
            padding-left: 10px;
            padding-top: 10px;
            padding-bottom: 10px; list-style: none; width: 98%;height: 140px;position: absolute;top: 1px;
            z-index: 3;
            cursor: pointer;
            background-color: #fff;border: 1px solid gray;" v-if="flaging">
              <li v-for="(item, index) in iconlist" :key="index" style="display: inline-block;">
                <i :class="item.icon" style="margin-right: 14px;width: 20px;
                height: 20px;" @click="icons(item.icon)"></i>
              </li>
            </div>
          </el-row>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="菜单排序" prop="sort">
              <el-input placeholder="请输入菜单排序" v-model="editForm.sort"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10" :offset="2" required v-if="editForm.parentId == 0 || sun == true || title == '编辑菜单'">
            <el-form-item label="是否导航" prop="isNavi" @change="daohang">
              <el-switch v-model="editForm.isNavi" active-text="是" inactive-text="否" style="margin-left: 10px;"
                :disabled="editForm.lever == 3"></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogClosed">取 消</el-button>
        <el-button type="primary" v-debounce="editPower">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import caozuo from "@/utils/pubilc.js";
export default {
  name: 'rightWorld',
  data() {
    return {
      caozuoquanxian: false,
      placeholder_title: '',
      syn: false,
      leaavel: [],
      select_flag1: false,
      select_flag2: false,
      iconclass: 'el-icon-arrow-down',
      btn_title: '添加图标',
      flaging: false,
      newid: 0,
      queryInfo: {
        roleId: window.sessionStorage.getItem('roleId'),
        limit: 100,
        page: 1,
        sort: null,
      },
      total: 0,
      title: '',
      // 菜单列表
      rightsList: [],
      // 一级目录列表
      firstRightsList: [],
      // 添加&&编辑菜单对话框显示
      editDialogVisible: false,
      editForm: {
        des: '',
        icon: '',
        isNavi: false,
        menuname: '',
        parentId: null,
        id: null,
        sort: null,
        url: ''
      },
      defaultProps: {
        children: 'childList',
        label: 'menuname'
      },
      iconlist: [
        { icon: 'el-icon-s-custom' },
        { icon: 'el-icon-s-platform' },
        { icon: 'el-icon-user' },
        { icon: 'el-icon-school' },
        { icon: 'el-icon-coordinate' },
        { icon: 'el-icon-monitor' },
        { icon: 'el-icon-document-checked' },
        { icon: 'el-icon-suitcase' },
        { icon: 'el-icon-attract' },
        { icon: 'el-icon-video-camera-solid' },
        { icon: 'el-icon-odometer' },
        { icon: 'el-icon-turn-off' },
        { icon: 'el-icon-help' },
        { icon: 'el-icon-finished' },
        { icon: 'el-icon-loading' },
        { icon: 'el-icon-bank-card' },
        { icon: 'el-icon-magic-stick' },
        { icon: 'el-icon-sunrise-1' },
        { icon: 'el-icon-moon' },
        { icon: 'el-icon-bangzhu' },
        { icon: 'el-icon-wallet' },
        { icon: 'el-icon-link' },
        { icon: 'el-icon-cpu' },
        { icon: 'el-icon-platform-eleme' },
        { icon: 'el-icon-eleme' },
        { icon: 'el-icon-delete-solid' },
        { icon: 'el-icon-delete' },
        { icon: 'el-icon-s-tools' },
        { icon: 'el-icon-setting' },
        { icon: 'el-icon-user-solid' },
        { icon: 'el-icon-user' },
        { icon: 'el-icon-phone' },
        { icon: 'el-icon-phone-outline' },
        { icon: 'el-icon-more' },
        { icon: 'el-icon-more-outline' },
        { icon: 'el-icon-star-on' },
        { icon: 'el-icon-star-off' },
        { icon: 'el-icon-s-goods' },
        { icon: 'el-icon-goods' },
        { icon: 'el-icon-warning' },
        { icon: 'el-icon-warning-outline' },
        { icon: 'el-icon-question' },
        { icon: 'el-icon-info' },
        { icon: 'el-icon-remove' },
        { icon: 'el-icon-circle-plus' },
        { icon: 'el-icon-success' },
        { icon: 'el-icon-error' },
        { icon: 'el-icon-zoom-in' },
        { icon: 'el-icon-zoom-out' },
        { icon: 'el-icon-remove-outline' },
        { icon: 'el-icon-circle-plus-outline' },
        { icon: 'el-icon-circle-check' },
        { icon: 'el-icon-circle-close' },
        { icon: 'el-icon-s-help' },
        { icon: 'el-icon-minus' },
        { icon: 'el-icon-plus' },
        { icon: 'el-icon-check' },
        { icon: 'el-icon-close' },
        { icon: 'el-icon-picture' },
        { icon: 'el-icon-picture-outline' },
        { icon: 'el-icon-picture-outline-round' },
        { icon: 'el-icon-upload' },
        { icon: 'el-icon-upload2' },
        { icon: 'el-icon-download' },
        { icon: 'el-icon-camera-solid' },
        { icon: 'el-icon-camera' },
        { icon: 'el-icon-video-camera-solid' },
        { icon: 'el-icon-video-camera' },
        { icon: 'el-icon-message-solid' },
        { icon: 'el-icon-bell' },
        { icon: 'el-icon-s-cooperation' },
        { icon: 'el-icon-s-order' },
        { icon: 'el-icon-s-platform' },
        { icon: 'el-icon-s-fold' },
        { icon: 'el-icon-s-unfold' },
        { icon: 'el-icon-s-operation' },
        { icon: 'el-icon-s-promotion' }
      ],
      editFormRules: {
        // menuname: [
        //   { required: true, message: '菜单名称不能为空', trigger: 'blur' }
        // ],
        // url: [
        //   { required: true, message: '菜单地址不能为空', trigger: 'blur' }
        // ],
        // icon: [
        //   { required: true, message: '菜单图标不能为空', trigger: 'blur' }
        // ],
        // lever: [
        //   { required: true, message: '菜单图标不能为空', trigger: 'blur' }
        // ]
      },
      editParentId: '',
      ids: '',
      iddd: null,
      newicon: null,
      newlever: null,
      sun: null,
      nry: null,
      tianjiacaidan: false,
      bianji: false,
      shanchu: false,
      sorts: []
    }
  },
  created() {
    caozuo(123).then(res => {
      this.tianjiacaidan = res;
    });
    caozuo(132).then(res => {
      this.bianji = res;
    });
    caozuo(133).then(res => {
      this.shanchu = res;
    });
    this.getRightsList();

  },
  methods: {
    // 修改菜单排序
    inpts(e) {
      const that = this;
      that.$http.post(`adminMenu/updateSortById/${e.id}/${e.sort}`).then(res => {
        if (res.data.code == 0) return this.$message.success('菜单排序修改成功');
        return this.$message.error('菜单排序修改失败')
      })
    },
    daohang(e) {
      console.log(e, '123');
      if (!e) {
        this.editForm.isNavi = 1;
      }
    },
    handleNodeClick(b) {
      // this.editForm.url = b.url;
      // console.log(b, '123');
      // this.editForm.lever = b.lever;
      this.editForm.parentId = b.id;
      this.select_flag2 = false;
    },
    icon_change1() {
      this.select_flag1 = true;
    },
    icon_change2() {
      this.select_flag2 = true;
    },
    icons(icon) {
      this.newicon = icon;
      this.editForm.icon = icon;
      if (this.newicon !== null) {   console.log('326');
        this.btn_title = '删除图标';
        setTimeout(() => {
          this.flaging = false;
        }, 1000);
      } else {
        console.log('331');
        this.btn_title = '添加图标'
      }
    },
    del_icon() {
      if (this.btn_title == '删除图标' || this.btn_title == '修改图标') {
        this.btn_title = '添加图标';
        this.flaging = true;
        this.select_icon()
      } else {
        this.flaging = true;
      }
    },
    select_icon() {
      this.flaging = true;
    },
    // 获取菜单列表
    async getRightsList() {
      let abc = {};
      this.$http.get('adminMenu/getAllAdminMenu', { params: this.queryInfo }).then(res => {
        if (res.data.code !== 0) {
          return this.$message.error('获取菜单列表失败！')
        } else {
          res.data.data.forEach(item => {
            if (item.childList.length > 0) {
              if (item.url !== item.childList[0].url) {
                abc = {
                  des: '',
                  icon: item.icon,
                  isNavi: 1,
                  menuname: item.menuname,
                  parentId: 0,
                  id: item.id,
                  sort: item.sort,
                  lever: item.lever,
                  url: item.childList[0].url
                };
                this.$http.post('adminMenu/updateAdminMenuById', abc
                ).then(ress => {
                  if (ress.data.code == 0) {
                    console.log(289);
                  }
                })
              } else {
                item.url = item.url;
              }
            }
          });
          this.rightsList = res.data.data; this.total = res.data.count;
          for (let i = 0; i < this.rightsList.length; i++) {
            this.rightsList[i].isNavi == 0 ? this.rightsList[i].isNavi = false : this.rightsList[i].isNavi = true;
            this.rightsList[i].b_j_anniu = false;
            for (let j = 0; j < this.rightsList[i].childList.length; j++) {
              this.rightsList[i].childList[j].childList = this.rightsList[i].childList[j].childList3;
              this.rightsList[i].childList[j].isNavi == 0 ? this.rightsList[i].childList[j].isNavi = false : this.rightsList[i].childList[j].isNavi = true;
              this.rightsList[i].childList[j].b_j_anniu = false;
            }
          }
        }
      })
    },
    // 双击展开折叠
    dbclick(row, column, event) {
      if (event.currentTarget.querySelector(".el-table__expand-icon")) {
        event.currentTarget.querySelector(".el-table__expand-icon").click()
      }
    },
    // 菜单编辑的菜单等级清空事件
    clearParentId() {
      this.editForm.parentId = 0
    },
    // 展示添加&&编辑菜单对话框
    async addRole(row) {
      this.editDialogVisible = true;
      this.btn_title = '添加图标';
      this.editForm.icon='';
      this.newicon='';
      this.title = '添加菜单';
      this.placeholder_title = '请输入你的目标地址';
      this.syn = false;
      let ntr = [
        {
          lever: 1,
          disabd: false,
        },
        {
          lever: 2,
          disabd: false,
        },
        {
          lever: 3,
          disabd: false
        }
      ];
      if (row.id == null || row.id == '' || row.id == undefined) {
        this.editForm.parentId = 0;
        this.editForm.id = 0;
        // this.disabd
        ntr.forEach(item => {
          this.nry = item.lever;
          // this.sun = item.disabd;
          if (item.lever == 1) {
            item.disabd = false
            this.sun = false
          } else {
            item.disabd = true
            this.sun = true
          }
        })
      } else {
        this.editForm.parentId = row.id;
        if (row.parentId == 0) {
          ntr.forEach(item => {
            this.nry = item.lever;
            if (item.lever == 2) {
              item.disabd = false
              this.sun = false
            } else {
              item.disabd = true
              this.sun = true
            }
          })
        } else {
          ntr.forEach(item => {
            this.nry = item.lever;
            if (item.lever == 3) {
              item.disabd = false
              this.sun = false
            } else {
              item.disabd = true
              this.sun = true
            }
          })
        }
      }
      this.leaavel = ntr;
    },
    async showEditRole(row) {
      this.editDialogVisible = true;
      this.editForm.parentId = row.id;
      this.sorts = row.childList;
      this.placeholder_title = '请选择你要移动的目标地址';
      this.title = '编辑菜单';
      let newlever = [
        {
          lever: 1,
          disabd: false,
        },
        {
          lever: 2,
          disabd: false,
        },
        {
          lever: 3,
          disabd: false
        }
      ];
      this.syn = true;
      const { data: res } = await this.$http.get('adminMenu/getAdminMenuById/' + row.id)
      if (res.code !== 0) {
        return row.b_j_anniu = false;
      } else {
        row.b_j_anniu = true;
        if (res.data.parentId == 0) res.data.parentId = null
        this.editForm = res.data;
        this.btn_title=this.editForm.icon==null||this.editForm.icon==undefined||this.editForm.icon==''?'添加图标':'修改图标';
        if (this.editForm.lever == 3) {
          newlever[0].disabd = true;
          newlever[2].disabd = true;
          newlever[1].disabd = true;
        } else {
          newlever.forEach(item => {
            this.nry = item.lever;
            if (item.lever == 1 || item.lever == 2) {
              item.disabd = false
              this.sun = false
            } else if (item.lever == 3) {
              item.disabd = true
              this.sun = true
            }
          });
        }
        this.leaavel = newlever;
      }
    },
    // 添加&&编辑菜单方法
    editPower() {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        if (this.editForm.parentId == 0) {
          this.public_p(this.rightsList)
        } else {
          console.log(this.sorts, '479');
          this.public_p(this.sorts)
        };
      })
    },
    public_p(publics) {
      let exists = false;
      if (this.title !== '编辑菜单') {
        for (let i = 0; i < publics.length; i++) {
          if (publics[i].sort == this.editForm.sort) {
            exists = true;
            this.$message.error('当前排序值已重复，请重新输入')
            break;
          }
        }
      }
      if (!exists) {
        this.public_function()
      }
    },
    async public_function() {
      this.editForm.adminId = window.sessionStorage.getItem('adminId') * 1;
      if (this.title == '添加菜单') {
        if (this.editForm.lever === 3) {
          this.editForm.id = null;
          this.editForm.isNavi = 0;
          this.editForm.url = '--';
          this.editForm.icon = '--'
        } else {
          if (this.editForm.isNavi == false) {
            this.editForm.isNavi = 0;
          } else {
            this.editForm.isNavi = 1;
          };
          this.editForm.id = null;
          this.editForm.isNavi = this.editForm.isNavi;
          this.editForm.url = this.editForm.url;
          this.editForm.icon = this.editForm.icon;

        }
        const { data: res } = await this.$http.post('adminMenu/addAdminMenu', this.editForm)
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message.success('添加菜单成功！')
        this.getRightsList()
        this.editDialogVisible = false
      } else {
        const { data: res } = await this.$http.post('adminMenu/updateAdminMenuById', this.editForm)
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message.success('编辑菜单成功！')
        this.getRightsList()
        this.editDialogVisible = false
      }
    },


    // 添加&&编辑菜单方法
    // editPower() {
    //   this.$refs.editFormRef.validate(async valid => {
    //     if (!valid) return
    //     if (this.title === '添加菜单') {
    //       if (this.editForm.lever === 3) {
    //         this.editForm.id = null;
    //         this.editForm.isNavi = 0;
    //         this.editForm.url = '--';
    //         this.editForm.icon = '--'
    //       } else {
    //         this.editForm.id = null;
    //
    //         this.editForm.url = this.editForm.url;
    //         this.editForm.icon = this.editForm.icon
    //       }
    //       const { data: res } = await this.$http.post('adminMenu/addAdminMenu', this.editForm)
    //       console.log(res)
    //       if (res.code !== 0) {
    //         return this.$message.error(res.msg)
    //       }
    //       this.$message.success('添加菜单成功！')
    //       this.getRightsList()
    //       this.editDialogVisible = false
    //     } else {
    //       console.log(this.editForm.parentId, 'dengji399')
    //       // this.editForm.parentId=
    //       const { data: res } = await this.$http.post('adminMenu/updateAdminMenuById', this.editForm)
    //       if (res.code !== 0) {
    //         return this.$message.error(res.msg)
    //       }
    //       this.$message.success('编辑菜单成功！')
    //       this.getRightsList()
    //       this.editDialogVisible = false
    //     }
    //   })
    // },
    // 编辑菜单对话框关闭事件
    editDialogClosed() {
      this.$refs.editFormRef.resetFields();
      this.editDialogVisible = false;
      this.flaging = false;
      this.select_flag2 = false;
      this.rightsList.forEach(item => {
        item.b_j_anniu = false
      })
    },
    // 是否导航的switch改变事件
    async changeIsNavi(row) {
      this.editForm.id = row.id;
      if (row.isNavi == true) {
        this.editForm.isNavi = 1
      } else {
        this.editForm.isNavi = 0
      };
      this.$http.post('adminMenu/updateAdminMenuById', this.editForm).then(res => {
        if (res.data.code == 0) {
          this.$message.success('编辑菜单成功！');
          this.getRightsList()
        }
      })
    },
    // 用户的选中事件
    handleSelectionChange(value) {
      if (value.length > 0) {
        return false
      } else {
        this.ids = ''
        value.forEach(item => {
          this.ids += item.id + ','
        })
        this.ids = this.ids.substring(0, this.ids.length - 1)
      }
    },
    // 根据id删除对应菜单信息
    async removePowerById(row) {
      const confirmResult = await this.$confirm('此操作将永久删除该菜单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      // 如果菜单确认删除，则返回值为字符串 confirm
      // 如果菜单取消删除，则返回值为字符串 cancel
      // console.log(confirmResult)
      if (confirmResult === 'cancel') {
        return this.$message.info('已取消删除')
      }
      if (row.id === '' || row.id === undefined || row.id === null) {
        const { data: res } = await this.$http.post('adminMenu/delAdminMenuById/' + this.ids + '/' + Number(window.sessionStorage.getItem('adminId')))
        if (res.code !== 0) {
          return this.$message.error('删除菜单失败！')
        }
        this.$message.success('删除菜单成功！')
        this.getRightsList()
      } else {
        const { data: res } = await this.$http.post('adminMenu/delAdminMenuById/' + row.id.toString() + '/' + Number(window.sessionStorage.getItem('adminId')))
        if (res.code !== 0) {
          return this.$message.error('删除菜单失败！')
        }
        this.$message.success('删除菜单成功！')
        this.getRightsList()
      }
    }
  }

}
</script>

<style long="less" scoped></style>