<template>
  <!-- 卡片视图区域 -->
  <el-card class="card">
      <el-row :gutter="24" style="padding-bottom:20px">
        <el-col :span="4">
        <li>
          <span>所属电站</span>
          <el-select v-model="suoshudianzhan" placeholder="请选择" clearable @clear="getPowerStationList"
            @change="change_vale" filterable>
            <el-option v-for="item in powerStationList" :key="item.id" :label="item.stationName" :value="item.id">
            </el-option>
          </el-select>
        </li>
      </el-col>
      <el-col :span="4">
        <li>
          <span>电桩类型</span>
          <el-select v-model="queryInfo.pileType" clearable placeholder="请选择电桩类型" @clear="getPowerStationList"
            filterable @change="current">
            <el-option v-for="item in pileType_list" :key="item.pileType" :label="item.label" :value="item.pileType">
            </el-option>
          </el-select>
        </li>
      </el-col>
        <el-col :span="4">
        <li>
          <span>电桩编号</span>
          <el-input placeholder="请输入电桩编号" clearable @keyup.enter.native="getPowerStationList"
            v-model="queryInfo.pileCode" @change="current" @clear="getPowerStationList">
          </el-input>
        </li>
      </el-col>
      <el-col :span="4">
        <li>
          <span>订单编号</span>
          <el-input placeholder="请输入订单号" clearable @keyup.enter.native="getPowerStationList"
            v-model="queryInfo.billCode" @change="current" @clear="getPowerStationList">
          </el-input>
        </li>
      </el-col>
        <el-col :span="4">
        <li>
          <span>充电车主</span>
          <el-input placeholder="请输入充电车主/手机号" clearable @keyup.enter.native="getPowerStationList"
            v-model="queryInfo.icCode" @change="current" @clear="getPowerStationList">
          </el-input>
        </li>
      </el-col>
      <el-col :span="2" style="display: flex;flex-direction: row;">
        <el-button type="primary" icon="el-icon-search" @click="debounceClick">查询</el-button>
        <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
    </el-col>
      </el-row>
      <!-- <el-form-item label="启动方式" :span="3">
            <el-select v-model="queryInfo.startType" placeholder="请选择" clearable @clear="getPowerStationList"
              style="width: 150px;" filterable>
              <el-option v-for="item in startTypeList" :key="item.startType" :label="item.stateName"
                :value="item.startType">
              </el-option>
            </el-select>
          </el-form-item> -->

    <!-- table表格区域 -->
    <el-table class="table" :data="chargingList" border @row-dblclick="dbclick" empty-text="暂无数据">
      <el-table-column align="center" type="expand">
        <template slot-scope="scope">
          <el-form label-position="left" class="form">
            <el-row :gutter="20" type="flex" class="row-bg" justify="space-around" style="display: flex;flex-direction: row;padding: 0 1%;">
              <el-col :span="6">
                <el-form-item label="订单编号：">
                  <span v-if="scope.row.startType == 5">{{ scope.row.billCode }}</span>
                  <span v-else>{{ time_all(scope.row) }}</span>
                  <span v-if="scope.row.billCode == null">--</span>
                </el-form-item>
                <el-form-item label="电桩编号：">
                  <span v-if="scope.row.pileCode">{{ scope.row.pileCode }}</span>
                  <span v-else> -- </span>
                </el-form-item>
                <el-form-item label="开始时间：">
                  <span v-if="scope.row.startTime">{{ scope.row.startTime }}</span>
                  <span v-else> -- </span>
                </el-form-item>
                <el-form-item label="结束时间：">
                  <span v-if="scope.row.endTime == null">--</span>
                  <span v-else>{{ scope.row.endTime }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="充电车主：">
                  <span v-if="scope.row.icCode != ''">
                    <span>{{ scope.row.icCode }}</span>
                  </span>
                  <span v-else>
                    <span> -- </span>
                  </span>
                </el-form-item>
                <el-form-item label="车牌号：">
                  <span v-if="scope.row.carNum != ''">
                    <span>{{ scope.row.carNum }}</span>
                  </span>
                  <span v-else>
                    <span> -- </span>
                  </span>
                </el-form-item>
                <el-form-item label="车架号：">
                  <span v-if="scope.row.carvin !== ''">{{ scope.row.carvin }}</span>
                  <span v-else> -- </span>
                </el-form-item>
                <el-form-item label="结束原因：">
                  <span>--</span>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="尖时电量（度）：">
                  <span v-if="scope.row.tipKwh">{{ timesalls(scope.row.tipKwh, 100, 4) }}度</span>
                  <span v-else> 0 度</span>
                </el-form-item>
                <el-form-item label="峰时电量（度）：">
                  <span v-if="scope.row.peakKwh">{{ timesalls(scope.row.peakKwh, 100, 4) }}度</span>
                  <span v-else> 0 度</span>
                </el-form-item>
                <el-form-item label="平时电量（度）：">
                  <span v-if="scope.row.flatKwh">{{ timesalls(scope.row.flatKwh, 100, 4) }}度</span>
                  <span v-else> 0 度</span>
                </el-form-item>
                <el-form-item label="谷时电量（度）：">
                  <span v-if="scope.row.valleyKwh">{{ timesalls(scope.row.valleyKwh, 100, 4) }}度</span>
                  <span v-else> 0 度</span>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="开始SOC：">
                  <el-progress :text-inside="true" :stroke-width="20" :percentage="scope.row.startSoc"
                    status="success"></el-progress>
                </el-form-item>
                <el-form-item label="已充SOC：">
                  <el-progress :text-inside="true" :stroke-width="20" :percentage="scope.row.nowSoc"
                    status="success"></el-progress>
                </el-form-item>
                <el-form-item label="充电时长（分钟）：">
                  <span>{{ timesalls1(scope.row.totalChargeTime) }}分钟</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column align="center" label="电桩编号" prop="pileCode" width="160"></el-table-column>
      <el-table-column align="center" label="手机号" prop="phone" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.phone == null">--</span>
          <span>{{ scope.row.phone }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="枪号" prop="gunNumber" width="80">
        <template slot-scope="scope">
          <el-tag type="" v-if="scope.row.gunNumber == 1">{{ scope.row.gunNumName }}</el-tag>
          <el-tag type="success" v-if="scope.row.gunNumber == 2">{{ scope.row.gunNumName }}</el-tag>
          <el-tag type="warning" v-if="scope.row.gunNumber == 3">{{ scope.row.gunNumName }}</el-tag>
          <el-tag type="danger" v-if="scope.row.gunNumber == 4">{{ scope.row.gunNumName }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" label="开始时间" prop="startTime" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.startTime == null">--</span>
          <span v-else>{{ scope.row.startTime }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="开始SOC" prop="startSoc" width="80">
        <template scope="scope">
          <span>{{ scope.row.startSoc }}%</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="当前SOC" prop="nowSoc" width="80">
        <template scope="scope">
          <span>{{ scope.row.nowSoc }}%</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="充电功率" width="80">
        <template scope="scope">
          <el-col
            v-if="scope.row.chargeKwh !== '' && scope.row.chargeKwh !== null && scope.row.chargeKwh !== undefined">
            <span>{{ jisuan(scope.row) }}</span>
          </el-col>
          <el-col v-else>
            <span> 0 </span>
          </el-col>
        </template>
      </el-table-column>
      <el-table-column align="center" label="电量（度）" prop="totalKwh" width="100">
        <template scope="scope">
          <span>{{ timesalls(scope.row.totalKwh, 100, 4) }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="电费  |  服务费（元）" width="180">
        <template scope="scope">
          <el-col>
            <span>￥{{ timesalls(scope.row.chargemoney, 100, 2) }}&emsp;|&emsp;</span>
            <span
              v-if="scope.row.chargemoneygive == 0 && scope.row.servicemoneygive == 0 && scope.row.billMoneygive == 0"></span>
            <span>￥{{ timesalls(scope.row.servicemoney, 100, 2) }}</span>
          </el-col>
          <el-col
            v-if="scope.row.chargemoneygive == 0 && scope.row.servicemoneygive == 0 && scope.row.billMoneygive == 0"></el-col>
          <el-col v-else>
            <span style="color: green;">
              ￥{{ timesalls(scope.row.chargemoneygive, 100, 2) }}&emsp;|&emsp;</span>
            <span></span>
            <span style="color: green;">
              ￥{{ timesalls(scope.row.servicemoneygive, 100, 2) }}</span>
          </el-col>
        </template>
      </el-table-column>
      <el-table-column align="center" label="总费用（元）" prop="billMoney" width="120">
        <template scope="scope">
          <span>￥{{ timesalls(scope.row.billMoney, 100, 2) }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="启动方式" prop="startType" width="80">
        <template scope="scope">
          <img style="width: 40px;
           height: 40px;"
            :src="scope.row.startType == 0 || scope.row.startType == 9 ? tupian + '小程序.png' : scope.row.startType == 1 ? tupian + '在线卡.png' : scope.row.startType == 2 ? tupian + '离线卡.png' : scope.row.startType == 3 ? tupian + '管理卡.png' : scope.row.startType == 4 ? tupian + 'Vin.png' : scope.row.startType == 5 ? tupian + 'hlht.png' : scope.row.startType == 6 ? tupian + 'zfb.png' : scope.row.startType == 9 ? tupian + 'app.png' : tupian + 'ocpp_APP'"
            alt="">
        </template>
      </el-table-column>
      <el-table-column align="left" label="所属电站" prop="stationName" :show-overflow-tooltip='true'></el-table-column>
      <el-table-column align="center" label="工作状态" prop="gunState" width="120">
        <template slot-scope="scope">
          <el-tag type="" v-if="scope.row.gunState == 0">{{ scope.row.runStateName }}</el-tag>
          <el-tag type="success" v-if="scope.row.gunState == 1">{{ scope.row.runStateName }}</el-tag>
          <el-tag type="success" v-if="scope.row.gunState == 2">{{ scope.row.runStateName }}</el-tag>
          <el-tag type="warning" v-if="scope.row.gunState == 3">{{ scope.row.runStateName }}</el-tag>
          <el-tag type="danger" v-if="scope.row.gunState == 4">{{ scope.row.runStateName }}</el-tag>
          <el-tag type="" v-if="scope.row.gunState == 5">{{ scope.row.runStateName }}</el-tag>
          <el-tag type="warning" v-if="scope.row.gunState == 6">{{ scope.row.runStateName }}</el-tag>
          <el-tag type="danger" v-if="scope.row.gunState == 7">{{ scope.row.runStateName }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="left" label="操作" v-if="caozuoquanxian" width="280">
        <template slot-scope="scope">
          <el-button size="small" type="danger"
            v-if="scope.row.startType == 1 && scope.row.payState !== 1 || scope.row.startType == 0 && scope.row.payState !== 1 || scope.row.startType == 6 && scope.row.payState !== 1"
            @click="jiesuan(scope.row)">结算</el-button>
          <!-- <el-button v-if="qingsuan" type="warning" @click="qingsuanMethods(scope.row)" size="small">清算订单</el-button> -->
          <el-button type="primary" size="small" @click="EleticMask(scope.row)">充电趋势图</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页导航区域 -->
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page"
      :page-sizes="[8, 20, 50, 100]" :page-size="queryInfo.limit" layout="total, sizes, prev, pager, next, jumper"
      :total="total" background>
    </el-pagination>
    <el-dialog title="充电趋势图" :visible.sync="diaload" width="80%" @close="closeMethods" append-to-body
      :close-on-click-modal="false">
      <div class="alls">
        <div class="center1">
          <h4>电流趋势图</h4>
          <div id="main1"></div>
        </div>
        <div class="center2">
          <h4>电压趋势图</h4>
          <div id="main2"></div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-debounce="closeMethods">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import caozuo from "@/utils/pubilc.js";
import * as echarts from 'echarts';
export default {
  name: 'chargingWorld',
  data() {
    return {
      diaload: false,
      qingsuan: false,
      chaxunanniu: false,
      yincang: true,
      page_num: 0,
      chongzhianniu: false,
      // 需求电流
      currentDemandJson: [],
      // 需求电压
      requiredVoltageJson: [],
      // 满足电流
      outputCurrentJson: [],
      // 满足电压
      outputVoltageJson: [],
      // 电压输出
      voltageOutputValueJson: [],
      pileType_list: [
        {
          pileType: 0,
          label: '锐创直流桩'
        },
        {
          pileType: 1,
          label: '锐创交流桩'
        },
        {
          pileType: 2,
          label: '无疆(二代)交流桩'
        },
        {
          pileType: 3,
          label: '光法(一代)直流桩'
        },
        {
          pileType: 4,
          label: '光法(一代)交流桩'
        },
        {
          pileType: 5,
          label: '锐创低速桩'
        },
        {
          pileType: 6,
          label: '其他电桩'
        }
      ],
      startTypeList: [
        {
          startType: 0,
          stateName: '小程序启动'
        },
        {
          startType: 1,
          stateName: '平台卡启动'
        },
        {
          startType: 2,
          stateName: '独立卡启动'
        },
        {
          startType: 3,
          stateName: '超级卡启动'
        },
        {
          startType: 4,
          stateName: 'Vin启动'
        },
        {
          startType: 5,
          stateName: '互联互通启动'
        },
        {
          startType: 9,
          stateName: '位置启动方式'
        },
      ],
      // tupian: tupian,
      caozuoquanxian: false,
      shifou: null,
      // 查询充电订单列表所需参数
      tupian: this.$imgUrls,
      queryInfo: {
        defaultValue: null,
        billCode: null,
        pileCode: null,
        pileType: null,
        gunState: '1,2',
        userNum: null,
        icCode: null,
        payState: null,
        startTime: null,
        endTime: null,
        page: 1,
        limit: 8,
        stationIds: null,
        roleId: window.sessionStorage.getItem('roleId'),
        startType: null,
        phone: null
      },
      // 充电订单数据
      chargingList: [],
      stateName: '',
      total: 0,
      value: '',
      suoshudianzhan: null,
      detailList: [],
      // 电站列表数据
      powerStationList: [],
      // 是否已支付
      isPay: [
        {
          label: "未支付",
          payState: 0
        }
      ],
      // 是否无效订单数据
      invalidOrder: [
        {
          label: "是",
          defaultValue: 0
        },
        {
          label: "否",
          defaultValue: 1
        }
      ]
    }
  },
  computed: {
    time_all() {
      return function (val) {
        const hr = val.billCode.slice(val.pileCode.length, val.billCode.length)
        return hr
      }
    },
    timesalls() {
      return function (value, num, count) {
        return (value / num).toFixed(count)
      }
    },
    timesalls1() {
      return function (value) {
        return Math.floor(value / 60)
      }
    },
    jisuan() {
      return function (val) {
        return val.pileType !== 5 ? (val.chargeKwh / 100000).toFixed(4) : val.chargeKwh
      }
    }
  },
  watch: {
    chargingList: {
      handler(newval, oldval) {
        this.chargingList = newval
      }
    }
  },
  created() {
    window.addEventListener('keydown', this.keyDown);
    caozuo(106).then(res => {
      this.caozuoquanxian = res;
    })
    caozuo(193).then(res => {
      this.qingsuan = window.sessionStorage.getItem('roleId')==1?res:false;
    })
    this.getPowerStationList()
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDown, false)
  },
  methods: {
    // 监听回车键执行事件
    keyDown(e) {
      // // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13 || e.keyCode === 108) {
        this.getPowerStationList() // 需要执行的方法方法
      }
    },
    closeMethods() {
      this.diaload = false;
    },
    EleticMask(e) {
      this.diaload = true;
      // 满足电压
      this.outputVoltageJson = e.outputVoltageJson.split(',');
      // 需求电流
      this.currentDemandJson = e.currentDemandJson.split(',');
      // 需求电压
      this.requiredVoltageJson = e.requiredVoltageJson.split(',');
      // 需求电压
      this.outputCurrentJson = e.outputCurrentJson.split(',');
      // 电压输出
      this.voltageOutputValueJson = e.voltageOutputValueJson.split(',');
      this.defaultMethods();
      this.initMethods();
    },
    defaultMethods() {
      var myChart = echarts.init(document.getElementById('main1'));
      var option;
      option = {
        title: {
          text: 'Stacked Line'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'Email',
            type: 'line',
            stack: 'Total',
            data: [120, 132, 101, 134, 90, 230, 210]
          },
          {
            name: 'Union Ads',
            type: 'line',
            stack: 'Total',
            data: [220, 182, 191, 234, 290, 330, 310]
          },
          {
            name: 'Video Ads',
            type: 'line',
            stack: 'Total',
            data: [150, 232, 201, 154, 190, 330, 410]
          },
          {
            name: 'Direct',
            type: 'line',
            stack: 'Total',
            data: [320, 332, 301, 334, 390, 330, 320]
          },
          {
            name: 'Search Engine',
            type: 'line',
            stack: 'Total',
            data: [820, 932, 901, 934, 1290, 1330, 1320]
          }
        ]
      };
      option && myChart.setOption(option);
    },
    initMethods() {
      var myChart = echarts.init(document.getElementById('main2'));
      var option;
      option = {
        title: {
          text: 'Stacked Line'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'Email',
            type: 'line',
            stack: 'Total',
            data: [120, 132, 101, 134, 90, 230, 210]
          },
          {
            name: 'Union Ads',
            type: 'line',
            stack: 'Total',
            data: [220, 182, 191, 234, 290, 330, 310]
          },
          {
            name: 'Video Ads',
            type: 'line',
            stack: 'Total',
            data: [150, 232, 201, 154, 190, 330, 410]
          },
          {
            name: 'Direct',
            type: 'line',
            stack: 'Total',
            data: [320, 332, 301, 334, 390, 330, 320]
          },
          {
            name: 'Search Engine',
            type: 'line',
            stack: 'Total',
            data: [820, 932, 901, 934, 1290, 1330, 1320]
          }
        ]
      };
      option && myChart.setOption(option);
    },
    qingsuanMethods(e) {
      const that = this;
      that.$http({
        url: `bill-info/updatePayState/${e.id}`
      }).then(res => {
        if (res.data.code !== 0) return;
      })
    },
    debounceClick() {
      this.chaxun()
    },
    zhanshi() {
      this.yincang = !this.yincang
    },
    // 重置搜索条件
    chongzhi() {
      this.chongzhianniu = true;
      this.queryInfo = {
        defaultValue: null,
        billCode: null,
        pileCode: null,
        pileType: null,
        gunState: '1,2',
        userNum: null,
        icCode: null,
        payState: null,
        startTime: null,
        endTime: null,
        page: 1,
        limit: 8,
        stationIds: null,
        roleId: window.sessionStorage.getItem('roleId'),
        startType: null,
        phone: null
      };
      this.suoshudianzhan = null;
      this.chaxun()
      setTimeout(() => {
        this.chongzhianniu = false
      }, 500);
    },
    // 查询所有搜索条件
    chaxun() {
      this.chaxunanniu = true;
      this.getPowerStationList()
    },
    // 所属电站搜索条件
    change_vale(e) {
      this.queryInfo.page = 1;
      if (e !== null || e !== undefined) {
        this.queryInfo.stationIds = e;
      }
    },
    current() {
      this.queryInfo.page = 1;
    },
    // 搜索充电订单列表
    async getChargingOrderList() {
      this.chargingList = [];
      this.queryInfo.stationIds = this.suoshudianzhan ? this.suoshudianzhan : window.sessionStorage.getItem('stationIds');
      await this.$http.get('bill-info/getAllBillInfo', { params: this.queryInfo }).then(resb => {
        if (resb.data.code == 0) {
          this.chargingList = resb.data.data;
          this.total = resb.data.count;
        }
      });
    },
    // 监听pageSize改变的事件
    handleSizeChange(pageSize) {
      this.queryInfo.limit = pageSize;
      this.queryInfo.page = 1;
      this.getPowerStationList()
    },
    // 获取电站列表数据
    getPowerStationList() {
      this.queryInfo.stationIds = this.queryInfo.stationIds == null ? window.sessionStorage.getItem('stationIds') : this.queryInfo.stationIds;
      this.chargingList = [];
      this.powerStationList = [];
      this.$http.get('bill-info/getAllBillInfo', { params: this.queryInfo }).then(resb => {
        if (resb.data.code == 0) {
          this.chargingList = resb.data.data;
          this.total = resb.data.count;
        }
      });
      if (this.powerStationList.length > 0) return;
      this.$http.get('station-info/getAllStationInfoName', {
        params: {
          roleId: window.sessionStorage.getItem('roleId'),
          stationIds: window.sessionStorage.getItem('stationIds'),
          stationMode: '0,1,2'
        }
      }).then(res => {
        res.data.data.forEach(item => {
          this.powerStationList.push({
            id: item.id,
            stationName: item.stationName
          })
        });
      })
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getPowerStationList()
    },
    // 展示订单包含子项内容
    async showDetail(id) {
      const { data: res } = await this.$http.get('order/getOrderById?id=' + id)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.detailList = res.data
    },
    // 双击展开折叠
    dbclick(row, column, event) {
      // console.log(event.currentTarget.querySelector(".el-table__expand-icon"))
      if (event.currentTarget.querySelector(".el-table__expand-icon")) {
        event.currentTarget.querySelector(".el-table__expand-icon").click()
      }
    },
    // 结算订单
    async jiesuan(row) {
      const confirmResult = await this.$confirm('此操作将结算该订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消结算！')
      }
      const { data: res } = await this.$http.post('bill-info/billSettlement', {
        id: row.id,
        adminId: window.sessionStorage.getItem('adminId') * 1,
        startType: row.startType,
        billMoney: row.billMoney,
        billInfoType: 0
      })
      if (res.code !== 0 && res.code !== 3) {
        return this.$message.error(res.msg)
      }
      if (res.code == 0) {
        this.$message.success('结算成功！');
        this.getPowerStationList()
      }
      if (res.code == 3) {
        const confirmResulting = await this.$confirm('该订单已提交手动结算，正在结算中，请稍后刷新页面', '提示', {
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'warning'
        }).catch(err => err)
        if (confirmResulting !== 'confirm') return;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.card{
li{
  display:flex;flex-direction:row;align-items:center;
  margin-right: 10%;
  span{
    font-size:14px;margin-right:10px;
    width: 80px;
  }
}
}
</style>