<template>
  <!-- 卡片视图区域 -->
  <el-card class="card">
    <!--  -->
    <el-form :inline="true" :model="queryInfo" class="form">
      <el-form-item label="停车场站名称：">
        <el-input placeholder="请输入停车场站名称" clearable v-model="queryInfo.name" @change="current"
          @clear="getPowerStationList">
        </el-input>
      </el-form-item>
      <el-form-item label="联系人：">
        <el-input placeholder="请输入联系人" clearable v-model="queryInfo.contacts" @change="current"
          @clear="getPowerStationList">
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="getPowerStationList">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
      </el-form-item>
      <el-form-item v-if="tianjiadianzhan">
        <el-button type="success" icon="el-icon-plus" @click="showPowerStation">添加停车场站</el-button>
      </el-form-item>
      <el-form-item v-if="shanchu">
        <el-button type="danger" icon="el-icon-delete" @click="removeById(0)">批量删除</el-button>
      </el-form-item>
    </el-form>
    <!-- table表格区域 -->
    <el-table class="table" :data="powerStationList" border @selection-change="handleSelectionChange" empty-text="暂无数据">
      <el-table-column align="center" type="selection" width="55"></el-table-column>
      <el-table-column align="center" label="名称" prop="stationName" width="260">
        <template slot-scope="scope">
          <span style="display: flex;justify-content: flex-start;">
            {{ scope.row.stationName }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="位置" prop="address">
        <template slot-scope="scope">
          <div style="display: flex;justify-content: flex-start;">
            {{ scope.row.address }}
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="联系人" prop="contacts" width="140"></el-table-column>
      <el-table-column align="center" label="联系电话" prop="contactsTel" width="140"></el-table-column>
      <el-table-column align="center" label="操作" width="400">
        <template slot-scope="scope">
          <el-button size="small" v-if="j_z_l_feilvbianji" :type="scope.row.feeModelId == 0 ? '' : 'warning'"
            @click="showTariff(scope.row)">{{
      scope.row.feeModelId == 0 ? '费率设置' : '费率编辑' }}</el-button>
          <el-button size="small" type="primary" icon="el-icon-edit" :disabled="scope.row.b_j_anniu"
            @click="showPowerStation(scope.row)" v-if="bianji">编辑</el-button>
          <el-button size="small" type="danger" icon="el-icon-delete" @click="removeById(scope.row)"
            v-if="shanchu">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页导航区域 -->
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page"
      :page-sizes="[10, 20, 50, 100]" :page-size="queryInfo.limit" layout="total, sizes, prev, pager, next, jumper"
      :total="total" background>
    </el-pagination>
    <!-- 添加&编辑停车场站的对话框 -->
    <el-dialog :title="title" :visible.sync="powerStationVisible" @close="powerStationDialogClosed" append-to-body
      :close-on-click-modal="false">
      <!-- 内容主体区域 -->
      <el-form :model="powerStationForm" :rules="powerStationRules" ref="powerStationRef" label-width="120px">
        <el-form-item label="停车场站名称" prop="stationName">
          <el-input v-model="powerStationForm.stationName" placeholder="请输入停车场站名称"></el-input>
        </el-form-item>
        <el-form-item label="停车场站地址" prop="address">
          <el-input v-model="powerStationForm.address" placeholder="请输入停车场站地址"></el-input>
          <el-button style="margin-top: 5px;" size="small" @click="getMap">获取地图位置</el-button>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="经度" prop="longitude">
              <el-input disabled v-model="powerStationForm.longitude" placeholder="请通过地图获取停车场站经度"></el-input>
            </el-form-item>
            <el-form-item label="联系人" prop="contacts">
              <el-input v-model="powerStationForm.contacts" placeholder="请输入停车场站联系人"></el-input>
            </el-form-item>
            <!-- <el-form-item label="总功率" prop="totalPower">
              <el-input v-model="powerStationForm.totalPower" placeholder="请输入停车场站总功率" style="width: 70%;"></el-input>
              （kWh）
            </el-form-item> -->
            <!-- <el-form-item label="离线充电" prop="brokenNet">
                <el-switch v-model="powerStationForm.brokenNet" active-color="#13ce66"
                  inactive-color="#ff4949"></el-switch>
                <span style="color: red; margin-left: 20px;">(仅支持直流充电桩)</span>
              </el-form-item> -->
            <!-- <el-form-item label="对外显示" prop="stationDisplay">
              <el-switch v-model="powerStationForm.stationDisplay"
                :active-color="powerStationForm.stationDisplay == false ? '#ff4949' : '#13ce66'"
                :inactive-color="powerStationForm.stationDisplay == false ? '#ff4949' : '#13ce66'"></el-switch>
              <span style="color: red; margin-left: 20px;">(仅锐创合作小程序)</span>
            </el-form-item> -->
            <el-form-item label="状态" prop="stationState">
              <el-select v-model="powerStationForm.stationState" placeholder="请选择停车场站状态" style="width: 100%;"
                filterable>
                <el-option v-for="item in stateData" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="类型" prop="stationType">
              <el-select v-model="powerStationForm.stationType" placeholder="请选择停车场站类型" style="width: 100%;" filterable>
                <el-option v-for="item in stationTypeData" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="纬度" prop="latitude">
              <el-input disabled v-model="powerStationForm.latitude" placeholder="请通过地图获取停车场站纬度"></el-input>
            </el-form-item>
            <el-form-item label="联系人电话" prop="contactsTel">
              <el-input v-model="powerStationForm.contactsTel" placeholder="请输入停车场站联系人的电话号码"></el-input>
            </el-form-item>
            <el-form-item label="组织代码" prop="hlhtEquipmentOwnerId">
              <el-input v-model="powerStationForm.hlhtEquipmentOwnerId" placeholder="请输入停车场站的组织代码"></el-input>
            </el-form-item>
            <el-form-item label="营业时间" prop="field">
              <el-input placeholder="请输入停车场站的场地信息" v-model="powerStationForm.field"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="二维码" prop="qrCodeType">
          <el-radio-group v-model="powerStationForm.qrCodeType" @change="qrCodeType_change">
            <el-radio v-for="(item, index) in radioMSK" :key="index" :label="item.qrCodeType">
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="本站通知">
          <el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="changeTimeall">
          </el-date-picker>
          <el-input placeholder="请输入停车场站的本站通知" v-model="powerStationForm.notice" style="margin-top: 20px;"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部按钮区域 -->
      <p slot="footer" class="dialog-footer">
        <el-button @click="powerStationDialogClosed">取 消</el-button>
        <el-button type="primary" v-debounce="editPowerStation">确 定</el-button>
      </p>
    </el-dialog>
    <!-- 地锁 -->
    <el-dialog :title="tariffForm2.feeModelId == 0 ? '费率设置' : '费率编辑'" :visible.sync="editDialogVisible2" style="width: 100%;
      height: 100%;list-style: none;
     " @close="btn_click2" append-to-body :close-on-click-modal="false">
      <div class="center">
        <el-form :model="tariffForm2" :rules="editFormRuless" ref="editFormRef" label-width="100px"
          label-position="left">
          <el-form-item label="费率类型：">
            <el-radio-group v-model="tariffForm2.feeModelType" @change="tariffChange">
              <el-radio v-for="(item, index) in testArray" :key="index" :label="item.id">
                {{ item.name }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="固定费率：">
            <span v-if="tariffForm2.feeModelType == 5" style="margin-right:20px;">停车费</span>
            <input v-if="tariffForm2.feeModelType == 5" v-model="tariffForm2.fixFee" type="number" :min="0.00"
              :max="6.50" :step="0.01" style="width:10%;text-align: center;height:20px;border:1px solid #ccc"
              class="money1" /><span v-if="tariffForm2.feeModelType == 5">（元）</span>
            <span style="margin:0 20px;">免费时长</span><input v-model="tariffForm2.fixServiceFee" type="number" min="0.00"
              max="6.50" step="0.01" style="width:10%;text-align: center;height:20px;border:1px solid #ccc"
              class="money1" />（小时）
          </el-form-item>
          <el-form-item v-if="tariffForm2.feeModelType == 6" label="费率时段：">
            <li v-for="(item, index) in liebiao" :key="index" style="display:flex;
              flex-direction:row;
              align-items:center;
              margin:20px 0px;">
              <span style="margin-right:20px">{{ (index * 1 + 1 * 1) + '、' }}</span>
              <el-time-select placeholder="起始时间" v-model="item.name" format='HH' :picker-options="{
      start: item.start,
      step: item.step,
      end: item.end,
      minTime: item.length > 1 ? item[index - 1].name : ''
    }"></el-time-select><span style="margin:20px;">--</span><el-time-select placeholder="结束时间" v-model="item.keys"
                format='HH' :picker-options="{
      start: item.start,
      step: item.step,
      end: item.end,
      minTime: item.name
    }" @change="changingStartime(index)"></el-time-select>
              <span style="margin:0 20px;">费用：</span>
              <el-input-number :controls="false" controls-position="right" v-model="item.value2"
                :min="0"></el-input-number>
              <span style="margin:0 20px;">元</span>
              <el-button type="danger" @click="delimiters(index)" icon="el-icon-delete"></el-button>
            </li>
            <li style="display:flex;justify-content:center;">
              <el-button v-if="adds_btn" @click="addTime" type="success" icon="el-icon-plus"></el-button>
            </li>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer"
          style="display: flex;justify-content: flex-end;margin-right: 5%;margin-top:30px">
          <el-button @click="btn_click2">取 消</el-button>
          <el-button type="primary" v-debounce="editTariff2">确 定</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 地图选点对话框 -->
    <el-dialog title="选择位置" :visible.sync="mapDialogVisible" width="70%" class="dialogStyle" @close="dialoag"
      append-to-body :close-on-click-modal="false">
      <div id="myPageTop">
        <el-input id="tipinput" style="width: 300px" placeholder="输入提示后查询" v-model="keyword">
          <el-button type="primary" slot="append" icon="el-icon-search" @click="searchKeyWord"></el-button>
        </el-input>
      </div>
      <div id="container"></div>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="qx4">取 消</el-button>
        <el-button type="primary" @click="editMapPosition">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>

</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
// import { getMap } from 'echarts';
window._AMapSecurityConfig = {
  // 设置安全密钥
  securityJsCode: "36ffc476104cf8a1b5433c836cd27f72",
}
const timeList = [
  { name: '00:00-00:29', selected: false, id: 0, background: "" },
  { name: '00:30-00:59', selected: false, id: 1, background: "" },
  { name: '01:00-01:29', selected: false, id: 2, background: "" },
  { name: '01:30-01:59', selected: false, id: 3, background: "" },
  { name: '02:00-02:29', selected: false, id: 4, background: "" },
  { name: '02:30-02:59', selected: false, id: 5, background: "" },
  { name: '03:00-03:29', selected: false, id: 6, background: "" },
  { name: '03:30-03:59', selected: false, id: 7, background: "" },
  { name: '04:00-04:29', selected: false, id: 8, background: "" },
  { name: '04:30-04:59', selected: false, id: 9, background: "" },
  { name: '05:00-05:29', selected: false, id: 10, background: "" },
  { name: '05:30-05:59', selected: false, id: 11, background: "" },
  { name: '06:00-06:29', selected: false, id: 12, background: "" },
  { name: '06:30-06:59', selected: false, id: 13, background: "" },
  { name: '07:00-07:29', selected: false, id: 14, background: "" },
  { name: '07:30-07:59', selected: false, id: 15, background: "" },
  { name: '08:00-08:29', selected: false, id: 16, background: "" },
  { name: '08:30-08:59', selected: false, id: 17, background: "" },
  { name: '09:00-09:29', selected: false, id: 18, background: "" },
  { name: '09:30-09:59', selected: false, id: 19, background: "" },
  { name: '10:00-10:29', selected: false, id: 20, background: "" },
  { name: '10:30-10:59', selected: false, id: 21, background: "" },
  { name: '11:00-11:29', selected: false, id: 22, background: "" },
  { name: '11:30-11:59', selected: false, id: 23, background: "" },
  { name: '12:00-12:29', selected: false, id: 24, background: "" },
  { name: '12:30-12:59', selected: false, id: 25, background: "" },
  { name: '13:00-13:29', selected: false, id: 26, background: "" },
  { name: '13:30-13:59', selected: false, id: 27, background: "" },
  { name: '14:00-14:29', selected: false, id: 28, background: "" },
  { name: '14:30-14:59', selected: false, id: 29, background: "" },
  { name: '15:00-15:29', selected: false, id: 30, background: "" },
  { name: '15:30-15:59', selected: false, id: 31, background: "" },
  { name: '16:00-16:29', selected: false, id: 32, background: "" },
  { name: '16:30-16:59', selected: false, id: 33, background: "" },
  { name: '17:00-17:29', selected: false, id: 34, background: "" },
  { name: '17:30-17:59', selected: false, id: 35, background: "" },
  { name: '18:00-18:29', selected: false, id: 36, background: "" },
  { name: '18:30-18:59', selected: false, id: 37, background: "" },
  { name: '19:00-19:29', selected: false, id: 38, background: "" },
  { name: '19:30-19:59', selected: false, id: 39, background: "" },
  { name: '20:00-20:29', selected: false, id: 40, background: "" },
  { name: '20:30-20:59', selected: false, id: 41, background: "" },
  { name: '21:00-21:29', selected: false, id: 42, background: "" },
  { name: '21:30-21:59', selected: false, id: 43, background: "" },
  { name: '22:00-22:29', selected: false, id: 44, background: "" },
  { name: '22:30-22:59', selected: false, id: 45, background: "" },
  { name: '23:00-23:29', selected: false, id: 46, background: "" },
  { name: '23:30-23:59', selected: false, id: 47, background: "" }];
import caozuo from "@/utils/pubilc.js";
export default {
  name: 'powerStationWorld',
  data() {
    // 验证联系人的规则
    const checkContacts = (rule, value, callback) => {
      const reg = /^(?=.{2,4}$)[\u4e00-\u9fa5]+$/
      if (reg.test(value)) {
        return callback()
      }
      callback(new Error('联系人姓名由2-4个汉字组成'))
    }
    // 验证手机号的规则
    const checkMobile = (rule, value, callback) => {
      // 验证手机号的正则表达式
      const regMobile = /^1[3-9]\d{9}$/;
      if (regMobile.test(value)) {
        // 正确的电话号码
        return callback()
      }
      callback(new Error('请输入正确的手机号码'))
    }
    const checkChargerPower = (rule, value, callback) => {
      const reg = /^[0-9]*$/
      if (reg.test(value)) {
        return callback()
      }
      callback(new Error('停车场站总功率只能为数字'))
    }
    return {
      radioMSK: [
        {
          qrCodeType: 0,
          name: '互联互通二维码'
        },
        {
          qrCodeType: 1,
          name: '通用二维码'
        }
      ],
      adds_btn: true,
      tariffForm2: {
        feeModelType: 5
      },
      liebiao: [
        {
          name: '',
          keys: '',
          start: '00:00',
          end: '24:00',
          step: '01:00',
          value2: 0
        }
      ],
      editDialogVisible2: false,
      objMoney: [],
      activeIndex: 0,
      list: [
        {
          paramKey: 0
        },
        {
          paramKey: 0
        },
        {
          paramKey: 0
        },
        {
          paramKey: 0
        }
      ],
      adds: true,
      // 功率档位
      objArray: [{ 0: "0" }],
      // 赠费
      // objARRAY: [],

      testArray: [
        {
          id: 5,
          name: '全天收费'
        },
        {
          id: 6,
          name: '时段收费'
        }
      ],


      editDialogVisibles: false,
      // 其他
      tupiananiu: false,
      chaxunaniu: false,
      tianjiadianzhan: false,
      shanchuanniu2: false,
      chongzhianniu: false,
      d_s_feilvbianji: false,
      cardType: [
        {
          value: 0,
          label: '禁用刷卡'
        },
        {
          value: 1,
          label: '限本站卡'
        },
        {
          value: 2,
          label: '通用刷卡'
        }
      ],
      tianjiadianzhan: false,
      changzhantupian: false,
      j_z_l_feilvbianji: false,
      bianji: false,
      shanchu: false,
      newfilelist: [],
      ids_all: null,
      title_name: '',
      fileurl: null,
      img_list: null,
      fileList: [],
      imgVisible: false,
      value1: ['', ''],
      num: 1,
      btn_index: 0,
      startTypelist: [
        {
          label: "小程序",
          startType: 0
        },
        {
          label: "在线卡",
          startType: 1
        },
        {
          label: "离线卡",
          startType: 2
        },
        {
          label: "管理卡",
          startType: 3
        },
        {
          label: "Vin",
          startType: 4
        },
        {
          label: "互联互通",
          startType: 5
        },
        {
          label: "未知方式",
          startType: 9
        },
      ],
      // 查询停车场站管理列表的参数对象
      queryInfo: {
        stationIds: window.sessionStorage.getItem('stationIds'),
        name: null,
        contacts: null,
        contactsTel: null,
        stationState: null,
        page: 1,
        limit: 10,
        roleId: window.sessionStorage.getItem('roleId'),
        stationMode: '2'
      },
      total: 0,
      radioeletric: [
        {
          id: 2,
          name: '地锁'
        }
      ],
      // 停车场站列表的数据
      powerStationList: [],
      powerStationForm: {
        stationMode: 0,
        qrCodeType: 0,
        // id: 0,
        stationName: '',
        address: '',
        longitude: '',
        latitude: '',
        contacts: '',
        contactsTel: '',
        stationState: '3',
        field: '24h',
        icState: 2,
        feeModelDisplay: true,
        brokenNetCharging: 0,
        hlhtEquipmentOwnerId: '',
        stationType: 1,
        stationDisplay: true,
        brokenNet: true,
        totalPower: 0,
        id: null,
        notice: '',
        parking: null,
        noticeEndTime: '',
        noticeStartTime: '',
      },
      shareFixedTariffer: false,
      // 停车场站的状态列表
      stateData: [
        {
          label: '正在规划',
          value: "0"
        }, {
          label: '正在修建',
          value: "1"
        }, {
          label: '仅供寻桩',
          value: "2"
        }, {
          label: '正常运营',
          value: "3"
        }, {
          label: '场站维护',
          value: "5"
        }, {
          label: '场站停用',
          value: "6"
        }
      ],
      // 停车场站类型的列表
      stationTypeData: [
        {
          label: '公共设备',
          value: 1
        }, {
          label: '个人专用',
          value: 50
        }, {
          label: '内部专用',
          value: 104
        }, {
          label: '公交专用',
          value: 100
        }, {
          label: '环卫专用',
          value: 101
        }, {
          label: '物流专用',
          value: 102
        }, {
          label: '出租车专用',
          value: 103
        }, {
          label: '其他',
          value: 255
        }
      ],
      powerStationRules: {
        hlhtEquipmentOwnerId: [
          { required: true, message: '组织代码不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '停车场站名称不能为空', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '停车场站地址不能为空', trigger: 'blur' }
        ],
        longitude: [
          { required: true, message: '经度不能为空', trigger: 'blur' }
        ],
        latitude: [
          { required: true, message: '纬度不能为空', trigger: 'blur' }
        ],
        rateMoneyId: [
          { required: true, message: '请至少选择一项', trigger: 'change' }
        ],
        contacts: [
          { required: true, message: '联系人不能为空', trigger: 'blur' },
          { validator: checkContacts, trigger: 'blur' }
        ],
        contactsTel: [
          { required: true, message: '联系人电话不能为空', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ],
        chargerPower: [
          { required: true, message: '停车场站总功率不能为空', trigger: 'blur' },
          { validator: checkChargerPower, trigger: 'blur' }
        ],
        state: [
          { required: true, message: '请至少选择一项', trigger: 'change' }
        ],
        stationType: [
          { required: true, message: '请至少选择一项', trigger: 'change' }
        ],
        qrCodeType: [
          { required: true, message: '请至少选择一项', trigger: 'change' }
        ]
      },
      powerStationVisible: false,
      title: '',
      // 省市区/县
      provinceData: [],
      cityData: [],
      districtData: [],
      addressCounty: {
        provinceId: '',
        provinceName: '',
        cityId: '',
        cityName: '',
        districtId: '',
        districtName: ''
      },
      page_num: 0,
      value: '',
      cursor: 'default',
      editDialogVisible: false,
      // 费率相关参数及数据
      tariffForm: {
        billingPolicy: {
          tip: 0,
          tipService: 0,
          peak: 0,
          peakService: 0,
          flat: 0,
          flatService: 0,
          valley: 0,
          valleyService: 0
        },
        feeModelType: 0,
        fixFee: 0,
        fixServiceFee: 0,
        timeFeeModel: {}
      },
      tariffData: [],
      editFormRules: {},
      editFormRuless: {},
      radio: 2,
      imgg: null,
      fixedTariff: true,
      shareFixedTariff: false,
      shareTariff: false,
      halfTime: false,
      dialogVisible: false,
      ids: '',
      // 停车场站地图弹框
      mapDialogVisible: false,
      keyword: null,

      bianji_ok: false,
      isDragSelecting: false,
      startBoxIndex: null,
      secolor: '#fff',
      timeList: timeList,
      allid: null,
      newarray: [],
      stationId: '',
      stationMode: '',
      objs: {}
    }
  },
  computed: {
    jisuan() {
      return function (abc, bac, num) {
        if (bac !== 0) {
          const hr = (Number(bac) * Number(abc) / 100).toFixed(num)
          return hr
        } else {
          return 0
        }
      }
    },
    jisuanTime() {
      return function (abc, bac) {
        let hr = '';
        if (!abc) return hr = '0';
        return hr = ((abc - bac) / 60 / 1000).toFixed(0);

      }
    },
    jisuaning() {
      return function (abc, bac, cba) {
        let hr = '';
        if (abc == '' || bac == '' || abc == undefined || bac == undefined || cba == '' || cba == undefined) return hr = '0';
        let parts1 = parseInt(abc.split(':')[0], 10) + parseInt(abc.split(':')[1], 10) / 60;
        let parts2 = parseInt(bac.split(':')[0], 10) + parseInt(bac.split(':')[1], 10) / 60;
        // 计算差值
        let difference = parts2 - parts1;
        // 处理跨天情况
        if (difference < 0) {
          difference += 24;
        }
        hr = difference
        return hr;
      }
    },
    feiyong() {
      return function (abc, bac, cba, nbs) {
        // let hr = '';
        // if (!abc) return hr = '0';
        // return hr = ((abc - bac) / 60 / 1000).toFixed(0);
      }
    }
  },
  watch: {
    // 充电金额方案
    objArray(newvalue) {
      if (newvalue == null || newvalue == undefined) return;
      this.objArray = newvalue;
      if (newvalue.length < 6) {
        this.adds = true;
      } else {
        this.adds = false
      }
    },
    // 时段收费方案
    liebiao(newvalue) {
      const that = this;
      if (newvalue == null || newvalue == undefined) return;
      that.liebiao = newvalue;
      let abc = newvalue.some(item => item.keys == '24:00');
      if (newvalue.length > 24) return that.adds_btn = false;
      return that.adds_btn = true;
    },
    'tariffForms.duration'(newvalue) {
      if (newvalue == null || newvalue == undefined) return;
      let pattern = /^\d+$/;
      if (!pattern.test(this.tariffForms.duration)) {
        this.tariffForms.duration = 0;
        this.$message.error('时长必须为正整数')
      }
    },
    newarray(newvalue) {
      if (newvalue.length < 2) return;
      this.objArray = newvalue;
    },
    'tariffForms.fixFee'(newvalue) {
      if (newvalue.length == 0 || newvalue == null || newvalue == undefined) return;
      const abc = /^(?:[1-9]\d*|0)(?:\.\d{1,2})?$/;
      if (!abc.test(newvalue)) return this.$message.error('请输入正确的金额')
      if (newvalue > 6.5) return newvalue = 6.5;
      if (newvalue < 0.01) return newvalue = 0.01;
      this.tariffForms.fixFee = newvalue;
    },
    'tariffForms.fixServiceFee'(newvalue) {
      if (newvalue.length == 0 || newvalue == null || newvalue == undefined) return;
      const abc = /^(?:[1-9]\d*|0)(?:\.\d{1,2})?$/;
      if (!abc.test(newvalue)) return this.$message.error('请输入正确的金额')
      if (newvalue > 6.5) return newvalue = 6.5;
      if (newvalue < 0.01) return newvalue = 0.01;
      this.tariffForms.fixServiceFee = newvalue;
    }
  },
  created() {
    caozuo(161).then(res => {
      this.tianjiadianzhan = res;
    });
    caozuo(153).then(ress => {
      this.changzhantupian = ress
    });
    caozuo(94).then(resb => {
      this.j_z_l_feilvbianji = resb;
    });
    caozuo(182).then(resb => {
      this.d_s_feilvbianji = resb;
    });
    caozuo(154).then(rese => {
      this.bianji = rese
    });
    caozuo(155).then(resr => {
      this.shanchu = resr;
    });
    this.getPowerStationList();
    this.getProvinceData();
  },
  methods: {
    current(){
this.queryInfo.page=1;
    },
    qrCodeType_change(e) {
      this.powerStationForm.qrCodeType = e;
    },
    timing(e) {
      if (e[0] == null || e[0] == undefined || e[0] == '') return;
      if (e[0] == e[1]) return this.$message.error('开始时间与结束时间不得相同');
    },
    async delimiters(e) {
      const that = this;
      console.log(e, '752');
      if (that.liebiao.length < 2 || e == 0) return this.$message.error('第一项默认禁止删除');
      await that.liebiao.forEach((item, index) => {
        if (index == e) {
          that.objs = {
            start: item.start
          }
        }
        that.$confirm('确定删除该时段的配置么？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(res => {
          that.liebiao.splice(index, 1);
        }).catch(err => {
          return that.$message.info('已经取消删除！')
        })
      })
    },
    changingStartime(e) {
      const that = this;
      that.liebiao.forEach((item, index) => {
        if (index !== e) return;
        if (item.name == '' && item.keys == '') return that.$message.error('开始时间与结束时间不得为空');
        if (item.name == item.keys || item.name > item.keys) {
          that.$message.error('此时间段内，开始时间不得大于或等于结束时间');
          item.keys = ''
        }
      })
    },
    addTime() {
      const that = this;
      if (that.liebiao[that.liebiao.length - 1].name == '' || that.liebiao[that.liebiao.length - 1].keys == '') return that.$message.error('请先设置上一时间段范围');
      that.adds_btn = !that.liebiao.some(item => item.keys == '24:00') == true;
      that.liebiao.some(item => item.keys == '24:00') !== true ? that.liebiao.push({
        name: '',
        keys: '',
        start: Object.keys(that.objs).length !== 0 ? that.objs.start : that.liebiao[that.liebiao.length - 1].keys,
        end: '24:00',
        step: '01:00',
        value2: 0
      }) : that.liebiao.forEach((element, index) => {
        if (element.start == '24:00') {
          that.liebiao.splice(index, 1)
        }
      });
    },
    // 确定添加/修改地锁费率
    editTariff2() {
      const that = this;
      if (that.tariffForm2.feeModelType == 6) {
        if (that.liebiao.length == 1) {
          if (that.liebiao[0].name == '' || that.liebiao[0].keys == '') return this.$message(`请添加你的费率${that.liebiao[0].name == '' ? '开始' : '结束'}时间`);
        } else {
          that.liebiao.forEach((element, index) => {
            if (element.name !== '' && element.keys !== '') return;
            that.liebiao.splice(index, 1)
          });
        }
      }
      that.$http.post('feeModel/updGLModel', {
        feeModelType: that.tariffForm2.feeModelType,
        fixFee: that.tariffForm2.feeModelType !== 6 && that.tariffForm2.fixFee !== null && that.tariffForm2.fixFee !== '' ? that.tariffForm2.fixFee * 100 : '',
        fixServiceFee: that.tariffForm2.fixServiceFee * 100,
        stationId: that.ids_all,
        id: that.stationId,
        stationName: that.tariffForm2.stationName,
        adminId: window.sessionStorage.getItem('adminId') * 1,
        stationMode: that.stationMode,
        billingPolicy: that.liebiao.length !== 0 && that.tariffForm2.feeModelType !== 5 ? JSON.stringify(that.liebiao) : '',
      }).then(res => {
        if (res.data.code == 0) {
          that.$message.success('成功');
        } else {
          that.$message.error('失败');
          that.pubilc_function()
        }
        that.editDialogVisible2 = false;
        that.getPowerStationList()
      })
    },
    // 选择位置取消（地图组件）
    qx4() {
      this.keyword = this.powerStationForm.address;
      this.initAMap()
    },
    // 关闭地图组件弹窗
    dialoag() {
      this.keyword = '';
      this.mapDialogVisible = false;
    },
    // 费率编辑（取消）
    qx2() {
      this.editDialogVisible = false;
      this.tariffForm = {
        billingPolicy: {
          tip: 0,
          tipService: 0,
          peak: 0,
          peakService: 0,
          flat: 0,
          flatService: 0,
          valley: 0,
          valleyService: 0
        },
        feeModelType: 0,
        fixFee: 0,
        fixServiceFee: 0,
        timeFeeModel: {}
      };
    },
    // 编辑（取消）
    qx1() {
      this.powerStationVisible = false;
      this.powerStationForm = {};
      this.powerStationList.forEach(item => {
        item.b_j_anniu = false;
      });
    },
    // 获取停车场站列表
    getPowerStationList() {
      this.chaxunaniu = true;
      this.$http.get('station-info/getAllStationInfo', { params: this.queryInfo }).then(res => {
        if (res.data.code !== 0) return this.$message.error(res.data.msg)
        this.powerStationList = [];
        this.powerStationList = res.data.data;
        this.total = res.data.count;
        this.powerStationList.forEach(item => {
          item.isstopsoc == 0 ? item.isstopsoc = false : item.isstopsoc = true;
          item.b_j_anniu = false;
          // item.j_z_l_f_l_b_j_anniu = false;
          item.s_c_anniu = false
        })
      })
    },
    // 重置搜索条件
    chongzhi() {
      this.chongzhianniu = true;
      this.queryInfo = {
        stationIds: window.sessionStorage.getItem('stationIds'),
        name: null,
        contacts: null,
        contactsTel: null,
        stationState: null,
        page: 1,
        limit: 10,
        stationMode: '2',
        roleId: window.sessionStorage.getItem('roleId')
      };
      this.getPowerStationList();
      setTimeout(() => {
        this.chongzhianniu = false
      }, 500)
    },
    changeTimeall(value) {
      if (value.length !== 0) {
        if (value[0] == value[1]) {
          this.$message.error('起始时间不得为同一天！');
          this.powerStationForm.startTime = ''
          this.powerStationForm.endTime = ''
        } else {
          this.powerStationForm.noticeStartTime = value[0];
          this.powerStationForm.noticeEndTime = value[1];
        }
      } else {
        this.powerStationForm.noticeStartTime = '';
        this.powerStationForm.noticeEndTime = '';
      }
    },
    // 关闭地锁费率编辑弹窗
    btn_click2() {
      this.editDialogVisible2 = false;
      this.tariffForm2.feeModelType = 0;
    },
    btn_event(item) {
      this.secolor = item.color;
    },
    handleMouseDown(index) {
      this.isDragSelecting = true;
      this.startBoxIndex = index;
      if (this.secolor !== '#fff') {
        this.timeList.forEach((item, inde) => {
          if (inde === index) {
            item.color = this.secolor
          }
        })
      }
    },
    getMap() {
      this.mapDialogVisible = true;
      this.keyword = this.powerStationForm.address;
      this.initAMap()
    },
    // 双击展开折叠
    dbclick(row, column, event) {
      // console.log(event.currentTarget.querySelector(".el-table__expand-icon"))
      if (event.currentTarget.querySelector(".el-table__expand-icon")) {
        event.currentTarget.querySelector(".el-table__expand-icon").click()
      }
    },
    initAMap() {
      const that = this;
      let markers = [];
      AMapLoader.load({
        key: "9747ad6cffe4a3e286980b02cbff8a41", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          'AMap.ToolBar',
          'AMap.Scale',
          'AMap.Geocoder',
          'AMap.Geolocation',
          'AMap.PlaceSearch',
          'AMap.AutoComplete',
          'AMap.CitySearch'
        ],
        resizeEnable: true
      }).then((AMap) => {
        that.map = new AMap.Map("container", {
          // 设置地图容器id
          viewMode: "3D", // 是否为3D地图模式
          zoom: 15, // 初始化地图级别
          center: [112.453868, 34.619631], // 初始化地图中心点位置
          resizeEnable: true
        })
        // 关键字查询
        that.searchMap();
        // 选中logo（定位）
        that.map.on('click', function (e) {
          // 点标记显示内容，HTML要素字符串
          const markerContent = '' +
            '<div class="custom-content-marker">' +
            '   <img src="//a.amap.com/jsapi_demos/static/demo-center/icons/dir-via-marker.png">' +
            // '   <div class="close-btn" onclick="clearMarker()">X</div>' +
            '</div>';

          const position = new AMap.LngLat(e.lnglat.getLng(), e.lnglat.getLat()) //Marker经纬度
          this.remove(markers)
          const marker = new AMap.Marker({
            position: position,
            icon: new AMap.Icon({
              image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
              size: [20, 30],
            }),
            content: markerContent, // 将 html 传给 content
            offset: new AMap.Pixel(-20, -28)
          })
          markers.push(marker)
          this.add(marker)
          that.powerStationForm.longitude = e.lnglat.getLng()
          that.powerStationForm.latitude = e.lnglat.getLat()
        })
      }).catch((e) => {
        console.log(e)
      })
    },
    searchKeyWord() {
      this.initAMap()
    },
    // 关键字查询
    searchMap() {
      // 搜索框自动完成类
      this.auto = new AMap.AutoComplete({
        input: "tipinput", // 使用联想输入的input的id
        city: '全国'
      });
      //构造地点查询类
      this.placeSearch = new AMap.PlaceSearch({
        map: this.map,
        city: this.keyword
      });
      // 当选中某条搜索记录时触发
      this.placeSearch.search(this.keyword, function (status, result) {
        // this.powerStationForm.address=result;
        // console.log(status)
        // console.log(result,'1185')
      })
    },
    editMapPosition() {
      if (this.keyword !== null) {
        this.powerStationForm.address = this.keyword;
      }
      this.mapDialogVisible = false
    },
    changeTime(value) {
      if (value) {
        if (value[0] == value[1]) {
          this.$message.error('起始时间不得为同一天！');
          this.queryInfo.startTime = '';
          this.queryInfo.endTime = '';
        } else {
          this.queryInfo.startTime = value[0];
          this.queryInfo.endTime = value[1]
        }
      } else {
        this.queryInfo.startTime = null
        this.queryInfo.endTime = null
        this.getPowerStationList()
      }
    },
    // 费率对话框的定价类型改变事件
    tariffChange(value) {
      const that = this;
      that.objArray = [{ 0: "0" }];
      that.tariffForm2.feeModelType = value;
    },
    // 省
    async getProvinceData() {
      this.$http.get('sysArea/getAllSysArea').then(res => {
        if (res.data.code !== 0) return this.$message.error(res.data.msg);
        this.provinceData = res.data.data;
      })
    },
    // 省改变
    provinceChange(value) {
      console.log(value, this.addressCounty, 1244);
      this.getCityData(value)
      this.addressCounty.cityName = ''
      this.addressCounty.districtName = ''
      this.addressCounty.provinceId = value
    },
    // 市
    async getCityData(id) {
      this.$http.get('sysArea/getAreaCityByParentId/' + id)
      console.log(res)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.cityData = res.data;
    },
    // 城市改变（没用到）
    cityChange(value) {
      this.getDistrictData(value)
      this.addressCounty.districtName = ''
      this.addressCounty.cityId = value
    },
    // 区、县
    async getDistrictData(id) {
      this.$http.get('sysArea/getAreaCountyByParentId/' + id)
      console.log(res)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.districtData = res.data
    },
    districtChange(value) {
      this.addressCounty.districtId = value
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.queryInfo.page=1;
      this.getPowerStationList()
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getPowerStationList()
    },
    // 展示添加&编辑对话框事件
    async showPowerStation(row) {
      const that = this;
      that.allid = row.id;
      that.powerStationVisible = true;
      that.initAMap()
      if (row.id === undefined || row.id === null) {
        that.title = '添加停车场站(地锁)';
        that.tianjiadianzhan = true;
      } else {
        that.title = '编辑停车场站(地锁)';
        await that.$http.get('station-info/getStationInfoById/' + that.allid).then(res => {
          if (res.data.code !== 0) return that.$message.error(res.data.msg);
          row.b_j_anniu = true;
          res.data.data.noticeStartTime == null ? '' : res.data.data.noticeStartTime; res.data.data.noticeEndTime == null ? '' : res.data.data.noticeEndTime
          that.value1 = [that.powerStationForm.noticeStartTime, that.powerStationForm.noticeEndTime];
          that.powerStationForm = res.data.data;
          that.powerStationForm.feeModelDisplay = that.powerStationForm.feeModelDisplay == 0 ? false : true;
          that.powerStationForm.brokenNet = that.powerStationForm.brokenNet == 0 ? true : false;
          that.powerStationForm.stationDisplay = that.powerStationForm.stationDisplay == 0 ? false : true;
        })
      }
    },
    // 添加&编辑停车场站的提交事件
    editPowerStation() {
      let hhh = null;
      let ccc = null;
      let ddd = null;
      const that = this;
      if (that.powerStationForm.brokenNet == false) {
        hhh = 1
      } else {
        hhh = 0
      }
      if (that.powerStationForm.feeModelDisplay == false) {
        ccc = 0
      } else {
        ccc = 1
      }
      if (that.powerStationForm.stationDisplay == false) {
        ddd = '0'
      } else {
        ddd = '1'
      };
      that.powerStationForm.longitude = that.powerStationForm.longitude.toString();
      that.powerStationForm.latitude = that.powerStationForm.latitude.toString();
      that.powerStationForm.stationState = that.powerStationForm.stationState.toString();
      that.powerStationForm.feeModelDisplay = ccc;
      that.powerStationForm.stationDisplay = ddd;
      that.powerStationForm.brokenNet = hhh;
      that.powerStationForm.adminId = window.sessionStorage.getItem('adminId') * 1;
      that.powerStationForm.stationMode = 2;
      if (that.title == '添加停车场站(地锁)') {
        that.$http.post('station-info/addStationInfo', that.powerStationForm).then(res => {
          if (res.data.code == 0) {
            that.$message.success('添加停车场站成功！');
            that.getPowerStationList()
          } else {
            that.$message.error('添加停车场站失败！');
          }
          that.powerStationVisible = false;
          that.value1 = [];
        })
      } else {
        that.powerStationForm.id = that.allid;
        if (that.powerStationForm.feeModelDisplay == true) {
          ccc = 1
        } else {
          ccc = 0
        };
        if (that.powerStationForm.brokenNet == true) {
          that.powerStationForm.brokenNet = 1
        } else {
          that.powerStationForm.brokenNet = 0
        };
        if (that.powerStationForm.stationDisplay == true) {
          that.powerStationForm.stationDisplay = 1
        } else {
          that.powerStationForm.stationDisplay = 0
        };
        that.$http.post('station-info/updStationInfo', that.powerStationForm).then(resss => {
          if (resss.data.code == 0) {
            that.$message.success('编辑停车场站成功！');
            that.getPowerStationList();
            that.powerStationVisible = false
          } else {
            that.$message.error(resss.data.msg);
          }
        })
      }
    },
    // 添加&编辑对话框的关闭事件
    powerStationDialogClosed() {
      this.$refs.powerStationRef.resetFields()
      this.powerStationVisible = false;
      this.powerStationList.forEach(item => {
        item.b_j_anniu = false;
      });
    },
    // 批量删除的复选框事件
    handleSelectionChange(value) {
      if (value.length > 0) {
        this.ids = '';
        value.forEach(item => {
          this.ids += item.id + ','
        });
        this.ids = this.ids.substring(0, this.ids.length - 1);
      } else {
        return false
      }
    },
    // 删除对应id的停车场站事件
    async removeById(row) {
      const confirmResult = await this.$confirm('此操作将永久删除该停车场站, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除！')
      } else {
        if (row == 0) {
          //批量删除
          this.$http.post('station-info/delStationInfoById/' + Number(window.sessionStorage.getItem('adminId')) + '/' + this.ids).then(res => {
            if (res.data.code !== 0) {
              this.$message.error(res.data.msg)
            } else {
              this.$message.success(res.data.msg)
              this.getPowerStationList()
            }
          })
        } else {
          // 逐个删除
          this.$http.post('station-info/delStationInfoById/' + Number(window.sessionStorage.getItem('adminId')) + '/' + row.id).then(resb => {
            if (resb.data.code !== 0) {
              return this.$message.error(resb.data.msg)
            } else {
              this.$message.success(resb.data.msg)
              this.getPowerStationList()
            }
          })
        }
      }
    },
    pubilc_function() {
      this.tariffForm.fixFee = 0;
      this.tariffForm.fixServiceFee = 0;
      this.tariffForm.billingPolicy.tip = 0;
      this.tariffForm.billingPolicy.tipService = 0;
      this.tariffForm.billingPolicy.peak = 0;
      this.tariffForm.billingPolicy.peakService = 0;
      this.tariffForm.billingPolicy.flat = 0;
      this.tariffForm.billingPolicy.flatService = 0;
      this.tariffForm.billingPolicy.valley = 0;
      this.tariffForm.billingPolicy.valleyService = 0
    },
    // 展示费率对话框
    showTariff(row) {
      const that = this;
      that.stationId = row.feeModelId;
      that.stationMode = row.stationMode;
      that.editDialogVisibles = false;
      that.editDialogVisible2 = true; that.editDialogVisible = false;
      that.ids_all = row.id;
      that.$http.get(`feeModel/getGLModelById/${row.feeModelId}`).then(res => {
        if (res.data.code !== 0 || res.data.data == null) return that.pubilc_function();
        that.tariffForm2 = res.data.data;
        that.tariffForm2.fixServiceFee = that.tariffForm2.fixServiceFee / 100;
        if (that.tariffForm2.feeModelType == 5) return that.tariffForm2.fixFee = that.tariffForm2.fixFee / 100;
        return that.liebiao = JSON.parse(that.tariffForm2.billingPolicy);
      })
    },
    tariffDialogClosed() {
      // this.$router.push('/blank')
      this.$refs.editFormRef.clearValidate();
      this.getPowerStationList()
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-input.is-disabled .el-input__inner::-webkit-input-placeholder {
  text-align: center;
}

.el-col-24 {
  width: 8%;
}

.money1 {
  height: 30px;
}


.el-col-24 button {
  font-weight: 700;
  color: #fff;
}

.btnstyle {
  display: inline-block;
  text-align: center;
  border-bottom: none;
}

.el-checkbox-button:nth-child(6n+1) {
  margin-right: -2px;
}

.el-checkbox-button:nth-child(6n+2) {
  margin-right: -2px;
}

.el-checkbox-button:nth-child(6n+3) {
  margin-right: -2px;
}

.el-checkbox-button:nth-child(6n+4) {
  margin-right: -2px;
}

.el-checkbox-button:nth-child(6n+5) {
  margin-right: -2px;
}

.el-checkbox-button:nth-child(n+6) {
  margin-top: -2px;
}

#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 540px;
}

#myPageTop {
  position: absolute;
  top: 100px;
  right: 60px;
  z-index: 9999;
}
</style>
