<template>
  <div class="allmas">
    <div class="all">
      <div class="top">
        <h4>引流折扣申请</h4>
      </div>
      <div class="center">
        <!-- 送券人（选择） -->
        <li>
          <span> 送券人：</span>
          <!-- @click="click2" -->
            <el-select v-model="sendCouponsName" placeholder="请选择送券人" filterable size="mini">
              <el-option v-for="item in namelist" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
            <b style="color: #7AC6FF;">年</b>
        </li>
        <li>
          <span> 引流场站：</span>
          <el-select v-model="stationIds" placeholder="请选择你的引流场站" filterable @change="stationMethods">
            <el-option v-for="item in Stationlist" :key="item.id" :label="item.stationName" :value="item.id">
            </el-option>
          </el-select>
          <b style="color: #93D1FF;">年</b>
        </li>
        <li v-if="defaultType" style="color: red;font-size: 18px;">已开通</li>
        <li v-if="!defaultType">
          <span> 引流时间类型：</span>
          <el-select v-model="TimeType" placeholder="请选择你的开通时间" filterable size="mini">
            <el-option v-for="item in TimelistType" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <b style="color: #A7D9FF;">年</b>
        </li>
        <li v-if="!defaultType">
          <span> 引流时间：</span>
          <el-select v-model="Time" placeholder="请选择你的开通时间" filterable size="mini">
            <el-option v-for="item in Timelist" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
          <b style="font-weight: normal;padding-left: 5px;">{{ TimeType == 0 ? '年' : '月' }}</b>
        </li>
        <li>
          <span> 引流地图类型：</span>
          <el-select v-model="selected2" placeholder="请选择你的开通平台类型" filterable @change="changeMethods" size="mini">
            <el-option v-for="item in options2" :key="item" :label="item==0?'地图平台':item==1?'聚合平台':''" :value="item">
            </el-option>
          </el-select>
          <b style="color: #D0EBFF;">年</b>
        </li>
        <li>
          <span> 引流平台：</span>
          <el-select v-model="moren" placeholder="请选择你的开通平台" filterable @change="changeMethods2" size="mini">
            <el-option v-for="item in options" :key="item.paramKey" :label="item.paramKey" :value="item.paramKey">
            </el-option>
          </el-select>
          <b style="color: #E6F4FF;">年</b>
        </li>
      </div>
    </div>
    <div class="footer">
      <button @click="okMethods" :disabled="defaultType">确认减免</button>
    </div>
    <div v-if="dialoag" class="dialoagdiv">
      <div class="content">
        <p>减免优惠码</p>
        <p>减免优惠码：{{ code }}</p>
        <p>
          <button @click="copyCode">复制分享</button>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'App',
  data() {
    return {
      defaultType: false,
      pushType: 1,
      Stationlist: [],
      namelist: [
        '王黎明', '王豪', '张瑜', '孟英', '付强', '陈淑华', '付盛'
      ],
      selected3: window.sessionStorage.getItem('trueName'),
      dialoag2: false,
      options3: [],
      title: '请输入折扣率',
      moren: '',
      dialoag1: false,
      dialoag2: false,
      selected2: 0,
      Time: 1,
      TimeType: 0,
      stationIds: '',
      Timelist: [1, 3, 5],
      TimelistType: [
        {
          id: 0,
          name: '年'
        },
        {
          id: 1,
          name: '月'
        }
      ],
      options2: [0,1],
      newobj: {
        money: ''
      },
      radiolist: [{
        checked: true,
        name: '减免折扣'
      }, {
        checked: false,
        name: '减免金额'
      }],
      sendCouponsName: '',
      selected: '',
      options: [],
      dialoag: false,
      titlelist: [],
      code: '',
      deslist: [],
      allmap: [],
      money: 0,
      currentMoney: 0
    }
  },
  created() {
    const that = this;
    that.$http.get('station-info/getAllStationInfoName', {
      params: {
        roleId: 1,
        stationMode: '0,1',
        stationIds: ''
      }
    }).then(res => {
      that.Stationlist = res.data.code !== 0 ? [] : res.data.data;
    });
    that.defaultMethods();
  },
  methods: {
    stationMethods() {
      const that = this;
      that.$http.get(`drainage-station-info/getAllPileCountByStationId/${that.stationIds}/${that.pushType}`).then(ress => {
        that.defaultType = ress.data.data.orNot == 1 ? true : false;
        that.objValue = ress.data.data;
        that.changeMethods2(0)
      })
    },
    defaultMethods2() {
      let result = [];
      for (let i = 0; i < this.allmap.length; i += 4) {
        result.push(this.allmap.slice(i, i + 4));
      }
      return result;
    },
    click2() {
      this.dialoag1 = false;
    },
    changeMoney(e) {
      let abc = /^\d+(\.\d{1,2})?$/;
      console.log(e, abc.test(e), '221');
      if (!abc.test(e)) return this.$message.error(e == '请输入减免金额' ? '请检查您的减免金额格式' : '请检查你的折扣率格式');
    },
    Methodscurrent(e) {
      this.radiolist[0].checked = e !== 0 ? false : this.radiolist[e].checked;
      this.radiolist[1].checked = e !== 1 ? false : this.radiolist[e].checked;
      this.title = e == 1 ? "请输入减免金额" : '请输入折扣率';
    },
    okMethods() {
      const that = this;
      if (that.stationIds == '') return that.$message.error('请选择引流场站');
      if (that.money < 1) return that.$message.error('敬请期待');
      if(that.defaultType)return that.$message.error('场站已申请，请切换');
      that.$http.post('drainage-preferential-log/editDrainagePreferentialLog', {
        name: that.moren,
        drainagePlatform: that.moren == '高德地图' ? 1 : that.moren == '河南省平台' ? 2 : that.moren == '新电途' ? 3 : that.moren == '小桔充电' ? 4 : that.moren == '高德地图' ? 4 : 5,
        stationId: that.stationIds * 1, type: that.TimeType,
        openingTime: that.TimeType == 0 ? 12 * that.Time : that.Time,
        sendCouponsName: that.sendCouponsName
      }).then(res => {
        that.code = res.data.data.code;
        that.dialoag = res.data.code !== 0 ? false : true;
      })
    },
    defaultMethods() {
      const that = this;
      // 引流类型
      that.$http.get(`sysConfig/getSysConfigById/${that.selected2 == 0 ? 35 : 36}`).then(res => {
        if (res.data.code !== 0) return;
        that.options = JSON.parse(res.data.data.valueName);
        that.options.forEach(item => {
          item.paramKey = item.paramKey.split('-')[0]
        })
        that.moren = that.options[0].paramKey;
      });
    },
    changeMethods() {
      this.dialoag1 = false;
      this.defaultMethods()
    },
    changeMethods2(e) {
      let abc = this.options.filter(item => item.paramKey == e);
      this.pushType = abc.paramKey == '高德地图' ? 1 : abc.paramKey == '河南省平台' ? 2 : abc.paramKey == '新电途' ? 3 : abc.paramKey == '小桔充电' ? 4 : abc.paramKey == '高德地图' ? 4 : 5;
      this.money = abc.paramValue == '敬请期待' ? 0 : abc.paramValue;
      this.dialoag2 = false;
    },
    click1() {
      const that = this;
      that.dialoag2 = false;
      that.$http.get(`sysConfig/getSysConfigById/${that.selected2 == 0 ? 35 : 36}`).then(res => {
        if (res.data.code !== 0) return;
        that.allmap = JSON.parse(res.data.data.valueName);
        that.deslist = res.data.data.des.split('<br>');
        that.allmap.forEach(item => {
          that.options.push({
            name: item.paramKey.split('-')[0],
            paramValue: item.paramValue
          })
        })
        that.dialoag1 = !that.dialoag1;
      });

    },
    click2() {
      this.dialoag1 = false;
      this.dialoag2 = !this.dialoag2;
    },
    // 复制分享
    copyCode() {
      this.$copyText(this.code).then(res => {
        if(res){
          this.dialoag = false;
          // this.$router.push('/' + window.sessionStorage.getItem('oldUrl'));
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.allmas {
  width: 100vw;
  background: linear-gradient(to bottom, #55B6FF, #fff);
  height: 100vh;
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}
.dialoagdiv {
  width: 100%;
  height: 100%;
  background-color: #5c5858;
  opacity: .9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 99;
  overflow: hidden;
}

.dialoagdiv>.content {
  width: auto;
  height: auto;
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.dialoagdiv>.content>p {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialoagdiv>.content>p:nth-child(1) {
  font-size: 24px;
}

.dialoagdiv>.content>p:nth-child(2) {
 margin: 0 10px;
  font-size: 16px;
}

.dialoagdiv>.content>p:nth-child(3) {
  padding: 0 5%;
}

.dialoagdiv>.content>p>button {
  text-align: center;
  padding: 10px 0;
  height: 40px;
  width: 80%;
  background-color: #55B6FF;
  color: #fff;
  border-radius: 15px;
  border: none;
  font-size: 18px;
}

.all {
  flex: 9;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.top,
.center {
  padding: 0 10%;
}

.top {
  flex: 1;
}

.top>h4 {
  font-size: 26px;
}

.center {
  flex: 9;
  display: flex;
  font-size: 20px;
  flex-direction: column;
}

.center>li {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  // margin-top: 50px;
  position: relative;
}

.center>li:nth-child(1) {
  margin-top: 10px;
}

.center>li>span {
  width: 180px;
}

.weidingyi {
  position: relative;
  width: 150px;
}

.weidingyi>span {
  position: absolute;
  right: -10%;
  top: .5%;
  width: 20px;
}

.postionableDiv {
  position: absolute;
  border: 1px solid #fff;
  z-index: 66;
  width: 170px;
  height: 140px;
  left: 105px;
  top: 70%;
  padding: 0 5px;
  font-size: 16px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.postionableDiv>span {
  line-height: 30px;
}

.footer {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 0 10%;
  background-color: #fff;
  align-items: center;
  overflow: hidden;
}

.footer>button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-radius: 15px;
  height: 40px;
  color: #fff;
  background-color: #55B6FF;
  border: none;
  font-size: 24px;
}
</style>
