<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <el-row :gutter="20">
        <el-form :inline="true" :model="queryInfo" class="demo-form-inline">
          <el-form-item label="申请用户手机号：">
            <el-input placeholder="请输入申请用户手机号" clearable v-model="queryInfo.mobile" @clear="getLogList"
              @change="current">
            </el-input>
          </el-form-item>
          <el-form-item label="发票抬头：">
            <el-input placeholder="请输入发票抬头" clearable v-model="queryInfo.invoiceHeader" @clear="getLogList"
              @change="current">
            </el-input>
          </el-form-item>
          <el-form-item label="申请日期：">
            <el-date-picker v-model="value" @blur="pickBlur" :picker-options="pickerOptions" type="daterange"
              range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm" @change="changeTime">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="getLogList">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">重置</el-button>
          </el-form-item>
        </el-form>
      </el-row>
      <!-- table表格区域  :row-class-name="tableRowClassName" :row-class-name="selectedHighlight"-->
      <el-table style="width: 100%" :row-class-name="tableRowClassName" @row-click="clickall" :data="logList" border
        empty-text="暂无数据">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column align="center" label="申请时间" prop="createTime" width="100"></el-table-column>
        <el-table-column align="center" label="商户" prop="adminName"></el-table-column>
        <el-table-column align="center" label="申请用户" prop="nickname" width="140"></el-table-column>
        <el-table-column align="center" label="用户手机号" prop="mobile" width="110"></el-table-column>
        <el-table-column align="center" label="发票抬头" prop="invoiceHeader" width="140"></el-table-column>
        <!-- <el-table-column align="center" label="发票形式" prop="pileCode"></el-table-column> -->
        <el-table-column align="center" label="税号" prop="dutyParagraph" width="200"></el-table-column>
        <el-table-column align="center" label="订单金额（元）" prop="money" width="100">
          <template scope="scope">
            <el-tag>￥{{ jisuan(scope.row.money, 2) }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="实付金额（元）" prop="moneyReal" width="100">
          <template scope="scope">
            <el-tag type="warning">￥{{ jisuan(scope.row.moneyReal, 2) }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="使用电量（度）" prop="totalKwh" width="80">
          <template scope="scope">
            <span>{{ jisuan(scope.row.totalKwh, 4) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="优惠金额（元）" prop="moneyGive" width="100">
          <template scope="scope">
            <el-tag type="success">￥{{ jisuan(scope.row.moneyGive, 2) }}</el-tag type="success">
          </template>
        </el-table-column>
        <el-table-column align="center" label="订单编号" prop="billCode" width="300"></el-table-column>
        <el-table-column align="center" label="结束时间" prop="updateTime" width="100">
          <template scope="scope">
            <span v-if="scope.row.updateTime != null">{{ scope.row.updateTime }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="备注" prop="des" width="120"></el-table-column>
        <el-table-column align="center" label="操作" v-if="caozuoquanxian" width="220">
          <template slot-scope="scope">
            <el-button size="small" :type="scope.row.isInvoice == 0 ? 'primary' : 'success'"
              @click="kaipiao(scope.row)">{{
        scope.row.isInvoice == 0 ? '未开票' : '已开票' }}</el-button>
            <el-button size="small" @click="detailMethods(scope.row)">查看开票订单</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页导航区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[10, 20, 50, 100]" :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-card>
    <el-dialog :visible.sync="dialoagBtn" width="80%" @close="MoneyDialoag2" append-to-body
      :close-on-click-modal="false">
      <div class="Gomoney">
        <h4>充电历史订单</h4>
        <div class="moneylist">
          <dl>
            <p>订单金额（元）</p>
            <p>{{ jisuan(moren.totalBillMoney, 100, 2) }}</p>
          </dl>
          <dl>
            <p>电费金额（元）</p>
            <p>{{ jisuan(moren.totalChargeMoney, 100, 2) }}</p>
          </dl>
          <dl>
            <p>优惠金额（元）</p>
            <p>{{ jisuan(moren.totalDiscountMoney, 100, 2) }}</p>
          </dl>
          <dl>
            <p>服务费金额（元）</p>
            <p>{{ jisuan(moren.totalServiceMoney, 100, 2) }}</p>
          </dl>
          <dl>
            <p>实际收入（元）</p>
            <p>{{ shiji(moren.totalServiceMoney, moren.totalChargeMoney) }}</p>
          </dl>
          <dl>
            <p>充电电量（kwh）</p>
            <p>{{ moren.totalBillKwhs }}</p>
          </dl>
          <dl>
            <p>充电时间（分钟）</p>
            <p>{{ jisuan(moren.totalLengthTime, 60, 0) }}</p>
          </dl>
          <dl>
            <p>充电次数</p>
            <p>{{ moren.totalBillCount }}</p>
          </dl>
        </div>
        <div class="list3" v-for="(item, index) in list3" :key="index">

          <h4
            style="display:flex; flex-direction:row;align-items:center; background:#e5e5e5;padding:10px 0;font-size:16px;">
            <span style="flex:1;padding-left:10px;font-size:14px;">{{ time_all(item) }}</span>
            <span style="flex:2;display:flex;justify-content:center;"> Vin码：{{ item.carvin !== '' ? item.carvin : '--'
              }}</span>
            <span style="flex:3;display:flex;justify-content:center;"> {{ item.createTime !== '' ? item.createTime :
        '--'
              }}</span>
            <span style="flex:1;display:flex;justify-content:center;">ID:{{ item.id }}</span>
            <span style="flex:1;display:flex;justify-content:center;"> 充电方式:{{ item.id }}</span>
            <span style="flex:2;display:flex;justify-content:center;"> 充电桩编码:{{ item.pileCode }}</span>
          </h4>
          <div class="CONTENT" style="border:1px solid gray;margin:20px 0;border-radius:15px;">
            <dl style="display:flex; flex-direction:row;">
              <dt style="display:flex;align-items:center;flex:1;">
                <el-image :src="tupian + 'morentouxiang.png'" style="width: 200px; height: 200px"></el-image>
              </dt>
              <dd style="display:flex; flex-direction:column;flex:9;">
                <!-- 纵向均分两行 -->
                <div class="li1" style="flex:1;display:flex; flex-direction:row;">
                  <!-- 横向，均分5列 -->
                  <div class="hengxiang" style="flex:3;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;">{{ item.stationName }}</span>
                    <span style="flex:1;display:flex;align-items:center;">充电口：{{ item.gunNumName }}</span>
                    <span style="flex:1;display:flex;align-items:center;">充电时长：{{ item.totalChargeTime }}分钟</span>
                  </div>
                  <div class="hengxiang" style="flex:2;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;"></span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:-15%;">开始充电：{{ item.startTime
                      }}</span><span style="flex:1;display:flex;align-items:center;margin-left:-15%;">结束充电：{{
        item.endTime
      }}</span>
                  </div>
                  <div class="hengxiang" style="flex:2;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;"></span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:25%;">开始SOC：{{ item.startSoc
                      }}</span><span style="flex:1;display:flex;align-items:center;margin-left:25%;">结束SOC：{{
        item.endSoc
      }}</span>
                  </div>
                  <!-- span 纵向 -->
                  <div class="hengxiang" style="flex:1;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;">￥{{ jisuan(item.billMoney, 100, 2)
                      }}</span><span style="flex:1;display:flex;align-items:center;">当前订单金额</span>
                  </div>
                  <!-- span 纵向 -->
                  <div class="hengxiang" style="flex:2;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;margin-left:25%;">￥{{ shifu(item) }}</span><span
                      style="flex:1;display:flex;align-items:center;margin-left:25%;">实际订单金额</span>
                  </div>
                </div>
                <div class="li2" style="flex:1;display:flex; flex-direction:row;">
                  <div class="hengxiang" style="flex:3;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;">尖电量：{{ jisuan(item.tipKwh, 100, 4) }}（度）</span>
                    <span style="flex:1;display:flex;align-items:center;">峰电量：{{ jisuan(item.peakKwh, 100, 4) }}（度）</span>
                  </div>
                  <div class="hengxiang" style="flex:2;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;margin-left:-15%;">平电量：{{
        jisuan(item.flatKwh, 100, 4)
      }}（度）</span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:-15%;">谷电量：{{
          jisuan(item.valleyKwh, 100, 4) }}（度）</span>
                  </div>
                  <div class="hengxiang" style="flex:2;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;margin-left:25%;">电费：{{ item.startSoc
                      }}</span><span style="flex:1;display:flex;align-items:center;margin-left:25%;">服务费：{{ item.endSoc
                      }}</span>
                  </div>
                  <!-- span 纵向 -->
                  <div class="hengxiang" style="flex:1;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;">{{ jisuan(item.totalKwh, 100, 4) }}</span><span
                      style="flex:1;display:flex;align-items:center;">已充电量（度）</span>
                  </div>
                  <!-- span 纵向 -->
                  <div class="hengxiang" style="flex:2;display:flex; flex-direction:column;">
                    <span :style="{ color: item.payTypeName == '未支付' ? 'red' : 'gray' }"
                      style="flex:1;display:flex;align-items:center;margin-left:25%;">{{ item.payTypeName }}</span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:25%;color:red"
                      v-if="item.payTypeName == '未支付'">结算</span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:25%;color:orange">推送</span>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <el-pagination @size-change="handleSizeChange3" @current-change="handleCurrentChange3"
          :current-page="indexobj.page" :page-sizes="[8, 20, 50, 100]" :page-size="carInfo.limit"
          layout="total, sizes, prev, pager, next, jumper" :total="total3" background>
        </el-pagination>
      </div>
    </el-dialog>
    <el-dialog title="发票信息" :visible.sync="diaload" width="20%" height="30%" :before-close="handleClose" center>
      <el-form :model="settlementForm" :rules="setformrule" ref="settlementRef" label-width="100px">
        <el-row :span="12">
          <el-form-item label="发票代码：" prop="invoiceCode">
            <el-input v-if="settlementForm.isInvoice == 0" v-model="settlementForm.invoiceCode"></el-input>
            <span v-else style="display: flex;justify-content: flex-end;">{{ settlementForm.invoiceCode }}</span>
          </el-form-item>
        </el-row>
        <el-row :span="12">
          <el-form-item label="发票号码：" prop="invoiceNumber">
            <el-input v-if="settlementForm.isInvoice == 0" v-model="settlementForm.invoiceNumber"></el-input>
            <span v-else style="display: flex;justify-content: flex-end;">{{ settlementForm.invoiceNumber }}</span>
          </el-form-item>
        </el-row>
        <el-row :span="12">
          <el-form-item label="发票链接：" prop="invoiceUrl">
            <el-input v-if="settlementForm.isInvoice == 0" v-model="settlementForm.invoiceUrl"></el-input>
            <!-- v-copy="settlementForm.invoiceUrl" -->
            <span v-else style="display: flex;justify-content: flex-end;">{{
        settlementForm.invoiceUrl == null || settlementForm.invoiceUrl == undefined || settlementForm.invoiceUrl
              == ''
              ? '--' : settlementForm.invoiceUrl
              }}</span>
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" v-if="settlementForm.isInvoice == 1">取 消</el-button>
        <el-button type="primary" @click="editSettlement">{{ settlementForm.isInvoice==1?'已阅':'开票'}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import caozuo from "@/utils/pubilc.js";
export default {
  name: 'logWorld',

  data() {
    return {
      moren: {},
      dialoagBtn: false,
      indexobj: {

      },
      carInfo: {},
      total3: 0,
      list3: [],
      caozuoquanxian: false,
      // code: '',
      // number: '',
      row: null,
      checkindex: [],
      settlementForm: {
        nickname: '',
        invoiceNumber: '',
        invoiceCode: '',
        moneyReal: '',
        money: '',
        moneyGive: '',
        totalKwh: '',
        dutyParagraph: '',
        invoiceHeader: '',
        updateTime: '',
        billCode: '',
        mobile: '',
      },
      pickerMinDate: '',
      pickerOptions: {
        onPick: obj => {
          this.pickerMinDate = new Date(obj.minDate).getTime()
          const start = this.formatDate(obj.minDate, 'start')
          const end = this.formatDate(obj.maxDate, 'end')
          obj.maxDate && (this.value = [start, end])
        },
        disabledDate: time => {
          if (this.pickerMinDate) {
            const oneMonth = 1000 * 3600 * 24 * 31
            const maxTime = this.pickerMinDate + oneMonth
            const mixTime = this.pickerMinDate - oneMonth + 1000 * 3600 * 24
            const tomorrow = new Date(this.formatDate(new Date().getTime() + 1000 * 3600 * 24, 'start'))
            return time.getTime() >= tomorrow || time.getTime() >= maxTime || time.getTime() < mixTime
          }
        }
      },
      // 查询参数对象
      queryInfo: {
        // startTime: null,
        // endTime: null,
        dutyParagraph: null,
        invoiceHeader: null,
        mobile: null,
        billCode: null,
        nickName: null,
        page: 1,
        limit: 8,
        adminId: window.sessionStorage.getItem('adminId'),
      },
      // 商品列表
      logList: [],
      chooseLogList: [],
      // 总数据条数
      total: 0,
      value: ['', ''],
      ids: '',
      diaload: false,
      seIndex: null,
      newIndex: null,
      xin: '',
      dis: false,
      setformrule: {
        invoiceCode: [
          { required: true, message: '发票代码不能为空', trigger: 'blur' }
        ],
        invoiceNumber: [
          { required: true, message: '发票号码不能为空', trigger: 'blur' }
        ]
      }
    }
  },

  computed: {
    shiji() {
      return function (abc, bac) {
        return (((abc * 1) + (bac * 1)) / 100).toFixed(2)
      }
    },
    wait_s() {
      return function (e) {
        return ((e.money - e.giveMoney) / 100).toFixed(2)
      }
    },
    time_all() {
      return function (val) {
        const hr = val.billCode.slice(val.pileCode.length, val.billCode.length)
        return hr
      }
    },
    jisuan() {
      return function (obj, num, fixed) {
        return (obj / num).toFixed(fixed)
      }
    },
    shifu() {
      return function (val) {
        return ((val.billMoney - val.billMoneygive) / 100).toFixed(2)
      }
    }
  },

  created() {
    caozuo(97).then(res => {
      this.caozuoquanxian = res;
    });
    this.getLogList()
  },

  methods: {
    // 历史记录
    handleSizeChange3(newSize) {
      this.indexobj.limit = newSize;
      this.indexobj.page=1;
      this.getIndex()
    },// 历史记录
    handleCurrentChange3(newPage) {
      this.indexobj.page = newPage;
      this.getIndex()
    },
    getIndex() {
      const that = this; that.list3 = [];
      that.$http.get('bill-info/getAllBillInfo', {
        params: that.indexobj
      }).then(res => {
        if (res.data.code !== 0) return;
        that.list3 = res.data.data;
      })
    },
    MoneyDialoag2() {
      this.dialoagBtn = false;
    },
    detailMethods(e) {

    },
    pickBlur() {
      this.pickerMinDate = ''
    },
    chongzhi() {
      this.queryInfo = {
        dutyParagraph: null,
        invoiceHeader: null,
        mobile: null,
        billCode: null,
        nickName: null,
        page: 1,
        limit: 8,
        adminId: window.sessionStorage.getItem('adminId'),
      };
      this.value = [];
      this.pickerMinDate = '';
      this.getLogList()
    },
    handleClose() {
      this.diaload = false;
      this.settlementForm = {};
      // this.logList.forEach(item => {
      //   if (item.id == this.row) return item.isInvoice = 0;
      // })
    },
    // 开票按钮, b
    // console.log(a, b)
    // this.seIndex = b;
    // console.log(res.data, '255');
    async kaipiao(a) {
      this.row = a.id;
      const { data: res } = await this.$http.get('invoice_log/getInvoiceLogById/' + a.id)
      if (res.code !== 0) return;
      this.settlementForm = res.data;
      this.diaload = true;
    },
    // 确定开票  // this.logList.forEach(item => {
    //   if (item.id == this.row) {
    //     item.title = '已开票';
    //     item.isInvoice = '1';
    //     this.checkindex.push({ isInvoice: this.seIndex, id: this.row });
    //   }
    // })
    async editSettlement() {
      if (this.settlementForm.isInvoice == 1) return this.diaload = false;
      this.settlementForm.isInvoice = 1;
      if (this.settlementForm.invoiceCode == '' || this.settlementForm.invoiceNumber == '' || this.settlementForm.invoiceCode == null || this.settlementForm.invoiceNumber == null) return this.$message.error('发票代码、发票号码不得为空');
      const { data: res } = await this.$http.post('invoice_log/updateInvoiceLogById', this.settlementForm)
      this.diaload = false;
      this.$message({
        message: res.code == 0 ? '开票成功' : '开票失败',
        type: res.code == 0 ? 'success' : 'error'
      }); this.settlementForm = {};
      if (res.code !== 0) return;
      this.getLogList();
    },
    formatDate(datetime, type) {
      const time = new Date(datetime)
      const year = time.getFullYear()
      const month = (time.getMonth() + 1).toString().padStart(2, '0')
      const date = (time.getDate()).toString().padStart(2, '0')
      return type === 'start' ? year + '-' + month + '-' + date + ' ' + '00' + ':' + '00' + ':' + '00' : year + '-' + month + '-' + date + ' ' + '23' + ':' + '59' + ':' + '59'
    },
    getDate() {
      const start = new Date()
      this.value[0] = this.formatDate(start.getTime() - 1000 * 3600 * 24 * 30, 'start')
      this.value[1] = this.formatDate(start.getTime(), 'end')
    },
    // 行内样式
    tableRowClassName({ row, rowIndex }) {
      if (this.checkindex.length > 0) {
        for (let index = 0; index < this.checkindex.length; index++) {
          const element = this.checkindex[index];
          if (row.isInvoice != element.isInvoice || row.id == element.id) {
            return 'warning-row';
          } else {
            return '';
          }
        }
      } else {
        if (row.isInvoice == 1) {
          this.logList.forEach(item => {
            if (item.id == row.id) {
              item.title = '已开票'
            }
          })
          return 'warning-row';
        }
      }
    },
    clickall(row) {
      // console.log(row,'227')
    },
    current() {
      this.queryInfo.page = 1;
    },
    changeTime(value) {
      this.queryInfo.page = 1;
      if (value != null) {
        this.queryInfo.startTime = value[0];
        this.queryInfo.endTime = value[1];
        if (this.queryInfo.startTime == this.queryInfo.endTime) {
          this.$message.error('起始时间不得为同一天！');
          this.queryInfo.startTime = ''
          this.queryInfo.endTime = ''
        }
      } else {
        this.pickerMinDate = '';
      }
    },
    // chongzhi() {
    //   this.queryInfo.pileCode = '';
    //   this.pickerMinDate='',
    //   this.value = [];
    //   this.getLogList()
    // },
    // 根据分页获取对应的日志列表
    async getLogList() {
      const { data: res } = await this.$http.get('invoice_log/getAllInvoiceLog', { params: this.queryInfo })
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      // this.$message.success('获取日志列表成功！')
      console.log(res)
      this.logList = res.data
      this.logList.forEach(item => {
        item.title = '未开票'
      })
      this.total = res.count
    },
    // 监听pageSize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.queryInfo.page=1;
      this.getLogList()
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getLogList()
    },
    // 批量删除的复选框改变事件
    // handleSelectionChange(value) {
    //   if (value.length > 0) {
    //     this.ids = ''
    //     value.forEach(item => {
    //       this.ids += item.id + ','
    //     })
    //     this.ids = this.ids.substring(0, this.ids.length - 1)
    //   } else {
    //     return false
    //   }
    // },
    // 删除发票
    async removeById(row) {
      const confirmResult = await this.$confirm('此操作将永久删除该发票, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除！')
      }
      if (row.id === null || row.id === '' || row.id === undefined) {
        const { data: res } = await this.$http.post('operationLog/delAdminOperationLogById/' + this.ids)
        if (res.code !== 0) {
          return this.$message.error('删除发票失败！')
        }
        this.$message.success('删除发票成功！')
        this.getLogList()
      } else {
        const { data: res } = await this.$http.post('operationLog/delAdminOperationLogById/' + row.id.toString())
        if (res.code !== 0) {
          return this.$message.error('删除发票失败！')
        }
        this.$message.success('删除发票成功！')
        this.getLogList()
      }
    }
  }
};
</script>

<style></style>