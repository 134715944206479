<template>
  <div>
    <el-form :model="elePileForm" :rules="elePileRules" ref="elePileRef" label-width="95px">
        <h4 style="display: flex;justify-content: center;align-items: center;height: 30px;font-size: 20px;">{{ title }}</h4>
      <el-form-item label="电桩编号" prop="pileCode">
        <el-input v-model="elePileForm.pileCode" :disabled="title === '编辑电桩'" placeholder="请输入电桩编号" style="width: 100%;"
          @change="changing"></el-input>
      </el-form-item>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="充电枪">
            <el-select v-model="elePileForm.gunNumber" :disabled="title === '编辑电桩'" placeholder="请选择充电枪" filterable>
              <el-option v-for="  item   in   gunOptions  " :key="item"
                :label="item == 1 ? '单枪' : item == 2 ? '双枪' : ''" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="功率Kw" prop="pilePower">
            <el-select v-model="elePileForm.pilePower" placeholder="请选择电桩功率" filterable>
              <el-option v-for="  item   in   chapOptions  " :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属电站" prop="stationId">
            <el-select v-model="elePileForm.stationId" placeholder="请选择所属电站" @change="change_station" filterable>
              <el-option v-for=" item in station_list " :key="item.stationId" :label="item.stationName"
                :value="item.stationId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="充电类型" prop="pileClassType">
            <el-select v-model="elePileForm.pileClassType" placeholder="请选择充电类型" filterable>
              <el-option v-for=" item   in   chatOptions  " :key="item"
                :label="item == 0 ? '慢充' : item == 1 ? '快充' : item == 2 ? '低速' : ''" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="电桩类型" prop="pileType">
            <el-select v-model="elePileForm.pileType" placeholder="请选择电桩类型" filterable>
              <el-option v-for="item   in   devtOptions  " :key="item"
                :label="item == 0 ? '锐创直流桩' : item == 1 ? '锐创交流桩' : item == 2 ? '无疆(二代)交流桩' : item == 3 ? '光法(一代)直流桩' : item == 4 ? '光法(一代)交流桩' : item == 5 ? '锐创低速桩' : ''"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="通讯方式" prop="netType">
            <el-select v-model="elePileForm.netType" placeholder="请选择通讯方式" filterable>
              <el-option v-for="  item   in   comTypeOptions  " :key="item"
                :label="item == 0 ? '4G路由' : item == 1 ? '以太网' : item == 2 ? 'DTU' : ''" :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="限定充电" prop="limitType">
            <el-select v-model="elePileForm.limitType" placeholder="请选择通讯方式" filterable>
              <el-option v-for="  item   in   limitTypeOptions  " :key="item"
                :label="item == 0 ? '全部允许' : item == 1 ? '禁止小程序用户' : item == 2 ? '禁止电卡用户' : item == 4 ? '禁止小程序和电卡用户' : item == 3 ? '禁止Vin用户' : item == 5 ? '禁止小程序和Vin用户' : item == 6 ? '禁止电卡和Vin用户' : ''"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="设备简述" prop="pileSketch">
            <el-input v-model="elePileForm.pileSketch" placeholder="请输入设备简述"></el-input>
          </el-form-item>
        </el-col>
        <el-col style="display: flex;justify-content: center;">
          <el-button type="primary" @click="editElePile">确 定</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
export default {
  name: '',
  mixins: [],

  components: {

  },

  props: {

  },

  data() {
    // 电桩编号的校验规则
    const checkpileCode = (rule, value, callback) => {
      const reg = /^[a-zA-Z0-9]+$/
      if (reg.test(value)) {
        return callback()
      }
      callback(new Error('电桩编号由数字、字母或者或者其中一种组成'))
    }
    return {
      limitTypeOptions: [0, 1, 2, 4, 3, 5, 6],
      station_list: [],
      title: '',
      // 枪号数据
      gunOptions: [1, 2],
      // 功率数据
      chapOptions: [
        {
          value: 0,
          label: 0
        },
        {
          value: 7,
          label: 7
        },
        {
          value: 11,
          label: 11
        },
        {
          value: 14,
          label: 14
        },
        {
          value: 20,
          label: 20
        },
        {
          value: 22,
          label: 22
        },
        {
          value: 30,
          label: 30
        },
        {
          value: 40,
          label: 40
        },
        {
          value: 60,
          label: 60
        },
        {
          value: 80,
          label: 80
        },
        {
          value: 120,
          label: 120
        },
        {
          value: 140,
          label: 140
        },
        {
          value: 160,
          label: 160
        },
        {
          value: 180,
          label: 180
        },
        {
          value: 200,
          label: 200
        },
        {
          value: 240,
          label: 240
        },
        {
          value: 260,
          label: 260
        },
        {
          value: 300,
          label: 300
        },
        {
          value: 320,
          label: 320
        },
        {
          value: 360,
          label: 360
        },
        {
          value: 400,
          label: 400
        },
        {
          value: 480,
          label: 480
        },
        {
          value: 600,
          label: 600
        }
      ],
      // 充电类型
      chatOptions: ['0', '1', '2'],
      // 电桩类型
      devtOptions: [0, 1, 2, 3, 4, 5],
      stationName: [],
      comTypeOptions: [0, 1, 2],
      // 车连接状态
      carOptions: [
        {
          label: '断开',
          value: 0
        }, {
          label: '半连接',
          value: 1
        }, {
          label: '连接',
          value: 2
        }
      ],
      elePileRules: {
        pileCode: [
          { required: true, message: '电桩编号不能为空', trigger: 'blur' },
          { validator: checkpileCode, trigger: 'blur' }
        ],
        stationId: [
          { required: true, message: '请至少选择一项', trigger: 'change' }
        ],
        gunNumber: [
          { required: true, message: '请至少选择一项', trigger: 'change' }
        ],
        pilePower: [
          { required: true, message: '请至少选择一项', trigger: 'change' }
        ],
        pileClassType: [
          { required: true, message: '请至少选择一项', trigger: 'change' }
        ],
        pileType: [
          { required: true, message: '请至少选择一项', trigger: 'change' }
        ],
        netType: [
          { required: true, message: '请至少选择一项', trigger: 'change' }
        ],
        pileSketch: [
          { required: false, message: '请至少选择一项', trigger: 'change' }
        ],
        limitType: [
          { required: true, message: '请至少选择一项', trigger: 'change' }
        ]
      },
      elePileForm: {
        id: null,
        pileCode: null,
        stationId: null,
        gunNumber: null,
        pilePower: null,
        pileClassType: null,
        pileType: null,
        netType: null,
        pileSketch: null,
        pileState: null,
        limitType: null,
        pileGl: ''
      },
    }
  },

  computed: {

  },

  watch: {

  },

  created() {
    const that = this;
    that.$http.get('station-info/getAllStationInfoName', {
      params: {
        roleId: window.sessionStorage.getItem('roleId'),
        stationIds: window.sessionStorage.getItem('stationIds'),
        stationMode: '0,1'
      }
    }).then(resb => {
      resb.data.data.forEach((item) => {
        that.station_list.push({
          stationId: item.id,
          stationName: item.stationName
        });
      })
    })
  },
  mounted() {
    this.title = this.$route.params.id == null || this.$route.params.id == undefined || this.$route.params.id == ''?'添加电桩':'编辑电桩';
    if (this.$route.params.id == null || this.$route.params.id == undefined || this.$route.params.id == '') {
      this.elePileForm.limitType = this.limitTypeOptions[0];
    } else {
      this.$http.get('pileInfo/getPileInfoById/' + this.$route.params.id).then(resb => {
        if (resb.data.code !== 0) {
          this.$message.error(resb.data.msg)
        } else {
          this.elePileForm = resb.data.data;
          if (resb.data.data.stationName == null) {
            this.elePileForm.stationName = '暂无电站名称'
          } else {
            this.elePileForm.stationName = resb.data.data.stationName
          }
        }
      })
    }
  },
  methods: {
    change_station(e) {
      this.queryInfo.stationIds = e;
    },
    editElePile() {
      this.$refs.elePileRef.validate(async valid => {
        if (!valid) return;
        if (this.elePileForm.pileCode == '') return this.$message.error('电桩编号为必传项');
        this.elePileForm.adminId = window.sessionStorage.getItem('adminId') * 1;
        if (this.title === '添加电桩') {
          const { data: res } = await this.$http.post('pileInfo/addPileInfo', this.elePileForm)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('添加电桩成功！')
          this.$store.commit('del', window.sessionStorage.getItem('activePath'));
          this.$store.commit('commitMethods', '');
          this.$router.push('/ElectricPile')
        } else {
          this.elePileForm.state === true ? this.elePileForm.state === 0 : this.elePileForm.state === 1
          const { data: res } = await this.$http.post('pileInfo/updPileInfo', this.elePileForm)
          if (res.code !== 0) return this.$message.error(res.msg)
          this.$message.success('编辑电桩成功！')
          this.$store.commit('del', window.sessionStorage.getItem('activePath'));
          this.$store.commit('commitMethods', '');
          this.$router.push('/ElectricPile')
        }
      })
    },
    elePileDialogClosed() {
      this.shows = false
      this.$refs.elePileRef.resetFields()
    },
    changing() {
      const that = this;
      that.$http.get(`pileInfo/getPileInfoCodeById/${that.elePileForm.pileCode}`).then(res => {
        if (res.data.code !== 0) return;
        that.$message.error('电桩编号已存在');
        that.elePileForm.pileCode = '';
      })
    },
    qx1() {
      this.$refs.elePileRef.resetFields();
      this.elePileVisible = false;
      this.elePileForm = {}
    },
  }
};
</script>

<style lang='less' scoped>
div{
  width:100%;
  height:100%;

  .el-form{
    padding:0 20%;
  }
}
</style>