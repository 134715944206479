<template>
  <div class="allType">
    <div class="top">
      <h4>流量中心</h4>
      <li>
        <span v-for="(item, index) in list" :key="index" @click="checkMethods(index)" :style="{
          'border-bottom': checkedIndex == index ? '1px solid blue' : 'none',
          color: checkedIndex == index ? 'blue' : '#000'
        }">
          {{ item }}
        </span>
      </li>
    </div>
    <div class="center">
      <!-- <h4>流量平台对接流程</h4> -->
      <li>
        <span v-for="(item, index) in deslist" :key="index">{{ item }}</span>
      </li>
    </div>
    <div class="bottom" v-if="allmap.length < 5">
      <div class="content">
        <li v-for="(item, index) in maplist" :key="index">
          <dl>
            <dt>
              <el-image style="width: 120px; height: 120px" :src="item.imageUrl" fit="scale-down">
              </el-image>
            </dt>
            <dd>
              <span>
                {{ item.name }}
              </span>
            </dd>
          </dl>
          <div class="p">
            <button @click="startMethods(item)">申请开通</button>
          </div>
        </li>
      </div>
    </div>
    <div class="bottom1" v-else>
      <div class="content" v-for="(item, index) in maplist" :key="index">
        <li v-for="(items, indexs) in item" :key="indexs">
          <dl>
            <dt>
              <el-image style="width: 120px; height: 120px" :src="item.imageUrl" fit="scale-down">
              </el-image>
            </dt>
            <dd>
              <span>
                {{ items.name }}
              </span>
            </dd>
          </dl>
          <div class="p">
            <button @click="startMethods(items)">申请开通</button>
          </div>
        </li>
      </div>
    </div>
    <el-dialog title="申请开通" :visible.sync="dialoag" width="40%" @close="MoneyDialoag" append-to-body
      :close-on-click-modal="false">
      <el-form :model="formobj" ref="editFormRef" label-width="140px">
        <el-form-item label="开通场站：" required>
          <!-- multiple -->
          <el-select v-model="formobj.stationIds" placeholder="请选择你的开通场站" filterable @change="stationMethods">
            <el-option v-for="item in Stationlist" :key="item.id" :label="item.stationName" :value="item.id">
            </el-option>
          </el-select>
          <span v-if="defaultType" style="margin-left: 10px;color: red;font-size: 18px;">已开通</span>
        </el-form-item>
        <el-form-item label="直流：" v-if="!defaultType && formobj.stationIds !== '' && objValue.fastCount > 0">
          <span style="padding-right: 10px;">数量：{{ objValue.fastCount
            }}</span><span style="padding-right: 10px;">金额：{{ objValue.fastMoney }}元</span><span
            style="padding-right: 10px;">总价：{{ objValue.fastCountMoney }}元</span>
        </el-form-item>
        <el-form-item label="交流：" v-if="!defaultType && formobj.stationIds !== '' && objValue.slowCount > 0">
          <span style="padding-right: 10px;">数量：{{ objValue.slowCount
            }}</span><span style="padding-right: 10px;">金额：{{ objValue.slowMoney }}元</span><span
            style="padding-right: 10px;">总价：{{ objValue.slowCountMoney }}元</span>
        </el-form-item>
        <el-form-item label="开通时间类型：" required v-if="!defaultType">
          <el-select v-model="formobj.TimeType" placeholder="请选择你的开通时间" filterable @change="titleTypeMethods">
            <el-option v-for="item in TimelistType" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开通时间：" required v-if="!defaultType">
          <el-input-number size="mini" v-model="formobj.Time" @change="timeCurrent" :precision="0" :step="1"
            :min="1"></el-input-number>
          <span style="margin-left: 10px;">{{ formobj.TimeType == 0 ? '年' : '月' }}</span>
        </el-form-item>
        <el-form-item label="开通金额：" v-if="!defaultType">
          <el-input v-model="formobj.checkMoney" disabled v-if="typing"></el-input>
          <span v-else>免费</span>
        </el-form-item>
        <el-form-item label="优惠码：" v-if="!defaultType">
          <el-input v-model="formobj.Freecode" @change="currentMethods"></el-input>
        </el-form-item>
        <el-form-item label="" v-if="currentType">
          <p style="color:red">该订单使用优惠码后，减免{{ moneys }}元</p>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
                <el-button @click="quxiao">取消</el-button>
                <el-button v-debounce="okMethods" type="primary" :disabled="defaultType">确定</el-button>
              </div>
    </el-dialog>
    <el-dialog title="扫码付款" :visible.sync="dialoag2" width="60%" @close="MoneyDialoag2" append-to-body
      :close-on-click-modal="false">
      <div class="list" style="display: flex;flex-direction: column;">
        <p style="display: flex;flex-direction: column;">
          <span style="margin-left: 45%;color: red;font-size: 18px;">付款金额：{{ times(formobj.checkMoney,moneys) }}元</span>
          <VueQr :text='imageurls' style="height: 22vh;width: 12vw;margin-left: 40%;"></VueQr>
        </p>
        <!-- -->
        <div class="footer" style="display: flex;flex-direction: row;justify-content: center;">
          <el-button @click="MoneyDialoag2">取消</el-button>
          <!-- {{ titleBtn }} -->
          <el-button type="primary" @click="Moneychange">支付成功，请点击</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import VueQr from 'vue-qr';
export default {
  name: 'Flowmanagement',
  components: {
    VueQr
  },
  data() {
    return {
      moneys: 0,
      typings: '',
      objValue: [],
      Stationlist: [],
      allmap: [],
      money: 0,
      listobj: {
        year: 1,
        money: 2000
      },
      dialoag2: false,
      TimelistType: [
        {
          id: 0,
          name: '年'
        },
        {
          id: 1,
          name: '月'
        }
      ],
      obj: {},
      out_trade_no: '',
      imageurls: '',
      formobj: {
        Freecode: '',
        money: 0,
        Time: 1,
        TimeType: 0,
        drainageName: '',
        stationIds: '',
        checkMoney: 0,
        checkMoneylow: 0,
        FastMoney: 0,
      },
      defaultType: false,
      typing: false,
      dialoag: false,
      checkedIndex: 0,
      list: [
        '地图平台',
        '聚合平台'
      ],
      pushType: 1,
      title: '',
      deslist: [],
      maplist: [],
      titleBtn: '支付',
      tupian: this.$imgUrls,
      currentType: false
    }
  },
  computed: {
    allsum: function () {
      return (abc, bac) => {
        return abc * 1 + bac * 1
      }
    },
    times:function(){
      return (abc,bac)=>{
        return abc - bac
      }
    }
  },
  created() {
    const that = this;
    that.$http.get(`sysConfig/getSysConfigById/${that.checkedIndex == 0 ? 35 : 36}`).then(res => {
      if (res.data.code !== 0) return;
      that.allmap = JSON.parse(res.data.data.valueName);
      that.deslist = res.data.data.des.split('<br>');
      if (that.allmap.length > 4) return that.clearMethods();
      // if (that.checkedIndex == 0) {
      that.allmap.forEach(item => {
        that.maplist.push({
          imageUrl: item.paramKey.split('-')[0] == '高德地图' ? require('../../assets/images/img/Nice.jpg') : item.paramKey.split('-')[0] == '百度地图' ? require('../../assets/images/img/baidu.jpg') : item.paramKey.split('-')[0] == '腾讯地图' ? require('../../assets/images/img/qq.jpeg') : '',
          name: item.paramKey.split('-')[0],
          paramValue: item.paramValue
        })
      })
    });
    that.$http.get('station-info/getAllStationInfoName', {
      params: {
        roleId: window.sessionStorage.getItem('roleId'),
        stationMode: '0,1',
        stationIds: window.sessionStorage.getItem('stationIds')
      }
    }).then(res => {
      that.Stationlist = res.data.code !== 0 ? [] : res.data.data;
    })
  },
  methods: {
    timeCurrent() {
      this.titleTypeMethods()
    },
    clearMethods() {
      const that = this;
      that.maplist = that.defaultMethods();
      that.maplist.forEach(item => {
        item.forEach(items => {
          items.imageUrl = items.paramKey.split('-')[0] == '高德地图' ? require('../../assets/images/img/Nice.jpg') : items.paramKey.split('-')[0] == '百度地图' ? require('../../assets/images/img/baidu.jpg') : items.paramKey.split('-')[0] == '腾讯地图' ? require('../../assets/images/img/qq.jpeg') : items.paramKey.split('-')[0] == '新电途' ? require('../../assets/images/img/newEleric.png') : items.paramKey.split('-')[0] == '中原智充' ? require('../../assets/images/img/look.png') : items.paramKey.split('-')[0] == '小桔充电' ? require('../../assets/images/img/orange.png') : items.paramKey.split('-')[0] == '快电特充' ? require('../../assets/images/img/quickly.png') : '';
          items.name = items.paramKey.split('-')[0];
          items.paramValue = items.paramValue
        })
      });
    },
    // 申请开通
    okMethods() {
      const that = this;
      if (that.formobj.stationIds == '') return that.$message.error('请选择你的开通场站');
      if(that.defaultType)return that.$message.error('场站已申请，请切换');
      if (that.moneys == that.formobj.checkMoney) {
        // 直接开通0元
        that.$http.post(
          'drainage/free',
          {
              stationIds: that.formobj.stationIds.toString(),
              drainageName: that.formobj.drainageName,
              code: that.formobj.Freecode,
              adminId: window.sessionStorage.getItem('adminId')*1,
              openingTime: that.obj.openingTime,
              type: that.obj.type,
              pushType:that.formobj.drainageName == '高德地图' ? 1 : that.formobj.drainageName == '河南省平台' ? 2 : that.formobj.drainageName == '新电途' ? 3 : that.formobj.drainageName == '小桔充电' ? 4 : 5
          }
        ).then(res => {
          if (res.data.code !== 0) return;
          that.$message.success('引流开通成功');
          that.dialoag = false;
          that.dialoag2 = false;
        })
      } else {
        that.$http.post('drainage/createNative', {
          money: that.moneys==0?that.formobj.checkMoney:that.formobj.checkMoney-that.moneys,
          stationIds: that.formobj.stationIds,
          drainageName: that.formobj.drainageName,
          code: that.formobj.Freecode,
          adminId: window.sessionStorage.getItem('adminId') * 1,
          drainageId: 0,
          pushType: that.formobj.drainageName == '高德地图' ? 1 : that.formobj.drainageName == '河南省平台' ? 2 : that.formobj.drainageName == '新电途' ? 3 : that.formobj.drainageName == '小桔充电' ? 4 : 5,
          openingTime: that.formobj.TimeType == 0 ? that.formobj.Time * 12 : that.formobj.Time,
          type: that.checkedIndex
        }).then(res => {
          that.out_trade_no = res.data.out_trade_no;
          that.imageurls = res.data.QrCode;
          that.dialoag = false;
          that.dialoag2 = true;
        })
      }
    },
    quxiao() {
      this.dialoag = false;
      this.formobj.Time = 1;
      this.formobj.Freecode = '';
      this.formobj.money = 0;
      this.money = 0;
    },
    stationMethods() {
      const that = this;
      that.defaultType = false;
      that.$http.get(`drainage-station-info/getAllPileCountByStationId/${that.formobj.stationIds}/${that.pushType}`).then(ress => {
        that.defaultType = ress.data.data.orNot == 1 ? true : false;
        that.objValue = ress.data.data;
        that.publicMethods();
      });
    },
    currentMethods() {
      const that = this;
      that.$http.get(`drainage-preferential-log/getAllDrainagePreferentialLog`, {
        params: {
          stationId: that.formobj.stationIds,
          adminId: window.sessionStorage.getItem('adminId'),
          code: that.formobj.Freecode,
          page: 1,
          limit: 10
        }
      }).then(res => {
        that.currentType = res.data.code == 0 ? true : false;
        if (res.data.code !== 0) {
          that.$message.error('减免优惠码错误，请核对您的引流场站信息')
        } else {
          that.obj = res.data.data[0];
          that.moneys = res.data.data[0].openingTime * that.objValue.slowCountMoney + res.data.data[0].openingTime * that.objValue.fastCountMoney;
        }
      })
    },
    titleTypeMethods() {
      this.publicMethods();
    },
    // 開通時間判斷開通總金額
    publicMethods() {
      if (this.formobj.TimeType == 0) {
        if (this.objValue.slowCountMoney > 0) {
          this.formobj.checkMoneylow = this.formobj.Time * 12 * this.objValue.slowCountMoney;
        } else {
          this.formobj.checkMoneylow = 0;
        }
        if (this.objValue.fastCountMoney > 0) {
          this.formobj.checkMoneyFast = this.formobj.Time * 12 * this.objValue.fastCountMoney;
        } else {
          this.formobj.checkMoneyFast = 0;
        }
        this.formobj.checkMoney = this.formobj.checkMoneylow * 1 + this.formobj.checkMoneyFast * 1;
      } else {
        if (this.objValue.slowCountMoney > 0) {
          this.formobj.checkMoneylow = this.formobj.Time * this.objValue.slowCountMoney;
        } else {
          this.formobj.checkMoneylow = 0;
        }
        if (this.objValue.fastCountMoney > 0) {
          this.formobj.checkMoneyFast = this.formobj.Time * this.objValue.fastCountMoney;
        } else {
          this.formobj.checkMoneyFast = 0;
        }
        this.formobj.checkMoney = this.formobj.checkMoneylow * 1 + this.formobj.checkMoneyFast * 1;
      }
      if (this.formobj.Freecode !== '') {
        this.currentMethods()
      }
    },
    Moneychange() {
      const that = this;
      that.$http.post(`drainage/queryPayStatus?out_trade_no=` + that.out_trade_no).then(res => {
        if (res.data.msg !== '支付成功') return that.$message.error('您还未支付，请先支付');
        that.$message.success('支付成功');
        that.dialoag2 = false;
      })
    },
    MoneyDialoag2() {
      this.dialoag2 = false;
    },
    MoneyDialoag() {

    },
    startMethods(e) {
      const that = this;
      that.money = e.paramValue;
      that.pushType = e.name == '高德地图' ? 1 : e.name == '河南省平台' ? 2 : e.name == '新电途' ? 3 : e.name == '小桔充电' ? 4 : e.name == '高德地图' ? 4 : 5;
      if (that.formobj.TimeType == 0) {
        that.formobj.money = that.formobj.Time * 12 * that.money;
      } else {
        that.formobj.money = that.formobj.Time * 12 * that.money;
      }
      that.publicMethods(that.formobj.Time, that.formobj.TimeType);
      that.formobj.drainageName = e.name;
      if (e.paramValue == '敬请期待') return that.$message.error(e.paramValue);
      that.typing = e.paramValue !== 0 ? true : false;
      that.$confirm('是否需要开通已选择的服务?', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 开通服务
        that.dialoag = true;
      }).catch(() => {
        // 取消开通服务
        that.$confirm('取消后，您申请的服务将不生效，是否仍要操作?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // 确定取消
        })
      })
    },
    checkMethods(e) {
      const that = this;
      that.checkedIndex = e;
      that.maplist = [];
      that.$http.get(`sysConfig/getSysConfigById/${that.checkedIndex == 0 ? 35 : 36}`).then(res => {
        if (res.data.code !== 0) return;
        that.allmap = JSON.parse(res.data.data.valueName);
        that.deslist = res.data.data.des.split('<br>');
        if (that.allmap.length > 4) return that.clearMethods();
        that.allmap.forEach(item => {
          that.maplist.push({
            imageUrl: item.paramKey.split('-')[0] == '高德地图' ? require('../../assets/images/img/Nice.jpg') : item.paramKey.split('-')[0] == '百度地图' ? require('../../assets/images/img/baidu.jpg') : item.paramKey.split('-')[0] == '腾讯地图' ? require('../../assets/images/img/qq.jpeg') : item.paramKey.split('-')[0] == '新电途' ? require('../../assets/images/img/newEleric.png') : item.paramKey.split('-')[0] == '中原智充' ? require('../../assets/images/img/look.png') : item.paramKey.split('-')[0] == '小桔充电' ? require('../../assets/images/img/orange.png') : item.paramKey.split('-')[0] == '快电特充' ? require('../../assets/images/img/quickly.png') : '',
            name: item.paramKey.split('-')[0],
            paramValue: item.paramValue
          })
        })
      })
    },
    defaultMethods() {
      let result = [];
      for (let i = 0; i < this.allmap.length; i += 4) {
        result.push(this.allmap.slice(i, i + 4));
      }
      return result;
    }
  }
};
</script>

<style lang='less' scoped>
.allType {
  width: 100%;
  height: 100%;

  list-style: none;

  .top {
    margin: 0 2% 0 1%;
    height: 120px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid gray;

    h4,
    li {
      flex: 1;
      display: flex;
      flex-direction: row;
      font-size: 20px;

      span {
        padding-bottom: 5px;
      }

      span:nth-child(1) {
        margin-right: 100px;

      }
    }

    li {
      font-size: 18px;
    }
  }

  .center {
    margin: 0 2% 0 1%;
    margin-top: 20px;
    background-color: #f8f2f2;
    // opacity: .5;
    width: 97%;
    display: flex;
    flex-direction: column;

    li {
      font-size: 18px;
      display: flex;
      flex-direction: column;

      span {
        line-height: 40px;
        display: flex;
        align-items: center;
        text-indent: 2em;
      }

      span:first-child {
        font-size: 20px;
        text-indent: .5em;
        font-weight: bolder;
        border-bottom: 1px solid #eee;
      }
    }
  }

  .bottom {
    margin: 50px 20px;
    display: flex;
    flex-direction: column;

    .content {
      height: 200px;
      width: 100%;
      display: flex;
      flex-direction: row;

      li {
        flex: 1;
        display: flex;
        flex-direction: column;
        border: 1px solid #c5c5c5;
        margin-right: 100px;
        border-radius: 15px;

        dl {
          flex: 7;
          display: flex;
          flex-direction: row;
          font-size: 18px;

          dt,
          dd {
            display: flex;
            align-items: center;
          }

          dt {
            flex: 3;
            justify-content: center;
          }

          dd {
            flex: 7;
          }
        }

        .p {
          flex: 3;
          display: flex;
          justify-content: center;
          align-items: center;
          border-top: 1px solid #c5c5c5;

          button {
            height: 30px;
            width: 100px;
            display: flex;
            font-size: 16px;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
            outline: none;
            background-color: #fff;
            color: blue;
            border: 1px solid blue;
          }
        }
      }
    }
  }

  .bottom1 {
    margin: 10px 20px;
    display: flex;
    flex-direction: column;

    .content {
      display: flex;
      flex-direction: row;
      margin-bottom: 40px;

      li {
        width: 450px;
        display: flex;
        flex-direction: column;
        border: 1px solid #c5c5c5;
        margin-right: 1%;
        border-radius: 15px;
        height: 200px;

        dl {
          flex: 7;
          display: flex;
          flex-direction: row;
          font-size: 18px;

          dt,
          dd {
            display: flex;
            align-items: center;
          }
        }

        .p {
          flex: 3;
          display: flex;
          justify-content: center;
          align-items: center;
          border-top: 1px solid #c5c5c5;

          button {
            height: 30px;
            width: 100px;
            display: flex;
            font-size: 16px;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
            outline: none;
            background-color: #fff;
            color: blue;
            border: 1px solid blue;
          }
        }
      }

      li:nth-child(4) {
        margin-right: 0;
      }
    }
  }
}
</style>
