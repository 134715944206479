<template>
  <div class="default">
    <div class="left">
      <div class="left1">
        <h4><span>今日收入概览</span><span></span></h4>
        <li><span>今日充电收入</span><span class="ts">{{ jisuan(newobj.todayChargingRevenueMoney, 100, 2)
            }}</span><span>元</span></li>
        <li><span>今日占位费</span><span class="ts">{{ jisuan(newobj.todaySpaceOccupancyFee, 100, 2) }}</span><span>元</span>
        </li>
        <li><span>今日充值金额</span><span class="ts">{{ jisuan(newobj.todayRechargeMoney, 100, 2) }}</span><span>元</span>
        </li>
        <li><span>今日退款金额</span><span class="ts">{{ jisuan(newobj.todayRefundMoney, 100, 2) }}</span><span>元</span></li>
        <li><span>今日充电电量</span><span class="ts">{{ jisuan(newobj.todayElectricityLevel, 100, 2) }}</span><span>元</span>
        </li>
      </div>
      <div class="left2">
        <h4><span>本月数据统计</span><span></span></h4>
        <li><span>本月充电收入</span><span class="ts">{{ jisuan(newobj.monthTodayChargingRevenue, 100, 2)
            }}</span><span>元</span>
        </li>
        <li><span>本月占位费</span><span class="ts">{{ jisuan(newobj.monthSpaceOccupancyFee, 100, 2) }}</span><span>元</span>
        </li>
        <li><span>本月充值金额</span><span class="ts">{{ jisuan(newobj.monthTotalRecharge, 100, 2) }}</span><span>元</span>
        </li>
        <li><span>本月退款金额</span><span class="ts">{{ jisuan(newobj.monthRefund, 100, 2) }}</span><span>元</span>
        </li>
        <li><span>本月充电电量</span><span class="ts">{{ jisuan(newobj.monthElectricityLevel, 100, 2) }}</span><span>元</span>
        </li>
      </div>
      <div class="left3">
        <h4><span>历史累计数据</span><span></span></h4>
        <div id="main"></div>
      </div>
    </div>
    <div class="center">
      <div class="center1">
        <h4>
          <span>充电收入总览</span>
          <div class="tsleibie">
            <el-select v-model="sousuodianzhan" placeholder="请选择电站" @change="change_vale" filterable>
              <el-option v-for="item in powerStationList" :key="item.id" :label="item.stationName" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <span>{{ time }}</span>
        </h4>
        <div class="li"><span>{{ jisuan(newobj.allMoney, 100, 2) }}</span><span>历史收入总和（元）</span></div>
      </div>
      <div class="center2">
        <li><span>累计收入</span><span>{{ jisuan(newobj.allServiceMoney, 100, 2) }}</span></li>
        <li><span>累计占位费</span><span>{{ jisuan(newobj.allSpaceOccupancyFee, 100, 2) }}</span></li>
        <li><span>累计电量</span><span>{{ jisuan(newobj.allElectricityLevel, 100, 2) }}</span></li>
        <li><span>累计充值</span><span>{{ jisuan(newobj.allRechargeMoney, 100, 2) }}</span></li>
      </div>
      <div class="center3">
        <h4><span>充电运营近三月数据分析</span><span></span></h4>
        <div id="content2"></div>
      </div>
    </div>
    <div class="right">
      <div class="right1">
        <li>
          <dl>
            <dt><el-image :src="pichers" fit="full" style="width: 10px;
            height: 20px;"></el-image> <span>充电站数量</span></dt>
            <dd><span>{{ newobj.numberOfStations }}</span></dd>
          </dl>
          <dl>
            <dt><el-image :src="pichers" fit="full" style="width: 10px;
            height: 20px;"></el-image> <span>充电枪数量</span></dt>
            <dd><span>{{ newobj.numberOfGuns }}</span></dd>
          </dl>
          <dl>
            <dt><el-image :src="pichers" fit="full" style="width: 10px;
            height: 20px;"></el-image> <span>在线充电枪</span></dt>
            <dd><span>{{ newobj.numberOfOnlineGuns }}</span></dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt><el-image :src="pichers" fit="full" style="width: 10px;
            height: 20px;"></el-image> <span>在用充电枪</span></dt>
            <dd><span>{{ newobj.numberOfChargedGuns }}</span></dd>
          </dl>
          <dl>
            <dt><el-image :src="pichers" fit="full" style="width: 10px;
            height: 20px;"></el-image> <span>充电次数</span></dt>
            <dd><span>{{ newobj.chargingTimes }}</span></dd>
          </dl>
          <dl>
            <dt><el-image :src="pichers" fit="full" style="width: 10px;
            height: 20px;"></el-image> <span>注册用户</span></dt>
            <dd><span>{{ newobj.registeredUser }}</span></dd>
          </dl>
        </li>
      </div>
      <div class="right2">
        <h4><span>本周数据统计</span><span></span></h4>
        <li>
          <dl>
            <dt><el-image :src="pichers" fit="full" style="width: 10px;
            height: 20px;margin-right: 5px;"></el-image> <span>本周收入</span></dt>
            <dd><span>{{ jisuan(newobj.weekTotalMoney, 100, 2) }}</span></dd>
          </dl>
          <dl>
            <dt><el-image :src="pichers" fit="full" style="width: 10px;
            height: 20px;margin-right: 5px;"></el-image> <span>本周充电人数</span></dt>
            <dd><span>{{ newobj.weekTotalNumberOfPeople }}</span> </dd>
          </dl>
        </li>
        <li>
          <dl>
            <dt><el-image :src="pichers" fit="full" style="width: 10px;
            height: 20px;margin-right: 5px;"></el-image> <span>本周充值</span></dt>
            <dd><span>{{ jisuan(newobj.weekTotalRecharge, 100, 2) }}</span></dd>
          </dl>
          <dl>
            <dt><el-image :src="pichers" fit="full" style="width: 10px;
            height: 20px;margin-right: 5px;"></el-image> <span>本周退款</span></dt>
            <dd><span>{{ jisuan(newobj.weekTotalRefund, 100, 2) }}</span></dd>
          </dl>
        </li>
      </div>
      <div class="right3">
        <h4><span>最新订单</span><span></span></h4>
        <div class="content">
          <dv-scroll-board :config="config" style="width:100%;height:100%" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  name: 'Default',
  data() {
    return {
      newobj: {},
      historyobj: {},
      time: '',
      sousuodianzhan: '',
      powerStationList: [],
      pichers: '',
      config: {},
      dianfei: 0,
      dianfei2: 0,
      zhu1list: [],
      zhu1Name: [],
      zhu2Name: [],
      month1: [],
      month2: [],
      month3: [],
      month4: [],
      months: [],
      dataMonth: []
    }
  },
  computed: {
    jisuan: function () {
      return function (abc, num, fixed) {
        if (abc == null || abc == undefined) return 0;
        return (abc / num).toFixed(fixed)
      }
    }
  },
  watch: {

  },
  created() {
    this.pichers = require('../../assets/images/img/square.png');
    this.sousuodianzhan = window.sessionStorage.getItem('roleId') == 1 ? '' : window.sessionStorage.getItem('stationIds').includes(',') ? window.sessionStorage.getItem('stationIds') : window.sessionStorage.getItem('stationIds') * 1,
      // 获取所有场站
      this.defaultMethods();
    setInterval(() => {
      //获取当前时间
      this.currentMethods()
    }, 1000);
    // 数据
    this.initMethods();
    this.initMethods2();
  },
  methods: {
    liebiao() {
      const that = this;
      that.$http.get('bill-info/getAllBillInfo', {
        params: {
          page: 1,
          limit: 20,
          gunState: '1,2',
          stationIds: this.sousuodianzhan,
          roleId: window.sessionStorage.getItem('roleId'),
        }
      }).then(resb => {
        that.config = {
          header: ['手机号/卡号', '充电电量', '订单金额'],
          waitTime: 5000,
          align: ['center', 'center', 'center'],
          carousel: 'page',
          data: resb.data.code !== 0 ? [] : resb.data.data.map(obj => [obj.phone == null || obj.phone == undefined || obj.phone == '' ? obj.icCode : obj.phone, (obj.totalKwh / 100).toFixed(4) + 'Kwh', '￥' + (obj.billMoney / 100).toFixed(2)]),
          headerHeight: 50,
          headerBGC: '#122232'
        };
      })
    },
    qingkong2() {
      let chartDom = document.getElementById('content2');
      let myChart = echarts.init(chartDom);
      myChart.clear();
    },
    qingkong1() {
      let chartDom = document.getElementById('main');
      let myChart = echarts.init(chartDom);
      myChart.clear();
    },
    initMethods2() {
      const that = this;
      that.$http.get('data-big-screen-statistics/getAll', {
        params: {
          type: 1,
          roleId: window.sessionStorage.getItem('roleId'),
          stationIds: that.sousuodianzhan,
          appId: window.sessionStorage.getItem('roleId') == 1 || window.sessionStorage.getItem('roleId') == 11 ? 0 : window.sessionStorage.getItem('appId')
        }
      }).then(res => {
        that.liebiao();
        if (res.data.code !== 0) return that.qingkong2();
        that.historyobj = res.data.data;
        // 左侧柱形图
        if (window.sessionStorage.getItem('roleId') == 5) {
          that.zhu1Name = ['累计收入', '累计充值', '累计退款', '累计电量'];
          that.zhu2Name = ['月收入', '月充值', '月退款', '月电量'];
          that.zhu1list = [
            that.historyobj.allPicServiceMoney == 0 || that.historyobj.allPicServiceMoney == null || that.historyobj.allPicServiceMoney == undefined ? 0 : (that.historyobj.allPicServiceMoney / 100).toFixed(2),
            that.historyobj.allPicRechargeMoney !== 0 && that.historyobj.allPicRechargeMoney !== null && that.historyobj.allPicRechargeMoney !== undefined ? (that.historyobj.allPicRechargeMoney / 100).toFixed(2) : 0,
            that.historyobj.allPicRefundMoney == 0 || that.historyobj.allPicRefundMoney == null || that.historyobj.allPicRefundMoney == undefined ? 0 : (that.historyobj.allPicRefundMoney / 100).toFixed(2),
            that.historyobj.allPicElectricityLevel == 0 || that.historyobj.allPicElectricityLevel == null || that.historyobj.allPicElectricityLevel == undefined ? 0 : (that.historyobj.allPicElectricityLevel / 100).toFixed(2)
          ];
        } else if (window.sessionStorage.getItem('roleId') == 11) {
          that.zhu1Name = ['累计收入', '累计电费', '累计电量'];
          that.zhu2Name = ['月收入', '月电费', '月电量'];
          that.zhu1list = [
            that.historyobj.allPicServiceMoney == 0 || that.historyobj.allPicServiceMoney == null || that.historyobj.allPicServiceMoney == undefined ? 0 : (that.historyobj.allPicServiceMoney / 100).toFixed(2),
            that.historyobj.allPicChargeMoney == 0 || that.historyobj.allPicChargeMoney == null || that.historyobj.allPicChargeMoney == undefined ? 0 : (that.historyobj.allPicChargeMoney / 100).toFixed(2),
            that.historyobj.allPicElectricityLevel == 0 || that.historyobj.allPicElectricityLevel == null || that.historyobj.allPicElectricityLevel == undefined ? 0 : (that.historyobj.allPicElectricityLevel / 100).toFixed(2)
          ];
        } else {
          that.zhu1Name = ['累计收入', '累计电费', '累计充值', '累计退款', '累计电量'];
          that.zhu2Name = ['月收入', '月电费', '月充值', '月退款', '月电量'];
          that.zhu1list = [
            that.historyobj.allPicServiceMoney == 0 || that.historyobj.allPicServiceMoney == null || that.historyobj.allPicServiceMoney == undefined ? 0 : (that.historyobj.allPicServiceMoney / 100).toFixed(2),
            that.historyobj.allPicChargeMoney == 0 || that.historyobj.allPicChargeMoney == null || that.historyobj.allPicChargeMoney == undefined ? 0 : (that.historyobj.allPicChargeMoney / 100).toFixed(2),
            that.historyobj.allPicRechargeMoney !== 0 && that.historyobj.allPicRechargeMoney !== null && that.historyobj.allPicRechargeMoney !== undefined ? (that.historyobj.allPicRechargeMoney / 100).toFixed(2) : 0,
            that.historyobj.allPicRefundMoney == 0 || that.historyobj.allPicRefundMoney == null || that.historyobj.allPicRefundMoney == undefined ? 0 : (that.historyobj.allPicRefundMoney / 100).toFixed(2),
            that.historyobj.allPicElectricityLevel == 0 || that.historyobj.allPicElectricityLevel == null || that.historyobj.allPicElectricityLevel == undefined ? 0 : (that.historyobj.allPicElectricityLevel / 100).toFixed(2)
          ];
        }
        let aaa = Math.max(...that.zhu1list);
        that.dianfei = aaa.toString().split('.')[0] < 100 ? aaa.toString().split('.')[0] * 1 + 100 * 1 : aaa.toString().split('.')[0] < 1000 ? aaa.toString().split('.')[0] * 1 + 1000 * 1 : aaa.toString().split('.')[0] < 10000 ? aaa.toString().split('.')[0] * 1 + 10000 * 1 : aaa.toString().split('.')[0] < 100000 ? aaa.toString().split('.')[0] * 1 + 100000 * 1 : aaa.toString().split('.')[0] * 1 + 1000000 * 1;
        that.zhu1();
        // 右侧滚动列表
        let aa = that.historyobj.currentMonth.split('|');
        let bb = that.historyobj.lastMonth.split('|');
        let cc = that.historyobj.twoMonth.split('|');
        var cnc = 0;
        if (window.sessionStorage.getItem('roleId') == 5) {
          that.month1 = [aa[2] !== null && aa[2] !== undefined && aa[2] !== 0 ? (aa[2] / 100).toFixed(2) : 0, bb[2] !== null && bb[2] !== undefined && bb[2] !== 0 ? (bb[2] / 100).toFixed(2) : 0, cc[2] !== null && cc[2] !== undefined && cc[2] !== 0 ? (cc[2] / 100).toFixed(2) : 0];
          that.month2 = [aa[1] !== null && aa[1] !== undefined && aa[0] !== 0 ? (aa[1] / 100).toFixed(2) : 0, bb[1] !== null && bb[1] !== undefined && bb[1] !== 0 ? (bb[1] / 100).toFixed(2) : 0, cc[1] !== null && cc[1] !== undefined && cc[1] !== 0 ? (cc[1] / 100).toFixed(2) : 0];
          that.month3 = [aa[3] !== null && aa[3] !== undefined && aa[0] !== 0 ? (aa[3] / 100).toFixed(2) : 0, bb[3] !== null && bb[3] !== undefined && bb[3] !== 0 ? (bb[3] / 100).toFixed(2) : 0, cc[3] !== null && cc[3] !== undefined && cc[3] !== 0 ? (cc[3] / 100).toFixed(2) : 0];
          that.month4 = [aa[4] !== null && aa[4] !== undefined && aa[0] !== 0 ? (aa[4] / 100).toFixed(2) : 0, bb[4] !== null && bb[4] !== undefined && bb[4] !== 0 ? (bb[4] / 100).toFixed(2) : 0, cc[4] !== null && cc[4] !== undefined && cc[4] !== 0 ? (cc[4] / 100).toFixed(2) : 0];
          cnc = Math.max(...that.month1, ...that.month2, ...that.month3, ...that.month4);
        } else if (window.sessionStorage.getItem('roleId') == 11) {
          that.month1 = [aa[2] !== null && aa[2] !== undefined && aa[2] !== 0 ? (aa[2] / 100).toFixed(2) : 0, bb[2] !== null && bb[2] !== undefined && bb[2] !== 0 ? (bb[2] / 100).toFixed(2) : 0, cc[2] !== null && cc[2] !== undefined && cc[2] !== 0 ? (cc[2] / 100).toFixed(2) : 0];
          that.month2 = [aa[5] !== null && aa[5] !== undefined && aa[0] !== 0 ? (aa[5] / 100).toFixed(2) : 0, bb[5] !== null && bb[5] !== undefined && bb[5] !== 0 ? (bb[5] / 100).toFixed(2) : 0, cc[5] !== null && cc[5] !== undefined && cc[5] !== 0 ? (cc[5] / 100).toFixed(2) : 0];
          that.month3 = [aa[4] !== null && aa[4] !== undefined && aa[0] !== 0 ? (aa[4] / 100).toFixed(2) : 0, bb[4] !== null && bb[4] !== undefined && bb[4] !== 0 ? (bb[4] / 100).toFixed(2) : 0, cc[4] !== null && cc[4] !== undefined && cc[4] !== 0 ? (cc[4] / 100).toFixed(2) : 0];
          cnc = Math.max(...that.month1, ...that.month2, ...that.month3);
        } else {
          that.month1 = [aa[2] !== null && aa[2] !== undefined && aa[2] !== 0 ? (aa[2] / 100).toFixed(2) : 0, bb[2] !== null && bb[2] !== undefined && bb[2] !== 0 ? (bb[2] / 100).toFixed(2) : 0, cc[2] !== null && cc[2] !== undefined && cc[2] !== 0 ? (cc[2] / 100).toFixed(2) : 0];
          that.month2 = [aa[5] !== null && aa[5] !== undefined && aa[0] !== 0 ? (aa[5] / 100).toFixed(2) : 0, bb[5] !== null && bb[5] !== undefined && bb[5] !== 0 ? (bb[5] / 100).toFixed(2) : 0, cc[5] !== null && cc[5] !== undefined && cc[5] !== 0 ? (cc[5] / 100).toFixed(2) : 0];
          that.month3 = [aa[1] !== null && aa[1] !== undefined && aa[0] !== 0 ? (aa[1] / 100).toFixed(2) : 0, bb[1] !== null && bb[1] !== undefined && bb[1] !== 0 ? (bb[1] / 100).toFixed(2) : 0, cc[1] !== null && cc[1] !== undefined && cc[1] !== 0 ? (cc[1] / 100).toFixed(2) : 0];
          that.month4 = [aa[3] !== null && aa[3] !== undefined && aa[0] !== 0 ? (aa[3] / 100).toFixed(2) : 0, bb[3] !== null && bb[3] !== undefined && bb[3] !== 0 ? (bb[3] / 100).toFixed(2) : 0, cc[3] !== null && cc[3] !== undefined && cc[3] !== 0 ? (cc[3] / 100).toFixed(2) : 0];
          that.months = [aa[4] !== null && aa[4] !== undefined && aa[0] !== 0 ? (aa[4] / 100).toFixed(2) : 0, bb[4] !== null && bb[4] !== undefined && bb[4] !== 0 ? (bb[4] / 100).toFixed(2) : 0, cc[4] !== null && cc[4] !== undefined && cc[4] !== 0 ? (cc[4] / 100).toFixed(2) : 0];
          cnc = Math.max(...that.month1, ...that.month2, ...that.month3, ...that.month4, ...that.months);
        }
        that.dataMonth = [aa[0], bb[0], cc[0]];

        that.dianfei2 = cnc[0] < 100 ? cnc[0] * 1 + 100 * 1 : cnc[0] < 1000 ? cnc[0] * 1 + 1000 * 1 : cnc[0] < 10000 ? cnc[0] * 1 + 10000 * 1 : cnc[0] < 100000 ? cnc[0] * 1 + 100000 * 1 : cnc[0] * 1 + 1000000 * 1;
        that.zhu2();
      })
    },
    initMethods() {
      const that = this;
      that.$http.get('data-big-screen-statistics/getAll', {
        params: {
          type: 0,
          roleId: window.sessionStorage.getItem('roleId'),
          stationIds: that.sousuodianzhan,
          appId: window.sessionStorage.getItem('roleId') == 1 || window.sessionStorage.getItem('roleId') == 11 ? 0 : window.sessionStorage.getItem('appId')
        }
      }).then(res => {
        if (res.data.code !== 0) return;
        that.newobj = res.data.data;
      })
    },
    change_vale() {
      this.initMethods();
      this.initMethods2();
    },
    async defaultMethods() {
      this.powerStationList = [];
      // await this.$http.get(`bill-info/getAllRechargeRefund/${window.sessionStorage.getItem('adminId')}`).then(ress => {
      //   if (ress.data.code !== 0) return;
      //   this.totalRechargeMoney = ress.data.data.totalRechargeMoney;
      //   this.totalRefundMoney = ress.data.data.totalRefundMoney;
      //   this.totalGiveMoney = ress.data.data.totalGiveMoney;
      // });
      await this.$http.get('station-info/getAllStationInfoName', {
        params: {
          roleId: window.sessionStorage.getItem('roleId'),
          stationIds: window.sessionStorage.getItem('stationIds'),
          stationMode: '0,1,2'
        }
      }).then(res => {
        this.powerStationList = res.data.data;
        if (res.data.data.length > 1) {
          this.powerStationList.push({
            id: window.sessionStorage.getItem('stationIds'),
            stationName: '全部电站'
          });
        }
      });
    },
    currentMethods() {
      const that = this;
      let abc = new Date();
      let y = abc.getFullYear();
      let m = (abc.getMonth() + 1 + '').padStart(2, '0');
      let d = (abc.getDate() + '').padStart(2, '0');
      let hh = (abc.getHours() + '').padStart(2, '0');
      let mm = (abc.getMinutes() + '').padStart(2, '0');
      let ss = (abc.getSeconds() + '').padStart(2, '0');
      that.time = `${y}年${m}月${d}日 ${hh}时${mm}分${ss}秒`;
    },
    zhu1() {
      const that = this;
      let chartDom = document.getElementById('main');
      let myChart = echarts.init(chartDom);
      let option = {
        xAxis: [
          {
            data: that.zhu1Name,
            axisLabel: {
              color: '#fff' // 设置x轴文字颜色为红色
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '',
            min: 0,
            max: that.dianfei,
            axisLabel: {
              color: '#fff'
            }
          }
        ],
        legend: {
          show: true,
          textStyle: {
            color: '#fff'
          },
          data: [
            {
              name: that.zhu1Name[0],
              itemStyle: {
                color: '#B0830C'
              }
            },
            {
              name: that.zhu1Name[1],
              itemStyle: {
                color: 'blue'
              }
            },
            {
              name: that.zhu1Name[2],
              itemStyle: {
                color: '#0AC89C'
              }
            },
            {
              name: that.zhu1Name[3],
              itemStyle: {
                color: '#1E81DE'
              }
            },
            {
              name: that.zhu1Name[4],
              itemStyle: {
                color: '#C75E27'
              }
            },
          ],
          icon: "rect",
          itemWidth: 15,
          itemHeight: 15, //图例宽高
        },
        grid: {
          left: '0%',    // 距离左边的距离
          right: '0%',   // 距离右边的距离
          top: '15%',     // 距离顶部的距离
          bottom: '0%',   // 距离底部的距离
          containLabel: true
        },
        series: [
          {
            data: [{
              value: that.zhu1list[0], itemStyle: {
                color: '#B0830C'
              }
            }, null, null, null, null],
            type: 'bar',
            name: that.zhu1Name[0],
            stack: 'sta',
            barWidth: 50,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#fff',
                fontSize: 12
              }
            }
          },
          {
            data: [null, {
              value: that.zhu1list[1], itemStyle: {
                color: 'blue'
              }
            }, null, null, null],
            type: 'bar',
            stack: 'sta',
            barWidth: 50,
            name: that.zhu1Name[1],
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#fff',
                fontSize: 12
              }
            }
          },
          {
            data: [null, null, {
              value: that.zhu1list[2], itemStyle: {
                color: '#0AC89C'
              }
            }, null, null],
            type: 'bar',
            stack: 'sta',
            barWidth: 50,
            name: that.zhu1Name[2],
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#fff',
                fontSize: 12
              }
            }
          },
          {
            data: [null, null, null, {
              value: that.zhu1list[3], itemStyle: {
                color: '#1E81DE'
              }
            }, null],
            type: 'bar',
            stack: 'sta',
            barWidth: 50,
            name: that.zhu1Name[3],
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#fff',
                fontSize: 12
              }
            }
          },
          {
            data: [null, null, null, null, {
              value: that.zhu1list[4], itemStyle: {
                color: '#C75E27'
              }
            }],
            type: 'bar',
            name: that.zhu1Name[4],
            stack: 'sta',
            barWidth: 50,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#fff',
                fontSize: 12
              }
            }
          },
        ]
      };
      if (window.sessionStorage.getItem('roleId') == 5) {
        option.legend.data = [
          {
            name: that.zhu1Name[0],
            itemStyle: {
              color: '#B0830C'
            }
          },
          {
            name: that.zhu1Name[1],
            itemStyle: {
              color: 'blue'
            }
          },
          {
            name: that.zhu1Name[2],
            itemStyle: {
              color: '#0AC89C'
            }
          },
          {
            name: that.zhu1Name[3],
            itemStyle: {
              color: '#1E81DE'
            }
          }
        ];
        option.series = [
          {
            data: [{
              value: that.zhu1list[0], itemStyle: {
                color: '#B0830C'
              }
            }, null, null, null],
            type: 'bar',
            name: that.zhu1Name[0],
            stack: 'sta',
            barWidth: 50,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#fff',
                fontSize: 12
              }
            }
          },
          {
            data: [null, {
              value: that.zhu1list[1], itemStyle: {
                color: 'blue'
              }
            }, null, null],
            type: 'bar',
            stack: 'sta',
            barWidth: 50,
            name: that.zhu1Name[1],
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#fff',
                fontSize: 12
              }
            }
          },
          {
            data: [null, null, {
              value: that.zhu1list[2], itemStyle: {
                color: '#0AC89C'
              }
            }, null],
            type: 'bar',
            stack: 'sta',
            barWidth: 50,
            name: that.zhu1Name[2],
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#fff',
                fontSize: 12
              }
            }
          },
          {
            data: [null, null, null, {
              value: that.zhu1list[3], itemStyle: {
                color: '#1E81DE'
              }
            }],
            type: 'bar',
            stack: 'sta',
            barWidth: 50,
            name: that.zhu1Name[3],
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#fff',
                fontSize: 12
              }
            }
          }
        ]
      } else if (window.sessionStorage.getItem('roleId') == 11) {
        option.legend.data = [
          {
            name: that.zhu1Name[0],
            itemStyle: {
              color: '#B0830C'
            }
          },
          {
            name: that.zhu1Name[1],
            itemStyle: {
              color: 'blue'
            }
          },
          {
            name: that.zhu1Name[2],
            itemStyle: {
              color: '#0AC89C'
            }
          }
        ];
        option.series = [
          {
            data: [{
              value: that.zhu1list[0], itemStyle: {
                color: '#B0830C'
              }
            }, null, null],
            type: 'bar',
            name: that.zhu1Name[0],
            stack: 'sta',
            barWidth: 50,
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#fff',
                fontSize: 12
              }
            }
          },
          {
            data: [null, {
              value: that.zhu1list[1], itemStyle: {
                color: 'blue'
              }
            }, null],
            type: 'bar',
            stack: 'sta',
            barWidth: 50,
            name: that.zhu1Name[1],
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#fff',
                fontSize: 12
              }
            }
          },
          {
            data: [null, null, {
              value: that.zhu1list[2], itemStyle: {
                color: '#0AC89C'
              }
            }],
            type: 'bar',
            stack: 'sta',
            barWidth: 50,
            name: that.zhu1Name[2],
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#fff',
                fontSize: 12
              }
            }
          }
        ]
      }
      myChart.setOption(option);
    },
    zhu2() {
      const that = this;
      let chartDom = document.getElementById('content2');
      let myChart = echarts.init(chartDom);
      let option = {
        xAxis: [
          {
            type: 'category',
            data: this.dataMonth,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
              fontSize: 14,
              color: '#000'
            },
            axisTick: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            min: 0,
            max: that.dianfei2
          }
        ],
        barWidth: 50,
        legend: {
          data: that.zhu2Name,
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          left: '.5%',    // 距离左边的距离
          right: '1%',   // 距离右边的距离
          top: '12%',     // 距离顶部的距离
          bottom: '1%',   // 距离底部的距离
          containLabel: true
        },
        series: [
          {
            name: that.zhu2Name[0],
            type: 'bar',
            data: that.month1,
            label: {
              show: true,
              position: 'top',
              formatter: (params) => Math.max(params.value),
              textStyle: {
                fontSize: 14,
                color: '#fff'
              }
            }
          },
          {
            name: that.zhu2Name[1],
            type: 'bar',
            data: that.months,
            label: {
              show: true,
              position: 'top',
              formatter: (params) => Math.max(params.value),
              textStyle: {
                fontSize: 14,
                color: '#fff'
              }
            }
          },
          {
            name: that.zhu2Name[2],
            type: 'bar',
            data: that.month2,
            label: {
              show: true,
              position: 'top',
              formatter: (params) => Math.max(params.value),
              textStyle: {
                fontSize: 14,
                color: '#fff'
              }
            }
          },
          {
            name: that.zhu2Name[3],
            type: 'bar',
            data: that.month3,
            label: {
              show: true,
              position: 'top',
              formatter: (params) => Math.max(params.value),
              textStyle: {
                fontSize: 14,
                color: '#fff'
              }
            }
          }, {
            name: that.zhu2Name[4],
            type: 'bar',
            data: that.month4,
            label: {
              show: true,
              position: 'top',
              formatter: (params) => Math.max(params.value),
              textStyle: {
                fontSize: 14,
                color: '#fff'
              }
            }
          }
        ]
      };
      if (window.sessionStorage.getItem('roleId') == 5) {
        option.series = [
          {
            name: that.zhu2Name[0],
            type: 'bar',
            data: that.month1,
            label: {
              show: true,
              position: 'top',
              formatter: (params) => Math.max(params.value),
              textStyle: {
                fontSize: 14,
                color: '#fff'
              }
            }
          },
          {
            name: that.zhu2Name[1],
            type: 'bar',
            data: that.month2,
            label: {
              show: true,
              position: 'top',
              formatter: (params) => Math.max(params.value),
              textStyle: {
                fontSize: 14,
                color: '#fff'
              }
            }
          },
          {
            name: that.zhu2Name[2],
            type: 'bar',
            data: that.month3,
            label: {
              show: true,
              position: 'top',
              formatter: (params) => Math.max(params.value),
              textStyle: {
                fontSize: 14,
                color: '#fff'
              }
            }
          },
          {
            name: that.zhu2Name[3],
            type: 'bar',
            data: that.month4,
            label: {
              show: true,
              position: 'top',
              formatter: (params) => Math.max(params.value),
              textStyle: {
                fontSize: 14,
                color: '#fff'
              }
            }
          }
        ]
      } else if (window.sessionStorage.getItem('roleId') == 11) {
        option.series = [
          {
            name: that.zhu2Name[0],
            type: 'bar',
            data: that.month1,
            label: {
              show: true,
              position: 'top',
              formatter: (params) => Math.max(params.value),
              textStyle: {
                fontSize: 14,
                color: '#fff'
              }
            }
          },
          {
            name: that.zhu2Name[1],
            type: 'bar',
            data: that.month2,
            label: {
              show: true,
              position: 'top',
              formatter: (params) => Math.max(params.value),
              textStyle: {
                fontSize: 14,
                color: '#fff'
              }
            }
          },
          {
            name: that.zhu2Name[2],
            type: 'bar',
            data: that.month3,
            label: {
              show: true,
              position: 'top',
              formatter: (params) => Math.max(params.value),
              textStyle: {
                fontSize: 14,
                color: '#fff'
              }
            }
          }
        ]
      }
      myChart.setOption(option);
    }
  }
};
</script>

<style lang='less' scoped>
// 表头层高
::v-deep .header {
  padding: 10px 0;
}

// 表头背景色
::v-deep .header-item {
  background-color: #77AAD5;
  text-align: center
}

/deep/.el-input__inner,
/deep/.el-scrollbar {
  background: #061b30;
  color: #fff
}

.default {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  color: #fff;
  background: #061b30;
  overflow: hidden;

  .left,
  .right {
    width: 25%;
    font-size: 16px;
  }

  // .left {
  //   width: 20%;
  // }

  // .right {
  //   width: 23%;
  // }

  .center {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 0 1%;
    margin: 0 10px;
    border-left: 1px solid #77AAD5;
    border-right: 1px solid #77AAD5;

    .center1 {
      display: flex;
      flex-direction: column;
      flex: 2;

      h4 {
        flex: 3;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        // padding-top: 1.4%;
        .tsliebiao {
          margin-left: 130px;
          // margin-top: -3%;
        }

        span:nth-child(1) {
          border-bottom: 1px solid #77AAD5;
        }

        span {
          padding-top: 1.4%;
        }
      }

      .li {
        display: flex;
        flex-direction: column;
        flex: 6;

        span {
          flex: 1;
          display: flex;
          justify-content: center;
          font-size: 20px;
        }

        span:nth-child(1) {
          font-size: 28px;
        }
      }
    }

    .center3 {
      flex: 5;

      #content2 {
        width: 96%;
        height: 100%;
        margin: 0 2%;
      }
    }

    .center2 {
      display: flex;
      flex-direction: row;
      flex: 1;
      border-bottom: 1px solid #77AAD5;

      li {
        flex: 1;
        font-size: 18px;
        display: flex;
        flex-direction: column;

        span {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .right {
    margin-right: 1.8%;
  }

  .left,
  .right,
  .center {
    display: flex;
    flex-direction: column;
  }

  h4 {
    height: 30px;
    font-size: 18px;
    display: flex;
    align-items: center;
    span:nth-child(1) {
      border-bottom: 1px solid #77AAD5;
    }
  }


  .left1,
  .right1,
  .left2,
  .right2,
  .left3,
  .right3 {
    flex: 1;
  }

  .left1,
  .left2 {
    padding: 0 10px 0 10px;
    display: flex;
    flex-direction: column;
    li {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #77AAD5;
      font-size: 15px;

      span,
      .ts {
        flex: 1;
      }

      .ts {
        font-size: 20px;
        display: flex;
        justify-content: center;
      }

      // span:nth-child(1) {
      //   color: #fff;
      // }

      span:nth-child(3) {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .left2 {
    margin-top: -10px;
    border-top: 1px solid #77AAD5;
    border-bottom: 1px solid #77AAD5;
  }

  .left3 {
    margin-left: 2%;
    margin-bottom: 1.2%;

    #main {
      height: calc(100% - 30px);
    }
  }

  .right1,
  .right2 {
    display: flex;
    flex-direction: column;
    font-size: 18px;

    li {
      flex: 1;
      display: flex;
      flex-direction: row;

      dl {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-right: 10px;

        dt,
        dd {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        dd {
          font-size: 20px;
          margin-left: 0;
        }
      }
    }


  }

  .right1 {
    padding: 0 10px;

    dl>dd {
      color: #77AAD5;
      font-size: 16px;
    }

    li:nth-child(1) {
      dl:nth-child(1)>dd {
        color: #c5c5c5
      }

      dl:nth-child(2)>dd {
        color: #0AC89C;
      }

      dl:nth-child(3)>dd {
        color: #C75E27;
      }
    }

    li:nth-child(2)>dl:nth-child(1)>dd {
      color: red;
    }
  }

  .right2 {
    border-bottom: 1px solid #77AAD5;
    border-top: 1px solid #77AAD5;

    li:nth-child(3) {
      dd {
        color: #B0830C;
      }

      dl:nth-child(1)>dd {
        color: #0AC89C;
      }
    }

    li:nth-child(2) {
      dd {
        color: #C75E27;
      }

      dl:nth-child(1)>dd {
        color: rgb(97, 97, 168);
      }
    }
  }

  .right3 {
    .content {
      width: 100%;
      height: calc(100% - 30px);
    }
  }
}
</style>
