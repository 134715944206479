<template>
  <div>
    <el-form :inline="true" :model="queryInfo" class="form">
        <el-form-item label="所属小程序:">
          <el-select v-model="miniurl" placeholder="请选择小程序" @change="chageMini" filterable @clear="clearMethods">
            <el-option v-for="item in miniurlList" :key="item.id" :label="item.appname" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="充电车主:"><el-input placeholder="请输入车主编号/手机号:" clearable v-model="queryInfo.userNumber"
            @clear="getWechartList" @change="current">
          </el-input></el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="getWechartList2">
            查询
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-refresh-left" @click="chongzhi">
            重置
          </el-button>
        </el-form-item>
      </el-form>
    <!-- 卡片视图区域 微信用户-->
    <el-card class="card">
      <!--  -->
        <li style="display: flex;flex-direction: row;justify-content: flex-end;padding-bottom: 20px;margin-left: -1%;">
          <el-button v-if="Vip" type="success" icon="el-icon-plus" @click="Vipanniu">
            批量添加Vip折扣
          </el-button> <el-button v-if="daochu" type="primary" @click="daochuanniu">
            导出消费记录
          </el-button>
        </li>
        <!-- <el-form-item>
          <el-button type="primary" @click="gengduo">
            更多筛选
          </el-button>
        </el-form-item> -->
      <!--  -->
      <div class="listname" v-if="listall.length > 0">
        <div class="dl" v-for="(item, index) in listall" :key="index">
          <p>
            {{ item.name }}
          </p>
          <p>
            {{ index !== 0 ? '￥' + jisuan(item.countMoney, 100, 2) : item.countMoney }}
          </p>
        </div>
      </div>
      <el-table :data="wchartlist" border stripe @selection-change="handleSelectionChange" @row-dblclick="dbclick"
        empty-text="暂无数据">
        <el-table-column align="center" type="selection" width="55"></el-table-column>
        <el-table-column align="center" type="expand">
          <template slot-scope="scope">
            <el-form label-position="left" class="demo-table-expand">
              <el-row :gutter="20" type="flex" class="row-bg" justify="space-around">
                <el-col :span="8">
                  <el-form-item label="ID">
                    <span>{{ scope.row.id }}</span>
                  </el-form-item>
                  <el-form-item :label="scope.row.alipayId !== null ? 'alipayId:' : 'openId:'">
                    <span>{{ scope.row.alipayId !== null ? scope.row.alipayId : scope.row.wxOpenId }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="唯一编码：">
                    <span>{{ scope.row.userNumber }}</span>
                  </el-form-item>
                  <el-form-item label="手机号：">
                    <span>{{ scope.row.phone }}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="昵称：">
                    <span>{{ scope.row.nickname }}</span>
                  </el-form-item>
                  <el-form-item label="余额（元）：">
                    <el-tag type="success">{{ jisuan(scope.row.money, 100, 2) }}元</el-tag>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column align="center" label="手机号" prop="phone" width="130"></el-table-column>
        <el-table-column align="center" label="用户类型" prop="terminalId" width="80">
          <template slot-scope="scope">
            <img style="width: 40px;
           height: 40px;"
              :src="scope.row.terminalId == 0 ? tupian + '小程序.png' : scope.row.terminalId == 1 ? tupian + 'zfb.png' : scope.row.terminalId == 2 ? tupian + 'zfb.png' : tupian + 'ocpp_APP'"
              alt="">
          </template>
        </el-table-column>
        <el-table-column align="left" label="所属小程序" prop="appName"></el-table-column>
        <el-table-column align="left" label="常用电站" prop="stationName">
          <template slot-scope="scope">
            <span v-if="scope.row.stationName == '' || scope.row.stationName == undefined">--</span>
            <span v-else>{{ scope.row.stationName }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="折扣类型" prop="discounType" width="140">
          <template slot-scope="scope">
            <el-select :disabled="qunxian == true ? false : true" v-model="scope.row.discounType"
              placeholder="请选择你的vip折扣方式" @change="genggai(scope.row, 0)">
              <el-option v-for="item in options_active" :key="item.discounType" :label="item.typeName"
                :value="item.discounType">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column align="center" label="折扣率" prop="discountRate" width="150">
          <template slot-scope="scope">
            <el-select :disabled="qunxian == true ? false : true" v-model="scope.row.discountRate"
              @change="genggai(scope.row, 1)">
              <el-option v-for="item in listType" :key="item.value"
                :label="scope.row.discounType == 1 && item.value == 0 ? '免收电费' : scope.row.discounType == 2 && item.value == 0 ? '免收服务费' : item.name"
                :value="item.value"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column align="center" label="唯一编码" prop="userNumber" width="140"></el-table-column>
        <el-table-column align="center" label="昵称" prop="nickname"></el-table-column>
        <el-table-column align="center" label="余额（元）" width="100">
          <template slot-scope="scope">
            <el-tag>
              ￥{{ jisuan(scope.row.money, 100, 2) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="禁用" prop="status" v-if="jinyong" width="100">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" :active-color="scope.row.status == false ? '#13ce66' : '#ff4949'"
              :inactive-color="scope.row.status == false ? '#13ce66' : '#ff4949'"
              @change="change_type(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column align="left" label="操作" width="240">
          <template slot-scope="scope">
            <!-- <el-button v-if="qunxian == true" type="primary" @click="bianji(scope.row)">编辑</el-button> -->
            <!-- <el-button type="info" @click="delMethods(scope.row.id)" v-if="zengsong == true">删除</el-button> -->
            <el-button type="success" @click="showPresent(scope.row)" v-if="zengsong == true">赠送/退款</el-button>
            <el-button @click="detailMethods(scope.row)">用户详情</el-button>
          </template>
        </el-table-column>
        <div slot="empty" class="empty">
          <span>暂无数据</span>
        </div>
      </el-table>
      <!-- 分页导航区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.page" :page-sizes="[6, 20, 50, 100]" :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-card>
    <!-- VIP的对话框 -->
    <el-dialog :title="titles" :visible.sync="vipdialoag" width="40%" @close="qx_vipdialoag" append-to-body
      :close-on-click-modal="false">
      <div>
        <el-form :model="active" label-width="140px" ref="activeRef">
          <div class="center" style="display: flex;flex-direction: column;">
            <!-- <div>
              <el-form-item label="活动名称:" prop="usingType">
                <el-input placeholder="请输入活动名称:" clearable v-model="active.phone" @clear="getWechartList">
                </el-input>
              </el-form-item>
            </div> -->
            <div
              style="display: flex;justify-content: center;padding-bottom: 20px;color: red;font-size: 14px;font-weight: bolder;">
              *注：0免费,1-99为折扣百分比,100为正常收款</div>
            <div :span="6" style="display: flex;flex-direction: row;">
              <el-form-item label="折扣方式:" prop="discounType">
                <el-select style="margin-right: 50px;" v-model="active.discounType" placeholder="请选择你的vip折扣方式">
                  <el-option v-for="item in options_active" :key="item.discounType" :label="item.typeName"
                    :value="item.discounType">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="折扣" prop="discountRate">
                <el-input-number size="medium" v-model="active.discountRate" :step="1" controls-position="right"
                  :min="0" :max="100" label="0-99"></el-input-number>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="qx_vipdialoag">取 消</el-button>
        <el-button type="primary" @click="qxvipdialoag">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 导出的对话框 -->
    <el-dialog title="导出记录" :visible.sync="daochu_dialoag" width="30%">
      <div class="cen">
        <el-form :model="daochuForm" label-width="140px">
          <el-form-item label="记录类型">
            <el-select v-model="currentName">
              <el-option v-for="item in recordlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型:" prop="usingType" v-if="currentName == 0">
            <el-select v-model="daochuForm.usingType" placeholder="请选择导出内容" filterable clearable style="flex:7">
              <el-option v-for="item in typelist" :key="item.usingType" :label="item.typeName"
                :value="item.usingType"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型:" prop="usingType" v-if="currentName == 1">
            <el-select v-model="daochuForm.usingType" placeholder="请选择导出内容" filterable clearable style="flex:7">
              <el-option v-for="item in typelist1" :key="item.usingType" :label="item.typeName"
                :value="item.usingType"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型:" prop="usingType" v-if="currentName == 2">
            <el-select v-model="daochuForm.usingType" placeholder="请选择导出内容" filterable clearable style="flex:7">
              <el-option v-for="item in typelist2" :key="item.usingType" :label="item.typeName"
                :value="item.usingType"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期">
            <el-date-picker v-model="value1" type="datetimerange" :picker-options="pickerOptions" range-separator="至"
              @blur="pickBlur" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']" @change="data_change">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="qx_dialoag">取 消</el-button>
        <el-button type="primary" @click="qd_dialoag">
          <i class="el-icon-loading" v-if="loading"></i>
          <span>确 定</span>
        </el-button>
      </span>
    </el-dialog>
    <!-- 赠送的对话框 -->
    <el-dialog title="余额赠送/退款" :visible.sync="editDialogVisible" width="30%" @close="editDialogClosed" append-to-body
      :close-on-click-modal="false">
      <!-- 内容主体区域 -->
      <el-form :model="wechartForm" ref="editFormRef" label-width="140px">
        <el-form-item label="操作选择：" prop="btn">
          <el-radio-group v-model="wechartForm.type">
            <el-radio v-for="item in leaavel" :key="item.type" :label="item.type">{{ item.type == 0 ? '赠送' : '退赠送'
              }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="场站选择：">
          <el-select v-model="wechartForm.stationId" placeholder="请选择你的场站" filterable @change="titleMethods">
            <el-option v-for="item in listStation" :key="item.id" :label="item.stationName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="总余额（元）：" prop="money">
          <el-tag>￥{{ wechartForm.money / 100 }}元</el-tag>
        </el-form-item>
        <!-- <el-form-item label="已赠金额" prop="giveMoney">
          <el-tag>￥{{ wechartForm.giveMoney/100 }}</el-tag>
        </el-form-item> -->
        <el-form-item label="赠送/退款金额（元）：" prop="num">
          <el-input-number :precision="2" :step="0.1" v-model="num" controls-position="right"
            :min="0"></el-input-number>
          <span style="margin-left: 10px;">元</span>
        </el-form-item>
        <el-form-item>
          <span style="color:red">注：以上总金额仅为后台手动赠送绑定站的赠送总金额</span>
        </el-form-item>
      </el-form>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogClosed">取 消</el-button>
        <el-button type="primary" v-debounce="editPresent">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 消费记录的对话框 -->
    <!-- <el-dialog title="消费记录" :visible.sync="expendDialogVisible" width="80%" @close="expendDialogClosed" append-to-body
      :close-on-click-modal="false"> -->
    <!-- 内容主体区域 -->
    <!-- <el-table :data="xiaofeilist" border>
        <el-table-column align="center" label="用户ID" prop="id" width="160">
          <template scope="scope">
            <span>{{ scope.row.id }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="唯一编码" prop="userNumber" width="200"></el-table-column>
        <el-table-column align="center" label="变动金额" prop="changMoney" width="160">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.usingType == '0' ? 'danger' : scope.row.usingType == '1' ? 'success' : scope.row.usingType == '2' ? 'warning' : scope.row.usingType == '3' ? 'primary' : 'danger'">￥{{
        scope.row.changMoney / 100 }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作类型" prop="usingType" width="100">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.usingType == '0' ? 'danger' : scope.row.usingType == '1' ? 'success' : scope.row.usingType == '2' ? 'warning' : scope.row.usingType == '3' ? 'primary' : 'danger'">
              {{ scope.row.usingType == '0' ? '消费' : scope.row.usingType == '1' ? '充值' : scope.row.usingType ==
        '2' ? '退款' : scope.row.usingType == '3' ? '赠送' : '欠费' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="流水单号" prop="billCode" width="320"></el-table-column>
        <el-table-column align="center" label="备注说明" prop="des"></el-table-column>
        <el-table-column align="center" label="操作时间" width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.dcreateTime != null">{{ scope.row.dcreateTime }}</span><span v-else>{{
        scope.row.createTime }}</span>
          </template>
        </el-table-column>
        <div slot="empty" class="empty">
          <span>暂无数据</span>
        </div>
      </el-table> -->
    <!-- 分页导航区域 -->
    <!-- <el-pagination @size-change="expendSizeChange" @current-change="expendCurrentChange" :current-page="xiaofei.page"
        :page-sizes="[8, 20, 50, 100]" :page-size="xiaofeiInfo.limit" layout="total, sizes, prev, pager, next, jumper"
        :total="xiaofeiTotal" background>
      </el-pagination>
    </el-dialog> -->
    <!-- 用户详情的对话框 -->
    <el-dialog title="" :visible.sync="Detaildialoag" width="60%" @close="DetailBtn" append-to-body
      :close-on-click-modal="false">
      <div class="cardslist">
        <div class="top">
          <dl>
            <dt>
              <el-image style="width: 200px; height: 200px" :src="tupian + 'morentouxiang.png'"
                fit="scale-down"></el-image>
            </dt>
            <dd>
              <!-- 横向排布 -->
              <li>
                <!-- p：横向排布，均分 -->
                <p><span>微信用户</span><span></span></p>
                <p><span>{{ listobj.userNumber }}</span><span></span></p>
                <p><span>{{ listobj.phone }}</span><span></span></p>
              </li>
              <li>
                <p><span>用户来源</span><span>{{ listobj.appname }}</span></p>
                <p><span>用户微信OpenID</span><span>{{ listobj.wxOpenId }}</span></p>
                <p><span>加入时间</span><span>{{ listobj.createTime }}</span></p>
                <p><span>最近一次充电时间</span><span>{{ listobj.startTime }}</span></p>
              </li>
            </dd>
          </dl>
        </div>
        <div class="center">
          <dl>
            <dt>
              <el-image style="width: 200px; height: 200px" :src="tupian + '总费用.png'" fit="scale-down"></el-image>
            </dt>
            <dd>
              <li>
                <div class="name">
                  <div class="top1"><span>钱包总余额</span></div>
                  <div class="top2" :style="{
        color: listobj.totalMoney == undefined || listobj.totalMoney == null || listobj.totalMoney == '' || listobj.totalMoney == 0 || listobj.totalMoney > 0 ? 'blue' : 'red'
      }"><span>{{ jisuan(listobj.totalMoney, 100, 2) }}</span><b>元</b></div>
                </div>
              </li>
              <!-- 纵向排布 -->
              <li>
                <div class="name">
                  <div class="top1"><span>账户余额</span></div>
                  <div class="top2" :style="{
        color: listobj.accontMoney == undefined || listobj.accontMoney == null || listobj.accontMoney == '' || listobj.accontMoney == 0 || listobj.accontMoney > 0 ? 'blue' : 'red'
      }"><span>{{ jisuan(listobj.accontMoney, 100, 2) }}</span><b>元</b></div>
                </div>
                <div class="name">
                  <div class="top1"><span>即充余额</span></div>
                  <div class="top2" :style="{
        color: listobj.anyMoney == undefined || listobj.anyMoney == null || listobj.anyMoney == '' || listobj.anyMoney == 0 || listobj.anyMoney > 0 ? 'blue' : 'red'
      }"><span>{{ jisuan(listobj.anyMoney, 100, 2) }}</span><b>元</b></div>
                </div>
                <div class="name">
                  <div class="top1"><span>绑定钱包余额</span></div>
                  <div class="top2" :style="{
        color: listobj.bindMoney == undefined || listobj.bindMoney == null || listobj.bindMoney == '' || listobj.bindMoney == 0 || listobj.bindMoney > 0 ? 'blue' : 'red'
      }"><span>{{ jisuan(listobj.bindMoney, 100, 2) }}</span><b>元</b></div>
                </div>
                <div class="name">
                  <div class="top1"><span>绑定活动赠送余额</span></div>
                  <div class="top2" :style="{
        color: listobj.bindGiftMoney == undefined || listobj.bindGiftMoney == null || listobj.bindGiftMoney == '' || listobj.bindGiftMoney == 0 || listobj.bindGiftMoney > 0 ? 'blue' : 'red'
      }"><span>{{ jisuan(listobj.bindGiftMoney, 100, 2) }}</span><b>元</b></div>
                </div>
                <div class="name">
                  <div class="top1"><span>绑定后台赠送余额</span></div>
                  <div class="top2" :style="{
        color: listobj.backendGiftMoney == undefined || listobj.backendGiftMoney == null || listobj.backendGiftMoney == '' || listobj.backendGiftMoney == 0 || listobj.backendGiftMoney > 0 ? 'blue' : 'red'
      }"><span>{{ jisuan(listobj.backendGiftMoney, 100, 2) }}</span><b>元</b></div>
                </div>
              </li>
              <li>
                <div class="name">
                  <div class="top1"><span>账户待退</span></div>
                  <div class="top2" :style="{
        color: listobj.accontRefundMoney == undefined || listobj.accontRefundMoney == null || listobj.accontRefundMoney == '' || listobj.accontRefundMoney == 0 || listobj.accontRefundMoney > 0 ? 'blue' : 'red'
      }"><span>{{ jisuan(listobj.accontRefundMoney, 100, 2) }}</span><b>元</b></div>
                </div>
                <div class="name">
                  <div class="top1"><span>即退余额</span></div>
                  <div class="top2" :style="{
        color: listobj.anyRefundMoney == undefined || listobj.anyRefundMoney == null || listobj.anyRefundMoney == '' || listobj.anyRefundMoney == 0 || listobj.anyRefundMoney > 0 ? 'blue' : 'red'
      }"><span>{{ jisuan(listobj.anyRefundMoney, 100, 2) }}</span><b>元</b></div>
                </div>
                <div class="name">
                  <div class="top1"><span>绑定待退余额</span></div>
                  <div class="top2" :style="{
        color: listobj.bindRefundMoney == undefined || listobj.bindRefundMoney == null || listobj.bindRefundMoney == '' || listobj.bindRefundMoney == 0 || listobj.bindRefundMoney > 0 ? 'blue' : 'red'
      }"><span>{{ jisuan(listobj.bindRefundMoney, 100, 2) }}</span><b>元</b></div>
                </div>
                <div class="name" style="border-right:none">
                  <div class="top1"><span>绑定后台待退余额</span></div>
                  <div class="top2" :style="{
        color: listobj.backendGiftRefundMoney == undefined || listobj.backendGiftRefundMoney == null || listobj.backendGiftRefundMoney == '' || listobj.backendGiftRefundMoney == 0 || listobj.backendGiftRefundMoney > 0 ? 'blue' : 'red'
      }"><span>{{ jisuan(listobj.backendGiftRefundMoney, 100, 2) }}</span><b>元</b></div>
                </div>
                <div class="name">
                  <div class="top1"><span></span></div>
                  <div class="top2"><span></span><b></b></div>
                </div>
              </li>
            </dd>
          </dl>
          <div class="ids">
            <div class="name">
              <div class="top1"><span>总充值</span>
              </div>
              <div class="top2" :style="{
        color: listobj.totalRecharge == undefined || listobj.totalRecharge == null || listobj.totalRecharge == '' || listobj.totalRecharge == 0 || listobj.totalRecharge > 0 ? 'blue' : 'red'
      }">
                <span>{{ jisuan(listobj.totalRecharge, 100, 2) }}</span>
                <b>元</b>
              </div>
            </div>

            <div class="name">
              <div class="top1"><span>总退款</span>
              </div>
              <div class="top2" :style="{
        color: listobj.totalRefund == undefined || listobj.totalRefund == null || listobj.totalRefund == '' || listobj.totalRefund == 0 || listobj.totalRefund > 0 ? 'blue' : 'red'
      }">
                <span>{{ jisuan(listobj.totalRefund, 100, 2) }}</span>
                <b>元</b>
              </div>
            </div>


            <div class="name">
              <div class="top1"><span>总赠送</span>
              </div>
              <div class="top2" :style="{
        color: listobj.totalGift == undefined || listobj.totalGift == null || listobj.totalGift == '' || listobj.totalGift == 0 || listobj.totalGift > 0 ? 'blue' : 'red'
      }">
                <span>{{ jisuan(listobj.totalGift, 100, 2) }}</span>
                <b>元</b>
              </div>
            </div>

            <div class="name">
              <div class="top1"><span>总充电消费</span>
              </div>
              <div class="top2" :style="{
        color: listobj.totalCharging == undefined || listobj.totalCharging == null || listobj.totalCharging == '' || listobj.totalCharging == 0 || listobj.totalCharging > 0 ? 'blue' : 'red'
      }">
                <span>{{ jisuan(listobj.totalCharging, 100, 2) }}</span>
                <b>元</b>
              </div>
            </div>

            <div class="name">
              <div class="top1"><span>总占位消费</span>
              </div>
              <div class="top2" :style="{
        color: listobj.totalSpaceOccupancyFee == undefined || listobj.totalSpaceOccupancyFee == null || listobj.totalSpaceOccupancyFee == '' || listobj.totalSpaceOccupancyFee == 0 || listobj.totalSpaceOccupancyFee > 0 ? 'blue' : 'red'
      }">
                <span>{{ jisuan(listobj.totalSpaceOccupancyFee, 100, 2) }}</span>
                <b>元</b>
              </div>
            </div>

            <div class="name">
              <div class="top1"><span>亲情消费</span>
              </div>
              <div class="top2" :style="{
        color: listobj.totalKinship == undefined || listobj.totalKinship == null || listobj.totalKinship == '' || listobj.totalKinship == 0 || listobj.totalKinship > 0 ? 'blue' : 'red'
      }">
                <span>{{ jisuan(listobj.totalKinship, 100, 2) }}</span>
                <b>元</b>
              </div>
            </div>
            <div class="name">
              <div class="top1"><span>异常金额</span>
              </div>
              <div class="top2" :style="{
        color: listobj.totalOwingMoney == undefined || listobj.totalOwingMoney == null || listobj.totalOwingMoney == '' || listobj.totalOwingMoney == 0 ? 'blue' : 'red'
      }">
                <span>{{ jisuan(listobj.totalOwingMoney, 100, 2) }}</span>
                <b>元</b>
              </div>
            </div>
          </div>
          <div class="footername">
            <span v-for="(item, index) in listname" :style="{
        color: listindex == index ? 'blue' : '#000'
      }" @click="listMethods(index)">{{ item }}</span>
          </div>
        </div>
        <div class="bottom">
          <h4>
            车牌列表
          </h4>
          <el-table style="width: 100%" :data="listTable" empty-text="暂无数据">
            <el-table-column align="center" label="车牌" prop="carNum">
              <template scope="scope">
                <div>
                  {{ scope.row.carNum !== '' ? scope.row.carNum : '--' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="车架号" prop="vin">
              <template scope="scope">
                <div>
                  {{ scope.row.vin !== '' ? scope.row.vin : '--' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="车辆品牌" prop="carName">
              <template scope="scope">
                <div>
                  {{ scope.row.carName !== '' ? scope.row.carName : '--' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="是否默认" prop="isDefault">
              <template scope="scope">
                <div>
                  {{ scope.row.isDefault == 1 ? '默认' : '--' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="创建时间" prop="createTime" width="100">
              <template scope="scope">
                <span>
                  {{ scope.row.createTime !== '' ? scope.row.createTime : '---' }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="footer">
          <!-- 纵向 -->
          <li>
            <!-- 横向均分 -->
            <p>
              <span>是否关联亲情副号：</span>
              <span>{{ typIng == true ? '是' : '否' }}</span>
            </p>
            <p>
              <span>亲情主号：</span>
              <span>{{ typIng == true ? listobj.mainCard.phone : '---' }}</span>
            </p>
            <p>
              <span>亲情主号余额：</span>
              <span>{{ typIng == true ? jisuan(listobj.mainCard.money, 100, 2) : '---' }}元</span>
            </p>
          </li>
          <li>
            <p>
              <span>近30天的充电数据</span>
            </p>
            <p>
              <span>充电次数：</span>
              <span>{{ listobj.totalBillCount }}次</span>
            </p>
            <p>
              <span>充电电量：</span>
              <span>{{ jisuan(listobj.totalBillKwhs, 100, 4) }}度</span>
            </p>
            <p>
              <span>充电费用：</span>
              <span>{{ jisuan(listobj.totalBillMoney, 100, 2) }}元</span>
            </p>
          </li>
        </div>
      </div>
    </el-dialog>
    <!-- 历史订单 -->
    <el-dialog :visible.sync="dialoagBtn" width="80%" @close="MoneyDialoag2" append-to-body
      :close-on-click-modal="false">
      <div class="Gomoney">
        <h4>{{ listindex == 3 ? '历史充电消费' : '历史占位消费' }}</h4>
        <div class="moneylist" v-if="list3.length > 0">
          <dl v-if="listindex == 3">
            <p>订单金额（元）</p>
            <p>{{ jisuan(moren.totalBillMoney, 100, 2) }}</p>
          </dl>
          <dl v-if="listindex == 3">
            <p>电费金额（元）</p>
            <p>{{ jisuan(moren.totalChargeMoney, 100, 2) }}</p>
          </dl>
          <dl v-if="listindex == 3">
            <p>优惠金额（元）</p>
            <p>{{ jisuan(moren.totalDiscountMoney, 100, 2) }}</p>
          </dl>
          <dl v-if="listindex == 3">
            <p>服务费金额（元）</p>
            <p>{{ jisuan(moren.totalServiceMoney, 100, 2) }}</p>
          </dl>
          <dl v-if="listindex == 3">
            <p>实际收入（元）</p>
            <p>{{ shiji(moren.totalServiceMoney, moren.totalChargeMoney) }}</p>
          </dl>
          <dl v-if="listindex == 3">
            <p>充电电量（kwh）</p>
            <p>{{ jisuan(moren.totalBillKwhs, 100, 4) }}</p>
          </dl>
          <dl v-if="listindex == 3">
            <p>充电时间（分钟）</p>
            <p>{{ jisuan(moren.totalLengthTime, 60, 0) }}</p>
          </dl>
          <dl v-if="listindex == 3">
            <p>充电次数</p>
            <p>{{ moren.totalBillCount }}</p>
          </dl>
          <dl v-if="listindex == 4">
            <p>停车总时长（小时）</p>
            <p>{{ jisuan(moren.totalParkTimeLength, 60, 2) }}</p>
          </dl>
          <dl v-if="listindex == 4">
            <p>停车优惠总时长（小时）</p>
            <p>{{ jisuan(moren.totalParreDuctionTimeLength, 60, 2) }}</p>
          </dl>
          <dl v-if="listindex == 4">
            <p>停车总额（元）</p>
            <p>{{ jisuan(moren.totalParkMoney, 60, 2) }}</p>
          </dl>
          <dl v-if="listindex == 4">
            <p>停车优惠总额（元）</p>
            <p>{{ jisuan(moren.totalParDiscountMoney, 60, 2) }}</p>
          </dl>
          <dl v-if="listindex == 4">
            <p>停车实收总额（元）</p>
            <p>{{ shifu(moren.totalParkMoney, moren.totalParDiscountMoney) }}</p>
          </dl>
          <dl v-if="listindex == 4">
            <p>停车总数</p>
            <p>{{ moren.parkBillCountTotal==null||moren.parkBillCountTotal==undefined||moren.parkBillCountTotal==''?'--':moren.parkBillCountTotal }}</p>
          </dl>
        </div>
        <div class="list3" v-for="(item, index) in list3" :key="index">
          <h4
            style="display:flex; flex-direction:row;align-items:center; background:#e5e5e5;padding:10px 0;font-size:16px;">
            <span style="flex:1;padding-left:10px;font-size:14px;">{{ time_all(item) }}</span>
            <span v-if="listindex == 3" style="flex:2;display:flex;justify-content:center;"> Vin码：{{ item.carvin !== ''
        ?
        item.carvin : '--'
              }}</span>
            <span style="flex:3;display:flex;justify-content:center;"> {{ item.createTime !== '' ? item.createTime :
        '--'
              }}</span>
            <span style="flex:1;display:flex;justify-content:center;">ID:{{ item.id }}</span>
            <span style="flex:1;display:flex;justify-content:center;" v-if="listindex == 3"> 充电方式:{{
        item.chargeMode == 0 ? '微信' : item.chargeMode == 1 ? '在线卡' : item.chargeMode == 2 ? '离线卡' :
          item.chargeMode ==
            3 ? '管理卡' : item.chargeMode == 4 ? 'VIN' : item.chargeMode == 5 ? '微信' : item.chargeMode == 6 ? '互联互通' :
              item.chargeMode == 7 ? '支付宝' : item.chargeMode == 8 ? 'APP' : 'OCPP_APP'
      }}</span>
            <span style="flex:2;display:flex;justify-content:center;"> {{ listindex == 3 ? '充电桩' : '地锁' }}编码:{{
        item.pileCode
      }}</span>
       <span style="flex:3;display:flex;justify-content:center;" v-if="listindex == 4"> 地锁地址:{{
       item.pileProtocolAddress
      }}</span>
          </h4>
          <div class="CONTENT" style="border:1px solid gray;margin:20px 0;border-radius:15px;">
            <dl style="display:flex; flex-direction:row;">
              <dt style="display:flex;align-items:center;flex:1;">
                <el-image :src="tupian + 'morentouxiang.png'" style="width: 200px; height: 200px"></el-image>
              </dt>
              <dd style="display:flex; flex-direction:column;flex:9;" v-if="listindex==3">
                <!-- 纵向均分两行 -->
                <div class="li1" style="flex:1;display:flex; flex-direction:row;">
                  <!-- 横向，均分5列 -->
                  <div class="hengxiang" style="flex:3;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;">{{ item.stationName }}</span>
                    <span style="flex:1;display:flex;align-items:center;">充电口：{{ item.gunNumber == 1 ? 'A枪' : 'B枪'}}</span>
                    <span style="flex:1;display:flex;align-items:center;">充电时长：{{jisuan(item.totalLengthTime,60, 0)}}分钟</span>
                  </div>
                  <div class="hengxiang" style="flex:2;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;"></span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:-15%;">开始充电：{{item.startTime}}</span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:-15%;">结束充电：{{item.endTime}}</span>
                  </div>
                  <div class="hengxiang" style="flex:2;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;"></span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:25%;">开始SOC：{{item.startSoc}}</span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:25%;">结束SOC：{{item.endSoc}}</span>
                  </div>
                  <!-- span 纵向 -->
                  <div class="hengxiang" style="flex:1;display:flex; flex-direction:column;">
                    <span style="flex:1;"></span>
                    <span style="flex:1;display:flex;align-items:center;">￥{{jisuan(item.billMoney, 100, 2)}}</span>
                    <span style="flex:1;display:flex;align-items:center;">当前订单金额 :</span>
                  </div>
                  <!-- span 纵向 -->
                  <div class="hengxiang" style="flex:2;display:flex; flex-direction:column;">
                    <span style="flex:1;"></span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:25%;">￥{{shifu(item.billMoney, item.billMoneygive)}}</span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:25%;">实际订单金额</span>
                  </div>
                </div>
                <div class="li2" style="flex:1;display:flex; flex-direction:row;">
                  <div class="hengxiang" style="flex:3;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;">尖电量：{{ jisuan(item.tipKwh, 100, 4)}}（度）</span>
                    <span style="flex:1;display:flex;align-items:center;">峰电量：{{ jisuan(item.peakKwh, 100, 4) }}（度）</span>
                  </div>
                  <div class="hengxiang" style="flex:2;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;margin-left:-15%;">平电量：{{jisuan(item.flatKwh, 100, 4)}}（度）</span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:-15%;">谷电量：{{jisuan(item.valleyKwh, 100, 4) }}（度）</span>
                  </div>
                  <div class="hengxiang" style="flex:2;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;margin-left:25%;">电费：{{ jisuan(item.chargemoney, 100, 2)}}</span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:25%;">服务费：{{ jisuan(item.servicemoney, 100, 2)}}</span>
                  </div>
                  <!-- span 纵向 -->
                  <div class="hengxiang" style="flex:1;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;">{{ jisuan(item.totalKwh, 100, 4)  }}</span>
                    <span style="flex:1;display:flex;align-items:center;">已充电量（度）</span>
                  </div>
                  <!-- span 纵向 -->
                  <div class="hengxiang" style="flex:2;display:flex; flex-direction:column;">
                    <span :style="{ color: item.payState !== 1 ? 'red' : 'green' }"  style="flex:1;display:flex;align-items:center;margin-left:25%;">{{ item.payState !== 1 ? '未结算' :  '已支付'  }}</span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:25%;color:red" v-if="item.payState !== 1">去结算</span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:25%;color:orange">推送</span>
                  </div>
                </div>
              </dd>

              <dd style="display:flex; flex-direction:column;flex:9;" v-else>
                <!-- 纵向均分两行 -->
                <div class="li1" style="flex:1;display:flex; flex-direction:row;">
                  <!-- 横向，均分5列 -->
                  <div class="hengxiang" style="flex:3;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;">{{ item.stationName }}</span>
                    <span style="flex:1;display:flex;align-items:center;">关联桩号：{{ item.pileGl == null || item.pileGl == undefined || item.pileGl == '' ? '--' : item.pileGl}}</span>
                    <span style="flex:1;display:flex;align-items:center;">关联枪号：{{ item.gunNumber == 1 ? 'A枪' : item.gunNumber == 2 ?'B枪':item.gunNumber == 3 ? 'C枪':'--'}}</span>
                    <span style="flex:1;display:flex;align-items:center;">停车占位时长：{{ jisuan(item.parkingOccupancyDuration,60, 0)}}小时</span>
                    <span style="flex:1;display:flex;align-items:center;">停车费用：￥{{ jisuan(item.parkingFees,100, 2)}}</span>
                  </div>
                  <div class="hengxiang" style="flex:3;display:flex; flex-direction:column;">
                    <span style="flex:1;display:flex;align-items:center;"></span>
                    <span style="flex:1;display:flex;align-items:center;">停车时间：{{ item.parkTime }}</span>
                    <span style="flex:1;display:flex;align-items:center;">驶离时间：{{ item.departureTime }}</span>
                    <span style="flex:1;display:flex;align-items:center;">停车减免时长：{{ jisuan(item.parkingReductionDuration, 60, 2) }}（小时）</span>
                    <span style="flex:1;display:flex;align-items:center;">订单优惠金额：{{ jisuan(item.billMoneygive, 100, 2) }}</span>
                  </div>

                  <!-- span 纵向 -->
                  <div class="hengxiang" style="flex:3;display:flex; flex-direction:column;">
                    <span style="flex:1;"></span>
                    <span style="flex:1;display:flex;align-items:center;">用户手机号：{{ item.phone }}</span>
                    <span style="flex:1;display:flex;align-items:center;">用户唯一编码：{{ item.userNumber }}</span>
                    <span style="flex:1;display:flex;align-items:center;">实际计费时长：{{ jisuan(item.parkingReductionDurationgive,60,2) }}（小时）</span>
                    <span style="flex:1;display:flex;align-items:center;">实际收入：￥{{jisuan(item.reallyParkingFees, 100, 2) }}</span>
                  </div>
                  <div class="hengxiang" style="flex:1;display:flex; flex-direction:column;">
                    <span :style="{ color: item.payState !== 1 ? 'red' : 'green' }"  style="flex:1;display:flex;align-items:center;margin-left:25%;">{{ item.payState !== 1 ? '未结算' :  '已支付'  }}</span>
                    <span style="flex:1;display:flex;align-items:center;margin-left:25%;color:red" v-if="item.payState !== 1">去结算</span>
                  </div>
                  <!-- span 纵向 -->
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <el-pagination @size-change="handleSizeChange3" @current-change="handleCurrentChange3"
          :current-page="indexobj.page" :page-sizes="[8, 20, 50, 100]" :page-size="carInfo.limit"
          layout="total, sizes, prev, pager, next, jumper" :total="total3" background>
        </el-pagination>
      </div>
    </el-dialog>
    <!-- 即充即退对话框 -->
    <el-dialog :visible.sync="gDialoag2" width="80%" @close="MoneyDialoag" append-to-body :close-on-click-modal="false">
      <div class="Gomoney">
        <h4>即充即退记录</h4>
        <div class="moneylist">
          <dl>
            <p>总充值（元）</p>
            <p>{{ jisuan(listalls.totalRechargeMoney, 100, 2) }}元</p>
          </dl>
          <dl>
            <p>总退款（元）</p>
            <p>{{ jisuan(listalls.totalRefundMoney, 100, 2) }}元</p>
          </dl>
          <dl>
            <p>即充即退总消费（元）</p>
            <p>{{ jisuan(listalls.totalConsumptionMoney, 100, 2) }}元</p>
          </dl>
        </div>
      </div>
      <el-table :data="Moneylist" border width="80%">
        <el-table-column align="center" label="用户ID" prop="id"></el-table-column>
        <el-table-column align="center" label="唯一编号" prop="userNumber"></el-table-column>
        <el-table-column align="center" label="变动金额" prop="changMoney" width="100">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.usingType == 0 || scope.row.usingType == 4 || scope.row.usingType == 6 ? 'danger' : scope.row.usingType == 1 ? 'success' : 'warning'">￥{{
        scope.row.changMoney / 100 }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作类型" prop="changMoney" width="100">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.usingType == '0' || scope.row.usingType == '4' || scope.row.usingType == '6' ? 'danger' : scope.row.usingType == 1 ? 'success' : 'warning'">{{
        scope.row.usingType == 0 ? '消费' : scope.row.usingType == 1 ? '充值' : scope.row.usingType == 2 ? '退款' :
          scope.row.usingType == 3 ? '赠送' : scope.row.usingType == 4 ? '欠费' : scope.row.usingType == 5 ? '后台赠送'
            : scope.row.usingType == 6 ? '后台退赠送' : '' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="流水单号" prop="billCode"></el-table-column>
        <el-table-column align="center" label="备注说明" prop="des"></el-table-column>
        <el-table-column align="center" label="操作时间" prop="userNumber" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.dcreateTime != null">{{ scope.row.dcreateTime }}</span><span v-else>{{
        scope.row.createTime }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
        :current-page="carInfo.page" :page-sizes="[8, 20, 50, 100]" :page-size="carInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total2" background>
      </el-pagination>
    </el-dialog>
    <!-- 记录的表格 -->
    <el-dialog :visible.sync="currentDialoag" width="80%" @close="currentMethods" append-to-body
      :close-on-click-modal="false" :title="listindex == 0 ? '账户记录' : '绑定钱包记录'">
      <p v-if="listindex == 2">
        <!-- 绑定站列表 -->
        <el-select v-model="stationId_userid" placeholder="请选择" @change="changeaMethods" @clear="getWechartList"
          filterable>
          <el-option v-for="item in stationList" :key="item.stationId" :label="item.stationName"
            :value="item.stationId">
          </el-option>
        </el-select>
      </p>
      <el-table :data="Moneylist" border width="80%">
        <el-table-column align="center" label="用户ID" prop="id"></el-table-column>
        <el-table-column align="center" label="唯一编码" prop="userNumber"></el-table-column>
        <el-table-column align="center" label="变动金额" prop="changMoney">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.usingType == 0 || scope.row.usingType == 6 || scope.row.usingType == 2 || scope.row.usingType == 4 ? 'danger' : scope.row.usingType == 1 || scope.row.usingType == 5 ? 'success' : scope.row.usingType == 3 ? 'warning' : ''">￥{{
        scope.row.changMoney / 100 }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作类型" prop="usingType">
          <template slot-scope="scope">
            <el-tag :type="scope.row.usingType == 0 || scope.row.usingType == 6 || scope.row.usingType == 4 || scope.row.usingType == 2 ? 'danger' : scope.row.usingType == 1 || scope.row.usingType == 5 ? 'success' :
        scope.row.usingType == 3 ? 'warning' : ''">{{
              scope.row.usingType == 0 ? '消费' : scope.row.usingType == 1 ? '充值' : scope.row.usingType == 2 ? '退款' :
              scope.row.usingType == 3 ? '赠送' : scope.row.usingType == 4 ? '欠费' : scope.row.usingType == 5 ? '后台赠送'
              : '后台退赠送'
              }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="流水单号" prop="billCode"></el-table-column>
        <el-table-column align="center" label="备注说明" prop="des"></el-table-column>
        <el-table-column align="center" label="操作时间">
          <template slot-scope="scope">
            <span v-if="scope.row.dcreateTime != null">{{ scope.row.dcreateTime }}</span><span v-else>{{
              scope.row.createTime }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
        :current-page="carInfo.page" :page-sizes="[8, 20, 50, 100]" :page-size="carInfo.limit"
        layout="total, sizes, prev, pager, next, jumper" :total="total2" background>
      </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import caozuo from "@/utils/pubilc.js";
import { saveBlobToFile } from '@/utils/saveBlobToFile';
export default {
  name: 'WechartAccount',
  data() {
    return {
      currentName: 0,
      recordlist: [
        {
          id: 0,
          name: '导出消费记录'
        }, {
          id: 1,
          name: '导出即充即退消费记录'
        }, {
          id: 2,
          name: '导出绑定金额消费记录'
        }
      ],
      currentIndex: 0,
      typIng: false,
      miniurl: '',
      miniurlList: [],
      listalls: {
        totalRechargeMoney: 0,
        totalRefundMoney: 0,
        totalConsumptionMoney: 0
      },
      moren: {
        totalBillMoney: 0,
        totalchargeMoney: 0,
        totalDiscountMoney: 0,
        totalServiceMoney: 0,
        totalBillKwhs: 0,
        totalLengthTime: 0,
        totalBillCount: 0
      },
      // 历史记录
      indexobj: {
        userId: 0,
        page: 1,
        limit: 8,
        consumeType: 0
      },
      total3: 0,
      dialoagBtn: false,
      list3: [],
      listobj: {

      },
      total2: 0,
      carInfo: {
        usingType: null,
        billInfoType: 2,
        gunState: '0,3,4,5,6,7',
        adminId: window.sessionStorage.getItem('adminId'),
        page: 1,
        limit: 8,
        payId: '',
        userNumber: '',
        billCode: '',
        pileCode: '',
        consumeType: 0,
        type: 2,
        stationIds: window.sessionStorage.getItem('stationIds'),
        roleId: window.sessionStorage.getItem('roleId'),
        userId: ''
      },
      currentDialoag: false,
      listindex: 0,
      tupian: this.$imgUrls,
      listTable: [],
      Moneylist: [],
      gDialoag2: false,
      listname: [
        '查看账户记录',
        '查看即充即退记录',
        '查看绑定钱包记录',
        '查看历史充电消费',
        '查看历史占位消费',
        // '查看历史亲情消费',
      ],
      listall: [],
      leaavel: [
        {
          type: 0
        },
        {
          type: 1
        }
      ],
      suoshudianzhan: '',
      powerStationList: [],
      listType: [
        {
          value: 0,
          name: '免费'
        },
        {
          value: 10,
          name: '1折'
        },
        {
          value: 15,
          name: '1.5折'
        },
        {
          value: 20,
          name: '2折'
        },
        {
          value: 25,
          name: '2.5折'
        }, {
          value: 30,
          name: '3折'
        },
        {
          value: 35,
          name: '3.5折'
        }, {
          value: 40,
          name: '4折'
        },
        {
          value: 45,
          name: '4.5折'
        }, {
          value: 50,
          name: '5折'
        },
        {
          value: 55,
          name: '5.5折'
        }, {
          value: 60,
          name: '6折'
        },
        {
          value: 65,
          name: '6.5折'
        }, {
          value: 70,
          name: '7折'
        },
        {
          value: 75,
          name: '7.5折'
        }, {
          value: 80,
          name: '8折'
        },
        {
          value: 85,
          name: '8.5折'
        }, {
          value: 90,
          name: '9折'
        },
        {
          value: 95,
          name: '9.5折'
        }, {
          value: 100,
          name: '不打折'
        }
      ],
      tab: false,
      tupian: this.$imgUrls,
      loading: false,
      titles: '添加Vip用户',
      qunxian: false,
      active: {
        ids: '',
        discounType: null,
        discounType: 3,
        discountRate: 100
      },
      vipdialoag: false,
      yonghu_dialoag: false,
      Vip: false,
      value1: ['', ''],
      changyong: '',
      // 折扣类型
      options_active: [{
        discounType: 1,
        typeName: '电费'
      }, {
        discounType: 2,
        typeName: '服务费'
      }, {
        discounType: 3,
        typeName: '订单金额'
      }],
      options_user: [
        {
          type: 0,
          typeName: '全部'
        }, {
          type: 1,
          typeName: '微信'
        },
        {
          type: 2,
          typeName: '支付宝'
        }, {
          type: 3,
          typeName: 'APP'
        },
        {
          type: 4,
          typeName: 'ocpp_APP'
        }
      ],
      daochuForm: {
        startTime: '',
        endTime: '',
        usingType: '',
        adminId: Number(window.sessionStorage.getItem('adminId')),
      },
      pickerMinDate: '',
      pickerOptions: {
        onPick: obj => {
          this.pickerMinDate = new Date(obj.minDate).getTime()
          const start = this.formatDate(obj.minDate, 'start')
          const end = this.formatDate(obj.maxDate, 'end')
          obj.maxDate && (this.value1 = [start, end])
        },
        disabledDate: time => {
          if (this.pickerMinDate) {
            const oneMonth = 1000 * 3600 * 24 * 31
            const maxTime = this.pickerMinDate + oneMonth
            const mixTime = this.pickerMinDate - oneMonth + 1000 * 3600 * 24
            const tomorrow = new Date(this.formatDate(new Date().getTime() + 1000 * 3600 * 24, 'start'))
            return time.getTime() >= tomorrow || time.getTime() >= maxTime || time.getTime() < mixTime
          }
        }
      },
      typelist: [
        {
          usingType: '0',
          typeName: '消费'
        },
        {
          usingType: '1',
          typeName: '充值'
        },
        {
          usingType: '2',
          typeName: '退款'
        },
        {
          usingType: '3',
          typeName: '赠送'
        }
      ],
      // 即充即退
      typelist1: [
        {
          usingType: '0',
          typeName: '消费'
        },
        {
          usingType: '1',
          typeName: '充值'
        },
        {
          usingType: '2',
          typeName: '退款'
        }
      ],
      // 绑定站
      typelist2: [
        {
          usingType: '0',
          typeName: '消费'
        },
        {
          usingType: '1',
          typeName: '充值'
        },
        {
          usingType: '3',
          typeName: '赠送'
        },
        {
          usingType: '5',
          typeName: '后台退赠'
        }
      ],
      daochu_dialoag: false,
      daochu: false,
      num: 0,
      zengsong: false,
      jinyong: false,
      zhuangTypelist: [],
      queryInfo: {
        nickName: '',
        userName: '',
        userNumber: '',
        stationIds: '',
        phone: '',
        adminId: window.sessionStorage.getItem('adminId'),
        roleId: window.sessionStorage.getItem('roleId'),
        page: 1,
        limit: 6,
        discounType: null,
        type: 0,
        stationMode: '0,1,2',
        appId: ''
      },
      xiaofeiInfo: {
        nickName: '',
        userName: '',
        userNumber: '',
        // stationId:0,
        phone: '',
        adminId: window.sessionStorage.getItem('adminId'),
        page: 1,
        limit: 8
      },
      // 微信用户列表
      wchartlist: [],
      radio: 0,
      wechartForm: {
        // 默认赠送
        btn: '赠送',
        money: 0,
        type: 0,
        stationId: ''
      },
      // 详情弹出层
      Detaildialoag: false,
      // 总数据条数
      total: 0,
      expendTotal: 0,
      xiaofeiTotal: 0,
      title: '',
      editDialogVisible: false,
      value: '',
      expendList: [],
      listStation: [],
      expendDialogVisible: false,
      ids: '',
      value: '',
      Idcard: '',
      xiaofeilist: [],
      stationId_userid: '',
      stationList: [],
      caozuoquanxian: false,
      xiaofei: {
        page: 1,
        limit: 8,
        userNumber: null,
        adminId: window.sessionStorage.getItem('adminId')
      },
      dangqian: { id: 0, status: 0 },
      xf: null
    }
  },
  computed: {
    shiji() {
      return function (abc, bac) {
        return (((abc * 1) + (bac * 1)) / 100).toFixed(2)
      }
    },
    wait_s() {
      return function (e) {
        return ((e.money - e.giveMoney) / 100).toFixed(2)
      }
    },
    time_all() {
      return function (val) {
        if (this.listindex == 3) {
          const hr = val.billCode.slice(val.pileCode.length, val.billCode.length)
          return hr
        } else {
          const hr = val.parkBillCode.slice(val.pileCode.length, val.parkBillCode.length)
          return hr
        }
      }
    },
    jisuan() {
      return function (obj, num, fixed) {
        if (obj == null || obj == undefined || obj == ''||obj==0) return '0';
        return (obj / num).toFixed(fixed)
      }
    },
    shifu() {
      return function (abc, bac) {
        return ((abc - bac) / 100).toFixed(2)
      }
    }
  },
  created() {
    const that = this;
    caozuo(151).then(res => {
      that.zengsong = res;
    });
    caozuo(98).then(res => {
      that.jinyong = res;
    });
    caozuo(98).then(res => {
      that.daochu = res;
    });
    caozuo(167).then(res => {
      that.Vip = res;
    });
    caozuo(169).then(res => {
      that.qunxian = res;
    });
    that.defaultMethods();
    that.stationNameMethods();
  },
  methods: {
    clearMethods() {
      this.miniurl = '';
      this.queryInfo.appId = '';
      that.getWechartList()
    },
    chageMini(e) {
      this.queryInfo.appId = e;
      this.getWechartList()
    },
    titleMethods(e) {
      this.wechartForm.stationId = e;
      this.showPresent2()
    },
    // 查询
    getWechartList2() {
      const that = this;
      if (window.sessionStorage.getItem('roleId') == 11) {
        that.queryInfo.appId = 1
      } else {
        that.queryInfo.appId = that.miniurl
      }
      that.getWechartList()
    },
    forlist() {
      this.$http.get('/station-info/getAllStationInfoNameByAppId', {
        params: {
          roleId: window.sessionStorage.getItem('roleId'),
          appId: this.wechartForm.appId,
          stationMode: '0,1',
          stationIds: window.sessionStorage.getItem('stationIds')
        }
      }).then(res => {
        if (res.data.code !== 0) return;
        this.listStation = res.data.data;
        this.wechartForm.stationId = res.data.data[0].id;
        this.showPresent2()
      })
    },
    currentMethods() {
      this.stationId_userid = '';
      this.list3 = [];
      this.total2 = 0;
      this.total3 = 0;
      this.Moneylist = [];
      this.currentDialoag = false;
    },
    listMethods(e) {
      const that = this;
      that.listindex = e;
      that.Moneylist = [];
      that.list3=[];
      that.total3=0;
      that.total2 = 0;
      that.carInfo.page = 1;
      that.carInfo.limit = 8;
      if (that.listindex == 0 || that.listindex == 2) {
        if (that.listindex == 0) {
          that.carInfo.usingType = null;
          // 0,1,2账户记录
          that.$http.get('charge-user-info/getRecordsList', {
            params: that.carInfo
          }).then(res => {
            that.currentDialoag = true;
            if (res.data.code !== 0) return;
            that.Moneylist = res.data.data;
            that.total2 = res.data.count;
          })
        } else {
          // 绑定钱包记录
          that.carInfo.type = 2;
          that.carInfo.usingType = '';
          that.carInfo.stationIds = that.stationId_userid;
          that.$http.get('charge-user-info/getAllConsumeLogAnyTimeByUserId', {
            params: that.carInfo
          }).then(ress => {
            if (ress.data.code !== 0) return;
            that.Moneylist = ress.data.data;
            that.listalls = {
              totalConsumptionMoney: ress.data.data[0].totalConsumptionMoney,
              totalRechargeMoney: ress.data.data[0].totalRechargeMoney,
              totalRefundMoney: ress.data.data[0].totalRefundMoney
            };
            that.total2 = ress.data.count;
          })
          that.currentDialoag = true;
        }
      } else {
        // 即充即退
        if (that.listindex == 1) {
          that.carInfo.type = 1;
          that.carInfo.usingType = '';
          that.carInfo.stationIds = '';
          that.$http.get('charge-user-info/getAllConsumeLogAnyTimeByUserId', {
            params: that.carInfo
          }).then(ress => {
            if (ress.data.code !== 0) return;
            that.Moneylist = ress.data.data;
            that.total2 = ress.data.count;
            that.listalls = {
              totalConsumptionMoney: ress.data.data[0].totalConsumptionMoney,
              totalRechargeMoney: ress.data.data[0].totalRechargeMoney,
              totalRefundMoney: ress.data.data[0].totalRefundMoney
            };
          })
          that.gDialoag2 = true;
        } else {
          // 历史充电消费记录
          if (that.listindex == 3) {
            that.indexobj.roleId = window.sessionStorage.getItem('roleId');
            that.indexobj.stationIds = window.sessionStorage.getItem('stationIds');
            that.indexobj.gunState = '0,3,4,5,6,7';
            that.$http.get('charge-user-info/getAllBillByUserId', {
              params: that.indexobj
            }).then(ress => {
              if (ress.data.code !== 0) return;
              that.list3 = ress.data.data;
              that.total3 = ress.data.count;
              that.moren = {
                totalBillMoney: ress.data.data[0].totalBillMoney,
                totalChargeMoney: ress.data.data[0].totalChargeMoney,
                totalDiscountMoney: ress.data.data[0].totalDiscountMoney,
                totalServiceMoney: ress.data.data[0].totalServiceMoney,
                totalBillKwhs: ress.data.data[0].totalBillKwhs,
                totalLengthTime: ress.data.data[0].totalLengthTime,
                totalBillCount: ress.data.data[0].totalBillCount,
              }
            })
            that.dialoagBtn = true;
          } else {
            // 历史占位消费
            that.$http.get('charge-user-info/getAllParkBillByUserId', {
              params: {
                startType: 0,
                userNumber: String(that.listobj.userNumber),
                page: that.indexobj.page,
                limit: that.indexobj.limit
              }
            }).then(ress => {
              if (ress.data.code !== 0) return;
              that.list3 = ress.data.data;
              that.total3 = ress.data.count;
              that.moren = {
                totalBillMoney: ress.data.data[0].totalBillMoney,
                totalChargeMoney: ress.data.data[0].totalChargeMoney,
                totalDiscountMoney: ress.data.data[0].totalDiscountMoney,
                totalServiceMoney: ress.data.data[0].totalServiceMoney,
                totalBillKwhs: ress.data.data[0].totalBillKwhs,
                totalLengthTime: ress.data.data[0].totalLengthTime,
                totalBillCount: ress.data.data[0].totalBillCount,
              }
            })
            that.dialoagBtn = true;
          }
        }


      }
    },
    MoneyDialoag() {
      this.gDialoag2 = false;
    },
    MoneyDialoag2() {
      this.dialoagBtn = false;
    },
    changeaMethods(e) {
      this.carInfo.stationIds = e;
      this.carInfo.page = 1;
      this.carInfo.limit = 8;
      this.getCarinfo();
    },
    // 关闭详情
    DetailBtn() {
      this.Detaildialoag = false;
    },
    // 用户详情
    detailMethods(e) {
      const that = this;
      that.indexobj.userId = e.id;
      that.$http.get('charge-user-info/getAllStationIdsByUserId', {
        params: {
          userId: e.id,
          page: 1,
          limit: 100
        }
      }).then(resd => {
        if (resd.data.code !== 0) return;
        that.stationId_userid = resd.data.data[0].stationId;
        that.stationList = resd.data.data;
      });
      that.$http.get(`charge-user-info/getNewChargeUserInfoById/${e.id}/${window.sessionStorage.getItem('roleId') == 1 ? 0 : window.sessionStorage.getItem('stationIds')}`).then(res => {
        if (res.data.code !== 0) return;
        that.listobj = res.data.data;
        if (res.data.data.mainCard == null && (res.data.data.deputyCardList == null || res.data.data.deputyCardList.length == 0)) {
          that.typIng = false;
        } else {
          if (res.data.data.mainCard == null && (res.data.data.deputyCardList !== null || res.data.data.deputyCardList.length !== 0)) {
            that.typIng = res.data.data.deputyCardList.length < 1 ? false : true;
            that.listobj.phone = res.data.data.deputyCardList.length < 1 ? '' : res.data.data.deputyCardList.map(item => item.phone).join(',');
          } else if (res.data.data.mainCard !== null && Array.isArray(res.data.data.mainCard) && (res.data.data.deputyCardList == null || res.data.data.deputyCardList.length == 0)) {
            that.typIng = Array.isArray(res.data.data.mainCard) ? false : true;
            that.listobj.phone = Array.isArray(res.data.data.mainCard) ? res.data.data.mainCard.phone : res.data.data.mainCard.map(item => item.phone).join(',');
          }
        }
        that.listTable = res.data.data.carlist;
        that.$http.get(`applet/getAppletAppById/${that.listobj.appletAppId}`).then(ress => {
          if (ress.data.code !== 0) return;
          that.listobj.appname = ress.data.data.appname;
          that.carInfo = {
            payId: e.id,
            usingType: 0,
            billInfoType: 2,
            adminId: window.sessionStorage.getItem('adminId'),
            page: 1,
            limit: 8,
            userNumber: e.userNumber,
            billCode: e.billCode,
            pileCode: e.pileCode,
            consumeType: 0,
            stationIds: that.listindex == 4 ? that.stationId_userid : '',
            type: 2,
            userId: e.id
          };
          that.tanceng();
        })
      })

    },
    // 打开详情
    tanceng() {
      this.Detaildialoag = true;
    },
    // 修改场站Id
    change_value(e) {
      this.queryInfo.page = 1;
    },
    // 获取场站列表
    defaultMethods() {
      const that = this;
      that.miniurlList = [];
      that.$http.get('applet/getAllAppletApp', {
        params: {
          adminId: window.sessionStorage.getItem('adminId'),
          page: 1,
          limit: 100
        }
      }).then(ress => {
        if (ress.data.code !== 0) return;
        that.miniurlList = ress.data.data;
        if (window.sessionStorage.getItem('roleId') == 1||window.sessionStorage.getItem('roleId') == 2||window.sessionStorage.getItem('roleId') == 3||window.sessionStorage.getItem('roleId') ==10) {
          that.miniurlList.push({
            id: 0,
            appname: '全部'
          })
        }
        that.miniurl = window.sessionStorage.getItem('roleId') == 1||window.sessionStorage.getItem('roleId') == 2||window.sessionStorage.getItem('roleId') == 3||window.sessionStorage.getItem('roleId') ==10 ? that.miniurlList[that.miniurlList.length - 1].id : that.miniurlList[0].id;
        that.queryInfo.appId = that.miniurl;
        that.getWechartList();
      });
      that.$http.get('station-info/getAllStationInfoName', {
        params: {
          roleId: window.sessionStorage.getItem('roleId'),
          stationIds: window.sessionStorage.getItem('stationIds'),
          stationMode: '0,1,2'
        }
      }).then(resb => {
        if (resb.data.code == 0) {
          resb.data.data.forEach(item => {
            that.powerStationList.push({
              id: item.id,
              stationName: item.stationName
            })
          })
        }
      });
    },
    // 删除
    delMethods(row) {
      this.$http.post(`charge-user-info/delChargeUserInfoById/${row}`).then(res => {
        this.$message({
          message: res.data.msg,
          type: res.data.code !== 0 ? 'warning' : 'success'
        });
        if (res.data.code !== 0) return;
        this.queryInfo.page = 1;
        this.getWechartList();
      })
    },
    // 当前
    current() {
      this.queryInfo.page = 1;
    },
    // 更多
    gengduo() {
      this.tab = !this.tab;
    },
    // 修改最小日期
    pickBlur() {
      this.pickerMinDate = ''
    },
    // 场站列表2
    stationNameMethods() {
      const that = this;
      that.$http.get('station-info/getAllStationInfoName', {
        params: {
          roleId: window.sessionStorage.getItem('roleId'),
          stationIds: window.sessionStorage.getItem('stationIds'),
          stationMode: '0,1,2'
        }
      }).then(res => {
        if (res.data.code == 0) {
          res.data.data.forEach(item => {
            that.zhuangTypelist.push({
              stationIds: item.id,
              stationName: item.stationName
            })
          });
          that.queryInfo.stationIds = '';
          that.suoshudianzhan = ''
        }
      });
    },
    // 更新日期
    formatDate(datetime, type) {
      const time = new Date(datetime)
      const year = time.getFullYear()
      const month = (time.getMonth() + 1).toString().padStart(2, '0')
      const date = (time.getDate()).toString().padStart(2, '0')
      return type === 'start' ? year + '-' + month + '-' + date + ' ' + '00' + ':' + '00' + ':' + '00' : year + '-' + month + '-' + date + ' ' + '23' + ':' + '59' + ':' + '59'
    },
    // 更新日期2
    getDate() {
      const start = new Date()
      this.value1[0] = this.formatDate(start.getTime() - 1000 * 3600 * 24 * 30, 'start')
      this.value1[1] = this.formatDate(start.getTime(), 'end')
    },
    // 清除所选电站
    qingchu() {
      this.queryInfo.stationIds = this.changyong;
      this.getWechartList();
    },
    //清空所选数据,恢复默认数据列表
    chongzhi() {
      this.queryInfo = {
        nickName: '',
        userName: '',
        userNumber: '',
        stationIds: '',
        phone: '',
        adminId: window.sessionStorage.getItem('adminId'),
        roleId: window.sessionStorage.getItem('roleId'),
        type: 0,
        stationMode: '0,1,2',
        page: 1,
        limit: 6,
        discounType: null,
        appId: ''
      };
      this.changyong = '';
      this.value1 = [];
      this.pickerMinDate = '';
      this.total2 = 0;
      this.listobj = {};
      this.Moneylist = [];
      this.listTable = [];
      this.listindex = 0;
      this.miniurl = window.sessionStorage.getItem('roleId') == 1 ? 0 : this.miniurlList[0].id;
      this.queryInfo.appId = this.miniurl;
      this.getWechartList()
    },
    qx_vipdialoag() {
      this.active = {};
      this.vipdialoag = false;
    },
    async bianji(row) {
      this.titles = '编辑Vip用户';
      await this.$http.get(`charge-user-info/getChargeUserInfoById/${row.id}`).then(res => {
        this.vipdialoag = true;
        this.$message({
          message: res.data.msg,
          type: res.data.code == 0 ? 'success' : 'error'
        });
      })
    },
    // 批量修改用户Vip
    genggai(row, type) {
      this.active.ids = String(row.id);
      this.active.discounType = row.discounType;
      this.active.discountRate = type !== 0 ? row.discountRate : 100;
      this.$http.post(`charge-user-info/updateVIPByIds?ids=${this.active.ids}&discounType=${this.active.discounType}&discountRate=${this.active.discountRate}&adminId=${window.sessionStorage.getItem('adminId') * 1}`).then(res => {
        this.$message({
          message: res.data.msg,
          type: res.data.code == 0 ? 'success' : 'error'
        });
        this.getWechartList()
        if (res.data.code == 0) return this.active = {};
      })

    },
    // 添加Vip用户
    qxvipdialoag() {
      this.vipdialoag = false;
      this.$refs.activeRef.validate(async valid => {
        if (!valid) return
        if (this.titles == '添加Vip用户') {
          if (this.ids != '') {
            this.active.ids = this.ids;
            this.$http.post(`charge-user-info/updateVIPByIds?ids=${this.active.ids}&discounType=${this.active.discounType}&discountRate=${this.active.discountRate}&adminId=${window.sessionStorage.getItem('adminId') * 1}`).then(res => {
              this.$message({
                message: res.data.msg,
                type: res.data.code == 0 ? 'success' : 'error'
              })
            });
          } else {
            this.$message.error('请先勾选表格中的vip用户')
          }
        }
      })
    },
    // VIP按钮
    Vipanniu() {
      this.vipdialoag = true;
    },
    yonghu() {
      this.yonghu_dialoag = true;
    },
    data_change(e) {
      if (e.length > 0) {
        this.daochuForm.startTime = e[0];
        this.daochuForm.endTime = e[1];
      } else {
        this.daochuForm.startTime = '';
        this.daochuForm.endTime = '';
      }
    },
    // daochu_change(e) {
    //   this.daochuForm.usingType = e.toString();
    // },
    // 取消导出
    qx_dialoag() {
      const that = this;
      that.daochuForm = {};
      that.value1 = [];
      this.loading = false;
      that.daochu_dialoag = false;
    },
    // 确定导出
    qd_dialoag() {
      const that = this;
      that.loading = true;
      if (that.value1[0] == '') return that.$message.error('请选择你的导出时间');
      that.daochuForm.adminId = Number(window.sessionStorage.getItem('adminId'));
      if (that.currentName == 0) {
        that.$http({
          url: 'charge-user-info/export',
          method: 'GET',
          params: that.daochuForm,
          responseType: 'blob'
        }).then(res => {
          if (res.data.size !== 0) {
            that.$message.success('消费记录文件导出成功');
            saveBlobToFile(res.data, '个人账户消费记录')
          } else {
            that.$message.error('消费记录不存在')
          };
          that.loading = false;
          that.daochu_dialoag = false;
          that.currentName = 0;
          that.value1 = [];
        })
      } else {
        let abcs = '';
        abcs = that.daochuForm.usingType;
        that.$http({
          url: 'charge-user-info-any-time/export',
          method: 'get',
          params: {
            appId: window.sessionStorage.getItem('appId') * 1,
            roleId: window.sessionStorage.getItem('roleId') * 1,
            type: that.currentName,
            usingType: abcs.toString(),
            stationIds: that.currentName == 1 ? null : window.sessionStorage.getItem('stationIds'),
            startTime: that.daochuForm.startTime,
            endTime: that.daochuForm.endTime
          },
          responseType: 'blob'
        }).then(res => {
          if (res.data.size !== 0) {
            that.$message.success(`${that.currentName == 1 ? '即充即退记录导出成功' : '绑定电站记录成功'}`);
            saveBlobToFile(res.data, '个人账户' + `${that.currentName == 1 ? `即充即退${that.daochuForm.usingType == 0 ? '消费' : that.daochuForm.usingType == 1 ? '充值' : that.daochuForm.usingType == 2 ? '退款' : that.daochuForm.usingType == 3 ? '赠送' :that.daochuForm.usingType == 4 ?'欠费':""}记录` : `绑定电站${that.daochuForm.usingType == 0 ? '消费' : that.daochuForm.usingType == 1 ? '充值' : that.daochuForm.usingType == 2 ? '退款' : that.daochuForm.usingType == 3 ? '赠送':that.daochuForm.usingType == 4 ? '欠费' : "退赠"}记录`}`)
          } else {
            that.$message.error(`${that.currentName == 1 ? `即充即退${that.daochuForm.usingType == 0 ? '消费' : that.daochuForm.usingType == 1 ? '充值' : that.daochuForm.usingType == 2 ? '退款' : that.daochuForm.usingType == 3 ? '赠送' :that.daochuForm.usingType == 4 ?'欠费' : ""}记录` : `绑定电站${that.daochuForm.usingType == 0 ? '消费' : that.daochuForm.usingType == 1 ? '充值' : that.daochuForm.usingType == 2 ? '退款' : that.daochuForm.usingType == 3 ? '赠送':that.daochuForm.usingType == 4 ? '欠费' : "退赠"}记录`}` + '不存在')
          };
          that.loading = false;
          that.daochu_dialoag = false;
          that.value1 = [];
          that.currentName = 0;
        })

      }

    },
    // 导出
    daochuanniu() {
      this.daochu_dialoag = true;
    },
    //常用电站
    chage_zhuangType(e) {
      this.queryInfo.stationIds = e;
      this.queryInfo.page = 1;
    },
    // 禁用状态(实时更新)
    change_type(row) {
      this.dangqian.id = row.id;
      if (row.status == true) {
        this.dangqian.status = 1;
      } else if (row.status == false) {
        this.dangqian.status = 0;
      }
      this.$http.post(`charge-user-info/updateStatusById/${this.dangqian.id}/${this.dangqian.status}`).then(res => {
        this.$message.success(res.data.msg);
        this.getWechartList()
      }).catch(res => {
        this.$message(res.data.msg)
      })
    },
    reset() {
      this.queryInfo.phone = '';
      this.getWechartList()
    },
    changeTime(value) {
      if (value) {
        this.queryInfo.startTime = value[0]
        this.queryInfo.endTime = value[1]
        if (this.queryInfo.startTime == this.queryInfo.endTime) {
          this.$message.error('起始时间不得为同一天！');
          this.queryInfo.startTime = ''
          this.queryInfo.endTime = ''
        }
      } else {
        console.log(value)
        this.queryInfo.startTime = null
        this.queryInfo.endTime = null
        this.getWechartList()
      }
    },
    // 根据分页获取对应的微信用户列表
    getWechartList() {
      const that = this;
      that.total2 = 0;
      that.listobj = {};
      that.listTable = [];
      that.Moneylist = [];
      that.listindex = 0;
      if (that.suoshudianzhan !== '') {
        that.queryInfo.stationIds = that.suoshudianzhan;
      } else {
        that.queryInfo.stationIds = window.sessionStorage.getItem('roleId') == 1 ||
          window.sessionStorage.getItem('roleId') == 2 ||
          window.sessionStorage.getItem('roleId') == 3 ||
          window.sessionStorage.getItem('roleId') == 10
          ? 0 : window.sessionStorage.getItem('stationIds');
      }
      that.queryInfo.appId = that.miniurl;
      that.$http.get('charge-user-info/getAllChargeUserInfo', { params: that.queryInfo }).then(res => {
        that.wchartlist = res.data.code !== 0 ? [] : res.data.count == 0 ? [res.data.data] : res.data.data;
        that.total = res.data.code !== 0 ? 0 : res.data.count == 0 ? 1 : res.data.count;
        if (res.data.code !== 0) return that.$message.error(res.data.msg);
        if (res.data.code == 0) {
          console.log('1650' + that.queryInfo.userNumber)
          if (that.queryInfo.userNumber == '' || that.queryInfo.userNumber == undefined) {

            that.$http.get('charge-user-info/getAllChargeUserInfoStatistics', { params: that.queryInfo }).then(resb => {
              if (resb.data.code !== 0) return;
              that.listall = [
                {
                  name: '用户总数量',
                  countMoney: resb.data.data.totalUserCount
                },
                {
                  name: '钱包余额',
                  countMoney: resb.data.data.totalBalance
                },
                {
                  name: '充值',
                  countMoney: resb.data.data.rechargeMoney
                },
                {
                  name: '退款(含退赠)',
                  countMoney: resb.data.data.refundMoney
                },
                {
                  name: '赠送',
                  countMoney: resb.data.data.totalGive
                }
              ];
            })
          } else {
            that.listall = [
              {
                name: '用户总数量',
                countMoney: res.data.data[0].totalUserCount
              },
              {
                name: '钱包余额',
                countMoney: res.data.data[0].totalBalance
              },
              {
                name: '充值',
                countMoney: res.data.data[0].rechargeMoney
              },
              {
                name: '退款(含退赠)',
                countMoney: res.data.data[0].refundMoney
              },
              {
                name: '赠送',
                countMoney: res.data.data[0].totalGive
              }
            ];
          }

        }

        that.wchartlist.forEach(item => {
          item.status = item.status == 1 ? true : false;
        })
      })

    },
    // 双击展开事件
    dbclick(row, column, event) {
      if (event.currentTarget.querySelector('.el-table__expand-icon')) {
        event.currentTarget.querySelector('.el-table__expand-icon').click()
      }
    },
    // 重推记录列表
    getCarinfo() {
      const that = this;
      that.Moneylist = [];
      if (that.listindex == 0) {
        that.carInfo.usingType = null;
        //账户记录
        that.$http.get('charge-user-info/getRecordsList', {
          params: that.carInfo
        }).then(res => {
          if (res.data.code !== 0) return;
          that.Moneylist = res.data.data;
          that.total2 = res.data.count;
        })
      } else {
        // 即充即退、绑定
        if (that.listindex == 2) {
          that.carInfo.type = 2;
          that.carInfo.usingType = '';
          that.carInfo.stationIds = that.stationId_userid;
          that.$http.get('charge-user-info/getAllConsumeLogAnyTimeByUserId', {
            params: that.carInfo
          }).then(ress => {
            if (ress.data.code !== 0) return;
            that.Moneylist = ress.data.data;
            that.listalls = {
              totalConsumptionMoney: ress.data.data[0].totalConsumptionMoney,
              totalRechargeMoney: ress.data.data[0].totalRechargeMoney,
              totalRefundMoney: ress.data.data[0].totalRefundMoney
            };
            that.total2 = ress.data.count;
          })
          that.currentDialoag = true;
        } else {
          that.carInfo.type = 1;
          that.carInfo.usingType = '';
          that.carInfo.stationIds = '';
          that.$http.get('charge-user-info/getAllConsumeLogAnyTimeByUserId', {
            params: that.carInfo
          }).then(ress => {
            if (ress.data.code !== 0) return;
            that.Moneylist = ress.data.data;
            that.total2 = ress.data.count;
            that.listalls = {
              totalConsumptionMoney: ress.data.data[0].totalConsumptionMoney,
              totalRechargeMoney: ress.data.data[0].totalRechargeMoney,
              totalRefundMoney: ress.data.data[0].totalRefundMoney
            };
          })
          that.gDialoag2 = true;
        }
      }
    },
    getIndex() {
      const that = this; that.list3 = [];
      that.$http.get('charge-user-info/getAllBillByUserId', {
        params: that.indexobj
      }).then(res => {
        if (res.data.code !== 0) return;
        that.list3 = res.data.data;
      })
    },
    handleSizeChange(newSize) {
      this.queryInfo.limit = newSize;
      this.queryInfo.page = 1;
      this.getWechartList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage;
      this.getWechartList()
    },
    // 监听：即充即退条数记录
    handleSizeChange2(newSize) {
      this.carInfo.limit = newSize;
      this.carInfo.page = 1;
      this.getCarinfo()
    },
    // 监听：即充即退页码记录
    handleCurrentChange2(newPage) {
      this.carInfo.page = newPage;
      this.getCarinfo()
    },
    // 监听：充电历史条数记录
    handleSizeChange3(newSize) {
      this.indexobj.limit = newSize;
      this.indexobj.page = 1;
      this.getIndex()
    },
    //监听：充电历史页码记录
    handleCurrentChange3(newPage) {
      this.indexobj.page = newPage;
      this.getIndex()
    },
    // 展示赠送对话框
    showPresent(row) {
      this.wechartForm.id = row.id;
      this.wechartForm.stationId = row.stationId;
      this.wechartForm.appId = row.appletAppId;
      this.forlist();
    },
    showPresent2() {
      this.$http.get('charge-user-info/getAllMoneyByStationId', {
        params: {
          userId: this.wechartForm.id,
          stationId: this.wechartForm.stationId * 1,
          consumeType: 0
        }
      }).then(ress => {
        this.wechartForm.money = ress.data.data.money;
        this.editDialogVisible = true;
      })
    },
    // 赠送、清退事件
    editPresent() {
      const that = this;
      that.editDialogVisible = false;
      that.$refs.editFormRef.validate(async valid => {
        if (!valid) return;
        if (that.num == undefined) return;
        that.wechartForm.adminId = window.sessionStorage.getItem('adminId') * 1;
        that.wechartForm.money = that.num * 100;
        that.wechartForm.consumeType = 0;
        that.$http.post(`charge-user-info/updGiveMoneyById?adminId=` + window.sessionStorage.getItem('adminId') + '&type=' + that.wechartForm.type + '&money=' + that.wechartForm.money + '&consumeType=' + 0 + '&id=' + that.wechartForm.id + '&stationId=' + that.wechartForm.stationId * 1).then(res => {
          if (res.data.code !== 0) return that.$message.error(that.wechartForm.type == 0 ? '赠送失败！' : '清赠费失败！');
          that.$message.success(that.wechartForm.type == 0 ? '赠送成功！' : '清赠费成功！');
          that.wechartForm.type = 0;
          that.wechartForm.money = 0;
          that.wechartForm.giveMoney = 0;
          that.wechartForm.stationId = '';
          that.getWechartList()
        })
      })
    },
    // 赠送对话框的关闭事件
    editDialogClosed() {
      this.num = 0;
      this.editDialogVisible = false;
      this.wchartlist.forEach(element => {
        element.z_s_anniu = false
      });
    },
    // 监听pageSize改变的事件
    // expendSizeChange(newSize) {
    //   this.xiaofei.limit = newSize
    //   this.xiaofei_function()
    // },
    // 监听页码值改变的事件
    // expendCurrentChange(newPage) {
    //   this.xiaofei.page = newPage;
    //   this.xiaofei_function()
    // },
    // 展示消费列表对话框
    // showExpend(row) {
    //   this.expendDialogVisible = true;
    //   this.xiaofei.userNumber = row.userNumber;
    //   row.x_f_j_l_anniu = true;
    //   this.xiaofei.page = 1;
    //   this.xiaofei_function()
    // },
    // async xiaofei_function() {
    //   await this.$http.get('charge-user-info/getRecordsList', {
    //     params: this.xiaofei
    //   }).then(res => {
    //     this.xiaofeiTotal = res.data.count;
    //     this.xiaofeilist = res.data.data;
    //   })
    // },
    async getExpendList() {
      const { data: res } = await this.$http.get('user/getRecordsList', { params: this.xiaofeiInfo })
      if (res.code !== 0) return this.$message.error(res.msg)
      this.$message.success('获取微信用户消费列表成功！')
      this.expendList = res.data;
      this.expendTotal = res.count;
    },
    // 消费列表对话框的关闭事件
    expendDialogClosed() {
      this.expendDialogVisible = false
      this.expendList = []
    },
    // 批量删除的复选框改变事件
    handleSelectionChange(value) {
      if (value.length > 0) {
        this.ids = ''
        value.forEach(item => {
          this.ids += item.id + ','
        })
        this.ids = this.ids.substring(0, this.ids.length - 1)
      } else {
        this.ids = '';
        return false
      }
    },
    // 删除微信用户方法
    async removeById(row) {
      const confirmResult = await this.$confirm('此操作将永久删除该微信用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除！')
      }
      if (row.id === null || row.id === '' || row.id === undefined) {
        const { data: res } = await this.$http.post('user/delUserById/' + this.ids + '/' + window.sessionStorage.getItem('adminId') * 1)
        if (res.code !== 0) {
          return this.$message.error('删除微信用户失败！')
        }
        this.$message.success('删除微信用户成功！')
        this.getWechartList()
      } else {
        const { data: res } = await this.$http.post('user/delUserById/' + row.id + '/' + window.sessionStorage.getItem('adminId') * 1)
        if (res.code !== 0) {
          return this.$message.error('删除微信用户失败！')
        }
        this.$message.success('删除微信用户成功！')
        this.getWechartList()
      }
    }
  }
};
</script>

<style lang="less" scoped>
.Gomoney {
  display: flex;
  flex-direction: column;

  .moneylist {
    height: 180px;
    display: flex;
    flex-direction: row;

    dl {
      flex: 1;
      display: flex;
      flex-direction: column;

      p {
        flex: 1;
        display: flex;
        align-items: center;
      }

    }
  }
}

// 用户详情
.cardslist {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .top,
  .center {
    flex: 2;
    display: flex;
    flex-direction: column;

    dl {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      dt {
        // flex: 2;
        width: 200px;
        display: flex;
        align-items: center;
      }

      dd {
        // flex: 8
        width: calc(100% - 200px);
      }
    }
  }

  .top {
    font-size: 16px;

    dd {
      display: flex;
      flex-direction: row;

      li {
        flex: 1;
        display: flex;
        flex-direction: column;

        p {
          display: flex;
          flex-direction: row;
          align-items: center;
        }


      }

      li:nth-child(2) {
        span:nth-child(1) {
          width: 160px;
        }
      }
    }

  }

  .center {
    border-bottom: 1px solid gray;
    border-top: 1px solid gray;
    padding-bottom: 20px;

    dl {
      flex: 9;
    }

    dd {
      display: flex;
      flex-direction: column;

      li {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid gray;
        padding: 10px 0;

        .name {
          flex: 1;
          margin-right: 30px;
          display: flex;
          flex-direction: column;
          border-right: 1px solid gray;
          color: #000;

          .top1,
          .top2 {
            flex: 1;
          }

          .top1 {
            font-size: 15px;
          }

          .top2 {
            color: blue;
            display: flex;
            flex-direction: row;

            span {
              font-size: 26px;
            }

            b {
              font-size: 16px;
            }

            display: flex;
            margin-top: 10px;
            align-items: center
          }
        }

        .name:last-child {
          border: none;
        }
      }
    }

    .ids {
      border-bottom: 1px solid gray;
      display: flex;
      flex-direction: row;
      height: 60px;
      display: flex;
      flex-direction: row;
      padding: 10px 0;

      .name {
        flex: 1;
        display: flex;
        flex-direction: column;
        flex: 1;
        border-right: 1px solid gray;
        align-items: center;

        .top1,
        .top2 {
          flex: 1;
        }

        .top1 {
          font-size: 15px;
          display: flex;
          justify-content: center;
        }

        .top2 {
          color: blue;
          display: flex;
          flex-direction: row;

          span {
            font-size: 26px;
          }

          b {
            font-size: 16px;
          }

          display: flex;
          margin-top: 10px;
          align-items: center;
        }
      }

      .name:last-child {
        border: none;
      }
    }

    .footername {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;

      span {
        flex: 1;
        color: blue;
        display: flex;
        justify-content: center;
        padding-top: 20px;
      }
    }
  }


  .bottom {
    display: flex;
    flex-direction: column;
    font-size: 16px;

    h4 {
      padding: 20px 0;
    }
  }

  .center,
  .bottom.top {
    flex: 3;

  }

  .footer {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-top: 1px solid gray;
    font-size: 16px;

    li {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      p {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

.listname {
  display: flex;
  flex-direction: row;
  height: 120px;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 20px;
  border: 1px solid gray;
  list-style: none;
  border-radius: 15px;
  font-size: 16px;

  .dl {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 20px;

    p {
      flex: 1;
      display: flex;
      justify-content: center;
      // font-size: 16px;
    }

    p:nth-child(1) {
      color: gray;
      // font-size: 16px;
    }
  }

  .dl:last-child {
    margin-right: 0;
  }
}
</style>